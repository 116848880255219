const intialState = {
    draft: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ],
    opencall: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ],
    sponsorship: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ],
    tasks: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ],
    resources: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ],
    promotion: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ],
    elive: [
        "Background",
        "Objective / Aim",
        "Target Audience",
        "Concept",
        "Agenda",
        "Speaker",
        "Partner & Sponsor"
    ]
}

function ForumState(state = intialState, action) {
    switch (action.type) {
        default: {
            return state
        }
    }
}

export default ForumState
