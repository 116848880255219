export const convertYoutubeLink = (object, key) => {
    if (object) {
        let result = ''
        let dataObject = object?.[key]
        if (dataObject) {
            let link = dataObject.split('?')
            if (dataObject.includes('/playlist')) {
                let target = ''
                const link2 = link[1].split('&')
                link2.map(link=>{
                    const link2 = link.split('=')
                    if (link2[0] === 'list') return target = link2[1]
                })
                result = `https://www.youtube.com/embed/videoseries?list=${target}`
            } else if (dataObject.includes('/watch')) {
                let target = ''
                const link2 = link[1].split('&')
                link2.map(link=>{
                    const link2 = link.split('=')
                    if (link2[0] === 'v') return target = link2[1]
                })
                result = `https://www.youtube.com/embed/${target}`
            }
        }
        return result
    }
}