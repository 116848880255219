const bootstrap = require('bootstrap');
import React,{ useCallback, useEffect, useRef, useState } from 'react';
import $ from 'jquery'
import '../../styles/people.scss'
import NavbarTop from '../../components/TopNavbar'
import BottomNavbar from '../../components/BottomNavbar'
import DetailUser from '../../components/DetailUser'
import Chat from '../../components/ChatFirebase'
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons'
import { getAttendeesPeople } from '../../store/actions/event'
import NoProfile from '../../images/no-profile.png'
import LoadingGif from '../../images/loading-livelife.gif'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import { useCookies } from 'react-cookie';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

firebase.initializeApp({
    apiKey: "AIzaSyBorJDNVFAjnyK8eGTgMP2WOl59-2Au1a8",
    authDomain: "elivebylivelife.firebaseapp.com",
    projectId: "elivebylivelife",
    storageBucket: "elivebylivelife.appspot.com",
    messagingSenderId: "1019869524899",
    appId: "1:1019869524899:web:bce3c9f5697f29ac976016",
    measurementId: "G-6R1V7NBF6F"
});
const dbchats = firebase.firestore()
let unsubscribe;

const People = (props) => {
    const { history } = props
    const [cookies, setCookie, removeCookie] = useCookies(['account']);
    const { addToast } = useToasts()
    const NavigationOwlPeople = useRef();
    const Dispatch = useDispatch()
    const [attendess, setAttendees] = useState([])
    const [attendessSearch, setAttendeesSearch] = useState([])
    const [attendessLoad, setAttendeesLoad] = useState(false)
    const [parameterAttendees, setParameterAttendees] = useState({
        sort: 'default',
        limitter: false,
        limitterSave: null,
        keywordLoad: false,
        keyword : null,
        id_idea : cookies['event'],
        id_user : null,
        id_status : 6,
        limit : 12,
        offsetSave: null,
        offset : '0',
        order_by: 'fullname ASC',
        join_as: '',
        var_join_as: '',
        not_id_user: cookies['account'],
        is_checkedin: 1
    })
    const [debouncedSearch, setDebouncedSearch] = useState(parameterAttendees.keyword);
    const [personSelect, setPersonSelect] = useState(null)
    const [personSelectChat, setPersonSelectChat] = useState(null)
    const LastPeopleRef = useRef();
    const LastPeopleAttendee = useCallback(node=>{
        if (attendessLoad) return
        if (parameterAttendees.limitter) return 
        if (LastPeopleRef.current) LastPeopleRef.current.disconnect()
        LastPeopleRef.current = new IntersectionObserver(entries=>{
            if (entries[0].isIntersecting) {
                let offset = Number(parameterAttendees.offset)
                let limit = Number(parameterAttendees.limit)
                let counting = offset += limit
                setParameterAttendees({
                    ...parameterAttendees,
                    offset: counting
                })
            }
        })
        if (node) LastPeopleRef.current.observe(node)
    },[attendessLoad]);

    // useEffect(()=>{
    //     if (history.location.state) {
    //         const { attendee, speaker } = history.location.state
    //         if (attendee) {
    //             let element = document.getElementById('attend')
    //             let elementHide = document.getElementById('speaker')
    //             let elementTab = document.getElementById('attend-tab')
    //             let elementTabHide = document.getElementById('speaker-tab')
    //             element.classList.add('show')
    //             element.classList.add('active')
    //             elementTab.classList.add('active')
    //             elementHide.classList.remove('show')
    //             elementHide.classList.remove('active')
    //             elementTabHide.classList.remove('active')
    //         }
    //         if (speaker) {
    //             let element = document.getElementById('speaker')
    //             let elementHide = document.getElementById('attend')
    //             let elementTab = document.getElementById('speaker-tab')
    //             let elementTabHide = document.getElementById('attend-tab')
    //             element.classList.add('show')
    //             element.classList.add('active')
    //             elementTab.classList.add('active')
    //             elementHide.classList.remove('show')
    //             elementHide.classList.remove('active')
    //             elementTabHide.classList.remove('active')
    //         }
    //     }
    // },[history.location])

    useEffect(()=>{
        $(".chatBtn-1312").click(function(){
            var myAlert =document.getElementById('chatForm');
            var bsAlert = new bootstrap.Toast(myAlert);
            // bsAlert.show();
        })
    },[])

    useEffect(()=>{
        if (parameterAttendees.limitter) return 
        if (!parameterAttendees.limitter) setAttendeesLoad(true)
        Dispatch(getAttendeesPeople(parameterAttendees,(status, data)=>{
            if (status) {
                if (parameterAttendees.keyword) setAttendeesSearch(prev=> [...prev, ...data])
                else setAttendees(prev=> [...prev, ...data])
                if (data.length < parameterAttendees.limit) {
                    setParameterAttendees({
                        ...parameterAttendees,
                        limitter: true
                    })
                }
                setAttendeesLoad(false)
            } else if (!status){
                addToast('Something Problem...', {
                    appearance: 'warning',
                    autoDismiss: true,
                })
            }
        }))
    },[parameterAttendees.offset, parameterAttendees.keywordLoad === true])

    useEffect(()=>{
        const timer = setTimeout(() => {
            if (debouncedSearch !== null) {
                setParameterAttendees({
                    ...parameterAttendees,
                    keyword: debouncedSearch,
                })
            }
        }, 1000);
        return () => clearTimeout(timer);
    },[debouncedSearch])

    useEffect(()=>{
        if (parameterAttendees.keyword) {
            setAttendeesSearch([])
            setParameterAttendees({
                ...parameterAttendees,
                offsetSave: parameterAttendees.offsetSave ? parameterAttendees.offsetSave : parameterAttendees.offset,
                offset: '0',
                limitterSave: parameterAttendees.limitterSave ? parameterAttendees.limitterSave : parameterAttendees.limitter,
                limitter: false,
                keywordLoad: !parameterAttendees.keywordLoad
            })
        } else if (parameterAttendees.keyword === '') {
            setParameterAttendees({
                ...parameterAttendees,
                offsetSave: null,
                offset: parameterAttendees.offsetSave,
                limitter: parameterAttendees.limitterSave,
                keywordLoad: false
            })
        }
    },[parameterAttendees.keyword])

    useEffect(()=>{
        if (document.getElementById('search')) {
            if ($('#search').val()) {
                document.getElementById('search').classList.add('active')
            } else {
                document.getElementById('search').classList.remove('active')
            }
        }
    },[$('#search').val()])

    const searchHandler = (e) => {
        if (e) e.preventDefault()
        if (document.getElementById('search')) {
            if (document.getElementById('search').classList.contains('active')) {
                document.getElementById('search').classList.remove('active')
            }
        }
    }

    // const handleOwlNav = (e, ops, pos) => {
    //     if(e) e.preventDefault()
    //     if (pos === 'people') {
    //         if (ops === 'prev') {
    //             NavigationOwlPeople.current.prev(500)
    //         } else if (ops === 'next') {
    //             NavigationOwlPeople.current.next(500)
    //         }
    //     }
    // }

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const handleFilter = (key, value) => {
        switch (key) {
            case 'sort':
                setParameterAttendees({
                    ...parameterAttendees,
                    sort: value
                })
                break;
            case 'keyword':
                setDebouncedSearch(value)
                break;
            default:
                break;
        }
    }

    return (
        <div id="people-87612">
            <div className="bg-homepage"/>
            <NavbarTop ChatPeople={unsubscribe}/>
                
            <section className="page-wrapper contentHeaderDetail pt-8">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className="headContent">
                                <div className="titleHead">
                                    <h2>People</h2>
                                </div>
                                <div className="customViewAll">
                                    <div className="filterRightColumn">
                                        <div className="position-relative float-right" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Search people">
                                            <div id="wrap">
                                                <input
                                                    id="search"
                                                    name="search"
                                                    type="text"
                                                    placeholder="What're we looking for ?"
                                                    onChange={e=> handleFilter('keyword', e.target.value)}
                                                    value={debouncedSearch}
                                                />
                                                <input id="search_submit" type="submit" onClick={searchHandler}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-12">
                            <div className="subHeadContent">
                                <ul className="nav nav-tabs speakerTabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="speaker-tab" data-bs-toggle="tab" data-bs-target="#speaker" type="button" role="tab" aria-controls="speaker" aria-selected="true">Speakers</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="attend-tab" data-bs-toggle="tab" data-bs-target="#attend" type="button" role="tab" aria-controls="attend" aria-selected="false">Attendees</button>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <div className="page-wrapper tab-content">
                {/* <div className="tab-pane fade" id="speaker" role="tabpanel" aria-labelledby="speaker-tab">
                    <section className="pb-5">
                        <div className="container">
                            <div className="contentPeople">
                                <div className="row">
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="a" className="imgPeopleBorder" >
                                                    <img style={{ cursor: 'pointer' }} id="q" src={ImageMenot} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople" style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Pranoto Bin Supra...</h4>
                                                <p>Founder & Tech Evang...</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder" >
                                                    <img style={{ cursor: 'pointer' }} src={ImageHaris} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Haris Riswandi</h4>
                                                <p>Head of Product at Liv...</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <img style={{ cursor: 'pointer' }} src={ImageHaykal} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Haykal Muhamad</h4>
                                                <p>SPV Marketing</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <img style={{ cursor: 'pointer' }} src={ImageKhalif} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>M. Khalif</h4>
                                                <p>Front-End Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <img style={{ cursor: 'pointer' }} src={ImageKuya} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Haris Anhar</h4>
                                                <p>CEO PT. Kretek</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="a" className="imgPeopleBorder">
                                                    <img style={{ cursor: 'pointer' }} id="q" src={ImageMenot} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Pranoto Bin Supra...</h4>
                                                <p>Founder & Tech Evang...</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="s" className="imgPeopleBorder">
                                                    <img style={{ cursor: 'pointer' }} id="w" src={ImageHaris} className="blur" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer' }} className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Haris Riswandi</h4>
                                                <p>Head of Product at Liv...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> */}
                <div className="tab-pane fade show active" id="attend" role="tabpanel" aria-labelledby="attend-tab">
                    {/* <section className="pt-4">
                        <div className="container">
                            <div className="suggestPeople">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="headContent">
                                            <div className="titleHeadOwl">
                                                <h2>Suggested people for connections</h2>
                                            </div>
                                            <div className="customNavigation">
                                                <span className="viewAll">View all</span>
                                                <a className="btn gray prev" onClick={e=> handleOwlNav(e, 'prev', 'people') }>
                                                    <FontAwesomeIcon icon={faChevronLeft} />
                                                </a>
                                                <a className="btn gray next" onClick={e=> handleOwlNav(e, 'next', 'people') }>
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <OwlCarousel
                                        id="people"
                                        className="owl-carousel"
                                        responsiveClass
                                        responsive={
                                            {
                                                0:{items:2,nav:false},
                                                600:{items:4,nav:false},
                                                1000:{items:6,nav:false,loop:false}
                                            }
                                        }
                                        margin={10}
                                        ref={NavigationOwlPeople}
                                    >
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="a" className="imgPeopleBorder">
                                                    <img id="q" src={ImageMenot} className="blur" style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople" style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                <h4>Pranoto Bin Supra...</h4>
                                                <p>Founder & Tech Evang...</p>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="pt-1">
                        <div className="container">
                            <div className="contentPeopleSort">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="headContent">
                                            <div className="PeopleSort2nd">
                                                <div className="dropdown text-end">
                                                    <span className="btnLight dropdown-toggle" role="button" id="dropSort" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Sort
                                                    </span>
                                                    <ul className="dropdown-menu sortMenu" aria-labelledby="dropSort">
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                onClick={_=> handleFilter('sort', 'default')}
                                                                style={{
                                                                    backgroundColor: parameterAttendees.sort === 'default' && 'var(--blueColor7)',
                                                                    color: parameterAttendees.sort === 'default' && 'var(--blackColor)'
                                                                }}
                                                            >
                                                                Default
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                onClick={_=> handleFilter('sort', 'fullname ASC')}
                                                                style={{
                                                                    backgroundColor: parameterAttendees.sort === 'fullname ASC' && 'var(--blueColor7)',
                                                                    color: parameterAttendees.sort === 'fullname ASC' && 'var(--blackColor)'
                                                                }}
                                                            >
                                                                Name (A-Z)
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                onClick={_=> handleFilter('sort', 'fullname DESC')}
                                                                style={{
                                                                    backgroundColor: parameterAttendees.sort === 'fullname DESC' && 'var(--blueColor7)',
                                                                    color: parameterAttendees.sort === 'fullname DESC' && 'var(--blackColor)'
                                                                }}
                                                            >
                                                                Name (Z-A)
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    {/* <div className="position-relative float-right filterArea text-end">
                                                        <button type="button" title="" className="btn filterBtn">
                                                            <span className="icon">
                                                                <FontAwesomeIcon icon={faSlidersH} style={{ marginRight: 3 }}/>
                                                            </span>
                                                            <span>Filter</span>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pb-5">
                        <div className="container">
                        <div className="contentPeople">
                                <div className="row">
                                    {
                                        (
                                            parameterAttendees.keyword ?
                                            (attendessSearch && attendessSearch)
                                            :
                                            (attendess && attendess)
                                        )
                                        .filter(attendee=>{
                                            if (parameterAttendees.search) {
                                                if (
                                                    (attendee.registrant_info.fullname ? attendee.registrant_info.fullname : '').includes(parameterAttendees.search) ||
                                                    (attendee.registrant_info.profession ? attendee.registrant_info.profession :'').includes(parameterAttendees.search)
                                                ) {
                                                    return attendee
                                                }
                                            } else {
                                                return attendee
                                            }
                                        })
                                        .sort((a,b) =>{
                                            if (parameterAttendees.sort === 'default' || parameterAttendees.sort === 'fullname ASC') {
                                                if((a.registrant_info.fullname ? a.registrant_info.fullname : '').toLowerCase() < (b.registrant_info.fullname ? b.registrant_info.fullname : '').toLowerCase()) { return -1; }
                                                if((a.registrant_info.fullname ? a.registrant_info.fullname : '').toLowerCase() > (b.registrant_info.fullname ? b.registrant_info.fullname : '').toLowerCase()) { return 1; }
                                                return 0;
                                            } else if (parameterAttendees.sort === 'fullname DESC') {
                                                if((a.registrant_info.fullname ? a.registrant_info.fullname : '').toLowerCase() > (b.registrant_info.fullname ? b.registrant_info.fullname : '').toLowerCase()) { return -1; }
                                                if((a.registrant_info.fullname ? a.registrant_info.fullname : '').toLowerCase() < (b.registrant_info.fullname ? b.registrant_info.fullname : '').toLowerCase()) { return 1; }
                                                return 0;
                                            }
                                        })
                                        .map((person,i)=>(
                                            <div className="col-lg-2 mb-4 custom-card-pp" ref={LastPeopleAttendee} onClick={()=>setPersonSelect(person)} key={i.toString()}>
                                                <div className="item" >
                                                    <div className="imgPeople">
                                                        <div id="a" className="imgPeopleBorder" >
                                                            <div style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#usrDetail" className="peopledetail-abs"/>
                                                            <img id="q"
                                                                src={person && person.registrant_info && person.registrant_info.profile_photo}
                                                                className="blur people-pa"
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = NoProfile;
                                                                }}
                                                            />
                                                            <div className="topBooth text-end" >
                                                                <span className="bmBtnCircle">
                                                                    <FontAwesomeIcon icon={faBookmarkRegular} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="descPeople" data-bs-toggle="modal" data-bs-target="#usrDetail">
                                                        <h4>{ person && person.registrant_info && handleLimitString(person.registrant_info.fullname, 17) }</h4>
                                                        <p>{ person && person.registrant_info && handleLimitString(`${person.registrant_info.profession ? person.registrant_info.profession : ''}${person.registrant_info.profession ? ` at ${person.registrant_info.organization}` : ''}`, 20) }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        attendessLoad &&
                                        <div className="col-lg-2 mb-4 loading-card" >
                                            <div className="item loading-item">
                                                <img src={LoadingGif} alt="loading" className="loading-ll-gif"/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>

            <div className="modal fade" id="usrDetail" role="dialog" aria-labelledby="usrDetailLabel">
                <DetailUser person={personSelect} setPersonSelect={setPersonSelect} setPersonSelectChat={setPersonSelectChat}/>
            </div>

            <div id="chatForm" role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-bs-autohide="false">
                <Chat person={personSelectChat} setPersonSelectChat={setPersonSelectChat} dbchats={dbchats} unsubscribe={unsubscribe}/>
            </div>

            <BottomNavbar />
        </div>
    );
};

export default People