import axios from 'axios'
import {
    SERVER
} from '../../api'

export const getHomePageBanner = ({ id }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id ? `&id=${id}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/events/find?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setHomePageBanner = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/events/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const getStage = ({ id }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id ? `&id=${id}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/events/find?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getSession = ({ id_idea, limit, offset, order_by, search }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${limit ? `&limit=${limit}`:''}${offset ? `&offset=${offset}`:''}${order_by ? `&order_by=${order_by}`:''}${search ? `&search=${search}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/activities/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setSession = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/activities/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const setMainStageEvent = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/events/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getArticle = ({ id_status, is_active_elive, limit, offset }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id_status ? `&id_status=${id_status}`:''}${is_active_elive ? `&is_active_elive=${is_active_elive}`:''}${limit ? `&limit=${limit}`:''}${offset ? `&offset=${offset}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/blog/list_berita?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setArticle = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/blog/update_berita?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const getPolling = ({ id_idea, survey_type, is_active, order_by }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${survey_type ? `&survey_type=${survey_type}`:''}${is_active ? `&is_active=${is_active}`:''}${order_by ? `&order_by=${order_by}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/surveys/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setPolling = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/surveys/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const getPartnerSponsorExhibitor = ({ id }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id ? `&id=${id}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/events/find?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setPartnerSponsorExhibitor = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/events/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const uploadImageToRepo = (sendData, { setCustomize, customize , addToast , uri }, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'POST',
            url: uri,
            data: sendData
        })
        console.log(data);
        // if (data.status) {
            // cb(data.status)
        // } else {
            cb(false, null)
        // }
    } catch (error) {
        if (error) {
            console.log(error,'error');
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const getResources = ({ id_idea }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/collaborations/list_resource?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setResource = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/collaborations/update_resource?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}

export const getSpeakerAndTalent = ({ id_idea, status, is_active, is_approved, is_delete, limit, offset, order_by, search }, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        let route = `${(id_idea !== null && id_idea !== undefined) ? `&id_idea=${id_idea}`:''}${(status !== null && status !== undefined) ? `&status=${status}`:''}${(is_active !== null && is_active !== undefined) ? `&is_active=${is_active}`:''}${(is_approved !== null && is_approved !== undefined) ? `&is_approved=${is_approved}`:''}${(is_delete !== null && is_delete !== undefined) ? `&is_delete=${is_delete}`:''}${(limit !== null && limit !== undefined) ? `&limit=${limit}`:''}${(offset !== null && offset !== undefined) ? `&offset=${offset}`:''}${(order_by !== null && order_by !== undefined) ? `&order_by=${order_by}`:''}${(search !== null && search !== undefined) ? `&search=${search}`:''}`
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/partners/list_talent?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.log(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const setSpeakerAndTalent = (sendData, { setCustomize, customize , addToast}, cb) => async dispatch => {
    try {
        setCustomize({
            ...customize,
            loading: true,
            disabled: true,
        })
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/partners/update_talent?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(data.status)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            } else {
                cb(false, 'Network Error')
            }
        }
    }
}