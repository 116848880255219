import React, { memo, useEffect, useState, forwardRef, useImperativeHandle, Fragment, useRef, useCallback } from 'react'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux'
import { topicList, topicUpdate } from '../../../store/actions/topic'
import { commentCreate, commentList, commentUpdate } from '../../../store/actions/comment'
import { checkingUserById } from '../../../store/actions/signUpIn'
import NoProfile from '../../../images/no-profile.png'
import moment from 'moment'
import LoadingGif from '../../../images/loading-livelife.gif'
import { MentionsInput, Mention } from 'react-mentions'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap';

const Content = forwardRef((props, ref) => {
    const {
        profile,
        eventState,
        fetchUsers,
        fetchTopic,
        Topics,
        type,
        topicQuery,
        topic
    } = props
    const [contents, setContents] = useState([])
    const [contentLoad, setContentLoad] = useState(false)
    const [contentLimit, setContentLimit] = useState(false)
    const Dispatch = useDispatch()
    const [commentParams, setCommentParams] = useState({})
    const [replyParams, setReplyParams] = useState({})
    const [contentParamsEdit, setContentEdit] = useState({})
    const [loading, setLoading] = useState({
        content : true,
    })
    const [contentParams, setContentParams] = useState({
        limit: 5,
        offset: "0"
    })

    const handleOpenComment = (key, action) => {
        setCommentParams({
            ...commentParams,
            [key] : action
        })
    }

    const handleValueContent = (key, action) => {
        setContentEdit({
            ...contentParamsEdit,
            [key] : action
        })
    }

    const handleValueReply = (key, action) => {
        setReplyParams({
            ...replyParams,
            [key] : action
        })
    }

    const handleGetComment = (key) => {
        if (commentParams[`limit_${key}`] >= commentParams[`max_limit_${key}`]) {
            setCommentParams({
                ...commentParams,
                [`limit_${key}`] : 'all'
            })
        } else {
            setCommentParams({
                ...commentParams,
                [`limit_${key}`] : commentParams[`limit_${key}`] + 5
            })
        }
    }

    const handleComment = (e, key, id_discussion) => {
        if (e) e.preventDefault()
        if (commentParams[key]) {
            setLoading({
                ...loading,
                [`${id_discussion}`] : true
            })
            Dispatch(commentCreate({
                id_discussion: id_discussion,
                id_user: profile?.account_info?.id,
                comment: commentParams[key],
            },(status)=>{
                if (status) {
                    Dispatch(commentList({
                        id_discussion: id_discussion,
                        // order_by: 'll_list_comment.created DESC',
                    },(status, comment)=>{
                        setContents(prev=>{
                            return prev?.map(content=>{
                                if (content?.id_discussion === id_discussion) {
                                    return {
                                        ...content,
                                        comments: (comment || [])?.map(comment=>({
                                            ...comment?.comment_info
                                        }))
                                    }
                                }
                                return content
                            })
                        })
                        setCommentParams({
                            ...commentParams,
                            [`limit_${id_discussion}`] : commentParams[`limit_${id_discussion}`] !== 'all' ? commentParams[`limit_${id_discussion}`] += 1 : 'all',
                            [`comment_value_${id_discussion}`] : ''
                        })
                        setLoading({
                            ...loading,
                            [`${id_discussion}`] : false
                        })
                    }))
                }
            }))
        }
    }

    const handleReply = (content, comment) => {
        if (replyParams[`comment_open_reply_${comment?.id_discussion_comment}`]) {
            setReplyParams({
                ...replyParams,
                [`comment_open_reply_${comment?.id_discussion_comment}`] : false,
                [`comment_reply_${comment?.id_discussion_comment}`] : '',
            })
        } else {
            setReplyParams({
                ...replyParams,
                [`comment_open_reply_${comment?.id_discussion_comment}`] : true,
                [`comment_reply_${comment?.id_discussion_comment}`] : '',
            })
        }
    }

    const handleContent = (content, key) => {
        if (key === 'update') {
            if (contentParamsEdit[`content_edit_${content?.id_discussion}`]) {
                setContentEdit({
                    ...contentParamsEdit,
                    [`content_edit_${content?.id_discussion}`] : false,
                    [`content_edit_value_${content?.id_discussion}`] : '',
                    [`content_edit_topic_${content?.id_discussion}`] : '',
                })
            } else {
                setContentEdit({
                    ...contentParamsEdit,
                    [`content_edit_${content?.id_discussion}`] : true,
                    [`content_edit_value_${content?.id_discussion}`] : content?.discussion,
                    [`content_edit_topic_${content?.id_discussion}`] : content?.topic,
                })
            }
        } else if (key === 'delete') {
            Dispatch(topicUpdate({
                id_discussion: content?.id_discussion,
                id_idea: content?.id_idea,
                id_user: content?.id_user?.id_user,
                is_delete: 1
            },(status)=>{
                if (status) {
                    setContents(contents?.map(contentPrev=>{
                        if (contentPrev?.id_discussion !== content?.id_discussion) {
                            return contentPrev
                        }
                    }))
                }
            }))
        }
    }

    const handleCommentEdit = (comment, content, key) => {
        if (key === 'update') {
            if (contentParamsEdit[`comment_edit_${comment?.id_discussion_comment}`]) {
                setContentEdit({
                    ...contentParamsEdit,
                    [`comment_edit_${comment?.id_discussion_comment}`] : false,
                    [`comment_edit_value_${comment?.id_discussion_comment}`] : '',
                })
            } else {
                setContentEdit({
                    ...contentParamsEdit,
                    [`comment_edit_${comment?.id_discussion_comment}`] : true,
                    [`comment_edit_value_${comment?.id_discussion_comment}`] : comment?.comment,
                })
            }
        } else if (key === 'delete') {
            Dispatch(commentUpdate({
                id_discussion_comment: comment?.id_discussion_comment,
                id_discussion: comment?.id_discussion,
                id_idea: content?.id_idea,
                id_user: comment?.id_user,
                is_delete: 1
            },(status)=>{
                if (status) {
                    setContents(contents?.map(contentPrev=>{
                        if (contentPrev?.id_discussion === content?.id_discussion) {
                            return {
                                ...contentPrev,
                                comments: contentPrev?.comments?.map(commentPrev=>{
                                    if (commentPrev?.id_discussion_comment !== comment?.id_discussion_comment) {
                                        return commentPrev
                                    }
                                })
                            }
                        }
                        return contentPrev
                    }))
                }
            }))
        }
    }

    const handleEditContent = (content) => {
        if (contentParamsEdit[`content_edit_value_${content?.id_discussion}`]) {
            Dispatch(topicUpdate({
                id_discussion: content?.id_discussion,
                id_idea: content?.id_idea,
                id_user: content?.id_user?.id_user,
                topic: contentParamsEdit[`content_edit_topic_${content?.id_discussion}`],
                type: type,
                discussion: contentParamsEdit[`content_edit_value_${content?.id_discussion}`]
            },(status)=>{
                if (status) {
                    setContents(contents?.map(contentPrev=>{
                        if (contentPrev?.id_discussion === content?.id_discussion) {
                            return {
                                ...contentPrev,
                                topic: contentParamsEdit[`content_edit_topic_${content?.id_discussion}`],
                                discussion: contentParamsEdit[`content_edit_value_${content?.id_discussion}`]
                            }
                        }
                        return contentPrev
                    }))
                    setContentEdit({
                        ...contentParamsEdit,
                        [`content_edit_${content?.id_discussion}`] : false,
                        [`content_edit_value_${content?.id_discussion}`] : '',
                        [`content_edit_topic_${content?.id_discussion}`] : '',
                    })
                }
            }))
        }
    }

    const handleEditComment = (e, comment, content) => {
        if (e) e.preventDefault()
        if (contentParamsEdit[`comment_edit_value_${comment?.id_discussion_comment}`]) {
            Dispatch(commentUpdate({
                id_discussion_comment: comment?.id_discussion_comment,
                id_discussion: comment?.id_discussion,
                id_idea: content?.id_idea,
                id_user: comment?.id_user,
                comment: contentParamsEdit[`comment_edit_value_${comment?.id_discussion_comment}`]
            },(status)=>{
                if (status) {
                    setContents(contents?.map(contentPrev=>{
                        if (contentPrev?.id_discussion === content?.id_discussion) {
                            return {
                                ...contentPrev,
                                comments: contentPrev?.comments?.map(commentPrev=>{
                                    if (commentPrev?.id_discussion_comment === comment?.id_discussion_comment) {
                                        return {
                                            ...commentPrev,
                                            comment: contentParamsEdit[`comment_edit_value_${comment?.id_discussion_comment}`]
                                        }
                                    }
                                    return commentPrev
                                })
                            }
                        }
                        return contentPrev
                    }))
                    setContentEdit({
                        ...contentParamsEdit,
                        [`comment_edit_${comment?.id_discussion_comment}`] : false,
                        [`comment_edit_value_${comment?.id_discussion_comment}`] : '',
                    })
                }
            }))
        }
    }

    const handleSubmitReply = (e, key, id_comment , id_discussion) => {
        if (e) e.preventDefault()
        if (replyParams[key]) {
            setLoading({
                ...loading,
                [`${id_discussion}`] : true
            })
            Dispatch(commentCreate({
                id_discussion: id_discussion,
                id_user: profile?.account_info?.id,
                comment: `${id_comment}|${replyParams[key]}`,
            },(status)=>{
                if (status) {
                    Dispatch(commentList({
                        id_discussion: id_discussion,
                        // order_by: 'll_list_comment.created DESC',
                    },(status, comment)=>{
                        setContents(prev=>{
                            return prev?.map(content=>{
                                if (content?.id_discussion === id_discussion) {
                                    return {
                                        ...content,
                                        comments: (comment || [])?.map(comment=>({
                                            ...comment?.comment_info
                                        }))
                                    }
                                }
                                return content
                            })
                        })
                        setCommentParams({
                            ...commentParams,
                            [`limit_${id_discussion}`] : commentParams[`limit_${id_discussion}`] !== 'all' ? commentParams[`limit_${id_discussion}`] += 1 : 'all'
                        })
                        setReplyParams({
                            ...replyParams,
                            [`comment_open_reply_${comment?.id_discussion_comment}`] : false,
                            [`comment_reply_${comment?.id_discussion_comment}`] : '',
                        })
                        setLoading({
                            ...loading,
                            [`${id_discussion}`] : false
                        })
                    }))
                }
            }))
        }
    }

    useEffect(()=>{
        (async()=>{
            try {
                if (eventState && profile) {
                    await Dispatch(topicList({
                        id_idea: eventState?.event_info?.id_idea,
                        // id_user: profile?.account_info?.id,
                        topic: topicQuery ? topic : null,
                        type: type,
                        order_by: 'll_discussions.created DESC',
                        group_by: null,
                        ...contentParams
                    },(status, data)=>{
                        if (status) {
                            if (!data.length || data.length < contentParams.limit) {
                                setContentLimit(true)
                                setLoading({
                                    ...loading,
                                    content: false,
                                })
                                setContentLoad(false)
                            }
                            const contentsTemp = []
                            let contentsLength = 0
                            const paramsComment = {}
                            data?.map(dt=>{
                                if (dt) {
                                    Dispatch(checkingUserById(dt?.discussion_info?.id_user, (_, user)=>{
                                        Dispatch(commentList({
                                            id_discussion: dt?.discussion_info?.id_discussion,
                                            is_delete: false
                                            // order_by: 'll_list_comment.created DESC',
                                        },(status, comment)=>{
                                            contentsLength++
                                            if (contents?.findIndex(a=> a?.id_discussion === dt?.discussion_info?.id_discussion) === -1) {
                                                contentsTemp.push({
                                                    ...dt?.discussion_info,
                                                    id_user: user?.registrant_info || dt?.discussion_info?.id_user,
                                                    comments: (comment || [])?.map(comment=>({
                                                        ...comment?.comment_info
                                                    })),
                                                })
                                            }
                                            paramsComment[`limit_${dt?.discussion_info?.id_discussion}`] = comment?.length >= 5 ? 5 : 'all'
                                            paramsComment[`max_limit_${dt?.discussion_info?.id_discussion}`] = comment?.length
                                            if (contentsLength === data.length) {
                                                setCommentParams({
                                                    ...commentParams,
                                                    ...paramsComment
                                                })
                                                setContents([
                                                    ...contents,
                                                    ...contentsTemp
                                                ])
                                                setLoading({
                                                    ...loading,
                                                    content: false,
                                                })
                                                setContentLoad(false)
                                            }
                                        }))
                                    }))
                                }
                            })
                        } else {
                            setCommentParams({})
                            setContents([])
                            setLoading({
                                ...loading,
                                content: false,
                            })
                            setContentLoad(false)
                        }
                    }))
                }
            } catch (error) {
                console.error(error)
            }
        })()
    },[eventState, profile, contentParams])

    useImperativeHandle(ref, () => ({
        refresh(eventState, profile) {
            if (eventState && profile) {
                setLoading({
                    ...loading,
                    content: true,
                })
                Dispatch(topicList({
                    id_idea: eventState?.event_info?.id_idea,
                    // id_user: profile?.account_info?.id,
                    topic: topicQuery ? topic : null,
                    type: type,
                    order_by: 'll_discussions.created DESC',
                    group_by: null,
                    limit: 1,
                    offset: '0'
                },(status, data)=>{
                    if (status) {
                        const contentsTemp = []
                        const paramsComment = {}
                        data?.map(dt=>{
                            Dispatch(checkingUserById(dt?.discussion_info?.id_user, (_, user)=>{
                                contentsTemp.push({
                                    ...dt?.discussion_info,
                                    id_user: user?.registrant_info || dt?.discussion_info?.id_user,
                                    comments: [],
                                })
                                paramsComment[`limit_${dt?.discussion_info?.id_discussion}`] = 'all'
                                paramsComment[`max_limit_${dt?.discussion_info?.id_discussion}`] = 0
                                setCommentParams({
                                    ...commentParams,
                                    ...paramsComment
                                })
                                setContents([
                                    ...contentsTemp,
                                    ...contents
                                ])
                                setLoading({
                                    ...loading,
                                    content: false,
                                })
                            }))
                        })
                    }
                }))
            }
        }
    }));

    useEffect(()=>{
        let animateReactionsOn;
        let animateReactionsOut;

        $(function() {
            $(".options").mouseenter(function() {
                let parent = $(this)

                animateReactionsOn = setTimeout(function() {
                    parent.find(".reaction").each(function(index) {
                        let element = $(this)
                        setTimeout(function() {
                            element.addClass("is-visible");
                        }, index * 60);
                    });
                }, 500);

                clearTimeout(animateReactionsOut);
            });

            $(".options").mouseleave(function() {
                let parent = $(this)

                animateReactionsOut = setTimeout(function() {
                    parent.removeClass("active");
                    parent.find(".reaction").removeClass("is-visible");
                }, 500);

                clearTimeout(animateReactionsOn);
            });

            let dataPopupOld = null;
            let dataPopupNew = null;


            $(".options .button").click(function() {
                let children = $(this)
                let parent = $(this).parent()

                if ($(this).parent().is(".Like, .Love, .Thankful, .Haha, .Wow, .Sad, .Angry")) {
                    parent.removeClass("Like Love Thankful Haha Wow Sad Angry");
                    parent.addClass("default");
                    parent.find(".button").text("Like");
                } else {
                    parent.addClass("Like");
                }

                if ($(this).parent().hasClass("default")) {
                    $(this).addClass("Like");
                }
            });


            $(".reaction").click(function() {
                dataPopupNew = $(this).attr("data-popup");

                $(".options").removeClass("default");

                $(".options .button").text(dataPopupNew);

                $('.options:contains(Like)').css('color', 'rgb(88, 144, 255)');
                $('.options:contains(Love)').css('color', 'rgb(242, 82, 104)');
                $('.options:contains(Thankful)').css('color', 'rgb(157, 135, 210)');
                $('.options:contains(Haha), .options:contains(Wow), .options:contains(Sad)').css('color', 'rgb(240, 186, 21)');
                $('.options:contains(Angry)').css('color', 'rgb(247, 113, 75)');

                $(".options").removeClass(dataPopupOld);
                $(".options").addClass(dataPopupNew);

                dataPopupOld = dataPopupNew
            });
        });
        $("textarea").each(function () {
            this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
        }).on("input", function () {
            this.style.height = "auto";
            this.style.height = (this.scrollHeight) + "px";
        });
    },[])

    const LastContentRef = useRef();
    const LastContentCall = useCallback(node=>{
        if (loading.content) return
        if (contentLimit) return
        if (contentLoad) return
        if (LastContentRef.current) LastContentRef.current.disconnect()
        LastContentRef.current = new IntersectionObserver(entries=>{
            if (entries[0].isIntersecting) {
                setContentParams({
                    ...contentParams,
                    offset: Number(contentParams?.offset) + Number(contentParams?.limit)
                })
                setContentLoad(true)
            }
        })
        if (node) LastContentRef.current.observe(node)
    },[contentLoad, contentLimit, loading.content]);

    return (
        <Fragment>
            {
                loading.content &&
                <div className="item loading-item">
                    <img src={LoadingGif} alt="loading" className="loading-ll-gif"/>
                </div>
            }
            {
                contents?.sort((a,b) => new Date(b?.created) - new Date(a?.created))?.map(content=>(
                    <div className="boxPost borderContent mb-4" key={`content_${content?.id_discussion}`} ref={LastContentCall}>
                        <div className="postHead">
                            <div className="row align-items-center">
                                <div className="col-1">
                                    <div className="profPic">
                                        <img
                                            src={content?.id_user?.profile_photo}
                                            alt="LiveLife User"
                                            className="mb-3"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NoProfile;
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="profName">
                                        <span style={{ cursor: 'pointer' }} >{ content?.id_user?.fullname }</span>
                                        <p className="timePost"> {moment(content?.created).fromNow()}. {content?.id_user?.live_in}</p>
                                    </div>
                                </div>
                                <div className="col-1 text-end">
                                    {
                                        profile?.account_info?.email === content?.email &&
                                        <Dropdown
                                            align={{ lg: 'end' }}
                                        >
                                            <Dropdown.Toggle id="dropdown-basic" className="option-forum" >
                                                <FontAwesomeIcon className="i" icon={faEllipsisH} style={{ cursor: 'pointer' }} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='option-menu-forum' >
                                                <Dropdown.Item onClick={_=> handleContent(content, 'update') } >Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={_=> handleContent(content, 'delete') }>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="postContent" style={{ height: contentParamsEdit[`content_edit_${content?.id_discussion}`] && 100 }} >
                            {
                                contentParamsEdit[`content_edit_${content?.id_discussion}`] ?
                                <Fragment>
                                    <div className="from-group">
                                        <MentionsInput
                                            value={contentParamsEdit[`content_edit_value_${content?.id_discussion}`]}
                                            onChange={e=> handleValueContent(`content_edit_value_${content?.id_discussion}`, e.target.value)}
                                            style={{
                                                fontSize: 12
                                            }}
                                            className="mention-textarea"
                                            placeholder="Share what's on your mind?"
                                        >
                                            <Mention
                                                displayTransform={(_, display) => `@${display} ` }
                                                trigger="@"
                                                markup="@__display__ "
                                                data={fetchUsers}
                                                renderSuggestion={(suggest)=>(
                                                    <div className="mention-textarea">
                                                        <img
                                                            src={suggest?.profile_photo}
                                                            alt="image"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = NoProfile;
                                                            }}
                                                        />
                                                        <span>  { suggest?.display } </span>
                                                    </div>
                                                )}
                                            />
                                            <Mention
                                                displayTransform={(_, display) => `#${display} ` }
                                                trigger="#"
                                                markup="#__display__ "
                                                data={fetchTopic}
                                                renderSuggestion={(suggest)=>(
                                                    <div className="mention-textarea">
                                                        <span># { suggest?.display } </span>
                                                    </div>
                                                )}
                                            />
                                        </MentionsInput>
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <textarea
                                        disabled
                                        value={content?.discussion}
                                    />
                                    {
                                        content?.topic &&
                                        <div className="hashtag"> { content?.topic } </div>
                                    }
                                </Fragment>
                            }
                        </div>
                        {
                            contentParamsEdit[`content_edit_${content?.id_discussion}`] &&
                            <div className="postContent" >
                                <div className="d-flex justify-content-end">
                                    <div className="me-auto mt-2">
                                        <select className="btnLight"
                                            value={contentParamsEdit[`content_edit_topic_${content?.id_discussion}`]}
                                            onChange={e=> handleValueContent(`content_edit_topic_${content?.id_discussion}`, e.target.value)}
                                        >
                                            <option value={''} >Select Topic</option>
                                            {
                                                Topics?.map(topic=>(
                                                    <option value={topic} >#{topic}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="from-group mt-2">
                                        <span className="btnLight" onClick={_=> handleContent(content, 'update') }>
                                            Cancel
                                        </span>
                                        <span className="btnLight" onClick={_=>handleEditContent(content)}>
                                            Update
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="postTool"
                            style={{
                                borderBottomLeftRadius: (content?.comments?.length || commentParams[`comment${content?.id_discussion}`]) ? 0 : '.5rem',
                                borderBottomRightRadius : (content?.comments?.length || commentParams[`comment${content?.id_discussion}`]) ? 0 : '.5rem',
                            }}
                        >
                            <div className="row align-items-center px-3 py-1">
                                <div className="col-10">
                                    {/* <div className="reactAct"> */}
                                        {/* <span className="reactLike"></span> */}
                                        {/* <!--<span className="reactLove"></span>--> */}
                                        {/* <span className="reactHuge"></span> */}
                                        {/* <!--<span className="reactCrying"></span> */}
                                        {/* <span className="reactAngry"></span>--> */}
                                        {/* <span className="reactLaugh"></span> */}
                                        {/* <!--<span className="reactSurprise"></span>--> */}
                                        {/* <span className="reactWho">Pranoto, Michael and 11 others</span> */}
                                    {/* </div> */}
                                </div>
                                <div className="col-2">
                                    <div className="commentSum text-end">
                                        <span style={{ cursor: 'pointer' }} >{content?.comments?.length < 1 ? `${content?.comments?.length} Comment` : `${content?.comments?.length} Comments`}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="postToolAction">
                                <ul className="nav nav-pills nav-fill">
                                    {/* <li className="nav-item">
                                        <div className="nav-link options">
                                            <span className="reactions">
                                                <div className="icon-container">
                                                    <span data-popup="Like" className="like reaction"></span>
                                                </div>
                                                <div className="icon-container">
                                                    <span data-popup="Love" className="love reaction"></span>
                                                </div>
                                                <div className="icon-container">
                                                    <span data-popup="Thankful" className="thankful reaction"></span>
                                                </div>
                                                <div className="icon-container">
                                                    <span data-popup="Haha" className="haha reaction"></span>
                                                </div>
                                                <div className="icon-container">
                                                    <span data-popup="Wow" className="wow reaction"></span>
                                                </div>
                                                <div className="icon-container">
                                                    <span data-popup="Sad" className="sad reaction"></span>
                                                </div>
                                                <div className="icon-container">
                                                    <span data-popup="Angry" className="angry reaction"></span>
                                                </div>
                                            </span>
                                            <FontAwesomeIcon className="i" icon={faThumbsUpRegular} /> <span className="button">Like</span>
                                        </div>
                                    </li> */}
                                    <li className="nav-item">
                                        <div className="nav-link" style={{ cursor: 'pointer' }} onClick={()=> handleOpenComment(`comment${content?.id_discussion}`, !commentParams[`comment${content?.id_discussion}`])}>
                                            <FontAwesomeIcon className="i" icon={faComment} /> Write a comment
                                        </div>
                                    </li>
                                    {/* <li className="nav-item">
                                        <div className="nav-link" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon className="i" icon={faShareSquare} /> Share
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        {
                            commentParams[`comment${content?.id_discussion}`] &&
                            <form onSubmit={(e)=>handleComment(e,`comment_value_${content?.id_discussion}`, content?.id_discussion)} >
                                <div className="postFooter">
                                    <div className="chat-footers d-flex align-items-center">
                                        <div className="flex-grow-1 pe-2">
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <FontAwesomeIcon icon={faComment} />
                                                </span>
                                                <MentionsInput
                                                    value={commentParams[`comment_value_${content?.id_discussion}`]}
                                                    onChange={e=> handleOpenComment(`comment_value_${content?.id_discussion}`, e.target.value)}
                                                    className="mention-content"
                                                    placeholder="Type a comment"
                                                    singleLine
                                                >
                                                    <Mention
                                                        displayTransform={(_, display) => `@${display} ` }
                                                        trigger="@"
                                                        markup="@__display__ "
                                                        data={fetchUsers}
                                                        renderSuggestion={(suggest)=>(
                                                            <div className="mention-content">
                                                                <img
                                                                    src={suggest?.profile_photo}
                                                                    alt="image"
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = NoProfile;
                                                                    }}
                                                                />
                                                                <span>  { suggest?.display } </span>
                                                            </div>
                                                        )}
                                                    />
                                                    <Mention
                                                        displayTransform={(_, display) => `#${display} ` }
                                                        trigger="#"
                                                        markup="#__display__ "
                                                        data={fetchTopic}
                                                        renderSuggestion={(suggest)=>(
                                                            <div className="mention-content">
                                                                <span># { suggest?.display } </span>
                                                            </div>
                                                        )}
                                                    />
                                                </MentionsInput>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                        {
                            content?.comments?.length ?
                            <div className="columnComment">
                                {
                                    loading[`${content?.id_discussion}`] &&
                                    <div className="loading-item">
                                        <img src={LoadingGif} alt="loading" className="loading-ll-gif-small"/>
                                    </div>
                                }
                                {
                                    content?.comments?.sort((a,b)=> b.id_discussion_comment - a.id_discussion_comment)?.slice(0, commentParams[`limit_${content?.id_discussion}`] !== 'all' ? commentParams[`limit_${content?.id_discussion}`] : Infinity)?.map(comment=>(
                                        <div key={`comment_${comment?.id_discussion_comment}`} className="d-flex" style={{ marginBottom: '1rem' }} >
                                            <img src={NoProfile} className="rounded-circle p-1 border" width="40" height="40" alt="image" />
                                            <div className="flex-grow-1 ms-3">
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    justifyContent: 'space-between'
                                                }} >
                                                    <h5 className="mt-0 link-13124">
                                                        { comment?.fullname }
                                                    </h5>
                                                    {/* {
                                                        profile?.account_info?.email === content?.email &&
                                                        <Dropdown
                                                            align={{ lg: 'end' }}
                                                        >
                                                            <Dropdown.Toggle id="dropdown-basic" className="option-forum" >
                                                                <FontAwesomeIcon className="i" icon={faEllipsisH} style={{ cursor: 'pointer' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='option-menu-forum' >
                                                                {
                                                                    contentParamsEdit[`comment_edit_${comment?.id_discussion_comment}`] ?
                                                                    <Dropdown.Item onClick={_=> handleCommentEdit(comment,content, 'update') }>Cancel</Dropdown.Item>
                                                                    :
                                                                    <Dropdown.Item onClick={_=> handleCommentEdit(comment,content, 'update') } >Edit</Dropdown.Item>
                                                                }
                                                                <Dropdown.Item onClick={_=>handleCommentEdit(comment,content, 'delete')}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    } */}
                                                </div>
                                                {
                                                    contentParamsEdit[`comment_edit_${comment?.id_discussion_comment}`] ?
                                                    <Fragment>
                                                        <form onSubmit={(e)=>handleEditComment(e,comment,content)} >
                                                            <MentionsInput
                                                                value={contentParamsEdit[`comment_edit_value_${comment?.id_discussion_comment}`]}
                                                                onChange={e=> handleValueContent(`comment_edit_value_${comment?.id_discussion_comment}`, e.target.value)}
                                                                className="mention-reply"
                                                                placeholder="Type a comment"
                                                                singleLine
                                                            >
                                                                <Mention
                                                                    displayTransform={(_, display) => `@${display} ` }
                                                                    trigger="@"
                                                                    markup="@__display__ "
                                                                    data={fetchUsers}
                                                                    renderSuggestion={(suggest)=>(
                                                                        <div className="mention-reply">
                                                                            <img
                                                                                src={suggest?.profile_photo}
                                                                                alt="image"
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src = NoProfile;
                                                                                }}
                                                                            />
                                                                            <span>  { suggest?.display } </span>
                                                                        </div>
                                                                    )}
                                                                />
                                                                <Mention
                                                                    displayTransform={(_, display) => `#${display} ` }
                                                                    trigger="#"
                                                                    markup="#__display__ "
                                                                    data={fetchTopic}
                                                                    renderSuggestion={(suggest)=>(
                                                                        <div className="mention-reply">
                                                                            <span># { suggest?.display } </span>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </MentionsInput>
                                                        </form>
                                                    </Fragment>
                                                    :
                                                    <p>{ comment?.comment }</p>
                                                }
                                                {/* <span onClick={_=> handleReply(content, comment)} >Reply</span>
                                                {
                                                    replyParams[`comment_open_reply_${comment?.id_discussion_comment}`] &&
                                                    <form onSubmit={(e)=> handleSubmitReply(e,`comment_reply_${comment?.id_discussion_comment}`, comment?.id_discussion_comment, content?.id_discussion)} >
                                                        <MentionsInput
                                                            value={replyParams[`comment_reply_${comment?.id_discussion_comment}`]}
                                                            onChange={e=> handleValueReply(`comment_reply_${comment?.id_discussion_comment}`, e.target.value)}
                                                            className="mention-reply"
                                                            placeholder="Type a comment"
                                                            singleLine
                                                        >
                                                            <Mention
                                                                displayTransform={(_, display) => `@${display} ` }
                                                                trigger="@"
                                                                markup="@__display__ "
                                                                data={fetchUsers}
                                                                renderSuggestion={(suggest)=>(
                                                                    <div className="mention-reply">
                                                                        <img
                                                                            src={suggest?.profile_photo}
                                                                            alt="image"
                                                                            onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null;
                                                                                currentTarget.src = NoProfile;
                                                                            }}
                                                                        />
                                                                        <span>  { suggest?.display } </span>
                                                                    </div>
                                                                )}
                                                            />
                                                            <Mention
                                                                displayTransform={(_, display) => `#${display} ` }
                                                                trigger="#"
                                                                markup="#__display__ "
                                                                data={fetchTopic}
                                                                renderSuggestion={(suggest)=>(
                                                                    <div className="mention-reply">
                                                                        <span># { suggest?.display } </span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </MentionsInput>
                                                    </form>
                                                }
                                                <div className="d-flex align-items-stretch mt-3">
                                                    <img src={NoProfile} className="mr-3 rounded-circle p-1 border" width="40" height="40" alt="image" />
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="mt-0 link-13124">Haris Riswandi</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    commentParams[`limit_${content?.id_discussion}`] !== 'all' &&
                                    <div className="otherComment" onClick={_=>handleGetComment(content?.id_discussion)}>
                                        <span>See other comments</span>
                                    </div>
                                }
                            </div>
                            :''
                        }
                    </div>
                ))
            }
            {
                contentLoad &&
                <div className="item loading-item">
                    <img src={LoadingGif} alt="loading" className="loading-ll-gif"/>
                </div>
            }
            <style jsx global>
                {`
                    .dropdown-toggle::after {
                        display: none;
                    }
                `}
            </style>
        </Fragment>
    )
})

export default memo(Content);