const bootstrap = require('bootstrap');
import React,{ useState, useEffect } from 'react';
import $ from 'jquery'
import '../styles/booth.scss'
import NavbarTop from '../components/TopNavbar'
import BottomNavbar from '../components/BottomNavbar'
import PopOverCustom from '../components/popOver'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkRegular, faClipboard as faClipboardRegular, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPaperPlane, faSlidersH, faBookmark, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Modal, Tooltip, Toast, ToastContainer, Alert } from 'react-bootstrap'

import LLLogo from '../images/livelife-logo.jpg'
import LLLogoSquare from '../images/ll.jpg'
import Imagebg1 from '../images/bg1.jpg'
import Imagebg2 from '../images/bg2.jpg'
import Imagebg3 from '../images/bg3.jpg'
import ImageKuya from '../images/kuya.jpg'
import ImageKhalif from '../images/khalif.jpg'
import ImageMenot from '../images/menot.jpg'
import ImageHaris from '../images/haris.jpg'
import ImageHaykal from '../images/haykal.jpg'

const Booth = () => {
    const History = useHistory()
    const [modalChatPerson, setModalChatPerson] = useState(false);
    const [openNote, setOpenNote] = useState(false);
    const [value, setValue] = useState({
        note: ''
    });

    useEffect(()=>{
        var input = $("#takeaNote");
        if (input) {
            if (input[0]) {
                var len = input.val().length;
                input[0].focus();
                input[0].setSelectionRange(len, len);
            }
        }
    })

    useEffect(()=>{
        $(".chatBtn-1312").click(function(){
            var myAlert =document.getElementById('chatForm');
            var bsAlert = new bootstrap.Toast(myAlert);
            bsAlert.show();
            setModalChatPerson(false)
        })
    },[modalChatPerson])

    const changeValue = (e, key, val) => {
        if (e) e.preventDefault()
        switch (key) {
            case 'note':
                setValue({
                    note: val
                })
                break;
            default:
                break;
        }
    }

    const handlerModalChatPerson = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModalChatPerson(value)
        } else {
            setModalChatPerson(value)
        }
    }

    const handlerModalNote = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setOpenNote(value)
            setValue({
                note: ''
            })
        } else {
            setOpenNote(value)
        }
    }

    const ModalChatPerson = (props) => {
        const {
            onHide
        } = props
        return(
            <div className="modal-content">
                <div className="modal-header modalNormalize">
                    <h2>Choose a person to chat</h2>
                    <div className="closeModal">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide} />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="modalContentText">
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageKhalif}/>
                                    <div className="listSpeakerDetail">
                                        <h4>M. Khalifurrahman</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <button type="button" className="BtnCircle chatBtn-1312">
                                            <FontAwesomeIcon icon={faPaperPlane} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle"
                                            onClick={e => handlerModalNote(e,true)}
                                        >
                                            <FontAwesomeIcon icon={faClipboardRegular} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle">
                                            <FontAwesomeIcon icon={faBookmarkRegular} className="i-313123"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageHaris}/>
                                    <div className="listSpeakerDetail">
                                        <h4>Haris Riswandi</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <button type="button" className="BtnCircle chatBtn-1312">
                                            <FontAwesomeIcon icon={faPaperPlane} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle"
                                            onClick={e => handlerModalNote(e,true)}
                                        >
                                            <FontAwesomeIcon icon={faClipboardRegular} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle active">
                                            <FontAwesomeIcon icon={faBookmark} className="i-313123"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageMenot}/>
                                    <div className="listSpeakerDetail">
                                        <h4>Pranoto</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <button type="button" className="BtnCircle chatBtn-1312">
                                            <FontAwesomeIcon icon={faPaperPlane} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle active"
                                            onClick={e => handlerModalNote(e,true)}
                                        >
                                            <FontAwesomeIcon icon={faClipboard} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle">
                                            <FontAwesomeIcon icon={faBookmarkRegular} className="i-313123"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageKuya}/>
                                    <div className="listSpeakerDetail">
                                        <h4>Haris Anhar</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <button type="button" className="BtnCircle chatBtn-1312">
                                            <FontAwesomeIcon icon={faPaperPlane} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle"
                                            onClick={e => handlerModalNote(e,true)}
                                        >
                                            <FontAwesomeIcon icon={faClipboardRegular} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle">
                                            <FontAwesomeIcon icon={faBookmarkRegular} className="i-313123"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageHaykal}/>
                                    <div className="listSpeakerDetail">
                                        <h4>M. Haykal</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <button type="button" className="BtnCircle chatBtn-1312">
                                            <FontAwesomeIcon icon={faPaperPlane} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle"
                                            onClick={e => handlerModalNote(e,true)}
                                        >
                                            <FontAwesomeIcon icon={faClipboardRegular} className="i-313123"/>
                                        </button>
                                        <button type="button" className="BtnCircle">
                                            <FontAwesomeIcon icon={faBookmarkRegular} className="i-313123"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ModalNote = (props) => {
        const {
            onHide,
        } = props
        return(
            <div className="modal-content">
                <div className="modal-header modalNormalize">
                    <h2><FontAwesomeIcon icon={faClipboardRegular}/> &nbsp; Take a note</h2>
                    <div className="closeModal">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide} />
                    </div>
                </div>
                <form className="formInput">
                    <div className="modal-body">
                        <div className="modalContentText">						
                            <div className="form-group">
                                <textarea
                                    defaultValue={value.note}
                                    onChange={e=> changeValue(e, 'note', e.target.value)}
                                    id="takeaNote"
                                    className="form-control form-control-sm"
                                    rows="6"
                                    placeholder="Take a note..."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="modalActLeft">		
                            <div className="btnActionCircle" >
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </div>
                        </div>
                        <div className="modalAction">
                            <span className="btn btnActionModal">View all</span>
                            {
                                value.note &&
                                <span id="saveNote" className="btn btnActionModal">Save</span>
                            }
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div id="booth-87612">
            <div className="bg-homepage"/>
            <NavbarTop />

                <section className="contentHeaderDetail pt-8 pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-2">
                                <div className="headContent">
                                    <div className="titleHead">
                                        <h2>Booths</h2>
                                    </div>
                                    <div className="customViewAll">
                                        <div className="filterRightColumn">
                                            <div className="position-relative float-left filterArea">
                                                <button type="button" title="" className="btn filterBtn">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faSlidersH} style={{ marginRight: 5 }} />
                                                    </span>
                                                    <span>Filter</span>
                                                </button>
                                            </div>
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={<Tooltip className="tooltip-custom">Search stage</Tooltip>}
                                            >
                                                <div id="wrap">
                                                    <form action="" autocomplete="off">
                                                        <input id="search" name="search" type="text" placeholder="What're we looking for ?"/>
                                                        <input id="search_submit" value="" type="submit"/>
                                                    </form>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-4">
                    <div className="container">
                        <div className="contentBooth">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="borderContent boothContent">
                                        <div className="bgBooth">
                                            <img src={Imagebg1} alt="Booth 1"/>
                                        </div>							
                                        <div className="BoothOverlay"/>							
                                        <div className="absContent">
                                            <div className="paddingContent">
                                                <div className="topBooth text-end">
                                                    <span className="bmBtnBox" onClick={e => handlerModalChatPerson(e,true)}>
                                                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 3 }} />
                                                        Chat
                                                    </span>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={<Tooltip className="tooltip-custom">Bookmark</Tooltip>}
                                                    >
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="botBooth text-start">
                                                    <h3>LiveLife</h3>
                                                    <div className="usrBooth">
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaris} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaris} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageMenot} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageMenot} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaykal} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaykal} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageKhalif} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageKhalif} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="usrMore" onClick={e => handlerModalChatPerson(e,true)} >
                                                            <span>+2 More</span>
                                                        </div>
                                                    </div>
                                                </div>									
                                                <div className="botBoothRight" onClick={_=> History.push('/booth-detail') } >
                                                    <img src={LLLogo} alt="Booth 1"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="borderContent boothContent">
                                        <div className="bgBooth">
                                            <img src={Imagebg1} alt="Booth 1"/>
                                        </div>							
                                        <div className="BoothOverlay"></div>							
                                        
                                        <div className="absContent">
                                            <div className="paddingContent">
                                                <div className="topBooth text-end">
                                                    <span className="bmBtnBox" onClick={e => handlerModalChatPerson(e,true)}>
                                                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 3 }} />
                                                        Chat
                                                    </span>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={<Tooltip className="tooltip-custom">Bookmark</Tooltip>}
                                                    >
                                                        <span className="bmBtnCircle active">
                                                            <FontAwesomeIcon icon={faBookmark} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="botBooth text-start">
                                                    <h3>LiveLife</h3>
                                                    <div className="usrBooth">
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaris} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaris} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageMenot} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageMenot} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaykal} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaykal} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageKhalif} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageKhalif} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="usrMore" onClick={e => handlerModalChatPerson(e,true)} >
                                                            <span>+2 More</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="botBoothRight" onClick={_=> History.push('/booth-detail') } >
                                                    <img src={LLLogo} alt="Booth 1"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="borderContent boothContent">
                                        <div className="bgBooth">
                                            <img src={Imagebg2} alt="Booth 2"/>
                                        </div>							
                                        <div className="BoothOverlay"></div>							
                                        
                                        <div className="absContent">
                                            <div className="paddingContent">
                                                <div className="topBooth text-end">
                                                    <span className="bmBtnBox" onClick={e => handlerModalChatPerson(e,true)}>
                                                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 3 }} />
                                                        Chat
                                                    </span>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={<Tooltip className="tooltip-custom">Bookmark</Tooltip>}
                                                    >
                                                        <span className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="botBooth text-start">
                                                    <h3>LiveLife</h3>
                                                    <div className="usrBooth">
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaris} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaris} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageMenot} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageMenot} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaykal} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaykal} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageKhalif} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageKhalif} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="usrMore" onClick={e => handlerModalChatPerson(e,true)} >
                                                            <span>+2 More</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="botBoothRight" onClick={_=> History.push('/booth-detail') } >
                                                    <img src={LLLogo} alt="Booth 1"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="borderContent boothContent">
                                        <div className="bgBooth">
                                            <img src={Imagebg3} alt="Booth 3"/>
                                        </div>							
                                        <div className="BoothOverlay"></div>							
                                        
                                        <div className="absContent">
                                            <div className="paddingContent">
                                                <div className="topBooth text-end">
                                                    <span className="bmBtnBox" onClick={e => handlerModalChatPerson(e,true)}>
                                                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 3 }} />
                                                        Chat
                                                    </span>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={<Tooltip className="tooltip-custom">Bookmark</Tooltip>}
                                                    >
                                                        <span className="bmBtnCircle active">
                                                            <FontAwesomeIcon icon={faBookmark} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="botBooth text-start">
                                                    <h3>LiveLife</h3>
                                                    <div className="usrBooth">
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaris} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaris} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageMenot} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageMenot} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageHaykal} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageHaykal} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="avaUsr">
                                                            <PopOverCustom
                                                                component={
                                                                    <div className='text-center widthPopProfile' >
                                                                        <img src={ImageKhalif} className='imgPopHover'/>
                                                                        <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                        <p className='pPopHover'>CEO PT. Kretek</p>
                                                                        <div className='btnViewProf'>
                                                                            <span>View Profile</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                                delay={200}
                                                            >
                                                                <img src={ImageKhalif} alt="image"/>
                                                            </PopOverCustom>
                                                        </div>
                                                        <div className="usrMore" onClick={e => handlerModalChatPerson(e,true)} >
                                                            <span>+2 More</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="botBoothRight" onClick={_=> History.push('/booth-detail') } >
                                                    <img src={LLLogo} alt="Booth 1"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        
                            </div>
                        </div>
                    </div>
                </section>

                <Modal
                    show={modalChatPerson}
                    onHide={e => handlerModalChatPerson(e,false)}
                    size="lg"
                    className="modaloverlayPerson"
                    centered
                    backdropClassName="modalBackdropPerson"
                    className="modalDialogPerson fade zoom-in"
                    data-easein="bounceLeftIn"
                >
                    <ModalChatPerson
                        onHide={e => handlerModalChatPerson(e,false)}
                    />
                </Modal>

                <Modal
                    show={openNote}
                    onHide={e => handlerModalNote(e,false)}
                    className="modaloverlayPerson"
                    centered
                    backdropClassName="modalBackdropNote"
                    className="modalDialogNote fade zoom-in"
                    data-easein="bounceLeftIn"
                >
                    <ModalNote
                        onHide={e => handlerModalNote(e,false)}
                    />
                </Modal>

                <div id="chatForm" role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-bs-autohide="false">
                    <div className="toast-header">
                        <img src={ImageHaris} className="me-2" alt="Haris Riswandi"/>
                        <h5 className="me-auto"><a>Haris Riswandi</a></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"/>
                    </div>
                    <div className="toast-body">
                        <div className="chatMessageWrapper">
                            <div className="chatUserAvatar">
                                <img src={LLLogoSquare} alt="LiveLife"/>
                            </div>
                            <ul className="chatMessage">
                                <li>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                                </li>
                                <li>
                                    <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                                </li>
                            </ul>
                        </div>
                        <div className="chatMessageWrapper chatRight">
                            <div className="chatUserAvatar">
                                <img src={ImageHaris} alt="LiveLife"/>
                            </div>
                            <ul className="chatMessage">
                                <li>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                                </li>
                                <li>
                                    <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                                </li>
                            </ul>
                        </div>
                        <div className="chatMessageWrapper">
                            <div className="chatUserAvatar">
                                <img src={LLLogoSquare} alt="LiveLife"/>
                            </div>
                            <ul className="chatMessage">
                                <li>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                                </li>
                                <li>
                                    <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="toast-footer">
                        <form>
                            <div className="row g-2">
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="liveChat" placeholder="Type a message" autoComplete="off" />
                                </div>
                                <div className="col-sm text-end sendMessage">
                                    <button type="submit" className="BtnCircle">
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            <BottomNavbar />
        </div>
    );
};

export default Booth