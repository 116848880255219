import {
    VENUES_ALL
} from '../constants'

const intialState = {
    venues: [],
}

function OptionState(state = intialState, action) {
    switch (action.type) {
        case VENUES_ALL:
            return {
                ...state, 
                venues : action.data,
            }
        default: {
            return state
        }
    }
}

export default OptionState
