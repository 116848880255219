import React, { useState } from 'react';

import NoProfile from '../images/no-profile.png'
import NoProfileDummy from '../images/no-profile-dummy.png'

function ModalSpeakers(props) {
    const {
        onHide
    } = props

    const [speakers] = useState([
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
        {
            id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
            fullname: 'Haris Altest Sajo',
            profession: 'CEO & Founder',
            organization: 'Drag Taste'
        },
    ])

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const goToNewTab = (user) => {
        const win = window.open(`https://livelifeindo.com/profile/view/${user && user.id}`, "_blank");
        win.focus();
    }
    
    return (
        <div className="modal-content">
            <div className="modal-header modalNormalize">
                <h2>Speakers</h2>
                <div className="closeModal">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide} />
                </div>
            </div>
            <div className="modal-body">
                <div className="modalContentText">
                    {
                        speakers &&
                        speakers.map((person, i)=>(
                            <div className="speakerListModal" key={i}>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <img src={NoProfileDummy}/>
                                        <div className="listSpeakerDetail">
                                            <h4 style={{ color: 'red' }}>{handleLimitString(person.fullname, 30)}</h4>
                                            <span style={{ color: 'red' }}>{handleLimitString(`${person.profession ? person.profession : ''}${person.profession ? ` at ${person.organization}` : ''}`, 40)}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="watchSession" onClick={_=> goToNewTab(person)}>
                                            <span style={{ backgroundColor: 'red' }}>View Profile</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default ModalSpeakers;