const bootstrap = require('bootstrap');
import React,{ useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import '../styles/boothDetail.scss'
import NavbarTop from '../components/TopNavbar'
import BottomNavbar from '../components/BottomNavbar'
import PopOverCustom from '../components/popOver'
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkRegular, faClipboard as faClipboardRegular, faTrashAlt, faClock, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faVoteYea, faPenFancy, faPaperPlane, faChevronLeft, faChevronRight, faBars, faHandPointRight, faCommentDots, faUserFriends, faQuestionCircle, faPoll, faGlobe, faFilePdf, faFileWord, faFileExcel, faUser, faCaretDown, faCaretUp, faSearch  } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Modal } from 'react-bootstrap'

import LLLogoSquare from '../images/ll.jpg'
import LLLogoOfficial from '../images/logo.png'
import Imagebg1 from '../images/bg1.jpg'
import Imagebg2 from '../images/bg2.jpg'
import Imagebg3 from '../images/bg3.jpg'
import ImageKuya from '../images/kuya.jpg'
import ImageKhalif from '../images/khalif.jpg'
import ImageMenot from '../images/menot.jpg'
import ImageHaris from '../images/haris.jpg'
import ImageHaykal from '../images/haykal.jpg'

const BoothDetail = () => {
    const NavigationOwlPeople = useRef();
    const [modalPerson, setModalPerson] = useState(false);

    const handlerModalPerson = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModalPerson(value)
        } else {
            setModalPerson(value)
        }
    }

    useEffect(()=>{
        var triggerTabList = [].slice.call(document.querySelectorAll('#thisSession a'))
		triggerTabList.forEach(function (triggerEl) {
			var tabTrigger = new bootstrap.Tab(triggerEl)
			triggerEl.addEventListener('click', function (event) {
				event.preventDefault()
				tabTrigger.show()
			})
		});

		$("#close-sidebar").click(function() {
			$(".page-wrapper").removeClass("toggled");
		});
		$("#show-sidebar").click(function() {
			$(".page-wrapper").addClass("toggled");
		});

		var delay = 500;
		$(".progress-bar").each(function(i){
            $(this).delay( delay*i ).animate( { width: $(this).attr('aria-valuenow') + '%' }, delay );

            $(this).prop('Counter',0).animate({
                Counter: $(this).text()
            }, {
                duration: delay,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now)+'%');
                }
            });
		});

        $(".chatBtn-1312").click(function(){
            var myAlert =document.getElementById('chatForm');
            var bsAlert = new bootstrap.Toast(myAlert);
            bsAlert.show();
        })

		$(document).on({
			mouseover: function(event) {
				$(this).find('.far').addClass('star-over');
				$(this).prevAll().find('.far').addClass('star-over');
			},
			mouseleave: function(event) {
				$(this).find('.far').removeClass('star-over');
				$(this).prevAll().find('.far').removeClass('star-over');
			}
		}, '.rate');


		$(document).on('click', '.rate', function() {
			if ( !$(this).find('.star').hasClass('rate-active') ) {
				$(this).siblings().find('.star').addClass('far').removeClass('fas rate-active');
				$(this).find('.star').addClass('rate-active fas').removeClass('far star-over');
				$(this).prevAll().find('.star').addClass('fas').removeClass('far star-over');
			} else {
				// console.log('has');
			}
		});
    },[])

    const ModalSessionPerson = (props) => {
        const {
            onHide
        } = props
        return(
            <div className="modal-content">
                <div className="modal-header modalNormalize">
                    <h2>Speakers</h2>
                    <div className="closeModal">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide} />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="modalContentText">
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageKhalif}/>
                                    <div className="listSpeakerDetail">
                                        <h4>M. Khalifurrahman</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <span>View Profile</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageHaris}/>
                                    <div className="listSpeakerDetail">
                                        <h4>Haris Riswandi</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <span>View Profile</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageMenot}/>
                                    <div className="listSpeakerDetail">
                                        <h4>Pranoto</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <span>View Profile</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageKuya}/>
                                    <div className="listSpeakerDetail">
                                        <h4>Haris Anhar</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <span>View Profile</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="speakerListModal">
                            <div className="row">
                                <div className="col-lg-9">
                                    <img src={ImageHaykal}/>
                                    <div className="listSpeakerDetail">
                                        <h4>M. Haykal</h4>
                                        <span>CEO & Founder at Drag Taste</span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="watchSession">
                                        <span>View Profile</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleOwlNav = (e, ops, pos) => {
        if(e) e.preventDefault()
        if (pos === 'people') {
            if (ops === 'prev') {
                NavigationOwlPeople.current.prev(500)
            } else if (ops === 'next') {
                NavigationOwlPeople.current.next(500)
            }
        }
    }
    
    return (
        <div id="booth-detail-87612">
            <div className="bg-homepage"/>
            <NavbarTop />
                
            <div className="page-wrapper chiller-theme">
                <span id="show-sidebar" className="btn btn-sm btn-dark">
                    <FontAwesomeIcon icon={faBars} />
                </span>

                <nav id="sidebar" className="sidebar-wrapper">
                    <div className="sidebar-content">
                        <div className="position-fixed sidebarFix">
                            <div className="sidebar-brand">
                                <div id="close-sidebar">
                                    <FontAwesomeIcon icon={faHandPointRight} />
                                </div>
                                <p>This Session</p>
                            </div>
                            <div className="sidebar-top">				
                                <ul className="nav nav-tabs flex-column flex-sm-row" id="thisSession" role="tablist">
                                    <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link active" id="chat-tab" data-bs-toggle="tab" data-bs-target="#chatTab" type="button" role="tab" aria-controls="tabChat" aria-selected="true">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faCommentDots} />
                                            </span>
                                            <span className="textTabs">Chat</span>
                                        </div>
                                    </li>
                                    <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link" id="people-tab" data-bs-toggle="pill" data-bs-target="#peopleTab" type="button" role="tab" aria-controls="peopleTab" aria-selected="false">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faUserFriends} />
                                            </span>
                                            <span className="textTabs">Our Team</span>
                                        </div>
                                    </li>
                                    <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link" id="qa-tab" data-bs-toggle="tab" data-bs-target="#QATab" type="button" role="tab" aria-controls="QATab" aria-selected="false">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </span>
                                            <span className="textTabs">Q & A</span>
                                        </div>
                                    </li>
                                    <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link" id="poll-tab" data-bs-toggle="pill" data-bs-target="#pollTab" type="button" role="tab" aria-controls="pollTab" aria-selected="false">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faPoll} />
                                            </span>
                                            <span className="textTabs">Polls</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>				
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="chatTab" role="tabpanel" aria-labelledby="chat-tab">
                                <div className="sideContent">
                                    <div className="chatMessageWrapper">
                                        <div className="chatUserAvatar">
                                            <img src={LLLogoSquare} alt="LiveLife"/>
                                        </div>
                                        <ul className="chatMessage">
                                            <li>
                                                <h6>LiveLife</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum?</p>
                                            </li>
                                            <li>
                                                <p>Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="chatMessageWrapper chatRight">
                                        <div className="chatUserAvatar">
                                            <img src={ImageHaris} alt="LiveLife"/>
                                        </div>
                                        <ul className="chatMessage">
                                            <li>
                                                <h6>Admin</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum?</p>
                                            </li>
                                            <li>
                                                <p>Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="chatMessageWrapper">
                                        <div className="chatUserAvatar">
                                            <img src={ImageMenot} alt="LiveLife"/>
                                        </div>
                                        <ul className="chatMessage">
                                            <li>
                                                <h6>Pranoto</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum?</p>
                                            </li>
                                            <li>
                                                <p>Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="chatMessageWrapper">
                                        <div className="chatUserAvatar">
                                            <img src={ImageHaykal} alt="LiveLife"/>
                                        </div>
                                        <ul className="chatMessage">
                                            <li>
                                                <h6>M. Haykal</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum?<span className="chatMessageTime">13:38</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="chatMessageWrapper">
                                        <div className="chatUserAvatar">
                                            <img src={ImageKuya} alt="LiveLife"/>
                                        </div>
                                        <ul className="chatMessage">
                                            <li>
                                                <h6>Haris Anhar</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum?<span className="chatMessageTime">13:38</span></p>
                                            </li>
                                        </ul>
                                    </div>					        
                                    <div className="chatMessageWrapper">
                                        <div className="chatUserAvatar">
                                            <img src={ImageKhalif} alt="LiveLife"/>
                                        </div>
                                        <ul className="chatMessage">
                                            <li>
                                                <h6>M. Khalifurrahman</h6>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum?<span className="chatMessageTime">13:38</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <br /><br />
                                </div>
                                <form className="sideContentFooter">
                                    <div className="row g-2">
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="liveChat" placeholder="Type a message"/>
                                        </div>
                                        <div className="col-sm text-end sendMessage">
                                            <button type="submit" className="BtnCircle"><i className="fas fa-paper-plane"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="QATab" role="tabpanel" aria-labelledby="qa-tab">
                                <div className="sideContent">
                                    <div className="borderContent">
                                        <div className="paddingContent sideQuestion">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="sideLeft">
                                                        <img src={LLLogoSquare} alt="LiveLife User"/>
                                                        <span>Ask a question</span>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="sideRight">
                                                        <span className="btnSidebar" data-bs-toggle="modal" data-bs-target="#addQuestion">
                                                            <FontAwesomeIcon icon={faPenFancy} /> Post
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="qnaArea">
                                        <div className="sortByOption dropdown">
                                            <a className="dropdown-toggle" data-bs-toggle="dropdown"> Sort by: Most voted <span className="caret"></span></a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    Recent
                                                </li>
                                                <li>
                                                    Most voted
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="borderContent mb-3">
                                        <div className="paddingContent sideQuestion">
                                            <div className="usrQuestTop">
                                                <img src={LLLogoSquare} alt="LiveLife User"/>
                                                <span>LiveLife Indonesia</span>
                                            </div>
                                            <div className="usrQuestContent">
                                                <div className="usrQuestion">
                                                    <p>Are these session being recorded?</p>
                                                </div>
                                                <div className="questionAnswer">
                                                    <p>Yes, you will hear from us on how to access the sessions after the event!</p>
                                                </div>
                                                <div className="questVote">
                                                    <FontAwesomeIcon icon={faVoteYea} className="icon-1234"/>
                                                    <span>45 upvotes</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="borderContent mb-3">
                                        <div className="paddingContent sideQuestion">
                                            <div className="usrQuestTop">
                                                <img src={ImageHaris} alt="Haris Riswandi"/>
                                                <span>Haris Riswandi</span>
                                            </div>
                                            <div className="usrQuestContent">
                                                <div className="usrQuestion">
                                                    <p>Are these session being recorded?</p>
                                                </div>
                                                <div className="questionAnswer">
                                                    <p>Yes, you will hear from us on how to access the sessions after the event!</p>
                                                </div>
                                                <div className="questVote">
                                                    <FontAwesomeIcon icon={faVoteYea} className="icon-1234"/>
                                                    <span>45 upvotes</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pollTab" role="tabpanel" aria-labelledby="poll-tab">
                                <div className="sideContent">
                                    <div className="borderContent mb-3 pollDisable">
                                        <div className="paddingContent sidePoll">
                                            <div className="pollTop">
                                                <span className="pollBtnDisable">210 votes</span>
                                                <span className="pollBtnDisable">Poll ended</span>
                                            </div>
                                            <div className="pollQuestion">
                                                <p>What new feature are you most excited about?</p>
                                            </div>
                                            <div className="pollAnswer">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
                                                    <span className="listPoll">Minimalist navigation panel</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                    <span className="listPoll">Global side panel</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100">5%</div>
                                                    <span className="listPoll">Redesigned lounges</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">10%</div>
                                                    <span className="listPoll">Expressive 1-1 chats with emoji</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="borderContent mb-3 pollDisable">
                                        <div className="paddingContent sidePoll">
                                            <div className="pollTop">
                                                <span className="pollBtnDisable">210 votes</span>
                                                <span className="pollBtnDisable">Poll ended</span>
                                            </div>
                                            <div className="pollQuestion">
                                                <p>Do you have a contingency plan for live events in 2021?</p>
                                            </div>
                                            <div className="pollAnswer">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                                                    <span className="listPoll">Yes</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30%</div>
                                                    <span className="listPoll">No</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="borderContent mb-3 pollDisable">
                                        <div className="paddingContent sidePoll">
                                            <div className="pollTop">
                                                <span className="pollBtnDisable">210 votes</span>
                                                <span className="pollBtnDisable">Poll ended</span>
                                            </div>
                                            <div className="pollQuestion">
                                                <p>Have you experienced video fatigue in the last year?</p>
                                            </div>
                                            <div className="pollAnswer">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">55%</div>
                                                    <span className="listPoll">Yes</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">45%</div>
                                                    <span className="listPoll">No</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="borderContent mb-3 pollDisable">
                                        <div className="paddingContent sidePoll">
                                            <div className="pollTop">
                                                <span className="pollBtnDisable">210 votes</span>
                                                <span className="pollBtnDisable">Poll ended</span>
                                            </div>
                                            <div className="pollQuestion">
                                                <p>What made you join us today at this event?</p>
                                            </div>
                                            <div className="pollAnswer">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
                                                    <span className="listPoll">Know more about virtual events</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                    <span className="listPoll">Attend garyvee's session</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100">5%</div>
                                                    <span className="listPoll">Try LiveLife for your next event</span>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">10%</div>
                                                    <span className="listPoll">Here for drag taste</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="peopleTab" role="tabpanel" aria-labelledby="people-tab">
                                <div className="sideContent p-0">
                                    <div className="sideSearch">
                                        <form>
                                            <div className="form-group">
                                                <span className="iconBox">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </span>
                                                <input type="text" placeholder="Search" className="inputSearch form-control"/>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="sidePeople">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <img src={LLLogoSquare} alt="LiveLife Indonesia"/>
                                            </div>
                                            <div className="col-7">
                                                <div className="sideFormatPpl">
                                                    <span className="peopleName">
                                                        LiveLife Indonesia <br />
                                                        <span className="subPplName">PT. Aktivitas Menarik Indonesia</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="text-end sideRightChat">
                                                    <span className="btnSidebar chatBtn-1312">
                                                        <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidePeople">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <img src={ImageHaris} alt="Haris Riswandi"/>
                                            </div>
                                            <div className="col-7">
                                                <div className="sideFormatPpl">
                                                    <span className="peopleName">
                                                        Haris Riswandi<br />
                                                        <span className="subPplName">PT. Kretek Abal Abal</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="text-end sideRightChat">
                                                    <span className="btnSidebar chatBtn-1312">
                                                        <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidePeople">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <img src={ImageMenot} alt="Pranoto"/>
                                            </div>
                                            <div className="col-7">
                                                <div className="sideFormatPpl">
                                                    <span className="peopleName">
                                                        Pranoto
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="text-end sideRightChat">
                                                    <span className="btnSidebar chatBtn-1312">
                                                        <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidePeople">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <img src={ImageHaykal} alt="M. Haykal"/>
                                            </div>
                                            <div className="col-7">
                                                <div className="sideFormatPpl">
                                                    <span className="peopleName">
                                                        M. Haykal<br />
                                                        <span className="subPplName">PT. Fastech Solusi Global</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="text-end sideRightChat">
                                                    <span className="btnSidebar chatBtn-1312">
                                                        <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidePeople">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <img src={ImageKhalif} alt="M. Khalifurrahman"/>
                                            </div>
                                            <div className="col-7">
                                                <div className="sideFormatPpl">
                                                    <span className="peopleName">
                                                        M. Khalifurrahman<br />
                                                        <span className="subPplName">PT. Fastech Solusi Global</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="text-end sideRightChat">
                                                    <span className="btnSidebar chatBtn-1312">
                                                        <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <main className="page-content pageBoothContent">
                    <section className="contentHeader pt-8 pb-4">
                        <div className="container">
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/SCHhkJMxsag?rel=0" title="YouTube video" allowfullscreen/>
                            </div>
                        </div>
                    </section>

                    <section className="pt-2">
                        <div className="container">
                            <div className="headBlock">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="headBooth">									
                                            <div className="headImgBooth">
                                                <img src={LLLogoSquare}/>
                                            </div>
                                            <div className="headTitleSession">
                                                <h2>LiveLife Indonesia</h2>
                                            </div>
                                            <div className="headTimeSession">
                                                <p>LiveLife Technologies Inc. 505 Montgomery Street, 10th floor, San Francisco, CA 94111</p>
                                            </div>
                                            <div className="bd-highlight headAction">
                                                <span className="headTopBtn chatBtn-1312">
                                                    <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                                </span>
                                                <span className="headTopBtn" style={{ marginRight: 5, marginLeft: 5, }} >
                                                    <FontAwesomeIcon icon={faBookmarkRegular} /> Bookmark
                                                </span>
                                                <span className="headTopBtn" data-bs-toggle="modal" data-bs-target="#takeaNote">
                                                    <FontAwesomeIcon icon={faClipboardRegular} /> Take a note
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="d-flex align-items-center headBoothDetail">
                                                <div className="diviRight bd-highlight">
                                                    <p>Social Link</p>
                                                    <div className="diviLink">
                                                        <span className="headTopBtn">
                                                            <FontAwesomeIcon icon={faGlobe} />&nbsp; Visit Website
                                                        </span>
                                                        <span className="btnLinkCircle facebook" style={{ marginRight: 5, marginLeft: 5, }}>
                                                            <FontAwesomeIcon icon={faFacebook} className="icon-media-1123"/>
                                                        </span>
                                                        <span className="btnLinkCircle twitter">
                                                            <FontAwesomeIcon icon={faTwitter} className="icon-media-1123"/>
                                                        </span>
                                                        <span className="btnLinkCircle instagram" style={{ marginRight: 5, marginLeft: 5, }}>
                                                            <FontAwesomeIcon icon={faInstagram} className="icon-media-1123"/>
                                                        </span>
                                                        <span className="btnLinkCircle linkedin">
                                                            <FontAwesomeIcon icon={faLinkedin} className="icon-media-1123"/>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="nextDivi bd-highlight ms-auto">
                                                    <p>Rate Us</p>
                                                </div>
                                                <div className="nextDivi bd-highlight">
                                                    <div className="stars">
                                                        <label className="rate">
                                                            <input type="radio" name="radio1" id="star1" value="star1"/>
                                                            <div className="face"/>
                                                            <i className="far fa-star star one-star"/>
                                                        </label>
                                                        <label className="rate">
                                                            <input type="radio" name="radio1" id="star2" value="star2"/>
                                                            <div className="face"/>
                                                            <i className="far fa-star star two-star"/>
                                                        </label>
                                                        <label className="rate">
                                                            <input type="radio" name="radio1" id="star3" value="star3"/>
                                                            <div className="face"/>
                                                            <i className="far fa-star star three-star"/>
                                                        </label>
                                                        <label className="rate">
                                                            <input type="radio" name="radio1" id="star4" value="star4"/>
                                                            <div className="face"/>
                                                            <i className="far fa-star star four-star"/>
                                                        </label>
                                                        <label className="rate">
                                                            <input type="radio" name="radio1" id="star5" value="star5"/>
                                                            <div className="face"/>
                                                            <i className="far fa-star star five-star"/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sessionContentText">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="headContent">
                                            <div className="titleHead">
                                                <h2>About</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <p>
                                            Wanting to do something different and meeting people? What better way than planning your own event?. At LiveLife, people who enjoy creative-activities gather, learn and collaborate to design a great experience together!
                                        </p>
                                        <p>
                                            Plan your event with LiveLife and find the right partners for your activity, venue, marketing & sponsor, and let's make it happen!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-2 pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="headContent">
                                        <div className="titleHead">
                                            <h2>Open Rooms</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="borderContent l-slategray">
                                        <div className="paddingContent">
                                            <div className="roomLogo">
                                                <img src={LLLogoOfficial} alt="icon"/>
                                            </div>
                                            <div className="roomTitle">
                                                <h3>AMA with Livelife's CEO & Product Head</h3>
                                            </div>
                                            <div className="roomBottom">
                                                <p>Hosted by</p>
                                                <div className="speakerAvatarStd">
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageKuya} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageKuya} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageKhalif} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageKhalif} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageMenot} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageMenot} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageHaris} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageHaris} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdMore" onClick={e => handlerModalPerson(e,true)}>
                                                        <p>+5</p>
                                                    </div>
                                                </div>
                                                <div className="bottomRight">
                                                    <span className="btnRight" style={{ cursor: 'pointer' }}>Enter</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="borderContent l-amber">
                                        <div className="paddingContent">
                                            <div className="roomLogo">
                                                <img src={LLLogoOfficial} alt="icon"/>
                                            </div>
                                            <div className="roomTitle">
                                                <h3>Get to Know Livelife</h3>
                                            </div>
                                            <div className="roomBottom">
                                                <p>Hosted by</p>
                                                <div className="speakerAvatarStd">
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageKuya} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageKuya} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageKhalif} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageKhalif} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageMenot} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageMenot} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdSmall">
                                                        <PopOverCustom
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={ImageHaris} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover'>Haris Anhar</h3>
                                                                    <p className='pPopHover'>CEO PT. Kretek</p>
                                                                    <div className='btnViewProf'>
                                                                        <span>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={ImageHaris} alt="image"/>
                                                        </PopOverCustom>
                                                    </div>
                                                    <div className="avaStdMore" onClick={e => handlerModalPerson(e,true)}>
                                                        <p>+5</p>
                                                    </div>
                                                </div>
                                                <div className="bottomRight">
                                                    <span className="btnRight" style={{ cursor: 'pointer' }}>Enter</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-2 pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="headContent">
                                        <div className="titleHead">
                                            <h2>Videos &nbsp;<span>3</span></h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <div className="boothFrame">
                                        <img src={Imagebg1} alt="Video Title"/>
                                        <div className="vidPlay position-absolute top-50 start-50 translate-middle">
                                            <span data-bs-toggle="modal" data-bs-target="#boothVideo">
                                                <i className="fas fa-play"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <div className="boothFrame">
                                        <img src={Imagebg2} alt="Video Title"/>
                                        <div className="vidPlay position-absolute top-50 start-50 translate-middle">
                                            <span data-bs-toggle="modal" data-bs-target="#boothVideo">
                                                <i className="fas fa-play"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <div className="boothFrame">
                                        <img src={Imagebg3} alt="Video Title"/>
                                        <div className="vidPlay position-absolute top-50 start-50 translate-middle">
                                            <span data-bs-toggle="modal" data-bs-target="#boothVideo">
                                                <i className="fas fa-play"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-2 pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="headContent">
                                        <div className="titleHead">
                                            <h2>Product & Services &nbsp;<span>4</span></h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <div className="boothFrameImg">
                                        <span data-bs-toggle="modal" data-bs-target="#boothProduct" style={{ cursor: 'pointer' }} >
                                            <img src={Imagebg1} alt="Video Title" className="align-middle"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <div className="boothFrameImg">
                                        <span data-bs-toggle="modal" data-bs-target="#boothProduct" style={{ cursor: 'pointer' }} >
                                            <img src={Imagebg2} alt="Video Title" className="align-middle"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3">
                                    <div className="boothFrameImg">
                                        <span data-bs-toggle="modal" data-bs-target="#boothProduct" style={{ cursor: 'pointer' }} >
                                            <img src={Imagebg3} alt="Video Title" className="align-middle"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <div className="boothFrameImg">
                                        <span data-bs-toggle="modal" data-bs-target="#boothProduct" style={{ cursor: 'pointer' }} >
                                            <img src={Imagebg3} alt="Video Title" className="align-middle"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-2 pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="headContent">
                                        <div className="titleHead">
                                            <h2>Team &nbsp;<span>7</span></h2>
                                        </div>
                                        <div className="customNavigation">
                                            <span className="btn gray pplPrev" onClick={e=> handleOwlNav(e, 'prev', 'people') }>
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </span>
                                            <span className="btn gray pplNext" onClick={e=> handleOwlNav(e, 'next', 'people') }>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <OwlCarousel
                                        id="people"
                                        className="owl-carousel"
                                        responsiveClass
                                        responsive={
                                            {
                                                0:{items:2,nav:false},
                                                600:{items:3,nav:false},
                                                1000:{items:5,nav:false,loop:false}
                                            }
                                        }
                                        margin={10}
                                        ref={NavigationOwlPeople}
                                    >
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="a" className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img id="q" src={ImageMenot} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople">
                                                <h4>Pranoto Bin Supra...</h4>
                                                <p>Founder & Tech Evang...</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img src={ImageHaris} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople">
                                                <h4>Haris Riswandi</h4>
                                                <p>Head of Product at Liv...</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img src={ImageHaykal} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople">
                                                <h4>Haykal Muhamad</h4>
                                                <p>SPV Marketing</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img src={ImageKhalif} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>						
                                            <div className="descPeople">
                                                <h4>M. Khalif</h4>
                                                <p>Front-End Designer</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img src={ImageKuya} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>						
                                            <div className="descPeople">
                                                <h4>Haris Anhar</h4>
                                                <p>CEO PT. Kretek</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="a" className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img id="q" src={ImageMenot} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople">
                                                <h4>Pranoto Bin Supra...</h4>
                                                <p>Founder & Tech Evang...</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="imgPeople">
                                                <div id="s" className="imgPeopleBorder">
                                                    <span data-bs-toggle="modal" data-bs-target="#usrDetail" style={{ cursor: 'pointer' }} >
                                                        <img id="w" src={ImageHaris} className="blur"/>
                                                    </span>
                                                    <div className="topBooth text-end">
                                                        <div className="bmBtnCircle">
                                                            <FontAwesomeIcon icon={faBookmarkRegular} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="descPeople">
                                                <h4>Haris Riswandi</h4>
                                                <p>Head of Product at Liv...</p>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-4 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="headContent">
                                        <div className="titleHead">
                                            <h2>Files</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="borderContent boothContent">
                                        <div className="boothFile">
                                            <div className="row">
                                                <div className="d-flex align-items-center">
                                                    <div className="me-auto p-2 align-middle">
                                                        <div className="boothFileDetail">
                                                            <div className="fileIcon pdf">
                                                                <FontAwesomeIcon icon={faFilePdf} className="fileIcon-1237"/>
                                                            </div>
                                                            <p>LiveLife for hybrid event excellence</p>
                                                        </div>
                                                    </div>
                                                    <div className="align-middle">
                                                        <div className="boothFileAction">
                                                            <div className="btn-group dropup">
                                                                <span className="dropdown-toggle fileAct" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fas fa-ellipsis-v"/>
                                                                </span>
                                                                <ul className="dropdown-menu">
                                                                    <li className="icon-21359">
                                                                        <i className="fas fa-download"/> Download
                                                                    </li>
                                                                    <li className="icon-21359">
                                                                        <i className="fas fa-briefcase"/> Add to briefcase
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4">
                                    <div className="borderContent boothContent">
                                        <div className="boothFile">
                                            <div className="row">
                                                <div className="d-flex align-items-center">
                                                    <div className="me-auto p-2 align-middle">
                                                        <div className="boothFileDetail">
                                                            <div className="fileIcon word">
                                                                <FontAwesomeIcon icon={faFileWord} className="fileIcon-1237"/>
                                                            </div>
                                                            <p>LiveLife for Associations</p>
                                                        </div>
                                                    </div>
                                                    <div className="align-middle">
                                                        <div className="boothFileAction">
                                                            <div className="btn-group dropup">
                                                                <span className="dropdown-toggle fileAct" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fas fa-ellipsis-v"/>
                                                                </span>
                                                                <ul className="dropdown-menu">
                                                                    <li className="icon-21359">
                                                                        <i className="fas fa-download"/> Download
                                                                    </li>
                                                                    <li className="icon-21359">
                                                                        <i className="fas fa-briefcase"/> Add to briefcase
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4">
                                    <div className="borderContent boothContent">
                                        <div className="boothFile">
                                            <div className="row">
                                                <div className="d-flex align-items-center">
                                                    <div className="me-auto p-2 align-middle">
                                                        <div className="boothFileDetail">
                                                            <div className="fileIcon excel">
                                                                <FontAwesomeIcon icon={faFileExcel} className="fileIcon-1237"/>
                                                            </div>
                                                            <p>LiveLife for Corporation</p>
                                                        </div>
                                                    </div>
                                                    <div className="align-middle">
                                                        <div className="boothFileAction">
                                                            <div className="btn-group dropup">
                                                                <span className="dropdown-toggle fileAct" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fas fa-ellipsis-v"/>
                                                                </span>
                                                                <ul className="dropdown-menu">
                                                                    <li className="icon-21359">
                                                                        <i className="fas fa-download"/> Download
                                                                    </li>
                                                                    <li className="icon-21359">
                                                                        <i className="fas fa-briefcase"/> Add to briefcase
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </main>

            </div>

            <div className="modal fade zoom-in" id="boothVideo" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2>LiveLife: Video 1</h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/SCHhkJMxsag?rel=0" title="Player One" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade zoom-in" id="boothProduct" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2>LiveLife: Video 1</h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={Imagebg1} className="d-block w-100" alt="Image 1"/>
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>First slide label</h5>
                                            <p>Some representative placeholder content for the first slide.</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={Imagebg2} className="d-block w-100" alt="Image 2"/>
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Second slide label</h5>
                                            <p>Some representative placeholder content for the second slide.</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={Imagebg3} className="d-block w-100" alt="Image 3"/>
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Third slide label</h5>
                                            <p>Some representative placeholder content for the third slide.</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={Imagebg3} className="d-block w-100" alt="Image 4"/>
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Last slide label</h5>
                                            <p>Some representative placeholder content for the third slide.</p>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade zoom-in" id="takeaNote" data-bs-keyboard="false" tabindex="-1" aria-labelledby="infoSessionLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2>
                                <FontAwesomeIcon icon={faClipboardRegular} /> &nbsp; Take a note
                            </h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                        </div>
                        <form className="formInput">
                            <div className="modal-body">
                                <div className="modalContentText">
                                    <div className="form-group">
                                        <textarea id="takeaNote" className="form-control form-control-sm" rows="6" placeholder="Take a note..."/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modalActLeft">
                                    <span className="btnActionCircle">
                                        <FontAwesomeIcon icon={faTrashAlt} /> 
                                    </span>
                                </div>
                                <div className="modalAction">
                                    <span className="btn btnActionModal">View all</span>
                                    <span id="saveNote" className="btn btnActionModal">Save</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade zoom-in" id="addQuestion" data-bs-keyboard="false" tabindex="-1" aria-labelledby="infoSessionLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2>
                                <FontAwesomeIcon icon={faClipboardRegular} /> &nbsp; Ask a question
                            </h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                        </div>
                        <form className="formInput">
                            <div className="modal-body">
                                <div className="modalContentText">
                                    <div className="form-group">
                                        <textarea id="takeaNote" className="form-control form-control-sm" rows="6" placeholder="Write a question..."/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modalActLeft">
                                    <span className="btnActionCircle">
                                        <FontAwesomeIcon icon={faTrashAlt} /> 
                                    </span>
                                </div>
                                <div className="modalAction">
                                    <span id="saveNote" className="btn btnActionModal">Save</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div id="chatForm" role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-bs-autohide="false">
                <div className="toast-header">
                    <img src={ImageHaris} className="me-2" alt="Haris Riswandi"/>
                    <h5 className="me-auto"><a>Haris Riswandi</a></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"/>
                </div>
                <div className="toast-body">
                    <div className="chatMessageWrapper">
                        <div className="chatUserAvatar">
                            <img src={LLLogoSquare} alt="LiveLife"/>
                        </div>
                        <ul className="chatMessage">
                            <li>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                            </li>
                            <li>
                                <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                            </li>
                        </ul>
                    </div>
                    <div className="chatMessageWrapper chatRight">
                        <div className="chatUserAvatar">
                            <img src={ImageHaris} alt="LiveLife"/>
                        </div>
                        <ul className="chatMessage">
                            <li>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                            </li>
                            <li>
                                <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                            </li>
                        </ul>
                    </div>
                    <div className="chatMessageWrapper">
                        <div className="chatUserAvatar">
                            <img src={LLLogoSquare} alt="LiveLife"/>
                        </div>
                        <ul className="chatMessage">
                            <li>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                            </li>
                            <li>
                                <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="toast-footer">
                    <form>
                        <div className="row g-2">
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="liveChat" placeholder="Type a message" autoComplete="off" />
                            </div>
                            <div className="col-sm text-end sendMessage">
                                <button type="submit" className="BtnCircle">
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal fade" id="usrDetail" role="dialog" aria-labelledby="usrDetailLabel">
                <div className="modal-dialog come-from-modal right" role="document">
                    <div className="modal-content min-vh-100">
                        <div className="bgUsrProfile">
                            <div className="usrClose">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <img src={Imagebg1} alt="Background Name"/>
                        </div>
                        <div className="usrProfDetail">
                            <img src={LLLogoSquare} alt="LiveLife Indonesia"/>
                            <h2>LiveLife Indonesia</h2>
                            <span>PT. Aktivitas Menarik Indonesia</span>
                        </div>
                        <div className="usrActDetail">
                            <div className="row">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="p-2">
                                        <span className="headTopBtn chatBtn-1312">
                                            <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                        </span>
                                    </div>
                                    <div className="p-2">
                                        <span className="headTopBtn">
                                            <FontAwesomeIcon icon={faUser} /> View profile
                                        </span>
                                    </div>
                                    <div className="p-2">
                                        <span className="headTopBtn">
                                            <FontAwesomeIcon icon={faBookmarkRegular} /> Bookmark
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="setupMeet">
                            <div className="row">
                                <div className="col-sm-auto">
                                    <h5>Setup Meeting with LiveLife</h5>
                                </div>
                                <div className="col-sm-12">
                                    <div className="borderContent">
                                        <div className="paddingContent pt-4 pb-4">
                                            <span className="headTopBtn collapsed" data-bs-toggle="collapse" href="#meetPeople">
                                                <span className="if-collapsed">More Slots <FontAwesomeIcon icon={faCaretDown} /></span>
                                                <span className="if-not-collapsed">Less Slots <FontAwesomeIcon icon={faCaretUp} /></span>
                                            </span>
                                            <div className="collapse" id="meetPeople">
                                                <div className="well">
                                                    <div className="timeline">
                                                        <div className="timelineMeet">
                                                            <div className="timelineMeetIcon">
                                                                <span className="timelineMeetNumber">01</span>
                                                            </div>
                                                            <div className="timelineMeetContent">
                                                                <div className="tlMeetTitle">
                                                                    <h5>Choose Date</h5>
                                                                </div>
                                                                <div className="chooseDateDropArea">
                                                                    <button type="button" title="" className="headTopBtn">
                                                                        <span className="icon">
                                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                                        </span>
                                                                        <span>Available Dates</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="timelineMeet">
                                                            <div className="timelineMeetIcon">
                                                                <span className="timelineMeetNumber">02</span>
                                                            </div>
                                                            <div className="timelineMeetContent">
                                                                <div className="tlMeetTitle">
                                                                    <h5>Choose Time</h5>
                                                                </div>
                                                                <div className="timelineDescription">
                                                                    <div className="filterBlock">
                                                                        <div className="chooseDateDropArea">
                                                                            <button type="button" title="" className="headTopBtn">
                                                                                <span className="icon">
                                                                                    <FontAwesomeIcon icon={faClock} />
                                                                                </span>
                                                                                <span>Available slots</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="timelineMeet tlAgenda">
                                                            <div className="timelineMeetIcon">
                                                                <span className="timelineMeetNumber">03</span>
                                                            </div>
                                                            <div className="timelineMeetContent">
                                                                <div className="tlMeetTitle">
                                                                    <h5>Agenda for the meeting</h5>
                                                                </div>
                                                                <div className="timeline__event__description">
                                                                    <div className="TextAreaWrapper">
                                                                        <textarea id="" name="" placeholder="Enter your invite message here" rows="4" cols="50" className="inputTextArea rounded valid ">Hi LiveLife, I would like to connect with you.</textarea>
                                                                        <span className="charLimiText">
                                                                            <p className=" bgColor normal typoText bodySmall" data-testid="" title="">254</p>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tlAction">
                                                        <div className="btnWrapper mt10">
                                                            <button title="" type="button" data-testid="" id="" className="headTopBtn">Send</button>
                                                        </div>
                                                        <div className="btnWrapper mt10">
                                                            <button title="" type="button" data-testid="" id="" className="headTopBtn">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal
                show={modalPerson}
                onHide={e => handlerModalPerson(e,false)}
                size="lg"
                className="modaloverlayPerson"
                centered
                backdropClassName="modalBackdropPerson"
                className="modalDialogPerson fade zoom-in"
                data-easein="bounceLeftIn"
            >
                <ModalSessionPerson
                    onHide={e => handlerModalPerson(e,false)}
                />
            </Modal>

            <BottomNavbar />
        </div>
    );
};

export default BoothDetail