import React, { memo } from 'react';
import { useHistory } from 'react-router-dom'
import '../styles/navbar-top.scss'
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faVideo, faUserFriends, faSignOutAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const NavbarBottom = ({
    hide = false
}) => {
    const [cookies, _, removeCookie] = useCookies(['']);
    const History = useHistory()
    
    const goOnTop = () => {
        window.scroll({
            top: 0, 
            left: 0,
            behavior: 'smooth'
        });
    }

    const logout = () => {
        History.replace(`/login?event=${cookies.event}`)
        removeCookie('account')
        removeCookie('event')
        removeCookie('key')
        window.location.reload();
    }

    return (
        <nav className={`navbar navbar-dark bgMenuMobile navbar-expand fixed-bottom d-md-none d-lg-none d-xl-none ${ hide && 'd-none' } `}>
            <ul className="navbar-nav nav-justified w-100">
                <li className="nav-item">
                    <span className={`nav-link ${History.location.pathname === '/home' && 'active'}`} onClick={_=> History.location.pathname !== '/home' && [History.push('/home'), goOnTop()]}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faHome} />
                        </span>
                    </span>
                </li>
                <li className="nav-item">
                    <span className={`nav-link ${History.location.pathname === '/stage' && 'active'}`} onClick={_=> History.location.pathname !== '/stage' && [History.push('/stage'), goOnTop()]}>
                        <span className="icon" >
                            <FontAwesomeIcon icon={faVideo} />
                        </span>
                    </span>
                </li>
                <li className="nav-item">
                    <span className={`nav-link ${History.location.pathname === '/people' && 'active'}`} onClick={_=> History.location.pathname !== '/people' && [History.push('/people'), goOnTop()]}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faUserFriends} />
                        </span>
                    </span>
                </li>
                <li className="nav-item">
                    <span className={`nav-link ${History.location.pathname === '/people' && 'active'}`} data-bs-toggle="modal" data-bs-target="#logout">
                        <span className="icon">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </span>
                    </span>
                </li>
            </ul>

            <div className="modal fade zoom-in" id="logout" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoSessionLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-sm modal-dialog-centered modal-dialog-scrollable logout-content-bar">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2><FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Logout?</h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btnActionModal" data-bs-dismiss="modal">No</button>
                            <button className="btn btnActionModal" data-bs-dismiss="modal" onClick={logout}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default memo(NavbarBottom)