import React,{ useEffect, useState, Fragment } from 'react';
import 'styles/customize.scss'
import NavbarTop from '../../components/TopNavbar';
import BottomNavbar from '../../components/BottomNavbar';
import ImageLogo from '../../images/logo_indo_beta.png'
import ImageSecurity from '../../images/security.png'
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    getHomePageBanner,
    setHomePageBanner,
    getStage,
    setMainStageEvent,
    getSession,
    setSession,
    getPolling,
    setPolling,
    getPartnerSponsorExhibitor,
    setPartnerSponsorExhibitor,
    uploadImageToRepo,
    getResources,
    setResource,
    getSpeakerAndTalent,
    setSpeakerAndTalent,
    getArticle,
    setArticle
} from 'store/actions/customize'

const Customize = (props) => {
    const Dispatch = useDispatch()
    const { addToast } = useToasts()
    const [modal, setModal] = useState(false);
    const [modalSession, setModalSession] = useState(false);
    const [modalAction, setModalAction] = useState({
        index: null,
        value: null
    });
    const eventState = useSelector(state=> state.eventState.event )

    const [customize, setCustomize] = useState({
        disabled: false,
        refresh: false,
        selectTab: 'homepage-banner',
        updateTab: [],
        nextTab: null,
        changed: false,
        loading: true,
        originalData: null,
        editedData: null,
        search_article: "",
        openMenu: false
    })

    const handlerModal = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModal(value)
        } else {
            setModal(value)
        }
    }

    const handlerModalSession = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModalSession(value)
        } else {
            setModalSession(value)
        }
    }

    const handleSelectNo = (event) => {
        if (event) event.preventDefault()
        if (
            customize.selectTab === 'homepage-banner' ||
            customize.selectTab === 'main-stage' ||
            customize.selectTab === 'session'
        ) {
            handlerModal(null, false)
            if (customize.selectTab !== 'session') {
                setTimeout(() => {
                    setCustomize({
                        ...customize,
                        changed: false,
                        nextTab: null,
                        selectTab: customize.nextTab,
                    })
                }, 50);
            }
        }
    }

    const handleSelectNoSession = (event) => {
        if (event) event.preventDefault()
        if ( customize.selectTab === 'session' ) {
            handlerModalSession(event, false)
        }
    }

    const handleSelectYesSession = (event) => {
        if (event) event.preventDefault()
        if (modalAction.index !== null) {
            let dataFix = customize.editedData?.map((data, indexJ)=>({
                ...data,
                ['is_active_elive'] : indexJ === modalAction?.index ? modalAction.value : data['is_active_elive'],
                zoom_link_elive: customize.originalData[modalAction?.index]['zoom_link_elive']
            }))
            updateData(null, 'session', {
                ['is_active_elive']: Number(modalAction.value),
                data: dataFix,
                zoom_link_elive: customize.originalData[modalAction?.index]['zoom_link_elive'],
                modalYes: true
            })
            setModalAction({
                index: null,
                value: null
            })
        }
    }

    const handleSelectYes = (event) => {
        if ( customize.selectTab === 'homepage-banner' ) {
            updateData(event, 'homepage-banner', {
                url_banner_elive : customize.editedData?.url_banner_elive,
                modalYes: true
            })
        } else if ( customize.selectTab === 'main-stage' ) {
            let zoom = checkingValueSavingButton('main-stage', 'zoom_link_elive')
            let video = checkingValueSavingButton('main-stage', 'url_event_video')

            if (zoom && video) {
                updateData(event, 'main-stage', {
                    url_event_video : customize.editedData.type === 'url_event_video' ? customize.editedData?.url_event_video : '',
                    zoom_link_elive : customize.editedData.type === 'zoom_link_elive' ? customize.editedData?.zoom_link_elive : '',
                    modalYes: true
                })
            } else if (zoom) {
                updateData(event, 'main-stage', {
                    zoom_link_elive : customize.editedData?.zoom_link_elive,
                    url_event_video : '',
                    modalYes: true
                })
            } else if (video){
                updateData(event, 'main-stage', {
                    url_event_video : customize.editedData?.url_event_video,
                    zoom_link_elive : '',
                    modalYes: true
                })
            }
        }
    }

    const SelectTab = (tab) => {
        let flag = false
        if (customize.changed) {
            flag = true
            setCustomize({
                ...customize,
                nextTab: tab,
                search_article: '',
                openMenu: !customize.openMenu
            })
            handlerModal(null, true)
        }
        if (!flag) {
            setCustomize({
                ...customize,
                selectTab: tab,
                search_article: '',
                openMenu: !customize.openMenu
            })
        }
    }

    useEffect(()=>{
        if (eventState) {
            if (customize.selectTab === 'homepage-banner') {
                try {
                    Dispatch(getHomePageBanner({ id: eventState?.event_info?.id_idea }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = {}
                            for (const key in (data?.event_info || {})) {
                                originalData[key] = (data?.event_info || {})[key] || ""
                            }
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'main-stage') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true,
                    })
                    Dispatch(getStage({ id: eventState?.event_info?.id_idea }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = {}
                            for (const key in (data?.event_info || {})) {
                                originalData[key] = (data?.event_info || {})[key] || ""
                            }
                            originalData.type = checkingTypeActive(originalData, 'main-stage', ['zoom_link_elive','url_event_video'])
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'session') {
                try {
                    if (eventState?.event_info?.id_idea) {
                        setCustomize({
                            ...customize,
                            disabled: true,
                        })
                        Dispatch(getSession({
                            id_idea : eventState?.event_info?.id_idea,
                            order_by: 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC'
                        }, { setCustomize, customize, addToast } ,(status, data)=>{
                            if (status) {
                                let originalData = data?.map(session=>({
                                    ...session.program_info,
                                    type: session.program_info?.recording_link_elive ? 'record' : 'zoom'
                                })) || []
                                setCustomize({
                                    ...customize,
                                    loading: false,
                                    changed: false,
                                    disabled: false,
                                    originalData,
                                    editedData: originalData
                                })
                            } else {
                                setCustomize({
                                    ...customize,
                                    loading: false,
                                    disabled: false,
                                    changed: false,
                                })
                            }
                        }))
                    }
                } catch (error) {}
            } else if (customize.selectTab === 'article') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true
                    })
                    Dispatch(getArticle({
                        id_status : true
                    }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = data?.map(session=>({
                                ...session.berita_info
                            })) || []
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                                originalData: [],
                                editedData: []
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'polling') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true
                    })
                    Dispatch(getPolling({
                        id_idea: eventState?.event_info?.id_idea,
                        survey_type: 'poll',
                        order_by: 'll_surveys.created ASC',
                    }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = data?.map(session=>({
                                ...session.survey_info
                            })) || []
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                                originalData: [],
                                editedData: []
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'feedback') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true
                    })
                    Dispatch(getPolling({
                        id_idea: eventState?.event_info?.id_idea,
                        survey_type: 'Feedback Event',
                        order_by: 'll_surveys.created ASC',
                    }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = data?.map(session=>({
                                ...session.survey_info
                            })) || []
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                                originalData: [],
                                editedData: []
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'resource') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true
                    })
                    Dispatch(getResources({
                        id_idea: eventState?.event_info?.id_idea,
                    }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = data?.map(session=>({
                                ...session.resource_info
                            })) || []
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                                originalData: [],
                                editedData: []
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'speaker-talent') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true
                    })
                    Dispatch(getSpeakerAndTalent({
                        id_idea: eventState?.event_info?.id_idea,
                        status: "confirmed",
                        is_active: true,
                        is_approved: true,
                        is_delete: false
                    }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = data?.map(session=>({
                                ...session.talent_info
                            })) || []
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: originalData
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                                originalData: [],
                                editedData: []
                            })
                        }
                    }))
                } catch (error) {}
            } else if (customize.selectTab === 'partner') {
                try {
                    setCustomize({
                        ...customize,
                        disabled: true,
                    })
                    Dispatch(getPartnerSponsorExhibitor({
                        id: eventState?.event_info?.id_idea,
                    }, { setCustomize, customize, addToast } ,(status, data)=>{
                        if (status) {
                            let originalData = [
                                ...data?.event_info?.partner_image_elive || []
                            ]
                            setCustomize({
                                ...customize,
                                loading: false,
                                changed: false,
                                disabled: false,
                                originalData,
                                editedData: ['','']
                            })
                        } else {
                            setCustomize({
                                ...customize,
                                loading: false,
                                disabled: false,
                                changed: false,
                            })
                        }
                    }))
                } catch (error) {}
            }
        }
    },[customize.selectTab, customize.refresh, eventState])
    
    const handleUpdateValue = (page, action, key, value, index = null) => {
        if (page === 'homepage-banner') {
            if (action === 'radio') {
                if (value !== customize.originalData?.[key]) {
                    updateData(null, page, {
                        [key]: value
                    })
                }
            } else if (action === 'input') {
                setCustomize({
                    ...customize,
                    changed: value !== customize.originalData[key],
                    editedData:{
                        ...customize.editedData,
                        [key] : value
                    }
                })
            }
        } else if(page === 'main-stage'){
            if (action === 'radio') {
                if (value !== customize.editedData?.[key]) {
                    setCustomize({
                        ...customize,
                        editedData:{
                            ...customize.editedData,
                            [key] : value
                        }
                    })
                }
            } else if (action === 'input') {
                setCustomize({
                    ...customize,
                    changed: value !== customize.originalData[key],
                    editedData:{
                        ...customize.editedData,
                        [key] : value
                    }
                })
            } else if (action === 'slide') {
                if (value !== customize.originalData?.[key]) {
                    updateData(null, page, {
                        [key]: Number(value)
                    })
                }
            }
        } else if (page === 'session'){
            if (action === 'slide') {
                if (value !== customize.originalData[index][key]) {
                    if (checkingValueSavingButton('session', 'zoom_link_elive', index)) {
                        handlerModalSession(null, true)
                        setModalAction({
                            index,
                            value
                        })
                    } else {
                        let dataFix = customize.editedData?.map((data, indexJ)=>({
                            ...data,
                            [key] : indexJ === index ? value : data[key],
                        }))
                        updateData(null, page, {
                            id : customize.originalData[index]['id'],
                            [key]: Number(value),
                            data: dataFix,
                            zoom_link_elive: customize.originalData[index]['zoom_link_elive'],
                            recording_link_elive: customize.originalData[index]['recording_link_elive']
                        })
                    }
                }
            } else if (action === 'radio') {
                let dataFix = customize.editedData?.map((data, indexJ)=>({
                    ...data,
                    [key] : indexJ === index ? value : data[key]
                }))
                setCustomize({
                    ...customize,
                    editedData: dataFix
                })
            } else if (action === 'input') {
                let dataFix = customize.editedData?.map((data, indexJ)=>({
                    ...data,
                    [key] : indexJ === index ? value : data[key]
                }))
                setCustomize({
                    ...customize,
                    changed: (value || '') !== (customize?.originalData?.[index]?.[key] || ''),
                    editedData: dataFix
                })
            } 
        } else if (page === 'article'){
            if (action === 'slide') {
                if (value !== customize.originalData[index][key]) {
                    let dataFix = customize.editedData?.map((data, indexJ)=>({
                        ...data,
                        [key] : indexJ === index ? value : data[key],
                    }))
                    updateData(null, page, {
                        id_berita: dataFix[index]['id_berita'],
                        [key]: Number(value),
                        data: dataFix,
                    })
                }
            }
        } else if (page === 'polling'){
            if (action === 'slide') {
                if (value !== customize.originalData[index][key]) {
                    let dataFix = customize.editedData?.map((data, indexJ)=>({
                        ...data,
                        [key] : indexJ === index ? value : data[key],
                    }))
                    updateData(null, page, {
                        id_survey: dataFix[index]['id_survey'],
                        [key]: Number(value),
                        data: dataFix,
                    })
                }
            }
        } else if (page === 'feedback'){
            if (action === 'slide') {
                if (value !== customize.originalData[index][key]) {
                    let dataFix = customize.editedData?.map((data, indexJ)=>({
                        ...data,
                        [key] : indexJ === index ? value : data[key],
                    }))
                    updateData(null, page, {
                        id_survey: dataFix[index]['id_survey'],
                        [key]: Number(value),
                        data: dataFix,
                    })
                }
            }
        } else if (page === 'resource'){
            if (action === 'slide') {
                if (value !== customize.originalData[index][key]) {
                    let dataFix = customize.editedData?.map((data, indexJ)=>({
                        ...data,
                        [key] : indexJ === index ? value : data[key],
                    }))
                    updateData(null, page, {
                        id_resource: dataFix[index]['id_resource'],
                        [key]: Number(value),
                        data: dataFix,
                    })
                }
            }
        } else if (page === 'speaker-talent'){
            if (action === 'slide') {
                if (value !== customize.originalData[index][key]) {
                    let dataFix = customize.editedData?.map((data, indexJ)=>({
                        ...data,
                        [key] : indexJ === index ? value : data[key],
                    }))
                    updateData(null, page, {
                        id_idea_talent: Number(dataFix[index]['id_idea_talent']),
                        [key]: Boolean(value),
                        data: dataFix,
                    })
                }
            }
        } else if (page === 'partner'){
            if (action === 'input') {
                if (key === 'filename') {
                    setCustomize({
                        ...customize,
                        editedData: [value || '', customize.editedData?.[1] || '']
                    })
                } else if (key === 'fileimage') {
                    setCustomize({
                        ...customize,
                        editedData: [customize.editedData?.[0] || '', value.target.files[0] || ''],
                    })
                }
            }
        }
    }

    const updateData = (event, page, props) => {
        if(event) event.preventDefault()
        if (page === 'homepage-banner') {
            const {
                id_idea = customize.originalData?.id_idea,
                url_banner_elive = customize.originalData?.url_banner_elive,
                type_banner_elive = customize.originalData?.type_banner_elive,
                modalYes
            } = props
            Dispatch(setHomePageBanner({ id_idea, url_banner_elive, type_banner_elive }, { setCustomize, customize, addToast },(status, message)=>{
                if (status) {
                    setCustomize({
                        ...customize,
                        loading: false,
                        changed: false,
                        originalData:{
                            ...customize.originalData,
                            id_idea,
                            url_banner_elive,
                            type_banner_elive
                        },
                        editedData:{
                            ...customize.editedData,
                            type_banner_elive
                        }
                    })
                    addToast('Success Update Homepage Banner', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    if (modalYes) {
                        handleSelectNo()
                    }
                } else {
                    setCustomize({
                        ...customize,
                        loading: false,
                    })
                    addToast(message, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    if (modalYes) {
                        handlerModal(null, false)
                    }
                }
            }))
        } else if (page === 'main-stage') {
            const {
                id_idea = customize.originalData?.id_idea,
                is_main_stage_elive = Number(customize.originalData?.is_main_stage_elive) || 0,
                url_event_video = customize.originalData?.url_event_video,
                zoom_link_elive = customize.originalData?.zoom_link_elive,
                modalYes
            } = props
            Dispatch(setMainStageEvent({
                id_idea,
                is_main_stage_elive,
                url_event_video,
                zoom_link_elive
            }, { setCustomize, customize, addToast },(status, message)=>{
                if (status) {
                    let customizeFill = {}
                    if (url_event_video === '') {
                        customizeFill.url_event_video = ''
                    } else if(zoom_link_elive === '') {
                        customizeFill.zoom_link_elive = ''
                    }
                    setCustomize({
                        ...customize,
                        loading: false,
                        changed: false,
                        originalData:{
                            ...customize.originalData,
                            id_idea,
                            is_main_stage_elive,
                            url_event_video,
                            zoom_link_elive
                        },
                        editedData:{
                            ...customize.editedData,
                            ...customizeFill,
                            is_main_stage_elive,
                        }
                    })
                    addToast('Success Update Main Stage', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    if (modalYes) {
                        handleSelectNo()
                    }
                } else {
                    setCustomize({
                        ...customize,
                        loading: false,
                    })
                    addToast(message, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    if (modalYes) {
                        handlerModal(null, false)
                    }
                }
            }))
        } else if (page === 'session') {
            const {
                id = eventState?.event_info?.id_idea,
                is_active_elive,
                zoom_link_elive,
                recording_link_elive,
                data = customize.editedData,
                modalYes = false,
                type
            } = props

            Dispatch(setSession({
                id,
                is_active_elive,
                zoom_link_elive : type === 'zoom' ? zoom_link_elive : '' ,
                recording_link_elive : type === 'record' ? recording_link_elive : ''
            }, { setCustomize, customize, addToast },(status, message)=>{
                if (status) {
                    setCustomize({
                        ...customize,
                        loading: false,
                        changed: false,
                        originalData: data,
                        editedData: data
                    })
                    addToast('Success Update Main Stage', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    if (modalYes) {
                        handleSelectNoSession()
                    }
                } else {
                    setCustomize({
                        ...customize,
                        loading: false,
                    })
                    addToast(message, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    if (modalYes) {
                        handlerModalSession(null, false)
                    }
                }
            }))
        } else if (page === 'article') {
            const {
                id_berita,
                is_active_elive,
                data,
            } = props
            if (id_berita) {
                Dispatch(setArticle({
                    id_berita,
                    is_active_elive
                }, { setCustomize, customize, addToast },(status, message)=>{
                    if (status) {
                        setCustomize({
                            ...customize,
                            loading: false,
                            changed: false,
                            originalData: data,
                            editedData: data
                        })
                        addToast('Success Update Article', {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    } else {
                        setCustomize({
                            ...customize,
                            loading: false,
                        })
                        addToast(message, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                }))
            } else {
                addToast(`Id survey is required!`, {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        } else if (page === 'polling') {
            const {
                id_survey,
                is_active_elive,
                data,
            } = props
            if (id_survey) {
                Dispatch(setPolling({
                    id_survey,
                    is_active_elive
                }, { setCustomize, customize, addToast },(status, message)=>{
                    if (status) {
                        setCustomize({
                            ...customize,
                            loading: false,
                            changed: false,
                            originalData: data,
                            editedData: data
                        })
                        addToast('Success Update Polling', {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    } else {
                        setCustomize({
                            ...customize,
                            loading: false,
                        })
                        addToast(message, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                }))
            } else {
                addToast(`Id survey is required!`, {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        } else if (page === 'feedback') {
            const {
                id_survey,
                is_active_elive,
                data,
            } = props
            if (id_survey) {
                Dispatch(setPolling({
                    id_survey,
                    is_active_elive
                }, { setCustomize, customize, addToast },(status, message)=>{
                    if (status) {
                        setCustomize({
                            ...customize,
                            loading: false,
                            changed: false,
                            originalData: data,
                            editedData: data
                        })
                        addToast('Success Update Polling', {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    } else {
                        setCustomize({
                            ...customize,
                            loading: false,
                        })
                        addToast(message, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                }))
            } else {
                addToast(`Id survey is required!`, {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        } else if (page === 'resource') {
            const {
                id_resource,
                is_active_elive,
                data,
            } = props
            if (id_resource) {
                Dispatch(setResource({
                    id_resource,
                    is_active_elive
                }, { setCustomize, customize, addToast },(status, message)=>{
                    if (status) {
                        setCustomize({
                            ...customize,
                            loading: false,
                            changed: false,
                            originalData: data,
                            editedData: data
                        })
                        addToast('Success Update Resource', {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    } else {
                        setCustomize({
                            ...customize,
                            loading: false,
                        })
                        addToast(message, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                }))
            } else {
                addToast(`Id resource is required!`, {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        } else if (page === 'speaker-talent') {
            const {
                id_idea_talent,
                is_active_elive,
                data,
            } = props
            if (id_idea_talent) {
                Dispatch(setSpeakerAndTalent({
                    id_idea_talent,
                    is_active_elive
                }, { setCustomize, customize, addToast },(status, message)=>{
                    if (status) {
                        setCustomize({
                            ...customize,
                            loading: false,
                            changed: false,
                            originalData: data,
                            editedData: data
                        })
                        addToast('Success Update Speaker/Talent', {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    } else {
                        setCustomize({
                            ...customize,
                            loading: false,
                        })
                        addToast(message, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                }))
            } else {
                addToast(`Id talent is required!`, {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        } else if (page === 'partner') {
            // BUILDUP !
            try {
                const {
                    id
                } = props

                if (id) {
                    let partner_image_elive = [...(customize?.originalData?.partner_image_elive || [])]
                    const formData = new FormData();
                    formData.append( 
                        customize?.editedData?.[0] || '', 
                        customize?.editedData?.[1] || '', 
                        customize?.editedData?.[0] || ''
                    );
                    Dispatch(uploadImageToRepo(formData,{
                        setCustomize, customize, addToast, uri: 'https://images.livelifeindo.com/upload/logo/partners'
                    },(status, message)=>{
                        // console.log(status);
                    }))
                    
                    // Dispatch(setPartnerSponsorExhibitor({
                    //     id_survey,
                    //     partner_image_elive
                    // }, { setCustomize, customize, addToast, uri },(status, message)=>{
                    //     if (status) {
                    //         setCustomize({
                    //             ...customize,
                    //             loading: false,
                    //             changed: false,
                    //             originalData: data,
                    //             editedData: data
                    //         })
                    //         addToast('Success Update Polling', {
                    //             appearance: 'success',
                    //             autoDismiss: true,
                    //         })
                    //     } else {
                    //         setCustomize({
                    //             ...customize,
                    //             loading: false,
                    //         })
                    //         addToast(message, {
                    //             appearance: 'error',
                    //             autoDismiss: true,
                    //         })
                    //     }
                    // }))
                } else {
                    addToast(`Id event is required!`, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            } catch (error) {}
        } else if (page === 'sponsor') {
            // BELUM ADA
            addToast('under construction', {
                appearance: 'warning',
                autoDismiss: true,
            })
        } else if (page === 'exhibitor') {
            // BELUM ADA
            addToast('under construction', {
                appearance: 'warning',
                autoDismiss: true,
            })
        }
    }

    const checkingValueSavingButton = (page, key, index) => {
        if (page === 'homepage-banner') {
            if (JSON.stringify(customize?.editedData?.[key]) === JSON.stringify(customize?.originalData?.[key])) {
                return false
            }
        }
        if (page === 'main-stage') {
            if (JSON.stringify(customize?.editedData?.[key]) === JSON.stringify(customize?.originalData?.[key])) {
                return false
            }
        }
        if (page === 'session') {
            if ((customize?.editedData?.[index]?.[key] || '') === (customize?.originalData?.[index]?.[key] || '')) {
                return false
            }
        }
        return true
    }

    const checkingTypeActive = (data, page, arrData) => {
        if (page === 'main-stage') {
            let result = arrData[0]
            arrData?.map(key=>{
                if (data[key]) {
                    result = key
                    return
                }
            })
            return result
        }
    }

    const goToNewTab = (link) => {
        if (link) {
            const win = window.open(link, "_blank");
            win.focus();
        }
    }

    const handleValues = (event) => {
        setCustomize({
            ...customize,
            search_article: event.target.value
        })
    }

    return (
        <div id="customize-87612">
            <div className="bg-homepage"/>
            <NavbarTop />

            <section className="forum pt-8 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pb-2">
                            <div id="tabpanel_pc">
                                <ul className="nav nav-tabs nav-tabs-dropdown" id="customizeList" role="tablist">
                                    {/* homepage-banner */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'homepage-banner') ? 'disabled' : customize.selectTab === 'homepage-banner' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('homepage-banner')}>
                                            <span className="iconTabs"><i className="fas fa-columns"></i></span>
                                            <span className="textTabs">Homepage Banner</span>
                                        </a>
                                    </li>
                                    {/* main-stage */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'main-stage') ? 'disabled' : customize.selectTab === 'main-stage' && "active"}`} id="live-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('main-stage')}>
                                            <span className="iconTabs"><i className="fas fa-globe-asia"></i></span>
                                            <span className="textTabs">Stage</span>
                                        </a>
                                    </li>
                                    {/* session */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'session') ? 'disabled' : customize.selectTab === 'session' && "active"}`} id="live-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('session')}>
                                            <span className="iconTabs"><i className="fas fa-globe-asia"></i></span>
                                            <span className="textTabs">Sessions</span>
                                        </a>
                                    </li>
                                    {/* articles */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'article') ? 'disabled' : customize.selectTab === 'article' && "active"}`} id="poll-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('article')}>
                                            <span className="iconTabs"><i className="fa-solid fa-newspaper"></i></span>
                                            <span className="textTabs">Articles</span>
                                        </a>
                                    </li>
                                    {/* polling */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'polling') ? 'disabled' : customize.selectTab === 'polling' && "active"}`} id="poll-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('polling')}>
                                            <span className="iconTabs"><i className="fas fa-poll"></i></span>
                                            <span className="textTabs">Polling</span>
                                        </a>
                                    </li>
                                    {/* resource */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'resource') ? 'disabled' : customize.selectTab === 'resource' && "active"}`} id="poll-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('resource')}>
                                            <span className="iconTabs"><i className="fa-solid fa-box-open"></i></span>
                                            <span className="textTabs">Resources</span>
                                        </a>
                                    </li>
                                    {/* speaker-talent */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'speaker-talent') ? 'disabled' : customize.selectTab === 'speaker-talent' && "active"}`} id="poll-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('speaker-talent')}>
                                            <span className="iconTabs"><i className="fa-solid fa-user-group"></i></span>
                                            <span className="textTabs">Speaker/Talent</span>
                                        </a>
                                    </li>
                                    {/* feedback */}
                                    <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'feedback') ? 'disabled' : customize.selectTab === 'feedback' && "active"}`} id="poll-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('feedback')}>
                                            <span className="iconTabs"><i className="fa-solid fa-message"></i></span>
                                            <span className="textTabs">Feedback</span>
                                        </a>
                                    </li>
                                    {/* partner */}
                                    {/* <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'partner') ? 'disabled' : customize.selectTab === 'partner' && "active"} disabled`} id="partner-tab" type="button" role="tab"
                                            // onClick={()=>!customize.disabled && SelectTab('partner')}
                                        >
                                            <span className="iconTabs"><i className="far fa-images"></i></span>
                                            <span className="textTabs">Partner</span>
                                        </a>
                                    </li> */}
                                    {/* sponsor */}
                                    {/* <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'sponsor') ? 'disabled' : customize.selectTab === 'sponsor' && "active"} disabled`} id="sponsor-tab" type="button" role="tab"
                                            // onClick={()=> !customize.disabled && SelectTab('sponsor')}
                                        >
                                            <span className="iconTabs"><i className="far fa-images"></i></span>
                                            <span className="textTabs">Sponsor</span>
                                        </a>
                                    </li> */}
                                    {/* exhibitor */}
                                    {/* <li className="nav-item profTab" role="presentation">
                                        <a className={`nav-link ${(customize.disabled && customize.selectTab !== 'exhibitor') ? 'disabled' : customize.selectTab === 'exhibitor' && "active"} disabled`} id="exhibitor-tab" type="button" role="tab"
                                            // onClick={()=> !customize.disabled && SelectTab('exhibitor')}
                                        >
                                            <span className="iconTabs"><i className="far fa-images"></i></span>
                                            <span className="textTabs">Exhibitor</span>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            <div id="tabpanel_mobile" >
                                <ul className="nav nav-tabs nav-tabs-dropdown" id="customizeList" role="tablist">
                                    {/* homepage-banner */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'homepage-banner' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem',
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'homepage-banner') ? 'disabled' : customize.selectTab === 'homepage-banner' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('homepage-banner')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: !customize?.openMenu && '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fas fa-columns"></i></span>
                                                <span className="textTabs">Homepage Banner</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'homepage-banner' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* main-stage */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'main-stage' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'main-stage') ? 'disabled' : customize.selectTab === 'main-stage' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('main-stage')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fas fa-globe-asia"></i></span>
                                                <span className="textTabs">Stage</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'main-stage' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* session */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'session' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'session') ? 'disabled' : customize.selectTab === 'session' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('session')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fas fa-globe-asia"></i></span>
                                                <span className="textTabs">Sessions</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'session' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* articles */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'article' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'article') ? 'disabled' : customize.selectTab === 'article' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('article')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fa-solid fa-newspaper"></i></span>
                                                <span className="textTabs">Articles</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'article' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* polling */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'polling' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'polling') ? 'disabled' : customize.selectTab === 'polling' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('polling')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fas fa-poll"></i></span>
                                                <span className="textTabs">Polling</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'polling' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* resource */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'resource' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'resource') ? 'disabled' : customize.selectTab === 'resource' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('resource')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fa-solid fa-box-open"></i></span>
                                                <span className="textTabs">Resources</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'resource' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* speaker-talent */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'speaker-talent' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'speaker-talent') ? 'disabled' : customize.selectTab === 'speaker-talent' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('speaker-talent')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fa-solid fa-user-group"></i></span>
                                                <span className="textTabs">Speaker/Talent</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'speaker-talent' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                    {/* feedback */}
                                    <li className="nav-item profTab" role="presentation"
                                        style={{
                                            display: customize?.openMenu || customize.selectTab === 'feedback' ? 'block' : 'none',
                                            borderRadius: !customize?.openMenu && '10rem'
                                        }}
                                    >
                                        <a className={`mobilemenu-custumize nav-link ${(customize.disabled && customize.selectTab !== 'feedback') ? 'disabled' : customize.selectTab === 'feedback' && "active"}`} id="link-tab" type="button" role="tab" onClick={()=> !customize.disabled && SelectTab('feedback')}
                                            style={{
                                                borderRadius: !customize?.openMenu && '0.5rem',
                                                border: !customize?.openMenu && '1px solid white'
                                            }}
                                        >
                                            <div>
                                                <span className="iconTabs"><i className="fa-solid fa-message"></i></span>
                                                <span className="textTabs">Feedback</span>
                                            </div>
                                            <div className="menuTabsIcon"
                                                style={{
                                                    display: !customize?.openMenu && customize.selectTab === 'feedback' ? 'block' : 'none'
                                                }}
                                            ><i className="fas fa-bars"></i></div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 pb-2">
                            <div className="boxForumTabs">
                            </div>
                            <div className="tab-content">	
                                {/* HOMEPAGE */}
                                <div className={`tab-pane fade ${customize.selectTab === 'homepage-banner' && "active show"}`} id="homepage-banner" role="tabpanel" aria-labelledby="link-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-homepage-banner')?.offsetHeight,
                                                width: document.getElementById('borderContentId-homepage-banner')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id="borderContentId-homepage-banner">
                                        <div className="boxCustomizeHead">
                                            <h2>Homepage Banner</h2>
                                        </div>
                                        <div className="boxCustomize" style={{ borderRadius: '0px' }} >
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="type" className="col-sm-3 col-form-label">Type</label>
                                                    <div className="col-sm-9 d-flex">
                                                        <div className="form-check form-check-inline homepage-banner-option" onClick={()=> handleUpdateValue('homepage-banner', 'radio', 'type_banner_elive', 'youtube')}>
                                                            <input className="form-check-input" type="radio" name="type" id="video" value="video" checked={customize.editedData?.type_banner_elive === 'youtube'}/>
                                                            <label className="col-form-label">Youtube</label>
                                                        </div>
                                                        <div className="form-check form-check-inline homepage-banner-option" onClick={()=> handleUpdateValue('homepage-banner', 'radio', 'type_banner_elive', 'image')}>
                                                            <input className="form-check-input" type="radio" name="type" id="image" value="image" checked={customize.editedData?.type_banner_elive === 'image'}/>
                                                            <label className="col-form-label">Image</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="pb-2 row" style={{ padding: '0px 10px 0px 0px' }} >
                                                    <label for="link" className="col-sm-3 col-form-label">Link</label>
                                                    <div className="col-sm-9 container-btn-save">
                                                        <input onChange={e=> handleUpdateValue('homepage-banner', 'input', 'url_banner_elive', e.target.value)} type="text" className="form-control" id="link" value={customize.editedData?.url_banner_elive} placeholder="e.g https://www.youtube.com/watch?v=SCHhkJMxsag&t=8s"/>
                                                        {
                                                            checkingValueSavingButton('homepage-banner', 'url_banner_elive') &&
                                                            <button className="btnLight" onClick={e=> updateData(e,'homepage-banner',{
                                                                url_banner_elive: customize.editedData?.url_banner_elive,
                                                            })} >
                                                                Save
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="boxNotes">
                                            <i>
                                                <strong>Note</strong>: 
                                                <br />Image size must be 21:9 / 1296 x 555 pixel
                                                <br />You can provide url image or video from other sources like youtube, tinypic etc
                                            </i>
                                        </div>

                                        {/* <div className="boxCustomizeFooter">
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight" onClick={e=> updateData(e,customize.selectTab)} >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>

                                {/* STAGE */}
                                <div className={`tab-pane fade ${customize.selectTab === 'main-stage' && "active show"}`} id="stage" role="tabpanel" aria-labelledby="live-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-stage')?.offsetHeight,
                                                width: document.getElementById('borderContentId-stage')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id="borderContentId-stage">
                                        <div className="boxCustomizeHead">
                                            <h2>Stage</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="type" className="col-10 col-form-label">Stage</label>
                                                    <div className="col-2">
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input" type="checkbox" id=""
                                                                checked={Number(customize.editedData?.is_main_stage_elive)}
                                                                onClick={e=> handleUpdateValue('main-stage', 'slide', 'is_main_stage_elive', e.target.checked)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="type" className="col-sm-3 col-form-label">Type</label>
                                                    <div className="col-sm-9 d-flex">										
                                                        <div className="form-check form-check-inline homepage-banner-option" onClick={()=> handleUpdateValue('main-stage', 'radio', 'type', 'zoom_link_elive')}>
                                                            <input className="form-check-input" type="radio" name="type" id="video" checked={customize.editedData?.type === 'zoom_link_elive'}/>
                                                            <label className="col-form-label">Live</label>
                                                        </div>
                                                        <div className="form-check form-check-inline homepage-banner-option" onClick={()=> handleUpdateValue('main-stage', 'radio', 'type', 'url_event_video')}>
                                                            <input className="form-check-input" type="radio" name="type" id="image" checked={customize.editedData?.type === 'url_event_video'}/>
                                                            <label className="col-form-label">Recording</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                {
                                                    customize.editedData?.type === 'zoom_link_elive' ?
                                                        <div className="pb-2 row" style={{ padding: '0px 10px 0px 0px' }} >
                                                            <label for="link" className="col-sm-3 col-form-label d-flex align-items-center">Zoom Link</label>
                                                            <div className="col-sm-9 container-btn-save">
                                                                <input
                                                                    onChange={e=> handleUpdateValue('main-stage', 'input', 'zoom_link_elive', e.target.value)}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="link"
                                                                    value={customize.editedData?.zoom_link_elive}
                                                                    placeholder="e.g https://usweb.zoom.us/..."
                                                                />
                                                                {
                                                                    checkingValueSavingButton('main-stage', 'zoom_link_elive') &&
                                                                    <button className="btnLight" onClick={e=> updateData(e,'main-stage',{
                                                                        zoom_link_elive: customize.editedData?.zoom_link_elive,
                                                                        url_event_video: ''
                                                                    })} >
                                                                        Save
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                    customize.editedData?.type === 'url_event_video' &&
                                                        <div className="pb-2 row" style={{ padding: '0px 10px 0px 0px' }} >
                                                            <label for="link" className="col-sm-3 col-form-label d-flex align-items-center">Video URL</label>
                                                            <div className="col-sm-9 container-btn-save">
                                                                <input
                                                                    onChange={e=> handleUpdateValue('main-stage', 'input', 'url_event_video', e.target.value)}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="link"
                                                                    value={customize.editedData?.url_event_video}
                                                                    placeholder="e.g https://www.youtube.com/watch?v=..."
                                                                />
                                                                {
                                                                    checkingValueSavingButton('main-stage', 'url_event_video') &&
                                                                    <button className="btnLight" onClick={e=> updateData(e,'main-stage',{
                                                                        url_event_video: customize.editedData?.url_event_video,
                                                                        zoom_link_elive: ''
                                                                    })} >
                                                                        Save
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="boxNotes">
                                                <i>
                                                    <strong>Note</strong>: 
                                                    <br />You can provide url zoom or recorded video from other sources like youtube
                                                </i>
                                            </div>
                                        </div>
                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight" onClick={e=> updateData(e, "stage-main-stage")}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>
                                
                                {/* SESSION */}
                                <div className={`tab-pane fade ${customize.selectTab === 'session' && "active show"}`} id="session" role="tabpanel" aria-labelledby="live-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-session')?.offsetHeight,
                                                width: document.getElementById('borderContentId-session')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id="borderContentId-session">
                                        <div className="boxCustomizeHead">
                                            <h2>Sessions</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            {
                                                Array.isArray(customize?.editedData) &&
                                                customize?.editedData?.map((session, index)=>(
                                                    <Fragment key={String(index)}>
                                                        <div className="from-group" style={{ borderBottom: (Number(session?.is_active_elive) || (customize?.editedData?.length -1 === index)) ? 'none' : '1px solid var(--blueColor8)' }} >
                                                            <div className="row">
                                                                <label for="type" className="col-10 col-form-label fw-bold">{ session?.programme_name }</label>
                                                                <div className="col-2">
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox"
                                                                            checked={Number(session?.is_active_elive)} onClick={e=> handleUpdateValue('session', 'slide', 'is_active_elive', e.target.checked, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            Number(session?.is_active_elive) ?
                                                            <div className="from-group" style={{ borderBottom: 'none' }} >
                                                                <div className="row" style={{ padding: '0px 0px 0px 0px' }}>
                                                                    <label for="type" className="col-sm-3 col-form-label">Type</label>
                                                                    <div className="col-sm-9 d-flex">									
                                                                        <div className="form-check form-check-inline homepage-banner-option" onClick={()=> handleUpdateValue('session', 'radio', 'type', 'zoom', index)}>
                                                                            <input className="form-check-input" type="radio" name={`type${index}`} id="video" checked={session?.type === 'zoom'}/>
                                                                            <label className="col-form-label">Live</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline homepage-banner-option" onClick={()=> handleUpdateValue('session', 'radio', 'type', 'record', index)}>
                                                                            <input className="form-check-input" type="radio" name={`type${index}`} id="image" checked={session?.type === 'record'}/>
                                                                            <label className="col-form-label">Recording</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                        {
                                                            Number(session?.is_active_elive) ?
                                                            <div className="from-group">
                                                                <div className="pb-2 row" style={{ padding: '0px 0px 0px 0px' }} >
                                                                    <label for="link" className="col-sm-3 col-form-label d-flex align-items-center">
                                                                        { session?.type === 'zoom' ? 'Zoom Link' : 'Video URL'}
                                                                    </label>
                                                                    <div className={`col-sm-9 container-btn-save ${!Number(session?.is_active_elive) && 'disabled'}`}>
                                                                        {
                                                                            session?.type === 'zoom' ?
                                                                            <input
                                                                                onChange={e=> handleUpdateValue('session', 'input', 'zoom_link_elive', e.target.value, index)}
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="link"
                                                                                value={session?.zoom_link_elive || ''}
                                                                                placeholder="e.g https://usweb.zoom.us/..."
                                                                                disabled={!Number(session?.is_active_elive)}
                                                                            />
                                                                            :
                                                                            <input
                                                                                onChange={e=> handleUpdateValue('session', 'input', 'recording_link_elive', e.target.value, index)}
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="link"
                                                                                value={session?.recording_link_elive || ''}
                                                                                placeholder="e.g https://www.youtube.com/watch?v=SCHhkJMxsag&t=8s"
                                                                                disabled={!Number(session?.is_active_elive)}
                                                                            />
                                                                        }
                                                                        {
                                                                            (
                                                                                session?.type === 'zoom' ?
                                                                                checkingValueSavingButton('session', 'zoom_link_elive', index)
                                                                                :
                                                                                checkingValueSavingButton('session', 'recording_link_elive', index)
                                                                            ) &&
                                                                            <button className="btnLight" onClick={e=> updateData(e,'session',{
                                                                                zoom_link_elive: session?.zoom_link_elive,
                                                                                recording_link_elive: session?.recording_link_elive,
                                                                                id: session?.id,
                                                                                type: session?.type
                                                                            })} >
                                                                                Save
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight" onClick={e=> updateData(e, "stage-sessions")}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>

                                {/* ARTICLES */}
                                <div className={`tab-pane fade ${customize.selectTab === 'article' && "active show"}`} id="article" role="tabpanel" aria-labelledby="poll-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-article')?.offsetHeight,
                                                width: document.getElementById('borderContentId-article')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <div id="borderContentId-article">
                                        <div className="boxCustomizeHead">
                                            <h2>Articles</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            <div className="from-group">
                                                <div className="pb-2 px-4 row">
                                                    <input value={customize.search_article} className="form-control" type="text" id="search-autocomplete" placeholder="Search article" onChange={e=>handleValues(e)} />
                                                </div>
                                            </div>
                                            {
                                                Array.isArray(customize?.editedData) &&
                                                customize?.editedData?.filter(x=>{
                                                    if (customize.search_article) {
                                                        if (((x.judul).toLowerCase()).includes((customize.search_article).toLowerCase())) {
                                                            return x
                                                        }
                                                    } else {
                                                        return x
                                                    }
                                                })
                                                ?.map((article,index)=>(
                                                    <div className="from-group" key={String(index)}>
                                                        <div className="pb-2 row">
                                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                                <div>
                                                                    <label for="type" className="col-10 col-form-label articles-title fw-bold" style={{ paddingBottom: 0, width: '100%' }} >{ article?.judul }</label>
                                                                    <label for="type" className="col-10 col-form-label d-flex gap-3 articles-sec-title" style={{ width: '100%', fontSize: 15, paddingTop: 5, paddingBottom: 0 }}>
                                                                        <div className="d-flex gap-1 align-items-center customize-link" >
                                                                            <i className="fa-solid fa-eye"></i>
                                                                            <span style={{ fontSize: 12 }} > { article?.dibaca } </span>
                                                                        </div>
                                                                        <div className="d-flex gap-1 align-items-center customize-link" style={{ cursor: 'pointer' }} onClick={_=> goToNewTab(`https://blog.livelifeindo.com/detail/${article?.judul_seo}`)}>
                                                                            <i className="fa-solid fa-link"></i>
                                                                            <span style={{ fontSize: 12 }} > go to website </span>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="col-2 d-flex justify-content-end align-items-center h-100">
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" id=""
                                                                            checked={Number(article?.is_active_elive)}
                                                                            onClick={e=> handleUpdateValue('article', 'slide', 'is_active_elive', e.target.checked, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                </div>

                                {/* POLLING */}
                                <div className={`tab-pane fade ${customize.selectTab === 'polling' && "active show"}`} id="polling" role="tabpanel" aria-labelledby="poll-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-polling')?.offsetHeight,
                                                width: document.getElementById('borderContentId-polling')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id='borderContentId-polling' >
                                        <div className="boxCustomizeHead">
                                            <h2>Polling</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            {
                                                Array.isArray(customize?.editedData) &&
                                                customize?.editedData?.map((poll,index)=>(
                                                    <div className="from-group" key={String(index)}>
                                                        <div className="pb-2 row">
                                                            <label for="type" className="col-10 col-form-label">{ poll?.survey_title }</label>
                                                            <div className="col-2">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id=""
                                                                        checked={Number(poll?.is_active_elive)}
                                                                        onClick={e=> handleUpdateValue('polling', 'slide', 'is_active_elive', e.target.checked, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>

                                {/* RESOURCE */}
                                <div className={`tab-pane fade ${customize.selectTab === 'resource' && "active show"}`} id="polling" role="tabpanel" aria-labelledby="poll-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-resource')?.offsetHeight,
                                                width: document.getElementById('borderContentId-resource')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id='borderContentId-resource' >
                                        <div className="boxCustomizeHead">
                                            <h2>Resources</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            {
                                                Array.isArray(customize?.editedData) &&
                                                customize?.editedData?.map((resource,index)=>(
                                                    <div className="from-group" key={String(index)}>
                                                        <div className="pb-2 row">
                                                            <label for="type" className="col-10 col-form-label">{ resource?.file_name }</label>
                                                            <div className="col-2">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id=""
                                                                        checked={Number(resource?.is_active_elive)}
                                                                        onClick={e=> handleUpdateValue('resource', 'slide', 'is_active_elive', e.target.checked, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>

                                {/* SPEAKER/TALENT */}
                                <div className={`tab-pane fade ${customize.selectTab === 'speaker-talent' && "active show"}`} id="polling" role="tabpanel" aria-labelledby="poll-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-talent')?.offsetHeight,
                                                width: document.getElementById('borderContentId-talent')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id='borderContentId-talent' >
                                        <div className="boxCustomizeHead">
                                            <h2>Speaker/Talent</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            {
                                                Array.isArray(customize?.editedData) &&
                                                customize?.editedData?.map((talent,index)=>(
                                                    <div className="from-group" key={String(index)}>
                                                        <div className="pb-2 d-flex flex-row justify-content-between align-items-center">
                                                            <div>
                                                                <label for="type" className="col-10 col-form-label fw-bold" style={{ paddingBottom: 0 }} >{ talent?.talent_role }</label>
                                                                <label for="type" className="col-10 col-form-label" style={{ fontSize: 15, paddingTop: 0 }}>{ talent?.talent_name }</label>
                                                            </div>
                                                            <div className="col-2 d-flex justify-content-end align-items-center h-100">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id=""
                                                                        checked={Number(talent?.is_active_elive)}
                                                                        onClick={e=> handleUpdateValue('speaker-talent', 'slide', 'is_active_elive', e.target.checked, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>

                                {/* FEEDBACK */}
                                <div className={`tab-pane fade ${customize.selectTab === 'feedback' && "active show"}`} id="feedback" role="tabpanel" aria-labelledby="poll-tab">
                                    {
                                        customize.loading &&
                                        <div className="component-loading-customize-homepage"
                                            style={{
                                                height: document.getElementById('borderContentId-feedback')?.offsetHeight,
                                                width: document.getElementById('borderContentId-feedback')?.offsetWidth
                                            }}
                                        >
                                            <div className="spinner-border spinner-custom-btn customize-spinner"  />
                                        </div>
                                    }
                                    <form className="borderContent" id='borderContentId-feedback' >
                                        <div className="boxCustomizeHead">
                                            <h2>Feedback</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            {
                                                Array.isArray(customize?.editedData) &&
                                                customize?.editedData?.map((poll,index)=>(
                                                    <div className="from-group" key={String(index)}>
                                                        <div className="pb-2 row">
                                                            <label for="type" className="col-10 col-form-label">{ poll?.survey_title }</label>
                                                            <div className="col-2">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id=""
                                                                        checked={Number(poll?.is_active_elive)}
                                                                        onClick={e=> handleUpdateValue('feedback', 'slide', 'is_active_elive', e.target.checked, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {/* <div className="boxCustomizeFooter">									
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>

                                {/* PARTNER */}
                                <div className={`tab-pane fade ${customize.selectTab === 'partner' && "active show"}`} id="partner" role="tabpanel" aria-labelledby="partner-tab">
                                    <form className="borderContent">
                                        <div className="boxCustomizeHead">
                                            <h2>Partner</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="type" className="col-sm-3 col-form-label">Name</label>
                                                    <div className="col-sm-9">
                                                        <input
                                                            onChange={e=> handleUpdateValue('partner', 'input', 'filename', e.target.value)}
                                                            type="text"
                                                            className="form-control"
                                                            id="sponsorName"
                                                            value={typeof(customize.editedData?.[0]) === 'string' ? customize.editedData?.[0] : ''}
                                                            placeholder="Name Image"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="link" className="col-sm-3 col-form-label">Image</label>
                                                    <div className="col-sm-9">
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            id="partnerImage"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            onChange={e=> handleUpdateValue('partner', 'input', 'fileimage', e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="boxNotes">
                                            <i>
                                                <strong>Note</strong>: 
                                                <br />Image must be transparent background and recommended image height 40 pixel
                                            </i>
                                        </div>

                                        <div className="boxCustomizeFooter">
                                            
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight"
                                                        onClick={e=>
                                                            updateData(e, 'partner', {
                                                                id: eventState?.event_info?.id_idea
                                                            })
                                                        }
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="clearfix"></div>
                                    <div className="imgLogoContent mt-3">
                                        <ul>
                                            {
                                                customize.partner?.partner_image_elive?.map((image, index)=>(
                                                    <li className="image" key={String(index)}>
                                                        <div className="imgRmv"><span><i className="fas fa-times"></i></span></div>
                                                        <img src={`https://images.livelifeindo.com/upload/logo/partners/${image}`}/>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className={`tab-pane fade ${customize.selectTab === 'sponsor' && "active show"}`} id="sponsor" role="tabpanel" aria-labelledby="sponsor-tab">
                                    <form className="borderContent">
                                        <div className="boxCustomizeHead">
                                            <h2>Sponsor</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="type" className="col-sm-3 col-form-label">Name</label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" id="sponsorName" value=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="link" className="col-sm-3 col-form-label">Image</label>
                                                    <div className="col-sm-9">
                                                        <input type="file" className="form-control" id="sponsorImage" value=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="boxNotes">
                                            <i>
                                                <strong>Note</strong>: 
                                                <br />Image must be transparent background and recommended image height 40 pixel
                                            </i>
                                        </div>

                                        <div className="boxCustomizeFooter">
                                            
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight" onClick={e=> updateData(e, "sponsor")}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="clearfix"></div>
                                    <div className="imgLogoContent mt-3">
                                        <ul>
                                            {
                                                customize.sponsor?.sponsor_image_elive?.map((image, index)=>(
                                                    <li className="image" key={String(index)}>
                                                        <div className="imgRmv"><span><i className="fas fa-times"></i></span></div>
                                                        <img src={`https://images.livelifeindo.com/upload/logo/sponsors/${image}`}/>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className={`tab-pane fade ${customize.selectTab === 'exhibitor' && "active show"}`} id="exhibitor" role="tabpanel" aria-labelledby="exhibitor-tab">
                                    <form className="borderContent">
                                        <div className="boxCustomizeHead">
                                            <h2>Exhibitor</h2>
                                        </div>
                                        <div className="boxCustomize">
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="type" className="col-sm-3 col-form-label">Name</label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" id="sponsorName" value=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="pb-2 row">
                                                    <label for="link" className="col-sm-3 col-form-label">Image</label>
                                                    <div className="col-sm-9">
                                                        <input type="file" className="form-control" id="sponsorImage" value=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="boxNotes">
                                            <i>
                                                <strong>Note</strong>: 
                                                <br />Image must be transparent background and recommended image height 40 pixel
                                            </i>
                                        </div>

                                        <div className="boxCustomizeFooter">
                                            
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight" onClick={e=> updateData(e, "exhibitor")}>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="clearfix"></div>
                                    <div className="imgLogoContent mt-3">
                                        <ul>
                                            {
                                                customize.exhibitor?.exhibitor_image_elive?.map((image, index)=>(
                                                    <li className="image" key={String(index)}>
                                                        <div className="imgRmv"><span><i className="fas fa-times"></i></span></div>
                                                        <img src={`https://images.livelifeindo.com/upload/logo/sponsors/${image}`}/>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                show={modal}
                onHide={e => handlerModal(e,false)}
                size="sm"
                centered
                backdropClassName="modalBackdropPerson"
                className="modaloverlayPerson modalDialogPerson fade zoom-in"
                data-easein="bounceLeftIn"
            >
                <div className="modal-customize" >
                    <div>
                        <span className="txt-modal-customize text-center w-100">
                            Data has changed,
                            <br/>
                            {
                                customize.selectTab === 'session' ? `Please Saving First?` : `Want to save?`
                            }
                        </span>
                        <div className="circle-x">
                            <FontAwesomeIcon icon={faTimesCircle} className="fa-circle" onClick={e=> handlerModal(e, false)}/>
                        </div>
                    </div>
                    <div className="modal-customize-wrap-btn" >
                        {
                            !customize.selectTab === 'session' &&
                            <div onClick={handleSelectYes} className="modal-customize-btn">
                                {
                                    customize.loading ?
                                    <div className="spinner-border spinner-custom-btn customize-spinner"/>:
                                    "Yes"
                                }
                            </div>
                        }
                        <div onClick={handleSelectNo} className="modal-customize-btn">{ customize.selectTab === 'session' ? 'OK' : 'No' }</div>
                    </div>
                </div>
            </Modal>

            {/* CANCEL METHOD */}
            <Modal
                show={modalSession}
                onHide={e => handlerModalSession(e,false)}
                size="sm"
                centered
                backdropClassName="modalBackdropPerson"
                className="modaloverlayPerson modalDialogPerson fade zoom-in"
                data-easein="bounceLeftIn"
            >
                <div className="modal-customize" >
                    <div>
                        <span className="txt-modal-customize">
                            Zoom Link has changed, remove changed?
                        </span>
                        <div className="circle-x">
                            <FontAwesomeIcon icon={faTimesCircle} className="fa-circle" onClick={e=> handlerModalSession(e, false)}/>
                        </div>
                    </div>
                    <div className="modal-customize-wrap-btn" >
                        <div onClick={handleSelectYesSession} className="modal-customize-btn">Yes</div>
                        <div onClick={handleSelectNoSession} className="modal-customize-btn">No</div>
                    </div>
                </div>
            </Modal>

            <BottomNavbar />
        </div>
    );
};

export default Customize