const bootstrap = require('bootstrap');
import React,{ useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import '../../styles/watch.scss'
import NavbarTop from '../../components/TopNavbar'
import BottomNavbar from '../../components/BottomNavbar'
import BannerZoom from './Component/BannerZoom'
import Resource from './Component/Resource'
import DescriptionEvent from './Component/DescriptionEvent'
import QandA from './Component/QandA';
import UserList from './Component/UserList'
import Polling from './Component/Polling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard , faTrashAlt} from '@fortawesome/free-regular-svg-icons'
import { faBars, faHandPointRight, faPaperPlane, faPenFancy, faPoll, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import CryptoJS from "crypto-js";
import { useQuery } from '../../plugins/useQuery'
import { useDispatch, useSelector } from 'react-redux';
import { getSpeakerAndTalent, getPartnerAndTalent, getVendorAndTalent, createQandA } from '../../store/actions/event'
import SpeakersModal from '../../components/SpeakersModal'
import DetailUser from '../../components/DetailUser'
import Chat from '../../components/ChatFirebase'
import firebase from 'firebase/compat/app'
import { useToasts } from 'react-toast-notifications';
import { useCookies } from 'react-cookie';

import NoProduct from '../../images/product.jpg'

const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const Days= ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

firebase.initializeApp({
    apiKey: "AIzaSyBorJDNVFAjnyK8eGTgMP2WOl59-2Au1a8",
    authDomain: "elivebylivelife.firebaseapp.com",
    projectId: "elivebylivelife",
    storageBucket: "elivebylivelife.appspot.com",
    messagingSenderId: "1019869524899",
    appId: "1:1019869524899:web:bce3c9f5697f29ac976016",
    measurementId: "G-6R1V7NBF6F"
});
const dbchats = firebase.firestore()
let unsubscribe;

const Watch = () => {
    const eventState = useSelector(state=> state.eventState.event )
    const Profile = useSelector(state=>state.cacheState.profile)
    const [cookies] = useCookies(['account']);
    const { addToast } = useToasts()
    const QandARef = useRef()
    const Query = useQuery()
    const Dispatch = useDispatch()
    const [modalPerson, setModalPerson] = useState(false);
    const [chatLoad, setChatLoad] = useState(false)
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const [personSelect, setPersonSelect] = useState(null)
    const [personSelectChat, setPersonSelectChat] = useState(null)
    const [QandAValue, setQandAValue] = useState('')

    useEffect(()=>{
        if (eventState) {
            let messageID = eventState && eventState.event_info && (eventState.event_info.project_name).toLowerCase().split(' ').join('')
            unsubscribe = dbchats.collection('event')
            .where("message_id", '==', messageID)
            .orderBy('createdAt', 'desc')
            .limit(1)
            .onSnapshot(async res=>{
                if (res) {
                    const data = res.docs.map(doc=>({
                        ...doc.data(),
                        id: doc.id
                    }))
                    if (data) {
                        setMessages(prev=> [...prev, ...data])
                        let container = document.getElementById('message-body')
                        if (container) container.scrollTop = container.scrollHeight;
                    }
                }
            })
        }
    },[dbchats, eventState])

    useEffect(()=>{
        if (eventState) {
            getChats(dbchats, eventState)
        }
    },[dbchats, eventState])

    const getChats = async (dbchats, eventState) => {
        try {
            if (dbchats && eventState) {
                let messageID = eventState && eventState.event_info && (eventState.event_info.project_name).toLowerCase().split(' ').join('')
                setChatLoad(true)
                await dbchats
                .collection('event')
                .where("message_id", '==', messageIDsort)
                .orderBy('createdAt', 'desc')
                .startAfter(startAfterStore || triggerStore)
                .limit(10)
                .get()
                .then(async res => {
                    let data = []
                    await res.docs.map(doc=>{
                        data.push({
                            ...doc.data(),
                            id: doc.id
                        })
                        if (doc.id) {
                            if (!doc.data().received) {
                                dbchats.collection('notification').doc(doc.id).update({
                                    received: true,
                                })
                            }
                        }
                    })
                    if (data) {
                        data.reverse()
                        if (trigger) {
                            data.pop()
                            setMessages(prev=> [...data, ...prev])
                            let container = document.getElementById('message-body')
                            if (container) container.scrollTop = container.scrollHeight;
                        } else {
                            setMessages(prev=> [...data, ...prev])
                            if (ChatsRef.current) {
                                const { scrollTop, scrollHeight, clientHeight } = ChatsRef.current;
                                const currentScroll = (scrollHeight-(parameters.scrollHeight - 20)) 
                                setParameters({
                                    ...parameters,
                                    scrollHeight: scrollHeight
                                })
                                if (parameters.scrollHeight) ChatsRef.current.scrollTo(0, currentScroll)
                            }
                        }
                    }
                    setChatLoad(false)
                })
            }
        } catch (error) {
            setChatLoad(false)
        }
    }

    const handlerModalPerson = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModalPerson(value)
        } else {
            setModalPerson(value)
        }
    }

    useEffect(()=>{
        var triggerTabList = [].slice.call(document.querySelectorAll('#thisSession a'))
		triggerTabList.forEach(function (triggerEl) {
			var tabTrigger = new bootstrap.Tab(triggerEl)
			triggerEl.addEventListener('click', function (event) {
				event.preventDefault()
				tabTrigger.show()
			})
		});
        $(document).on({
            mouseover: function(event) {
                $(this).find('.far').addClass('star-over');
                $(this).prevAll().find('.far').addClass('star-over');
            },
            mouseleave: function(event) {
                $(this).find('.far').removeClass('star-over');
                $(this).prevAll().find('.far').removeClass('star-over');
            }
        }, '.rate');
    
        $(document).on('click', '.rate', function() {
            if ( !$(this).find('.star').hasClass('rate-active') ) {
                $(this).siblings().find('.star').addClass('far').removeClass('fas rate-active');
                $(this).find('.star').addClass('rate-active fas').removeClass('far star-over');
                $(this).prevAll().find('.star').addClass('fas').removeClass('far star-over');
            } else {
            }
        });

        $("#close-sidebar").click(function() {
            $(".page-wrapper").removeClass("toggled");
        });
        $("#show-sidebar").click(function() {
            $(".page-wrapper").addClass("toggled");
        });

        var delay = 500;
        $(".progress-bar").each(function(i){
        $(this).delay( delay*i ).animate( { width: $(this).attr('aria-valuenow') + '%' }, delay );

            $(this).prop('Counter',0).animate({
                Counter: $(this).text()
            }, {
                duration: delay,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now)+'%');
                }
            });
        });

        $(".chatBtn-1312").click(function(){
            var myAlert =document.getElementById('chatForm');
            var bsAlert = new bootstrap.Toast(myAlert);
            bsAlert.show();
        })

        if (Query.get('id')) {
            let API = Query.get('id').split(' ').join('+')
            let decry = CryptoJS.AES.decrypt(API, 'elive-livelife22')
            let utf8 = decry.toString(CryptoJS.enc.Utf8);
            const convert = JSON.parse(utf8);
        }
    },[])

    const converterDate = (data) => {
        if (data) {
            let flag = null
            let timeStart
            let timeEnd
            let timeZone
            let dateConvert = null
            let dateConvertTo = null
            if(data.from_date) {
                flag = true
                let split = data.from_date.split('/')
                dateConvert = split.reverse().join('-')
                if (data.event_time) {
                    timeStart = data.event_time
                }
                if (data.event_end_time) {
                    timeEnd = data.event_end_time
                }
                if (data.time_zone) {
                    timeZone = data.time_zone
                }
                if(data.to_date) {
                    if (data.from_date !== data.to_date) {
                        let split = data.to_date.split('/')
                        dateConvertTo = split.reverse().join('-')
                    }
                }
            }
            if (!flag) return ' Cooming Soon'
            if (!timeStart) return ' Cooming Soon'
            let day = dateConvert ? new Date(dateConvert).getDay() : null
            let date = dateConvert ? new Date(dateConvert).getDate() : null
            let month = dateConvert ? new Date(dateConvert).getMonth() : null
            let year = dateConvert ? new Date(dateConvert).getFullYear() : null
            let day2 = dateConvertTo ? new Date(dateConvertTo).getDay() : null
            let date2 = dateConvertTo ? new Date(dateConvertTo).getDate() : null
            let month2 = dateConvertTo ? new Date(dateConvertTo).getMonth() : null
            let year2 = dateConvertTo ? new Date(dateConvertTo).getFullYear() : null

            return `${day ? Days[day] : '-'} ${date ? date : '-'} ${month ? Months[month] : '-'}, ${year ? !dateConvertTo ? year : '' : '-'} ${timeStart ? timeStart : ''} ${dateConvertTo ? `${day2 ? ` - ${Days[day2]}` : '-'} ${date2 ? date2 : '-'} ${month2 ? Months[month2] : '-'}, ${year2 ? year2 : '-'} ${timeEnd ? timeEnd : ''}` : `${timeEnd ? `- ${timeEnd}` : ''}`}`
        }
    }

    const handleChange = (key, value) => {
        switch (key) {
            case 'message':
                setMessage(value)
                break;
            default:
                break;
        }
    }

    const handleShowHour = (value) => {
        const dates = new Date(value * 1000)
        let minutes = dates.getMinutes();
        let hour = dates.getHours();
        return `${hour < 10 ? '0'+hour: hour}:${minutes < 10 ? '0'+minutes : minutes}`
    }

    const handleShowDate = (value) => {
        // today
        let dateToday = new Date(new Date()).getDate()
        let monthToday = new Date(new Date()).getMonth()
        let yearToday = new Date(new Date()).getFullYear()
        let today = `${dateToday} ${Months[monthToday]} ${yearToday}`

        if (value) {
            let convrt = value.toDate()
            let date = new Date(convrt).getDate()
            let month = new Date(convrt).getMonth()
            let year = new Date(convrt).getFullYear()
            return `${date} ${Months[month]} ${year}` === today ? 'Today' : `${date} ${Months[month]} ${year}`
        }
        return
    }

    const submit = (e) => {
        if(e) e.preventDefault()
        if (message) {
            let times = firebase.firestore.Timestamp.fromDate(new Date())
            dbchats
            .collection('event')
            .add({
                createdAt: times,
                sender_email: Profile.account_info.email,
                sender_fullname: Profile.account_info.fullname,
                message: message,
                message_id: eventState && eventState.event_info && (eventState.event_info.project_name).toLowerCase().split(' ').join(''),
                photo_sender: Profile.account_info.profile_photo,
            })
            .then((docRef) => {
                setMessage('')
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
            
        }
    }

    const submitQandA = (e) => {
        if(e) e.preventDefault()
        if (QandAValue) {
            Dispatch(createQandA({
                id_user: cookies['account'],
                id_idea: eventState?.event_info?.id_idea,      
                question: QandAValue,      
                answer:null,      
                vote:null
            },(status)=>{
                if (status) {
                    QandARef.current?.refetch(eventState)
                    setQandAValue('')
                    $(".mdl_btn-close").click()
                    addToast('Question Added', { appearance: 'success' });
                }
            }))
        } else {
            addToast('Please write a question', { appearance: 'warning' });
        }
    }

    return (
        <div id="watch-87612">
            <div className="bg-homepage"/>
            <NavbarTop />

            <div className="page-wrapper chiller-theme">
                <span id="show-sidebar" className="btn btn-sm btn-dark">
                    <FontAwesomeIcon icon={faBars} />
                </span>

                <nav id="sidebar" className="sidebar-wrapper">
                    <div className="sidebar-content">
                        <div className="position-fixed sidebarFix">
                            <div className="sidebar-brand">
                                <div id="close-sidebar">
                                    <FontAwesomeIcon icon={faHandPointRight} />
                                </div>
                                <p>This Session</p>
                            </div>
                            <div className="sidebar-top">				
                                <ul className="nav nav-tabs flex-column flex-sm-row" id="thisSession" role="tablist">
                                    <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link" id="qa-tab" data-bs-toggle="tab" data-bs-target="#QATab" type="button" role="tab" aria-controls="QATab" aria-selected="false">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </span>
                                            <span className="textTabs">Q & A</span>
                                        </div>
                                    </li>
                                    <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link" id="poll-tab" data-bs-toggle="pill" data-bs-target="#pollTab" type="button" role="tab" aria-controls="pollTab" aria-selected="false">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faPoll} />
                                            </span>
                                            <span className="textTabs">Polls</span>
                                        </div>
                                    </li>
                                    {/* <li className="flex-sm-fill text-sm-center nav-item" role="presentation">
                                        <div className="nav-link" id="poll-tab" data-bs-toggle="pill" data-bs-target="#chats" type="button" role="tab" aria-controls="chats" aria-selected="false">
                                            <span className="iconTabs">
                                                <FontAwesomeIcon icon={faComments} />
                                            </span>
                                            <span className="textTabs">Chats</span>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>				
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="QATab" role="tabpanel" aria-labelledby="qa-tab">
                                <QandA ref={QandARef} />
                            </div>
                            <div className="tab-pane fade" id="pollTab" role="tabpanel" aria-labelledby="poll-tab">
                                <Polling
                                />
                            </div>
                            <div className="tab-pane fade" id="chats" role="tabpanel" aria-labelledby="poll-tab">
                                <div className="sideContent" style={{ padding: 0, overflow: 'hidden', top: '7rem' }}>
                                    <div className="toast-body"
                                        style={{
                                            minHeight: '35rem',
                                            padding: '0px 10px',
                                        }}
                                        id="message-body"
                                    >
                                        {
                                            chatLoad &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }} >
                                                <div className="spinner-border spinner-custom-btn"/>
                                            </div>
                                        }
                                        {
                                            messages &&
                                            messages.map((message, i)=>(
                                                (message && message.sender_email ) == (Profile && Profile.account_info && Profile.account_info.email) ?
                                                    <div key={i}>
                                                        <div>
                                                            {
                                                                (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt)) &&
                                                                <div className="date-chats" >
                                                                    {
                                                                        handleShowDate(message && message.createdAt)
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="chatMessageWrapper chatRight">
                                                                <div className="chatUserAvatar">
                                                                    {
                                                                        (
                                                                            ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                                            (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                                                        ) &&
                                                                        <img src={Profile && Profile.account_info && Profile.account_info.profile_photo ? Profile.account_info.profile_photo : 'https://images.livelifeindo.com/upload/default/no-profile.png'} alt="LiveLife"/>
                                                                    }
                                                                </div>
                                                                <ul className={
                                                                    (
                                                                        ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                                        (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                                                    ) ? "chatMessage chatMessageFirst" : "chatMessage"}
                                                                >
                                                                    <li>
                                                                        <p>
                                                                            {message && message.message}
                                                                            { (handleShowHour(message && message.createdAt) !== handleShowHour(messages[i+1] && messages[i+1].createdAt))
                                                                            &&
                                                                                <span className="chatMessageTime">{handleShowHour(message && message.createdAt)}</span>
                                                                            }
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div key={i}>
                                                        <div>
                                                            {
                                                                (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt)) &&
                                                                <div className="date-chats" >
                                                                    {
                                                                        handleShowDate(message && message.createdAt)
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="chatMessageWrapper"
                                                                style={{
                                                                    marginTop: messages[i-1] && ((messages[i-1] && messages[i-1].sender_email) !== (message && message.sender_email)) && '.3rem'
                                                                }}
                                                            >
                                                                <div className="chatUserAvatar">
                                                                    {
                                                                        (
                                                                            ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                                            (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                                                        ) &&
                                                                        <img src={person && person.registrant_info && person.registrant_info.profile_photo ? person.registrant_info.profile_photo : 'https://images.livelifeindo.com/upload/default/no-profile.png'} alt="LiveLife"/>
                                                                    }
                                                                </div>
                                                                <ul className={
                                                                    (
                                                                        ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                                        (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                                                    ) ? "chatMessage chatMessageFirst" : "chatMessage"}
                                                                >
                                                                    <li>
                                                                        <p>
                                                                            {message && message.message}
                                                                            { (handleShowHour(message && message.createdAt) !== handleShowHour(messages[i+1] && messages[i+1].createdAt))
                                                                            &&
                                                                                <span className="chatMessageTime">{handleShowHour(message && message.createdAt)}</span>
                                                                            }
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))
                                        }
                                    </div>
                                    <div className="toast-footer"
                                    >
                                        <form>
                                            <div className="row g-2">
                                                <div className="col-sm-10"
                                                    style={{ width: '89%' }}
                                                >
                                                    <input
                                                        value={message}
                                                        type="text" className="form-control" name="liveChat" placeholder="Type a message" autoComplete="off" 
                                                        onChange={e=> handleChange('message', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-sm text-end sendMessage"
                                                    style={{
                                                        display: 'block'
                                                    }}
                                                >
                                                    <button
                                                        disabled={!message}
                                                        type="submit"
                                                        className="BtnCircle"
                                                        onClick={submit}
                                                        style={{
                                                            backgroundColor: !message && '#9897ff',
                                                            color: !message && '#e8e8ff',
                                                            marginLeft: 0
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <main className="page-content">
                    <section className="contentHeader pt-8 pb-4">
                        <BannerZoom eventState={eventState} />
                    </section>

                    <section className="pt-2">
                        <div className="container">
                            <div className="headBlock">
                                <div className="row">
                                    <div className="col-sm-auto col-10 w100">
                                        <div className="headTitleSession">
                                            <h2>{ eventState?.event_info?.project_name }</h2>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="headTimeSession">
                                            <p>{converterDate(eventState?.event_info)}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 d-none">
                                        <div className="row">
                                            <div className="d-flex align-items-center headBlock-media">
                                                <div className="diviRight bd-highlight">
                                                    <span className="headTopBtn" data-bs-toggle="modal" data-bs-target="#takeaNote">
                                                        <FontAwesomeIcon icon={faClipboard} style={{ marginRight: 3 }}/>
                                                        Take a note
                                                    </span>
                                                </div>
                                                <div className="nextDivi-container" >
                                                    <div className="nextDivi bd-highlight">
                                                        <p>Rate this session</p>
                                                    </div>
                                                    <div className="nextDivi bd-highlight">
                                                        <div className="stars">
                                                            <label className="rate">
                                                                <input type="radio" name="radio1" id="star1" value="star1"/>
                                                                <div className="face"/>
                                                                <i className="far fa-star star one-star"/>
                                                            </label>
                                                            <label className="rate">
                                                                <input type="radio" name="radio1" id="star2" value="star2"/>
                                                                <div className="face"/>
                                                                <i className="far fa-star star two-star"/>
                                                            </label>
                                                            <label className="rate">
                                                                <input type="radio" name="radio1" id="star3" value="star3"/>
                                                                <div className="face"/>
                                                                <i className="far fa-star star three-star"/>
                                                            </label>
                                                            <label className="rate">
                                                                <input type="radio" name="radio1" id="star4" value="star4"/>
                                                                <div className="face"/>
                                                                <i className="far fa-star star four-star"/>
                                                            </label>
                                                            <label className="rate">
                                                                <input type="radio" name="radio1" id="star5" value="star5"/>
                                                                <div className="face"/>
                                                                <i className="far fa-star star five-star"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DescriptionEvent eventState={eventState} />
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-2 pb-3">
                        <div className="container">
                            <UserList
                                storeAction={getSpeakerAndTalent}
                                eventState={eventState}
                                setPersonSelect={setPersonSelect}
                                title={'Speakers'}
                            />
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-3">
                        <div className="container">
                            <UserList
                                storeAction={getPartnerAndTalent}
                                eventState={eventState}
                                setPersonSelect={setPersonSelect}
                                title={'Partners'}
                                dataRoot={'media_info'}
                            />
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-3">
                        <div className="container">
                            <UserList
                                storeAction={getVendorAndTalent}
                                eventState={eventState}
                                setPersonSelect={setPersonSelect}
                                title={'Media'}
                                dataRoot={'media_info'}
                            />
                        </div>
                    </section>

                    <section className="sessionWatchHeader pt-2">
                        <div className="container">
                            <Resource
                                eventState={eventState}
                            />
                        </div>
                    </section>

                    {/* HIDE */}
                    <section className="sessionWatchHeader pt-2 pb-5 d-none">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="headContent">
                                        <div className="titleHead">
                                            <h2>Products & Services &nbsp;<span>4</span></h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="borderContent">
                                        <div className="ratio ratio-1x1">
                                            <div className="sesProduct">
                                                <a href="#" rel="nofollow" target="_blank">
                                                    <img src={NoProduct} alt="Booth 1" draggable={false}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="borderContent">
                                        <div className="ratio ratio-1x1">
                                            <div className="sesProduct">
                                                <a href="#" rel="nofollow" target="_blank">
                                                    <img src={NoProduct} alt="Booth 1" draggable={false}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="borderContent">
                                        <div className="ratio ratio-1x1">
                                            <div className="sesProduct">
                                                <a href="#" rel="nofollow" target="_blank">
                                                    <img src={NoProduct} alt="Booth 1" draggable={false}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="borderContent">
                                        <div className="ratio ratio-1x1">
                                            <div className="sesProduct">
                                                <a href="#" rel="nofollow" target="_blank">
                                                    <img src={NoProduct} alt="Booth 1" draggable={false}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </div>

            <div className="modal fade zoom-in" id="takeaNote" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoSessionLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2><i className="far fa-clipboard"></i> &nbsp; Take a note</h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <form className="formInput">
                            <div className="modal-body">
                                <div className="modalContentText">
                                    <div className="form-group">
                                        <textarea id="takeaNote" className="form-control form-control-sm" rows="6" placeholder="Take a note..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modalActLeft">
                                    {/* <span className="btnActionCircle">
                                        <FontAwesomeIcon icon={faTrashAlt} /> 
                                    </span> */}
                                </div>
                                <div className="modalAction">
                                    {/* <span className="btn btnActionModal disabled" >View all</span> */}
                                    <span id="saveNote" className="btn btnActionModal">Save</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade zoom-in" id="addQuestion" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoSessionLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header modalNormalize">
                            <h2><i className="far fa-clipboard"></i> &nbsp; Ask a question</h2>
                            <div className="closeModal">
                                <button type="button" className="btn-close mdl_btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <form className="formInput">
                            <div className="modal-body">
                                <div className="modalContentText">
                                    <div className="form-group">
                                        <textarea onChange={e=> setQandAValue(e.target.value)} id="takeaNote" className="form-control form-control-sm" rows="6" placeholder="Write a question..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modalActLeft">
                                    {/* <span className="btnActionCircle">
                                        <FontAwesomeIcon icon={faTrashAlt} /> 
                                    </span> */}
                                </div>
                                <div className="modalAction">
                                    <button id="saveNote" onClick={submitQandA} className="btn btnActionModal">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Modal
                show={modalPerson}
                onHide={e => handlerModalPerson(e,false)}
                size="lg"
                centered
                backdropClassName="modalBackdropPerson"
                className="modaloverlayPerson modalDialogPerson fade zoom-in"
                data-easein="bounceLeftIn"
            >
                <SpeakersModal onHide={e => handlerModalPerson(e,false)}/>
            </Modal>

            <div className="modal fade" id="usrDetail" role="dialog" aria-labelledby="usrDetailLabel">
                <DetailUser
                    person={personSelect}
                    setPersonSelect={setPersonSelect}
                    setPersonSelectChat={setPersonSelectChat}
                />
            </div>

            <div id="chatForm" role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-bs-autohide="false">
                <Chat person={personSelectChat}/>
            </div>

            <BottomNavbar />
        </div>
    );
};

export default Watch