import React,{ Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/login.scss';
import ImageLogo from '../../images/logo_indo_beta.png';
import NoImage from '../../images/no-image.png';
import { useQuery } from '../../plugins/useQuery';
import { getEventByKey } from '../../store/actions/event';
import { checkingEmailUser, checkingEmailAttendee, createUser, createAttendee, UpdateUser } from '../../store/actions/signUpIn';
import NotFound from '../404';
import Loading from '../../components/Loading';
import CountryPhone from '../../components/CountryPhone';
import Alert from '../../components/Alert';
import $ from 'jquery';
import MD5 from "crypto-js/md5";
import IntlTelInput from 'react-intl-tel-input';

const Register = () => {
    const Query = useQuery()
    const History = useHistory()
    const Dispatch = useDispatch()
    const Event = useSelector(state=> state.eventState.event)
    const [notFoundPages, setNotFoundPages] = useState(true)
    const [paramsClick, setParamsClick] = useState(false)
    const [alertEmail, setAlertEmail] = useState('normal')
    const [alertEmailStatus, setAlertEmailStatus] = useState(null)
    const [checkingAgain, setCheckingAgain] = useState(false)
    const [alertProps, setAlertProps] = useState({
        text: '', //string
        variant: '', //danger //warning //success
        show: false, //true //false
        close: false, //true //false,
        loading: false
    });
    const [params, setParams] = useState({
        email: '',
        firstName: '',
        lastName: '',
        // organization: '',
        // profession: '',
        phone_dialCode: '',
        phone_code: 'id',
        phone: '',
    })

    useEffect(()=>{
        if (!Query.get('event')) {
            setNotFoundPages(null)
        } else if (Query.get('event') && !Event) {
            Dispatch(getEventByKey(Query.get('event')))
        }
    },[Query.get('event')])

    useEffect(()=>{
        if (Event == '404') {
            setNotFoundPages(null)
        } else {
            if (Event) {
                if (Event.event_pricing) {
                    let prices = Event.event_pricing
                    prices.forEach(({price})=>{
                        if (Number(price) !== 0) {
                            window.open(`https://livelifeindo.com/event/view?key=${Query.get('event')}`,"_self")
                        }
                    })
                }
            }
        }
    },[Event])

    useEffect(()=>{
        if (alertEmail === 'normal') setAlertEmail('waiting')
        if (params.email && validateEmail(params.email)) {
            setAlertProps({
                text: '',
                variant: '',
                show: false,
                close: false,
                loading: true
            })
            var sec = 5;
            var timer = setInterval(function(){
                if (params.email !== $('#email-input').val()) clearInterval(timer);
                sec--;
                if (sec < 0) {
                    clearInterval(timer);
                    Dispatch(checkingEmailUser(params.email, (status, userId)=>{
                        if (userId && status) {
                            Dispatch(checkingEmailAttendee(userId, Query.get('event'), (status)=>{
                                if (status === true) {
                                    setAlertProps({
                                        text: 'Email has registered for this event, please login',
                                        variant: 'danger',
                                        show: true,
                                        close: false,
                                        loading: false
                                    })
                                    // console.log('Attendee');
                                } else {
                                    if (status === false) {
                                        setAlertProps({
                                            text: '',
                                            variant: '',
                                            show: false,
                                            close: false,
                                            loading: false
                                        })
                                        // console.log('User');
                                        if (alertEmail !== 'normal') setAlertEmail('normal')
                                        setAlertEmailStatus('attendee')
                                    }
                                }
                            }))
                        } else {
                            if (status === false) {
                                setAlertProps({
                                    text: '',
                                    variant: '',
                                    show: false,
                                    close: false,
                                    loading: false
                                })
                                // console.log('Not User');
                                if (alertEmail !== 'normal') setAlertEmail('normal')
                                setAlertEmailStatus('user')
                            }
                        }
                    }))
                }
            }, 1000);
        } else {
            clearInterval(timer)
            setAlertProps({
                text: '',
                variant: '',
                show: false,
                close: false,
                loading: false
            })
        }
    },[params.email, checkingAgain])

    const getValue = (data, key) => {
        if (data) {
            if (data.event_info) {
                if (data.event_info[key]) {
                    if (key === 'venue_name') {
                        return `${data.event_info[key]}, `
                    }
                    if (key === 'venue_address') {
                        return `${data.event_info[key]}, `
                    }
                    return data.event_info[key]
                }
            }
        }
        return ''
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const setValue = (value, key) => {
        switch (key) {
            case 'email':
                setParams({
                    ...params,
                    email: value
                })
                break;
            case 'firstName':
                setParams({
                    ...params,
                    firstName: value
                })
                break;
            case 'lastName':
                setParams({
                    ...params,
                    lastName: value
                })
                break;
            // case 'organization':
            //     setParams({
            //         ...params,
            //         organization: value
            //     })
            //     break;
            // case 'profession':
            //     setParams({
            //         ...params,
            //         profession: value
            //     })
            //     break;
            case 'phone':
                setParams({
                    ...params,
                    phone: value
                })
                break;
            default:
                break;
        }
    }

    const submit = (e) => {
        if (e) e.preventDefault()
        setParamsClick(true)
        let flag = true
        for(let key in params){
            if (!params[key]) {
                setAlertProps({
                    ...alertProps,
                    text: 'please complete the form..',
                    variant: 'danger',
                    show: true,
                    close: true
                })
                flag = false
                return
            }
        }
        if (flag) {
            if (alertEmail === 'normal') {
                if (alertEmail !== 'loading') setAlertEmail('loading')
                if (alertEmailStatus === 'attendee') {
                    Dispatch(checkingEmailUser(params.email, (status, userId)=>{
                        if (userId && status) {
                            let objectDataUser = {
                                id: userId,
                                fullname: `${params.firstName} ${params.lastName}`,
                                // organization: params.organization,
                                // profession: params.profession,
                                phone: `${params.phone}`,
                                phone_code: `${params.phone_code}`,
                                phone_dialCode: `${params.phone_dialCode}`
                            }
                            Dispatch(UpdateUser(objectDataUser, (status, id)=>{
                                if (status === true && id) {
                                    let objectDataAttendee = {
                                        active: 1,
                                        id_idea: Query.get('event'),
                                        id_user: id,
                                        id_status: 6,
                                        book_for: 0,
                                        price_tag: `IDR`,
                                        price_key: 0,
                                        price: 0,
                                        is_onthespot: 1,
                                    }
                                    Dispatch(createAttendee(objectDataAttendee, (status)=>{
                                        if (status !== 'error') {
                                            setAlertProps({
                                                ...alertProps,
                                                text: 'Register is success, please check your email',
                                                variant: 'success',
                                                show: true,
                                                close: true
                                            })
                                            if (alertEmail !== 'success') setAlertEmail('success')
                                        } else {
                                            setAlertProps({
                                                ...alertProps,
                                                text: 'Create Attendee Problem..',
                                                variant: 'danger',
                                                show: true,
                                                close: true
                                            })
                                            if (alertEmail !== 'loading') setAlertEmail('loading')
                                        }
                                    }))
                                } else {
                                    setAlertProps({
                                        ...alertProps,
                                        text: 'Create User Problem..',
                                        variant: 'danger',
                                        show: true,
                                        close: true
                                    })
                                    if (alertEmail !== 'loading') setAlertEmail('loading')
                                }
                            }))
                        }
                    }))
                } else if(alertEmailStatus === 'user') {
                    let password = `${Math.random().toString(36).slice(-6)}`
                    console.log(password);
                    let objectDataUser = {
                        active: 1,
                        type_id: 2,
                        fullname: `${params.firstName} ${params.lastName}`,
                        email: params.email,
                        activation_code: `${MD5(`livelifesg2015${params.email}`)}`,
                        password: `${MD5(password)}`,
                        // organization: params.organization,
                        // profession: params.profession,
                        phone: `${params.phone}`,
                        phone_code: `${params.phone_code}`,
                        phone_dialCode: `${params.phone_dialCode}`,
                        profileVisible: 1,
                        profileSearchable: 1,
                        groupsVisible: 1,
                        ageVisible: 1,
                        photoVisible: 1,
                        updatesVisible: 1,
                        eventsVisible: 1,
                        openInvite: 1,
                        popup_posting: 1
                    }
                    Dispatch(createUser(objectDataUser,((status, id)=>{
                        if (status === true && id) {
                            let objectDataAttendee = {
                                active: 1,
                                id_idea: Query.get('event'),
                                id_user: id,
                                id_status: 6,
                                book_for: 0,
                                price_tag: `IDR`,
                                price_key: 0,
                                price: 0,
                                is_onthespot: 1,
                            }
                            Dispatch(createAttendee(objectDataAttendee, (status)=>{
                                if (status !== 'error') {
                                    setAlertProps({
                                        ...alertProps,
                                        text: 'Register is success, please check your email',
                                        variant: 'success',
                                        show: true,
                                        close: true
                                    })
                                    if (alertEmail !== 'success') setAlertEmail('success')
                                } else {
                                    setAlertProps({
                                        ...alertProps,
                                        text: 'Create Attendee Problem..',
                                        variant: 'danger',
                                        show: true,
                                        close: true
                                    })
                                    if (alertEmail !== 'loading') setAlertEmail('loading')
                                }
                            }))
                        } else {
                            setAlertProps({
                                ...alertProps,
                                text: 'Create User Problem..',
                                variant: 'danger',
                                show: true,
                                close: true
                            })
                            if (alertEmail !== 'loading') setAlertEmail('loading')
                        }
                    })))
                }
            } else {
                setCheckingAgain(!checkingAgain)
                setAlertProps({
                    ...alertProps,
                    text: 'Waiting for email checking..',
                    variant: 'danger',
                    show: true,
                    close: true
                })
            }
        }
    }

    const processNumber = (isValid, phone, country, fullNumber) => {
        return fullNumber.replace(/(\s|-)/g, '');
    };

    const processNumberCountry = (isValid, phone, country) => {
        setParams({
            ...params,
            phone_code: country.iso2,
            phone_dialCode: country.dialCode,
        })
    };

    return (
        notFoundPages ?
        <Fragment>
            <Loading status={Event}/>
            <div className="website-logo">
                <div className="logo">
                    <img className="logo-size" src={ImageLogo} alt="livelifeindo" draggable={false}/>
                </div>
            </div>
            <div className="event-login">
                <div className="img-holder">
                    <div className="bg"
                        style={{
                            backgroundImage: Query.get('event') && `url(${getValue(Event, 'event_photo')})`
                        }}
                    />
                    <div className="info-holder">
                        {
                            <img
                                src={Query.get('event') && getValue(Event, 'event_photo')}
                                alt="livelifeindo" draggable={false}
                                onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                            />
                        }
                    </div>
                </div>

                <div className="form-holder">
                    <div className="form-content align-middle d-flex justify-content-center">
                        <div className="form-items">
                            <h2>
                                {
                                    Query.get('event') &&
                                    getValue(Event, 'title')
                                }
                            </h2>
                            <p style={{ marginBottom: 2 }} >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'venue_name')} ${getValue(Event, 'venue_address')} ${getValue(Event, 'venue_city')}`
                                }
                            </p>
                            <p className="regist-timeD" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - ${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                }
                            </p>
                            <p className="regist-timeM" style={{ marginBottom: 2 }}>
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - `
                                }
                            </p>
                            <p className="regist-timeM" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                } 
                            </p>
                            <form autoComplete="off" className="animation-input-lgn">
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'flex'
                                    }}
                                >
                                    <input className="form-control" type="text" id="email-input" placeholder="E-mail Address" autoComplete="off"
                                        style={{
                                            backgroundColor: ((!validateEmail(params.email) && params.email) || (paramsClick && !params.email) ||alertProps.text === 'Email has registered for this event, please login') && '#f6d6d9'
                                        }}
                                        onChange={e=>setValue(e.target.value, 'email')}
                                        value={params.email}
                                    />
                                    {
                                        alertProps.loading &&
                                        <div className="spinner-border spinner-loading-form"/>
                                    }
                                </div>
                                <div className="row form-firstlastname-wrap ">
                                    <div className="col-md-6 form-firstname">
                                        <input
                                            style={{
                                                backgroundColor: (paramsClick && !params.firstName) && '#f6d6d9',
                                            }}
                                            className="form-control"
                                            type="text"
                                            placeholder="Firstname"
                                            autoComplete="off"
                                            onChange={e=>setValue(e.target.value, 'firstName')}
                                            value={params.firstName}
                                        />
                                    </div>
                                    <div className="col-md-6 form-lastname">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Lastname"
                                            autoComplete="off"
                                            onChange={e=>setValue(e.target.value, 'lastName')}
                                            value={params.lastName}
                                            style={{
                                                backgroundColor: (paramsClick && !params.lastName) && '#f6d6d9',
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <input className="form-control" type="text" placeholder="Organization" autoComplete="off"
                                    style={{
                                        backgroundColor: (paramsClick && !params.organization) && '#f6d6d9',
                                    }}
                                    onChange={e=>setValue(e.target.value, 'organization')}
                                    value={params.organization}
                                />
                                <input className="form-control" type="text" placeholder="Profession" autoComplete="off"
                                    style={{
                                        backgroundColor: (paramsClick && !params.profession) && '#f6d6d9',
                                    }}
                                    onChange={e=>setValue(e.target.value, 'profession')}
                                    value={params.profession}
                                /> */}
                                <IntlTelInput
                                    format={false}
                                    formatOnInit={false}
                                    separateDialCode={true}
                                    fieldId="phone"
                                    defaultCountry={params.phone_code}
                                    containerClassName="intl-tel-input"
                                    inputClassName="form-control"
                                    onPhoneNumberFocus={(...args) => {
                                        processNumberCountry(...args);
                                    }}
                                    onPhoneNumberChange={(...args) => {
                                        setValue(processNumber(...args), 'phone');
                                    }}
                                />
                                <div className="form-button">
                                    <button
                                        disabled={(alertEmail !== 'normal' ) ? true : false}
                                        id="submit" onClick={e=>submit(e)} className="ibtn"
                                        style={{
                                            opacity: (alertEmail !== 'normal' ) && '0.7',
                                            width: '115px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            alertEmail === 'loading' ?
                                            <div className="spinner-border spinner-custom-btn"  />
                                            :
                                            `Register`
                                        }
                                    </button>
                                </div>
                            </form>
                            <div className="option-section-3871" >
                                Already registered? <span onClick={_=> History.push(`/login?event=${Query.get('event')}`) } > Login </span>
                            </div>
                            <Alert
                                show={alertProps.show}
                                text={alertProps.text}
                                variant={alertProps.variant}
                                setAlertProps={setAlertProps}
                                close={alertProps.close}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
        :
        <NotFound
            title={'Event'}
        />
    );
};

export default Register