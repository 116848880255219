import React, { useEffect, useState } from 'react';
import Imagebg1 from '../images/bg1.jpg'
import LLLogoSquare from '../images/ll.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkRegular, faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faCaretUp, faSlidersH, faPaperPlane, faUser, faChevronLeft, faChevronRight  } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux';
import { getProfileDetail } from '../store/actions/checking'
import NoProfile from '../images/no-profile.png'

function Detail(props) {
    const {
        person,
        // setPersonSelectChat,
    } = props
    const Dispatch = useDispatch()
    const [user, setUser] = useState(null)

    useEffect(()=>{
        setUser(null)
        if (person) {
            const {
                registrant_info
            } = person
            if (registrant_info) {
                if (registrant_info.id === 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw') {
                    setUser({
                        account_info: {
                            fullname: registrant_info.fullname,
                            id: registrant_info.id
                        },
                        contact_detail: {
                            profession: registrant_info.profession,
                            organization: registrant_info.organization
                        }
                    })
                }
                Dispatch(getProfileDetail(registrant_info.id_user ,(status, data)=>{
                    if (status) {
                        setUser(data)
                    } else {
                        setUser(null)
                    }
                }))
            } else if (person) {
                const {
                    id_user,
                    name,
                    role,
                    photo,
                    description,
                    email
                } = person
                if (id_user) {
                    setUser({
                        registrant_info:{
                            email: email
                        },
                        account_info: {
                            fullname: name,
                            id: id_user,
                            profile_photo: photo,
                            about: description,
                        },
                        contact_detail: {
                            profession: role,
                            organization: ''
                        }
                    })
                }
            }
        }
    },[person])

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const goToNewTab = () => {
        const win = window.open(`https://livelifeindo.com/profile/view/${user && user.account_info && user.account_info.id}`, "_blank");
        win.focus();
    }

    return (
        <div className="modal-dialog come-from-modal right" role="document">
            <div className="modal-content min-vh-100">
                <div className="bgUsrProfile">
                    <div className="usrClose">
                        <div className="btn-close" data-bs-toggle="modal" data-bs-target="#usrDetail"/>
                    </div>
                    <img src={(user?.account_info?.profile_photo) || NoProfile} alt="Background Name"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = NoProfile;
                        }}
                    />
                </div>
                <div className="usrProfDetail">
                    <img src={(user?.account_info?.profile_photo) || NoProfile} alt="LiveLife Indonesia"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = NoProfile;
                        }}
                    />
                    <h2>{user && handleLimitString(user?.account_info?.fullname, 26)}</h2>
                    <span>{user && handleLimitString(`${user?.contact_detail?.profession || ''}${user?.contact_detail?.organization ? ` at ${user.contact_detail.organization}` : ''}`, 38)}</span>
                </div>
                <div className="usrActDetail">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="p-2">
                                <span className="headTopBtn chatBtn-1312 disabled"
                                // onClick={_=> setPersonSelectChat(user)}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} /> Chat
                                </span>
                            </div>
                            <div className="p-2">
                                <span className="headTopBtn" onClick={goToNewTab}>
                                    <FontAwesomeIcon icon={faUser} /> View profile
                                </span>
                            </div>
                            <div className="p-2">
                                <span className="headTopBtn disabled">
                                    <FontAwesomeIcon icon={faBookmarkRegular} /> Bookmark
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="setupMeet">
                    <div className="row">
                        <div className="col-sm-auto">
                            <h5>Setup Meeting with LiveLife</h5>
                        </div>
                        <div className="col-sm-12">
                            <div className="borderContent mb-1">
                                <div className="paddingContent pt-4 pb-4">
                                    <span className="headTopBtn collapsed" data-bs-toggle="collapse" href="#meetPeople">
                                        <span className="if-collapsed">More Slots <FontAwesomeIcon icon={faCaretDown} /></span>
                                        <span className="if-not-collapsed">Less Slots <FontAwesomeIcon icon={faCaretUp} /></span>
                                    </span>
                                    <div className="collapse" id="meetPeople">
                                        <div className="well">
                                            <div className="timeline">
                                                <div className="timelineMeet">
                                                    <div className="timelineMeetIcon">
                                                        <span className="timelineMeetNumber">01</span>
                                                    </div>
                                                    <div className="timelineMeetContent">
                                                        <div className="tlMeetTitle">
                                                            <h5>Choose Date</h5>
                                                        </div>
                                                        <div className="chooseDateDropArea">
                                                            <button type="button" title="" className="headTopBtn">
                                                                <span className="icon">
                                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                                </span>
                                                                <span>Available Dates</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timelineMeet">
                                                    <div className="timelineMeetIcon">
                                                        <span className="timelineMeetNumber">02</span>
                                                    </div>
                                                    <div className="timelineMeetContent">
                                                        <div className="tlMeetTitle">
                                                            <h5>Choose Time</h5>
                                                        </div>
                                                        <div className="timelineDescription">
                                                            <div className="filterBlock">
                                                                <div className="chooseDateDropArea">
                                                                    <button type="button" title="" className="headTopBtn">
                                                                        <span className="icon">
                                                                            <FontAwesomeIcon icon={faClock} />
                                                                        </span>
                                                                        <span>Available slots</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timelineMeet tlAgenda">
                                                    <div className="timelineMeetIcon">
                                                        <span className="timelineMeetNumber">03</span>
                                                    </div>
                                                    <div className="timelineMeetContent">
                                                        <div className="tlMeetTitle">
                                                            <h5>Agenda for the meeting</h5>
                                                        </div>
                                                        <div className="timeline__event__description">
                                                            <div className="TextAreaWrapper">
                                                                <textarea id="" name="" placeholder="Enter your invite message here" rows="4" cols="50" className="inputTextArea rounded valid " defaultValue="Hi LiveLife, I would like to connect with you."></textarea>
                                                                <span className="charLimiText">
                                                                    <p className=" bgColor normal typoText bodySmall" data-testid="" title="">254</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tlAction">
                                                <div className="btnWrapper mt10">
                                                    <button title="" type="button" data-testid="" id="" className="headTopBtn">Send</button>
                                                </div>
                                                <div className="btnWrapper mt10">
                                                    <button title="" type="button" data-testid="" id="" className="headTopBtn">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="aboutProfile">
                    <div className="row">
                        <div className="col-sm-auto">
                            <h5>About</h5>
                        </div>
                        <div className="col-sm-12">
                            <p>
                                {
                                    user?.account_info?.about || '-'
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detail;