import React , { memo, useRef } from 'react'
import OwlCarousel from "react-owl-carousel";
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import NoProfile from '../../../images/no-profile.png'

const UserList = (props) => {
    const {
    } = props
    const peopleAttendee = useSelector(state=> state.eventState.peopleAttendee )
    const NavigationOwl = useRef();
    const History = useHistory()

    const handleOwlNav = (e, ops) => {
        if(e) e.preventDefault()
        if (ops === 'prev') {
            NavigationOwl.current.prev(500)
        } else if (ops === 'next') {
            NavigationOwl.current.next(500)
        }
    }

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    return (
        peopleAttendee?.length >= 6 &&
        <section className="pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <div className="headContent">
                            <div className="titleHeadOwl">
                                <h2>People &nbsp;<span>Featured</span></h2>
                            </div>
                            <div className="customNavigation">
                                <span className="viewAll" onClick={_=> History.push('/people', { attendee: true, speaker: false })} >View all</span>
                                <span className="btn gray pplPrev" onClick={e=> handleOwlNav(e, 'prev') }>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                                <span className="btn gray pplNext" onClick={e=> handleOwlNav(e, 'next') }>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <OwlCarousel
                        key={peopleAttendee}
                        id="people"
                        className="owl-carousel"
                        responsiveClass
                        responsive={
                            {
                                0:{items:2,nav:false},
                                600:{items:4,nav:false},
                                1000:{items:6,nav:false,loop:false}
                            }
                        }
                        margin={10}
                        ref={NavigationOwl}
                    >
                        {
                            peopleAttendee &&
                            peopleAttendee.map((person, i)=>(
                                <div className="item" key={i.toString()}>
                                    <div className="imgPeople">
                                        <div id="a" className="imgPeopleBorder">
                                            <img id="q" src={person && person.registrant_info && person.registrant_info.profile_photo} className="blur ds-pa"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = NoProfile;
                                                }}
                                            />
                                            <div className="topBooth text-end">
                                                <div className="bmBtnCircle disabled">
                                                    <FontAwesomeIcon icon={faBookmark} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="descPeople">
                                        <h4>{ person && person.registrant_info && handleLimitString(person.registrant_info.fullname, 17) }</h4>
                                        <p>{ person && person.registrant_info && handleLimitString(`${person.registrant_info.profession ? person.registrant_info.profession : ''}${person.registrant_info.profession ? ` at ${person.registrant_info.organization}` : ''}`, 20) }</p>
                                    </div>
                                </div>
                            ))
                        }
                    </OwlCarousel>

                </div>
            </div>
        </section>
    )
};

export default memo(UserList);