const bootstrap = require('bootstrap');
import React,{ useState, useEffect, useRef, useCallback } from 'react';
import $ from 'jquery'
import '../styles/inbox.scss'
import NavbarTop from '../components/TopNavbar'
import BottomNavbar from '../components/BottomNavbar'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faSmileWink,  } from '@fortawesome/free-regular-svg-icons'
import { faPaperPlane, faMicrophoneAlt, faSearch, faVideo, faPhoneAlt, faPenNib,  } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { sendChat, getChat } from '../store/actions/chat'
import { SEND_CHAT_ACTION } from '../store/constants'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import NoProfile from '../images/no-profile.png'

firebase.initializeApp({
    apiKey: "AIzaSyBorJDNVFAjnyK8eGTgMP2WOl59-2Au1a8",
    authDomain: "elivebylivelife.firebaseapp.com",
    projectId: "elivebylivelife",
    storageBucket: "elivebylivelife.appspot.com",
    messagingSenderId: "1019869524899",
    appId: "1:1019869524899:web:bce3c9f5697f29ac976016",
    measurementId: "G-6R1V7NBF6F"
});
const dbchats = firebase.firestore()

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const Days= ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
let unsubscribe;
let personSelectSubs;

const Inbox = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['account']);
    const History = useHistory()
    const Profile = useSelector(state=>state.cacheState.profile)
    const [chatsLoad, setChatsLoad] = useState(false)
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const [selectMessages, setSelectMessages] = useState([])
    const [people, setPeople] = useState([])
    const [peopleSelect, setPeopleSelect] = useState(null)

    useEffect(()=>{
        if (selectMessages.length !== 0) {
            let container = document.getElementById('chat-content')
            if (container) container.scrollTop = container.scrollHeight;
        }
    },[selectMessages])

    useEffect(()=>{
        if (Profile) {
            if (Profile.account_info) {
                getChats(dbchats, Profile)
            }
        }
    },[dbchats, Profile])

    const getChats = async (dbchats, Profile) => {
        try {
            if (dbchats && Profile) {
                setChatsLoad(true)
                unsubscribe = await dbchats
                .collection('chat')
                .where("chats_find", 'array-contains', Profile.account_info.email)
                .orderBy('createdAt', 'desc')
                .onSnapshot(async res => {
                    const data = res.docs.map(doc=>({
                        ...doc.data(),
                        id: doc.id
                    }))
                    let peopleChats = []
                    let peopleChatsCheck = []
                    let result = data.reduce((prev, current) => {
                        if (current.message_id) {
                            let splitMail = current.message_id.split('x')
                            splitMail.map(mail=>{
                                if (mail !== Profile.account_info.email) {
                                    if (peopleChatsCheck.indexOf(mail) === -1) {
                                        peopleChatsCheck.push(mail)
                                        peopleChats.push({
                                            email : current.email !== Profile.account_info.email ? current.email : mail,
                                            fullname : current.email !== Profile.account_info.email ? current.fullname : current.sender_fullname,
                                            photo : current.email !== Profile.account_info.email ? current.photo : current.photo_sender,
                                            message : current.message,
                                            createdAt: current.createdAt,
                                            id: current.message_id
                                        })
                                    }
                                }
                            })
                        }
                        prev[current.message_id] = prev[current.message_id] || [];
                        prev[current.message_id].unshift(current);
                        return prev;
                    }, Object.create(null));
                    setPeople(peopleChats);
                    if (personSelectSubs) {
                        peopleChats.map(person=>{
                            if (person.id === personSelectSubs.id) {
                                setPeopleSelect(person)
                                if (result[person.id]) {
                                    setSelectMessages(result[person.id])
                                }
                            }
                        })
                        
                    }
                    setMessages(result)
                },(error)=>{
                    setChatsLoad(false)
                    console.error(error);
                })
            }
        } catch (error) {
            setChatsLoad(false)
            console.error(error);
        }
    }

    const submit = (e) => {
        if(e) e.preventDefault()
        if (message && peopleSelect) {
            let times = firebase.firestore.Timestamp.fromDate(new Date())
            dbchats
            .collection('chat')
            .add({
                createdAt: times,
                email: peopleSelect.email,
                sender_email: Profile.account_info.email,
                fullname: peopleSelect.fullname,
                sender_fullname: Profile.account_info.fullname,
                message: message,
                message_id: peopleSelect.id,
                photo: peopleSelect.photo,
                photo_sender: Profile.account_info.profile_photo,
                chats_find : [peopleSelect.email, Profile.account_info.email]
            })
            .then((docRef) => {
                setMessage('')
                dbchats.collection('notification').add({
                    id_message: docRef.id,
                    createdAt: times,
                    email: peopleSelect.email,
                    sender_email: Profile.account_info.email,
                    fullname: peopleSelect.fullname,
                    sender_fullname: Profile.account_info.fullname,
                    received: false,
                    message: message,
                    message_id: peopleSelect.id,
                    photo: Profile.account_info.profile_photo,
                    type: 'chat'
                })
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
            
        }
    }

    const handleSelectPeople = (data) => {
        setPeopleSelect(data)
        personSelectSubs = data
        if (messages[data.id]) {
            setSelectMessages(messages[data.id])
        } else {
            setSelectMessages([])
        }
    }

    const timeSince = (date) => {
        let dates = date.toDate()
        let seconds = Math.floor((new Date() - dates) / 1000);
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    }

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    return (
        <div id="forum-87612">
            <div className="bg-homepage"/>
            <NavbarTop
                inboxNew={unsubscribe}
                inboxNewPerson={personSelectSubs}
            />

            <section className="forum pt-8 pb-5">
                <div className="container">
                    <div className="chat-wrapper">
                        <div className="chat-sidebar">
                            <div className="chat-sidebar-header">
                                <div className="d-flex align-items-center">
                                    <div className="chat-user-online">
                                        <img src={Profile ? Profile.account_info.profile_photo : cookies['_US-IF'] && cookies['_US-IF'].profile_photo} width="40" height="40" className="rounded-circle" alt="profile"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NoProfile;
                                            }}
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-2">
                                        <p className="mb-0">{Profile && Profile.account_info && Profile.account_info.fullname}</p>
                                    </div>
                                    <div className="dropdown">
                                        <div className="cursor-pointer font-24 dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown"><i className="fas fa-ellipsis-h"></i></div>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <span className="dropdown-item disabled" style={{ cursor: 'pointer' }} >Settings</span>
                                            <span className="dropdown-item disabled" style={{ cursor: 'pointer' }}>Help & Feedback</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3"></div>
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text bg-transparent">
                                        <FontAwesomeIcon icon={ faSearch } />
                                    </span>
                                    <input type="text" className="form-control" placeholder="People, groups, & messages" disabled/>
                                </div>
                                <div className="chat-tab-menu mt-3">
                                    <ul className="nav nav-pills nav-justified">
                                        <li className="nav-item">
                                            <span className="nav-link active" id="chat-pill" data-bs-toggle="pill" data-bs-target="#pillChat">
                                                <div className="font-24">
                                                    <FontAwesomeIcon icon={faPaperPlane} className="i"/>
                                                </div>
                                                <div><small>Chats</small></div>
                                            </span>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link" id="notif-pill" data-bs-toggle="pill" data-bs-target="#pillNotif">
                                                <div className="font-24">
                                                    <i className='fas fa-bell'/>
                                                    {/* <span className="notifBullet">3</span> */}
                                                </div>
                                                <div><small>Notifications</small></div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="chat-sidebar-content">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pillChat" aria-labelledby="chat-pill">
                                        <div className="p-3 recent-chat">
                                            <div className="dropdown">
                                                <span className="text-secondary dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown">Recent Chats</span>
                                                <div className="dropdown-menu">
                                                    <span className="dropdown-item" style={{ cursor: 'pointer' }} >Recent Chats</span>
                                                    <span className="dropdown-item disabled" style={{ cursor: 'pointer' }}>Hidden Chats</span>
                                                    <div className="dropdown-divider"></div>
                                                    <span className="dropdown-item disabled" style={{ cursor: 'pointer' }}>Sort by Time</span>
                                                    <span className="dropdown-item disabled" style={{ cursor: 'pointer' }}>Sort by Unread</span>
                                                    <div className="dropdown-divider"></div>
                                                    <span className="dropdown-item disabled" style={{ cursor: 'pointer' }}>Show Favorites</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chat-list">
                                            <div className="list-group list-group-flush">
                                                {
                                                    people && 
                                                    people.map((chat,i)=>(
                                                        <div className={(peopleSelect && peopleSelect.id) === chat.id ? "list-group-item active": "list-group-item"} style={{ cursor: "pointer" }} onClick={e=>handleSelectPeople(chat)}>
                                                            <div className="d-flex">
                                                                <div className="chat-user-hidden">
                                                                    <img src={chat.photo} width="35" height="35" className="rounded-circle" alt=""
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src = NoProfile;
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="flex-grow-1 ms-2">
                                                                    <h6 className="mb-0 chat-title">{chat.fullname}</h6>
                                                                    <p className="mb-0 chat-msg">{handleLimitString(chat.message, 15)}</p>
                                                                </div>
                                                                <div className="chat-time">{timeSince(chat.createdAt)}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="pillNotif" role="tabpanel" aria-labelledby="notif-pill">
                                        {/* <div className="chat-list">
                                            <div className="list-group list-group-flush">
                                                <a href="#" className="list-group-item active">
                                                    <div className="d-flex">
                                                        <div className="chat-user-offline">
                                                            <img src={NoProfile} width="35" height="35" className="rounded-circle" alt="" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-2">
                                                            <h6 className="mb-0 chat-title">Unknown</h6>
                                                            <p className="mb-0 chat-msg">1 Messages.</p>
                                                        </div>
                                                        <div className="chat-time">TIME</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="chat-header d-flex align-items-center">
                            <div>
                                <h4 className="mb-1 font-weight-bold" style={{ cursor: 'pointer' }}>{peopleSelect && peopleSelect.fullname}</h4>
                                <div className="list-inline d-sm-flex mb-0 d-none">
                                    <div className="list-inline-item d-flex align-items-center text-secondary">
                                        {/* <small className='far fa-dot-circle me-1 chart-online'></small> */}
                                        {/* Active Now */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="chat-top-header-menu ms-auto">
                                <div>
                                    <FontAwesomeIcon icon={faVideo} />
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                </div>
                            </div> */}
                        </div>
                        <div className="chat-content" id="chat-content">
                            {
                                selectMessages &&
                                selectMessages
                                .map((message,i)=>(
                                    (message && message.sender_email) == (Profile && Profile.account_info && Profile.account_info.email) ?
                                    <div className="chat-content-rightside" key={i}>
                                        <div className="d-flex ms-auto">
                                            <div className="flex-grow-1 me-2">
                                                <p className="mb-0 chat-time text-end"><span>{timeSince(message.createdAt)} . </span>You</p>
                                                <p className="chat-right-msg">{message && message.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="chat-content-leftside" key={i}>
                                        <div className="d-flex">
                                            <img src={peopleSelect && peopleSelect.photo} width="48" height="48" className="rounded-circle" alt=""
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = NoProfile;
                                                }}
                                            />
                                            <div className="flex-grow-1 ms-2">
                                                <p className="mb-0 chat-time">{peopleSelect && peopleSelect.fullname}<span> . {timeSince(message.createdAt)}</span></p>
                                                <p className="chat-left-msg">{message && message.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <form className="chat-footer d-flex align-items-center">
                            <div className="flex-grow-1 pe-2">
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={faPenNib} />
                                    </span>
                                    <input disabled={!peopleSelect} value={message} type="text" className="form-control" placeholder="Type a message" onChange={e=>setMessage(e.target.value)}/>
                                </div>
                            </div>
                            <div className="chat-footer-menu-gbl">
                                <div className="disabled">
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </div>
                                <div className="disabled">
                                    <FontAwesomeIcon icon={faSmileWink} />
                                </div>
                                <div className="disabled">
                                    <FontAwesomeIcon icon={faMicrophoneAlt} />
                                </div>
                                <button className={!peopleSelect && "disabled"} onClick={submit} type="submit">
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                        </form>

                        <div className="overlay chat-toggle-btn-mobile"></div>

                    </div>
                </div>
            </section>

            <BottomNavbar />
        </div>
    );
};

export default Inbox