import React , { memo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment';

const Session = () => {
    const sessionDashboard = useSelector(state=> state.eventState.sessionDashboard )

    const converterDate = (data) => {
        if (data) {
            let flag = null
            let day
            let timeStart
            let timeEnd
            let timeZone
            if(data.show_date) {
                flag = true
                day = moment(data.show_date).format('ddd DD MMM, YYYY')
                if (data.starttime) {
                    timeStart = data.starttime
                }
                if (data.endtime) {
                    timeEnd = data.endtime
                }
                if (data.time_zone) {
                    timeZone = data.time_zone
                }
            }
            if (!flag) return 'Cooming Soon'
            return `${day} - ${timeStart || ""}-${timeEnd || ""} ${timeZone || ""}`
        }
    }

    return (
        sessionDashboard?.length &&
        sessionDashboard?.map((session) => (
            <div className="headForum borderContent mb-2" key={session?.id}>
                <h4>{ session?.program_info?.programme_name }</h4>
                <p>{converterDate(session?.program_info)}</p>
            </div>
        ))
    )
};

export default memo(Session);