import React , { memo, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux'
import { getPollingAnswersByUser, createPollingAnswer } from '../../../store/actions/event';

const QandA = (props) => {
    const {
    } = props
    const [cookies] = useCookies(['account']);
    const Dispatch = useDispatch()
    const pollingDashboard = useSelector(state=> state.eventState.pollingDashboard )
    const [dataPoll,setDataPoll] = useState([])
    const [data,setData] = useState([])
    const [dataMe,setDataMe] = useState([])
    const [getData,getAllData] = useState(false)
    const [answerSelect, setAnswerSelect] = useState([]);
    const [pollLoading, setPollLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        setDataPoll(pollingDashboard?.map(polling=>({
            ...polling.question_info
        })))
    },[pollingDashboard])

    useEffect(()=>{
        if (dataPoll.length) {
            setData([])
            setDataMe([])
            dataPoll?.map((poll, index)=>{
                const { id_survey, id_survey_question } = poll
                Dispatch(getPollingAnswersByUser({ id_survey, id_user: cookies['account'], id_survey_question }, async (status, data)=>{
                    if (!status) {
                        setDataMe(prev=>[
                            ...prev,
                            {
                                ...poll,
                                question_choice: JSON.parse(poll.question_choice),
                                length_answer: 0
                            }
                        ])
                        setAnswerSelect(prev=>[
                            ...prev,
                            {
                                id_survey,
                                id_survey_question,
                                answer: []
                            }
                        ])
                    }
                    if (dataPoll.length-1 === index) {
                        getAllData(true)
                    }
                }))
            })
        }
    },[dataPoll, refresh])

    useEffect(()=>{
        if (getData) {
            getAllData(false)
            dataPoll?.map((poll)=>{
                const { id_survey, id_survey_question, question_choice, survey_question } = poll
                Dispatch(getPollingAnswersByUser({ id_survey, id_user: null, id_survey_question }, async (status, data)=>{
                    if (status) {
                        if (data.result) {
                            const { result } = data
                            const answerData = result.map(poll=>({
                                ...poll?.answer_info
                            }))
                            let questions = JSON.parse(question_choice)
                            if (questions) {
                                let questObj = {}
                                let totalyVote = 0
                                await questions?.map(quest=>{
                                    questObj[quest] = 0
                                })
                                await answerData?.forEach(async answer=>{
                                    if (answer?.survey_answer) {
                                        let answerMe = []
                                        let StringifyData = JSON.stringify(answer?.survey_answer)
                                        if (StringifyData[1] === '[' && StringifyData[StringifyData.length-2] === ']') {
                                            answerMe = JSON.parse(JSON.parse(StringifyData))
                                        } else  {
                                            answerMe.push(JSON.parse(StringifyData))
                                        }
                                        await answerMe?.forEach(ans=>{
                                            if ((questObj[ans] !== null) || (questObj[ans] !== undefined)) {
                                                questObj[ans] = questObj[ans] +=1
                                                totalyVote+=1
                                            }
                                        })
                                    }
                                })
                                let finRest = []
                                let temp = {}
                                for (const key in questObj) {
                                    temp.item = key
                                    temp.percentage = String(Math.round((questObj[key] / totalyVote) * 100))
                                    finRest.push(temp)
                                    temp = {}
                                }
                                setData(prev=> ([
                                    ...prev,
                                    {
                                        id_survey,
                                        id_survey_question,
                                        question: survey_question,
                                        answer: finRest,
                                        length : answerData?.length || 0
                                    }
                                ]))
                            }
                        }
                    }
                }))
            })
        }
    },[getData])

    const handlePollSelect = (type, value, poll) => {
        const { id_survey, id_survey_question } = poll
        let pollSelect
        answerSelect?.map(answer=>{
            if (answer?.id_survey === id_survey && answer?.id_survey_question === id_survey_question) {
                pollSelect = answer
            }
        })
        if (type === 'checkbox') {
            if (pollSelect) {
                let checking = pollSelect?.answer?.indexOf(value)
                if (checking === -1) {
                    pollSelect?.answer?.push(value)
                } else {
                    pollSelect?.answer?.splice(checking, 1);
                }
            }
        } else if (type === 'radio') {
            if (pollSelect) {
                let checking = pollSelect?.answer?.length
                if (!checking) {
                    pollSelect?.answer?.push(value)
                } else {
                    pollSelect?.answer?.pop();
                    pollSelect?.answer?.push(value)
                }
            }
        }
        setAnswerSelect(answer=> {
            if (answer?.id_survey === id_survey && answer?.id_survey_question === id_survey_question) {
                return pollSelect
            }
            return answer
        })
    }

    const handleSubmitPoll = (e, poll, answerList) => {
        if (e) e.preventDefault()
        const { id_survey, id_survey_question } = poll
        let answer
        answerList?.map(ans=>{
            if (ans?.id_survey === id_survey && ans?.id_survey_question === id_survey_question) {
                answer = ans 
            }
        })

        if (answer && answer?.answer.length !== 0) {
            setPollLoading(true)
            Dispatch(getPollingAnswersByUser({ id_survey, id_user: cookies['account'], id_survey_question }, (status)=>{
                if (status) {
                    addToast('Poll Has Been Selected', {
                        appearance: 'warning',
                        autoDismiss: true,
                    })
                    setRefresh(!refresh)
                    setPollLoading(false)
                } else if (!status) {
                    Dispatch(createPollingAnswer({
                        "id_survey_question" : id_survey_question,
                        "survey_answer" : JSON.stringify(answer?.answer),
                        "id_user" : cookies['account']
                    },(status)=>{
                        if (!status) {
                            setPollLoading(false)
                        } else {
                            setRefresh(!refresh)
                            setPollLoading(false)
                        }
                    }))
                }
            }))
        } else {
            addToast('Please Choose answer first', {
                appearance: 'warning',
                autoDismiss: true,
            })
        }
    }

    const getAnswerLength = (poll, listAnswer) => {
        let result = 0
        if (listAnswer.length) {
            listAnswer?.map(answer=>{
                if (poll?.id_survey === answer?.id_survey && poll?.id_survey_question === answer?.id_survey_question) {
                    result = answer?.length || 0
                }
            })
        }
        return result
    }

    const getAnswerStatus = (pollList, answer) => {
        let result = true
        if (pollList.length) {
            pollList?.map(poll=>{
                if ((answer?.id_survey === poll?.id_survey) && (answer?.id_survey_question === poll?.id_survey_question)) {
                    result = false
                }
            })
        }
        return result
    }

    return (
        <div className="sideContent">
            {
                dataMe?.map((polling,index)=>(
                    polling &&
                    <div className="borderContent mb-3" key={(index).toString()}>
                        <div className="paddingContent sidePoll">
                            <div className="pollTop">
                                <span className="pollBtn">{getAnswerLength(polling, data)} votes</span>
                            </div>
                            <div className="pollQuestion">
                                <p>{ polling?.survey_question }</p>
                            </div>
                            <div className="pollAnswer">
                                {
                                    polling?.question_choice?.map((answer,i)=>(
                                        <div className="form-check" key={i.toString()}>
                                            <input
                                                className="form-check-input"
                                                onChange={e=> handlePollSelect(
                                                    (polling.question_type).toLowerCase() === 'option' ? 'radio' : (polling.question_type).toLowerCase(), e.target.value, polling)
                                                }
                                                type={(polling.question_type).toLowerCase() === 'option' ? 'radio' : (polling.question_type).toLowerCase()} id={i} name="question" value={answer}
                                            />
                                            <label className="form-check-label" htmlFor={i}>
                                                {answer}
                                            </label>
                                        </div>
                                    ))
                                }
                                <div className="pollSubmit">
                                    <button type="submit"
                                        onClick={e=> handleSubmitPoll(e, polling, answerSelect)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                data?.map((polling, index)=>(
                    getAnswerStatus(dataMe,polling) &&
                    <div className="borderContent mb-3 pollDisable" key={(index).toString()}>
                        <div className="paddingContent sidePoll">
                            <div className="pollTop">
                                <span className="pollBtnDisable">{polling?.length} votes</span>
                                {/* <span className="pollBtnDisable" style={{ marginLeft: 5 }} >Poll ended</span> */}
                            </div>
                            <div className="pollQuestion">
                                <p>{ polling?.question }</p>
                            </div>
                            <div className="pollAnswer">
                                {
                                    polling?.answer?.map((poll,idx)=>(
                                        <div className="progress" key={(idx).toString()}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: poll.percentage >= 100 ? "100%" : `${poll.percentage}%`
                                                }}
                                                aria-valuenow={poll.percentage >= 100 ? "100" : `${poll.percentage}`}
                                                aria-valuemin="0" aria-valuemax="100"
                                            >
                                            </div>
                                            <span className="listPoll">{ poll?.item }</span>
                                            <span
                                                className="listPoll"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    width: '100%',
                                                    padding: '0px 20px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {poll.percentage >= 100 ? 100 : poll.percentage}%
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
};

export default memo(QandA);