import {
    CHATS,
    SEND_CHAT,
    SEND_CHAT_ACTION
} from '../constants'

const intialState = {
    chats: [],
    chatsTriggered: false,
    sendChat: false
}

function ChatState(state = intialState, action) {
    let checker = state.chats.findIndex((data) => data.id === (action.data && action.data.id) );
    switch (action.type) {
        case CHATS:
            if (checker !== -1) {
                let finder = state.chats.find((data) => data.id === action.data.id );
                let newChats = {
                    id : action.data.id,
                    offset: action.data.offset,
                    limitter: action.data.limitter,
                    messages: [...(action.data.messages.length !== 0 && action.data.messages.reverse()), ...finder.messages]
                }
                let temp = state.chats
                temp[checker] = newChats
                return {
                    ...state,
                    chats: temp,
                    chatsTriggered: !state.chatsTriggered,
                }
            } else {
                return {
                    ...state,
                    chats: [...state.chats, {
                        id : action.data.id,
                        offset: action.data.offset,
                        limitter: action.data.limitter,
                        messages: action.data.messages.length !== 0 && action.data.messages.reverse()
                    }],
                    chatsTriggered: !state.chatsTriggered
                }
            }
        case SEND_CHAT:
            if (checker !== -1) {
                let finder = state.chats.find((data) => data.id === action.data.id );
                let newChats = {
                    id : action.data.id,
                    offset: action.data.offset,
                    limitter: action.data.limitter,
                    messages: [...finder.messages, action.data.message]
                }
                let temp = state.chats
                temp[checker] = newChats
                return {
                    ...state,
                    chats: temp,
                    chatsTriggered: !state.chatsTriggered,
                    sendChat: true
                }
            } else {
                return {
                    ...state,
                    chats: [...state.chats, {
                        id : action.data.id,
                        offset: action.data.offset ? action.data.offset : 0,
                        limitter: action.data.limitter ? action.data.limitter : 10,
                        messages: [action.data.message]
                    }],
                    chatsTriggered: !state.chatsTriggered,
                    sendChat: true
                }
            }
        case SEND_CHAT_ACTION :
            return {
                ...state,
                sendChat: action.data.action
            }
        default: {
            return state
        }
    }
}

export default ChatState
