import React , { memo } from 'react'

const Description = (props) => {
    const {
        eventState
    } = props

    const handleTimeFormat = (session) => {
        let time = session?.starttime || ''
        let timeZone = session?.time_zone || ''
        let program_name = session?.programme_name || ''

        return `${time} ${timeZone} (PT): ${program_name}`
    }

    return (
        <div className="sessionContentText">
            <div className="timeTitle">
                { handleTimeFormat(eventState?.program_info) }
            </div>
            {/* <div className="contentAbout">About the session</div> */}
            <p>
                { eventState?.program_info?.description }
            </p>
            {/* DONT DELETE THIS. EXAMPLE FOR EVENT DESC */}
            {/* <div className="timeTitle" style={{ color: 'red' }}>
                12:28 PM (PT): The Radical Reimagination of Engagement
            </div>
            <div className="whoIs">
                <div className="whoIsName" style={{ color: 'red' }}>Vaibhav Jain</div>
                <div className="whoIsJob" style={{ color: 'red' }}>CEO & Founder</div>
                <div className="whoIsAs" style={{ color: 'red' }}>Hubilo</div>
            </div>
            <div className="contentAbout" style={{ color: 'red' }}>About the session</div>
            <p style={{ color: 'red' }}>
                Virtual and hybrid events are shaping the future of the events industry. To utilize their immense scale and potential to the fullest, there is a critical need for radical reimagination of engagement too. Let's explore what all we need to learn about the digitally transformed audience to succeed with our events.
            </p> */}
        </div>
    )
};

export default memo(Description);