import React , { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    getFeedbackPoll,
} from 'store/actions/event'

const Feedback = (props) => {
    const {
        eventState
    } = props
    const Dispatch = useDispatch()
    const [data, setData] = useState([])
    
    useEffect(()=>{
        if ((eventState?.is_active_feedback_elive) == 1) {
            Dispatch(getFeedbackPoll({
                id_idea: eventState?.id_idea,
                survey_type: 'Feedback Event',
                is_active_elive: 1,
                order_by: 'll_surveys.created ASC',
            },(status, data)=>{
                if (status) {
                    let originalData = data?.map(session=>({
                        ...session.survey_info
                    })) || []
                    setData(originalData)
                }
            }))
        }
    },[])

    const goToLink = (link) => {
        if (link) {
            const win = window.open(link, "_blank");
            win.focus();
        }
    }

    return (
        (((eventState?.is_active_feedback_elive) == 1) && data.length) &&
        <section>
            <div className="pt-2 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="homeFeed">
                                <div className="row">
                                    <div className="col-sm-5 mx-auto">
                                        <div className="homeFeedDetail text-center">
                                            <p>Let's give us feedback for better next event.</p>
                                            <div className="homeFeedAction">
                                            {
                                                data?.map(feedback=>(
                                                    <span key={feedback.id_survey} onClick={e=>goToLink(`https://livelifeindo.com/esurvey/feedback?sid=${feedback.id_survey}&key=${feedback.id_idea}`)}><i className="far fa-comment-dots"></i> &nbsp; { feedback?.survey_title } </span>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default memo(Feedback);