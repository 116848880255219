import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faClock } from '@fortawesome/free-regular-svg-icons'
import NoProfile from '../images/no-profile.png'
import NoImage from '../images/no-image.png'
import PopOverCustom from '../components/popOver'
import NoProfileDummy from '../images/no-profile-dummy.png'
import CryptoJS from "crypto-js";

function ModalSpeakers(props) {
    const {
        onHide,
        session,
        handlerModalPerson,
        History,
        goOnTop
    } = props

    const [speakers] = useState([
        // {
        //     id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
        //     fullname: 'Haris Altest Sajo',
        //     profession: 'CEO & Founder',
        //     organization: 'Drag Taste'
        // }
    ])

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const goToNewTab = (user) => {
        const win = window.open(`https://livelifeindo.com/profile/view/${user && user.id}`, "_blank");
        win.focus();
    }

    const converterDateSession = (data) => {
        if (data) {
            let flag = null
            let dateConvertFrom
            let dateConvertTo
            let timeStart
            let timeEnd
            let timeZone
            if(data.show_date) {
                flag = true
                dateConvertFrom = data.show_date
                if (data.starttime) {
                    timeStart = data.starttime
                }
                if (data.endtime) {
                    timeEnd = data.endtime
                }
                if (data.time_zone) {
                    timeZone = data.time_zone
                }
                if(data.show_date_end) {
                    if (data.show_date !== data.show_date_end) {
                        dateConvertTo = data.show_date_end
                    }
                }
            }
            if (!flag) return ' Cooming Soon'
            return dateConvertTo ? ` ${dateConvertFrom} ${timeStart ? `| ${timeStart}` : ''} ${timeZone ? timeZone : ''} ${dateConvertTo ? ` - ${dateConvertTo}` : ''} ${timeEnd ? `| ${timeEnd}` : ''} ${timeZone ? timeZone : ''}` : ` ${dateConvertFrom} ${timeStart ? `| ${timeStart}` : ''} ${timeEnd ? ` - ${timeEnd}` : ''} ${timeZone ? timeZone : ''}`
        }
    }

    const handleParams = (data) => {
        if (data) {
            var encryptUser = CryptoJS.AES.encrypt(JSON.stringify({ id: data.id }), 'elive-livelife22').toString();
            History.push(`/watch/q?id=${encryptUser}`)
            goOnTop()
        }
    }

    return (
        <div className="modal-content">
            <div className="modal-header">
                <div
                    style={{
                        background: `url(${session?.program_info?.image_banner_url}), url(${NoImage})`,
                        height: 300,
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                    className="image-event-session-8713"
                />
                <div className="closeModal">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide}/>
                </div>
            </div>
            <div className="modal-subheader">
                <div className="sessionTimeBookmark">
                    <p>
                        <FontAwesomeIcon icon={faClock} style={{ marginRight: 5 }}/>
                        {
                            converterDateSession(session?.program_info)
                        }
                    </p>
                    <div className="itemBookmark">
                        <button className="bmBtn modalBmBtn disabled">
                            <FontAwesomeIcon icon={faBookmark} />
                        </button>
                    </div>
                </div>					
                <h4 className="clearfix">
                    { session?.program_info?.programme_name }
                </h4>
            </div>
            <div className="modal-body">
                <div className="modalContentText" style={{ maxHeight: 'none' }}>
                    {
                        (session?.program_info?.description) &&
                        <p>
                            {
                                session?.program_info?.description
                            }
                        </p>
                    }
                </div>
            </div>
            <div className="modal-footer">
                <div className="speakerFooter">
                    <div className="speakerAvatar" style={{alignItems: 'center' }} >
                        <div className="avaSmall">
                            {
                                speakers &&
                                speakers.map((person,index)=>(
                                (index < 4) &&
                                    <PopOverCustom
                                        key={index}
                                        component={
                                            <div className='text-center widthPopProfile' >
                                                <img src={NoProfileDummy} className='imgPopHover'/>
                                                <h3 className='h3PopHover' style={{color:'red'}}>{handleLimitString(person.fullname, 20)}</h3>
                                                <p className='pPopHover' style={{color:'red'}}>{handleLimitString(`${person.profession ? person.profession : ''}${person.profession ? ` at ${person.organization}` : ''}`, 25)}</p>
                                                <div className='btnViewProf' onClick={_=> goToNewTab(person)} >
                                                    <span style={{backgroundColor: 'red'}}>View Profile</span>
                                                </div>
                                            </div>
                                        }
                                        placement="top"
                                        delay={200}
                                    >
                                        <img src={NoProfileDummy} alt="image"
                                            style={{
                                                marginLeft: index !== 0 && 3
                                            }}
                                        />
                                    </PopOverCustom>
                                ))
                            }
                        
                        </div>
                        {
                            speakers.length ?
                            session &&
                            (session.index % 2 === 0) &&
                            <div className="avaMore" onClick={e => handlerModalPerson(e,true)}>
                                <p>
                                    +{ speakers && speakers.length -4 }
                                </p>
                            </div>
                            : 
                            ''
                        }
                    </div>
                    <div className="watchSession">
                        <span
                            // className="disabled"
                            onClick={_=> handleParams(session && session.program_info) }
                        >Watch Session</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalSpeakers;