import {
    EVENT_KEY,
    DASHBOARD_PEOPLE,
    DASHBOARD_SESSIONS,
    DASHBOARD_POLLING,
} from '../constants'

const intialState = {
    event: null,
    peopleAttendee: null,
    sessionDashboard: null,
    pollingDashboard: [],
}

function EventState(state = intialState, action) {
    switch (action.type) {
        case EVENT_KEY:
            return {
                ...state, 
                event : action.data,
            }
        case DASHBOARD_PEOPLE:
            return {
                ...state, 
                peopleAttendee : action.data,
            }
        case DASHBOARD_SESSIONS:
            return {
                ...state, 
                sessionDashboard : action.data,
            }
        case DASHBOARD_POLLING:
            return {
                ...state, 
                pollingDashboard : [...state.pollingDashboard, ...action.data]
            }
        default: {
            return state
        }
    }
}

export default EventState
