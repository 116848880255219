import React ,{ memo, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenFancy } from '@fortawesome/free-solid-svg-icons'
import NoProfile from 'images/no-profile.png'
import { getQandA, updateQandA } from 'store/actions/event';
import { checkingUserById } from 'store/actions/signUpIn';
import { useDispatch, useSelector } from 'react-redux';

const QandA = forwardRef((props, ref) => {
    const Event = useSelector(state=> state.eventState.event )
    const Profile = useSelector(state=>state.cacheState.profile)
    const Dispatch = useDispatch()
    const [list, setList] = useState([]);
    const [sort, setSort] = useState('Recent');

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const read = (event) => {
        if (event?.event_info?.id_idea) {
            setList([])
            Dispatch(getQandA({ id_idea: event?.event_info?.id_idea }, (status, data)=>{
                if (status) {
                    data?.result?.map(session=>{
                        Dispatch(checkingUserById(session?.question_info?.id_user, (status, data)=>{
                            if (status) {
                                setList(prev=>[
                                    ...prev,
                                    {
                                        ...session?.question_info,
                                        id_user: data?.registrant_info
                                    }
                                ])
                            }
                        }))
                    })
                }
            }))
        }
    }

    const vote = (e, list, data) => {
        if(e) e.preventDefault()
        let sendData = {
            id_idea_question: data?.id_idea_question,
            id_user: data?.id_user?.id_user,
            id_idea: data?.id_idea,
            answer: data?.answer,
            question: data?.question,
            vote: Number(data?.vote) + 1
        }
        Dispatch(updateQandA(sendData, (status, data)=>{
            if (status) {
                setList(prev=>
                    prev?.map(x=>{
                        if (x?.id_idea_question === sendData?.id_idea_question) {
                            return {
                                ...x,
                                vote: sendData.vote
                            }
                        }
                        return x
                    })
                )
            }
        }))
    }

    useEffect(()=>{
        read(Event)
    },[Event?.event_info?.id_idea])

    useImperativeHandle(ref, ()=>({
        refetch(event) {
            read(event)
        }
    }))

    return (
        <div className="sideContent">
            <div className="borderContent">
                <div className="paddingContent sideQuestion">
                    <div className="row">
                        <div className="col-8">
                            <div className="sideLeft">
                                <img src={Profile?.account_info?.profile_photo || NoProfile}
                                    alt="LiveLife User"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoProfile;
                                    }}
                                />
                                <span>Ask a question</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="sideRight">
                                <span className="btnSidebar" data-bs-toggle="modal" data-bs-target="#addQuestion">
                                    <FontAwesomeIcon icon={faPenFancy} /> Post
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qnaArea">
                <div className="sortByOption dropdown">
                    <a className="dropdown-toggle" data-bs-toggle="dropdown"> Sort by: { sort } <span className="caret"></span></a>
                    <ul className="dropdown-menu">
                        <li onClick={()=> setSort('Recent')} >Recent</li>
                        <li onClick={()=> setSort('Most voted')} >Most voted</li>
                    </ul>
                </div>
            </div>
            {
                list?.sort((a,b)=> sort === 'Recent' ? b?.id_idea_question - a?.id_idea_question : b?.vote - a?.vote)
                ?.map((x, index)=>(
                    <div className="borderContent mb-3" key={(index).toString()}>
                        <div className="paddingContent sideQuestion">
                            <div className="usrQuestTop">
                                <img
                                    src={x?.id_user?.profile_photo || NoProfile}
                                    alt={''}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoProfile;
                                    }}
                                    draggable={false}
                                />
                                <span>{ handleLimitString(x?.id_user?.fullname, 25) }  </span>
                            </div>
                            <div className="usrQuestContent">
                                <div className="usrQuestion">
                                    <p>{ x?.question }</p>
                                </div>
                                {
                                    x?.answer &&
                                    <div className="questionAnswer">
                                        <p>{ x?.answer }</p>
                                    </div>
                                }
                                <div className="questVote" onClick={(e)=>vote(e, list, x)}>
                                    <span style={{ marginRight: 5 }}>
                                        {/* <i className="fa-regular fa-circle-check"></i> */}
                                        <i className="fas fa-check-circle"></i>
                                    </span>
                                    <span>{x?.vote <= 1 ? `${x?.vote} vote` : `${x?.vote} votes`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
});

export default memo(QandA);