import React,{ StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ToastProvider, useToasts } from 'react-toast-notifications';

const FormWithToasts = () => {
  const { addToast } = useToasts();
  const onSubmit = async value => {
    const { error } = await dataPersistenceLayer(value);
    if (error) {
        addToast(error.message, { appearance: 'error' });
    } else {
        addToast('Saved Successfully', { appearance: 'success' });
    }
  };
  return <form onSubmit={onSubmit}/>;
};

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <ToastProvider>
        <StrictMode>
          <Router>
            <FormWithToasts />
            <App />
          </Router>
        </StrictMode>
      </ToastProvider>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
