import React, { memo } from 'react';
import NoImage from '../images/no-image.png'

const checkingBanner = (program) => {
    let program_info = program?.program_info
    if (program_info?.is_active_elive === '1' && program_info?.zoom_link_elive) {
        return 'zoom'
    }
    if (program_info?.is_active_elive === '1' && program_info?.recording_link_elive) {
        return 'youtube'
    }
    if (program_info?.is_active_elive === '1' && program_info?.image_banner) {
        return 'image'
    }
    return 'empty'
}

const convertBannerYT = (program) => {
    let program_info = program?.program_info
    if (program_info) {
        if (program_info.recording_link_elive) {
            let data = program_info.recording_link_elive
            let split = data.split('watch?v=')
            return split.join('embed/')
        }
    }
}

const BannerEvent = memo(({ eventState }) =>{
    if (eventState) {
        let checking = checkingBanner(eventState)
        if ((checking === 'image')) {
            return (
                <img
                    src={eventState?.program_info?.image_banner}
                    alt=""
                    draggable={false}
                    onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                />
            )
        } else if(checking === 'youtube'){
            return (
                <iframe width="560" height="315" src={convertBannerYT(eventState)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
            )
        }
    }
});

export default BannerEvent;