import {
    PROFILE_USER
} from '../constants'

const intialState = {
    profile: null
}

function CacheState(state = intialState, action) {
    switch (action.type) {
        case PROFILE_USER:
            return {
                ...state, 
                profile : action.data,
            }
        default: {
            return state
        }
    }
}

export default CacheState
