import axios from 'axios'
import {
    SERVER
} from '../../api'
import {
    EVENT_KEY,
    DASHBOARD_PEOPLE,
    DASHBOARD_SESSIONS,
    DASHBOARD_POLLING,
} from '../constants'

export const getEventByKey = (id) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/events/find?token=${process.env.REACT_APP_API_TOKEN}&id=${id}`,
        })
        if (data.status) {
            dispatch({
                type: EVENT_KEY,
                data : data.result
            })
        }
    } catch (error) {
        if (error) {
            dispatch({
                type: EVENT_KEY,
                data : 404
            })
        }
    }
}

export const getEventId = (id, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/events/find?token=${process.env.REACT_APP_API_TOKEN}&id=${id}`,
        })
        if (data.status === true) {
            cb(data.status, data.result)
        } else {
            cb(data.status)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status)
                    }
                }
            }
        }
    }
}

export const getDataCheckinMe = ({ id_idea, id_user, id_status },cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${id_user ? `&id_user=${id_user}`: ''}${id_status ? `&id_status=${id_status}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const updateCheckinMe = (datasend,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/registrants/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: datasend
        })
        if (data.status) {
            cb(data.status, data.message)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getAttendee = ({ is_checkedin, keyword, id_idea, id_user, id_status, limit, offset, order_by }) => async dispatch => {
    try {
        let route = `${is_checkedin ? `&is_checkedin=${is_checkedin}`:''}${id_idea ? `&id_idea=${id_idea}`:''}${keyword ? `&keyword=${keyword}` : ''}${id_user ? `&id_user=${id_user}`: ''}${id_status ? `&id_status=${id_status}`: ''}${limit ? `&limit=${limit}`: ''}${offset ? `&offset=${offset}`: ''}${order_by ? `&order_by=${order_by}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            const shuffledArray = data.result.sort((a, b) => 0.5 - Math.random());
            dispatch({
                type: DASHBOARD_PEOPLE,
                data : shuffledArray.slice(0,12)
            })
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getAttendeesPeople = ({ keyword, id_idea, id_user, id_status, limit, offset, order_by, join_as, var_join_as, not_id_user, is_checkedin }, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${keyword ? `&keyword=${keyword}` : ''}${id_user ? `&id_user=${id_user}`: ''}${id_status ? `&id_status=${id_status}`: ''}${limit ? `&limit=${limit}`: ''}${offset ? `&offset=${offset}`: ''}${order_by ? `&order_by=${order_by}`: ''}${join_as ? `&join_as=${join_as}`: ''}${var_join_as ? `&var_join_as=${var_join_as}`: ''}${not_id_user ? `&not_id_user=${not_id_user}`: ''}${is_checkedin ? `&is_checkedin=${is_checkedin}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        cb(data.status, data.result)
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, [])
                    }
                }
            }
        }
    }
}

export const getSessions = ({ id_idea, limit, offset, order_by, search, is_active_elive = "1" },cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${limit ? `&limit=${limit}` : ''}${offset ? `&offset=${offset}`: ''}${order_by ? `&order_by=${order_by}`: ''}${search ? `&search=${search}`: ''}${is_active_elive ? `&is_active_elive=${is_active_elive}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/activities/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb('error', null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, [])
                    }
                }
            }
        }
    }
}

export const getSessionDetail = ({ id, id_idea },cb) => async dispatch => {
    try {
        let route = `${id ? `&id=${id}`:''}${id_idea ? `&id_idea=${id_idea}` : ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/activities/find?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb('error', null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, null)
                    }
                }
            }
        }
    }
}

export const getSessionsUpcoming = ({ id_idea, limit, offset, order_by, time, is_active_elive = 1 }) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${time ? `&time=${time}`:''}${limit ? `&limit=${limit}` : ''}${offset ? `&offset=${offset}`: ''}${order_by ? `&order_by=${order_by}`: ''}${is_active_elive ? `&is_active_elive=${is_active_elive}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/activities/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            dispatch({
                type: DASHBOARD_SESSIONS,
                data : data.result
            })
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                    }
                }
            }
        }
    }
}

export const getPollingAnswersByUser = ({ id_survey, id_user, id_survey_question, is_active_elive = 1},cb) => async dispatch => {
    try {
        let route = `${id_survey ? `&id_survey=${id_survey}`:''}${id_user ? `&id_user=${id_user}` : ''}${id_survey_question ? `&id_survey_question=${id_survey_question}`: ''}${is_active_elive ? `&is_active_elive=${is_active_elive}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/surveys/list_answer?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status, null)
                    }
                }
            }
        }
    }
}

export const getQandA = ({ id_idea }, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/events/list_question?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        cb(data?.status, data)
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                    }
                }
            }
        }
    }
}

export const createQandA = (datasend, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${SERVER}/events/create_question?token=${process.env.REACT_APP_API_TOKEN}`,
            data: datasend
        })
        if (data.status) {
            cb(data.status, data)
        }
    } catch (error) {
        if (error?.response?.data) {
            let { status, message } = error.response.data
            if (message === 'Not Found' && status === false) {
            }
        }
    }
}

export const updateQandA = (datasend, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/events/update_question?token=${process.env.REACT_APP_API_TOKEN}`,
            data: datasend
        })
        if (data.status) {
            cb(data.status, data)
        }
    } catch (error) {
        if (error?.response?.data) {
            let { status, message } = error.response.data
            if (message === 'Not Found' && status === false) {
            }
        }
    }
}

export const getPolling = ({ id_idea, survey_type = 'poll', is_active, order_by = 'll_surveys.created ASC', is_active_elive = 1}) => async dispatch => {
    try {
        let pollList = []
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${survey_type ? `&survey_type=${survey_type}` : ''}${is_active ? `&is_active=${is_active}`: ''}${order_by ? `&order_by=${order_by}`: ''}${is_active_elive ? `&is_active_elive=${is_active_elive}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/surveys/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            if (data.result) {
                data.result.map(async (poll, i)=>{
                    await dispatch(getPollingQuestions({id_survey: poll.survey_info.id_survey, statusData: i === data.result.length-1 ? true : false}, pollList))
                })
            }
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                    }
                }
            }
        }
    }
}

export const getPollingQuestions = ({ id_survey, statusData }, poll) => async dispatch => {
    try {
        let route = `${id_survey ? `&id_survey=${id_survey}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/surveys/list_question?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            if (statusData) {
                dispatch({
                    type: DASHBOARD_POLLING,
                    data : [...poll, ...data.result]
                })
                poll = [...poll, ...data.result]
            } else {
                dispatch({
                    type: DASHBOARD_POLLING,
                    data : [...poll, ...data.result]
                })
                poll = [...poll, ...data.result]
            }
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                    }
                }
            }
        }
    }
}

export const createPollingAnswer = (dataSend,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${SERVER}/surveys/create_answer?token=${process.env.REACT_APP_API_TOKEN}`,
            data: dataSend
        })
        if (data.status) {
            cb(data.status, data)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status, null)
                    }
                }
            }
        }
    }
}

export const getSpeakerAndTalent = ({ id_idea, status, is_active, is_approved, is_delete, limit, offset, order_by, search, is_active_elive }, cb) => async dispatch => {
    try {
        let route = `${(id_idea !== null && id_idea !== undefined) ? `&id_idea=${id_idea}`:''}${(is_active_elive !== null && is_active_elive !== undefined) ? `&is_active_elive=${is_active_elive}`:''}${(status !== null && status !== undefined) ? `&status=${status}`:''}${(is_active !== null && is_active !== undefined) ? `&is_active=${is_active}`:''}${(is_approved !== null && is_approved !== undefined) ? `&is_approved=${is_approved}`:''}${(is_delete !== null && is_delete !== undefined) ? `&is_delete=${is_delete}`:''}${(limit !== null && limit !== undefined) ? `&limit=${limit}`:''}${(offset !== null && offset !== undefined) ? `&offset=${offset}`:''}${(order_by !== null && order_by !== undefined) ? `&order_by=${order_by}`:''}${(search !== null && search !== undefined) ? `&search=${search}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/partners/list_talent?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.error(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getPartnerAndTalent = ({ id_idea, status, is_active, is_approved, is_delete, limit, offset, order_by, search }, cb) => async dispatch => {
    try {
        let route = `${(id_idea !== null && id_idea !== undefined) ? `&id_idea=${id_idea}`:''}${(status !== null && status !== undefined) ? `&status=${status}`:''}${(is_active !== null && is_active !== undefined) ? `&is_active=${is_active}`:''}${(is_approved !== null && is_approved !== undefined) ? `&is_approved=${is_approved}`:''}${(is_delete !== null && is_delete !== undefined) ? `&is_delete=${is_delete}`:''}${(limit !== null && limit !== undefined) ? `&limit=${limit}`:''}${(offset !== null && offset !== undefined) ? `&offset=${offset}`:''}${(order_by !== null && order_by !== undefined) ? `&order_by=${order_by}`:''}${(search !== null && search !== undefined) ? `&search=${search}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/partners/list_media?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.error(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getVendorAndTalent = ({ id_idea, status, is_active, is_approved, is_delete, limit, offset, order_by, search }, cb) => async dispatch => {
    try {
        let route = `${(id_idea !== null && id_idea !== undefined) ? `&id_idea=${id_idea}`:''}${(status !== null && status !== undefined) ? `&status=${status}`:''}${(is_active !== null && is_active !== undefined) ? `&is_active=${is_active}`:''}${(is_approved !== null && is_approved !== undefined) ? `&is_approved=${is_approved}`:''}${(is_delete !== null && is_delete !== undefined) ? `&is_delete=${is_delete}`:''}${(limit !== null && limit !== undefined) ? `&limit=${limit}`:''}${(offset !== null && offset !== undefined) ? `&offset=${offset}`:''}${(order_by !== null && order_by !== undefined) ? `&order_by=${order_by}`:''}${(search !== null && search !== undefined) ? `&search=${search}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/partners/list_vendor?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.error(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getResources = ({ id_idea, is_active_elive }, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${is_active_elive ? `&is_active_elive=${is_active_elive}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/collaborations/list_resource?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.error(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getBlogArticles = ({ id_status, is_active_elive, limit, offset }, cb) => async dispatch => {
    try {
        let route = `${id_status ? `&id_status=${id_status}`:''}${is_active_elive ? `&is_active_elive=${is_active_elive}`:''}${limit ? `&limit=${limit}`:''}${offset ? `&offset=${offset}`:''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/blog/list_berita?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.error(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getFeedbackPoll = ({ id_idea, survey_type = 'poll', is_active, order_by = 'll_surveys.created ASC', is_active_elive }, cb) => async dispatch => {
    try {
        let route = `${id_idea ? `&id_idea=${id_idea}`:''}${survey_type ? `&survey_type=${survey_type}`:''}${is_active ? `&is_active=${is_active}`:''}${order_by ? `&order_by=${order_by}`:''}${is_active_elive ? `&is_active_elive=${is_active_elive}`: ''}`
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/surveys/list?token=${process.env.REACT_APP_API_TOKEN}${route}`,
        })
        if (data.status) {
            cb(data.status, data.result)
        } else {
            cb(false, null)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    console.error(message);
                    cb(false, message)
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}