import axios from 'axios'
import {
    SERVER
} from '../../api'

export const checkingEmailUser = (email, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/account/find?token=${process.env.REACT_APP_API_TOKEN}&email=${email}`,
        })
        if (data.status === true) {
            let { id } = data.result.account_info
            cb(data.status, id)
        } else {
            cb(data.status, null)
        }
    } catch (error) {
        console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status, null)
                    }
                }
            }
        }
    }
}

export const checkingEmailAttendee = (userId, eventId ,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/find?token=${process.env.REACT_APP_API_TOKEN}&id_user=${userId}&id_idea=${eventId}`,
        })
        if (data.status === true) {
            cb(data.status)
        } else {
            cb(data.status)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status)
                    }
                }
            }
        }
    }
}

export const checkingUserId = (userId, eventId, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/find?token=${process.env.REACT_APP_API_TOKEN}&id_user=${userId}&id_idea=${eventId}`,
        })
        if (data.status === true) {
            cb(data.status, data.result)
        } else {
            cb(data.status)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status)
                    }
                }
            }
        }
    }
}

export const createUser = (dataBody ,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${SERVER}/account/create?token=${process.env.REACT_APP_API_TOKEN}`,
            data: dataBody
        })
        if (data.status === true) {
            cb(data.status, data.id)
        } else {
            cb(data.status, null)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    console.error(error.response.data);
                    cb('error', null)
                }
            }
        }
    }
}

export const createAttendee = (dataBody ,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${SERVER}/registrants/create?token=${process.env.REACT_APP_API_TOKEN}`,
            data: dataBody
        })
        if (data.status === true) {
            cb(data.status, data.id)
        } else {
            cb(data.status, null)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    console.error(error.response.data);
                    cb('error', null)
                }
            }
        }
    }
}

export const UpdateUser = (dataBody ,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/account/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: dataBody
        })
        if (data.status === true) {
            cb(data.status, data.id)
        } else {
            cb(data.status, null)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    console.error(error.response.data);
                    cb('error', null)
                }
            }
        }
    }
}

export const LoginAccount = (dataBody ,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${SERVER}/auth/login?token=${process.env.REACT_APP_API_TOKEN}`,
            data: dataBody
        })
        if (data.status === true) {
            cb(data)
        } else {
            cb(data)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    console.error(error.response.data);
                    cb('error', null)
                }
            }
        }
    }
}

export const checkingUserById = (userId ,cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/find?token=${process.env.REACT_APP_API_TOKEN}&id_user=${userId}`,
        })
        if (data.status === true) {
            cb(data.status, data.result)
        } else {
            cb(data.status)
        }
    } catch (error) {
        // console.clear()
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(status)
                    }
                }
            }
        }
    }
}