import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import '../styles/navbar-top.scss'
import Logo from '../images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faVideo, faStore, faComments, faBell, faPaperPlane, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { useCookies } from 'react-cookie';

import NoProfile from '../images/no-profile.png'
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

firebase.initializeApp({
    apiKey: "AIzaSyBorJDNVFAjnyK8eGTgMP2WOl59-2Au1a8",
    authDomain: "elivebylivelife.firebaseapp.com",
    projectId: "elivebylivelife",
    storageBucket: "elivebylivelife.appspot.com",
    messagingSenderId: "1019869524899",
    appId: "1:1019869524899:web:bce3c9f5697f29ac976016",
    measurementId: "G-6R1V7NBF6F"
});
const dbnotif = firebase.firestore()
let unsubscribe;
let unsubscribeCounting;

const NavbarTop = (props) => {
    let {
        inboxNew,
        inboxNewPerson,
        ChatPeople
    } = props
    const [cookies, setCookie, removeCookie] = useCookies(['']);
    const History = useHistory()
    const Profile = useSelector(state=>state.cacheState.profile)
    const [notification, setNotification] = useState([])
    const [notificationCount, setNotificationCount] = useState([])
    const [loadFirst, setLoadFirst] = useState(false)
    const [startAfterStore, setStartAfterStore] = useState([])
    const [notifLoad, setNotifLoad] = useState(false)
    const NotifTriggeredRef = useRef();
    const NotifRef = useRef();

    const NotifTriggered = useCallback(node=>{
        if (notifLoad) return
        if (Profile) {
            if (Profile.account_info) {
                if (NotifTriggeredRef.current) NotifTriggeredRef.current.disconnect()
                NotifTriggeredRef.current = new IntersectionObserver(entries=>{
                    if (entries[0].isIntersecting) {
                        getNotification(dbnotif, Profile.account_info.email, loadFirst)
                    }
                })
                if (node) NotifTriggeredRef.current.observe(node)
            }
        }
    },[notifLoad]);

    const goOnTop = () => {
        window.scroll({
            top: 0, 
            left: 0,
            behavior: 'smooth'
        });
    }

    const logout = () => {
        History.replace(`/login?event=${cookies.event}`)
        removeCookie('account')
        removeCookie('event')
        removeCookie('key')
        window.location.reload();
    }

    const goToNewTab = () => {
        const win = window.open(`https://livelifeindo.com/profile/view/${cookies['account']}`, "_blank");
        win.focus();
    }

    const timeSince = (date) => {
        let dates = date.toDate()
        let seconds = Math.floor((new Date() - dates) / 1000);
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    }

    useEffect(()=>{
        if (Profile) {
            if (Profile.account_info) {
                getNotification(dbnotif, Profile.account_info.email, loadFirst)
                if (!loadFirst) setLoadFirst(true)
                unsubscribe = dbnotif.collection('notification')
                .where("email", '==', Profile.account_info.email)
                .orderBy('createdAt', 'desc')
                .limit(1)
                .onSnapshot(async res=>{
                    if (res) {
                        const data = res.docs.map(doc=>({
                            ...doc.data(),
                            id: doc.id
                        }))
                        if (data) {
                            setNotification(prev=> [...data, ...prev])
                        }
                    }
                })
            }
        }
    },[dbnotif, Profile])

    useEffect(()=>{
        if (Profile) {
            if (Profile.account_info) {
                unsubscribeCounting = dbnotif.collection('notification')
                .where("email", '==', Profile.account_info.email)
                .where("received", '==', false)
                .onSnapshot(async res=>{
                    if (res) {
                        const data = res.docs.map(doc=>({
                            ...doc.data(),
                        }))
                        if (data) {
                            setNotificationCount(data)
                        }
                    }
                })
            }
        }
    },[Profile])

    const getNotification = async (dbnotif, email, trigger) => {
        try {
            if (dbnotif) {
                setNotifLoad(true)
                await dbnotif
                .collection('notification')
                .where("email", '==', email)
                .orderBy('createdAt', 'desc')
                .startAfter(startAfterStore)
                .limit(6)
                .get()
                .then(async res => {
                    let data = res.docs.map(doc=> ({
                        ...doc.data(),
                        id: doc.id
                    }))
                    if (data) {
                        setStartAfterStore(res.docs[res.docs.length-1])
                        if (!trigger) {
                            data.shift()
                            setNotification(prev=> [ ...prev, ...data])
                        } else {
                            setNotification(prev=> [ ...prev, ...data])
                        }
                    }
                    setNotifLoad(false)
                })
            }
        } catch (error) {
            setNotifLoad(false)
        }
    }

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const handleClickButton = (href) => {
        if (inboxNewPerson !== undefined) inboxNewPerson = undefined
        if (inboxNew !== undefined) inboxNew()
        if (ChatPeople !== undefined) ChatPeople()
        History.push(href)
        goOnTop()
    }

    return (
        <section className="topHeader">
            <div className="headerWrapper">
                <div className="headerInnerWrapper w100 d-flex align-items-center justify-content-between">
                    <img src={Logo} alt="icon"/>
                    <div className="menuWrapper">
                        <ul className="menuInnerWrapper">
                            <li className={`menuItem ${History.location.pathname === '/home' && 'active'}`}
                                onClick={_=> History.location.pathname !== '/home' && handleClickButton('/home')}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faHome}/>
                                </span>
                                <span className="menuText">Home</span>
                            </li>
                            <li className={`menuItem ${History.location.pathname === '/stage' && 'active'}`}
                                onClick={_=> History.location.pathname !== '/stage' && handleClickButton('/stage')}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faVideo} />
                                </span>
                                <span className="menuText">Stage</span>
                            </li>
                            <li className={`menuItem ${History.location.pathname === '/people' && 'active'}`}
                                onClick={_=> History.location.pathname !== '/people' && handleClickButton('/people')}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faUserFriends} />
                                </span>
                                <span className="menuText">People</span>
                            </li>
                            <li className={`menuItem ${History.location.pathname === '/booth' && 'active'} itemDisable`}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faStore} />
                                </span>
                                <span className="menuText">Booth</span>
                            </li>
                            <li className={`menuItem ${(History.location.pathname === '/forum' || History.location.pathname === '/inbox') && 'active'}`}
                                onClick={_=> History.location.pathname !== '/forum' && handleClickButton('/forum')}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faComments} />
                                </span>
                                <span className="menuText">Forum</span>
                            </li>
                        </ul>
                    </div>
                    <div className="rightActionWrapper">
                        {/* <div className="chatIconWrapper bellIcon actionIcon">
                            <div className="dropdown">
                                <span className="dropdown-toggle" id="dropNotif" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="rightIcon">
                                        <FontAwesomeIcon icon={faBell} />
                                        <span className="notifBullet">1</span>
                                    </span>
                                </span>
                                <div className="dropdown-menu dropdown-menu-end dropTopHead" aria-labelledby="dropNotif">
                                    <div className="dropTitle">
                                        <h5>Notifications</h5>
                                    </div>
                                    <div className="dropBody">
                                        <div className="dropdown-item active">
                                            <div className="row">
                                                <div className="col-2">
                                                    <div className="dropImgIcon">
                                                        <img src={ImageHaykal} align="M. Haykal"/>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="dropText">
                                                        <p>Haykal has viewed your profile</p>
                                                        <span>1 minute ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="row">
                                                <div className="col-2">
                                                    <div className="dropImgIcon">
                                                        <img src={ImageHaris} align="Haris Riswandi"/>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="dropText">
                                                        <p>Haris has viewed your profile</p>
                                                        <span>4 days ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="row">
                                                <div className="col-2">
                                                    <div className="dropImgIcon">
                                                        <FontAwesomeIcon icon={faBell} className="notif-icon-2131"/>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="dropText">
                                                        <p>We hope you're having fun in the Rooms! But did you get div chance to visit the booths yet? If not, go check out our partners to get all the information.</p>
                                                        <span>1 months ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="row">
                                                <div className="col-2">
                                                    <div className="dropImgIcon">
                                                        <FontAwesomeIcon icon={faBell} className="notif-icon-2131"/>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="dropText">
                                                        <p>We hope you are enjoying listening to Pranoto LIVE! Stay put as the session might get extended by div few minutes.</p>
                                                        <span>2 months ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div className="row">
                                                <div className="col-2">
                                                    <div className="dropImgIcon">
                                                        <FontAwesomeIcon icon={faBell} className="notif-icon-2131"/>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="dropText">
                                                        <p>Yay! Yay! Yay! Pranoto is going live in just div few minutes.</p>
                                                        <span>2 months ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="chatIconWrapper actionIcon">
                            <div className="dropdown">
                                <span className="dropdown-toggle" id="dropMessage" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="rightIcon">
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                        {
                                            notificationCount && notificationCount.length !== 0 &&
                                            <span className="notifBullet">{notificationCount && notificationCount.length}</span>
                                        }
                                    </span>
                                </span>
                                <div className="dropdown-menu dropdown-menu-end dropTopHead" aria-labelledby="dropMessage">
                                    <div className="dropTitle">
                                        <h5>Messages</h5>
                                    </div>
                                    <div className="dropSearch">
                                        <form className="dropSearchInput">
                                            <div className="row">
                                                <div className="form-group col-9">
                                                    <input type="text" className="form-control" placeholder="Search" disabled/>
                                                </div>
                                                <div className="form-group col-3 ncb">
                                                    <span className="btnLight disable">
                                                        New Chat
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="dropBodyMsg" ref={NotifRef}>
                                        {
                                            notification &&
                                            notification.map((notif, i)=>(
                                                <div className="dropdown-item active" key={i.toString()} ref={NotifTriggered}>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <div className="dropImgIcon">
                                                                <img src={notif && notif.photo }
                                                                    alt="avatar"
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = NoProfile;
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="dropText">
                                                                <p>{notif.sender_fullname}
                                                                {
                                                                    notif.type === 'chat' && ' sent a message'
                                                                }
                                                                </p>
                                                                {
                                                                    notif.type === 'chat' &&
                                                                    <p className="message-notification">{handleLimitString(notif.message, 35)}</p>
                                                                }
                                                                <span>{timeSince(notif.createdAt)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            notifLoad &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }} >
                                                <div className="spinner-border spinner-custom-btn"/>
                                            </div>
                                        }
                                    </div>
                                    <div className="dropFooterMsg text-center" onClick={_=> History.push('/inbox')}>
                                        <span>View all message</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="avtarWrapper">
                            <div className="avatarWrapper">
                                <div className="avtLayout" style={{ cursor: 'pointer' }} >
                                    <span className="dropdown-toggle" id="dropUser" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={Profile ? Profile.account_info.profile_photo : cookies['_US-IF'] && cookies['_US-IF'].profile_photo} alt="avatar"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NoProfile;
                                            }}
                                        />
                                    </span>
                                    <div className="dropdown-menu dropdown-menu-end dropTopHeadUsr" aria-labelledby="dropUser">
                                        <div className="dropBodyProfile">
                                            <span onClick={_=> goToNewTab()} className="dropdown-item">Profile</span>
                                            <span onClick={_=> History.push('/edit-profile')} className="dropdown-item">Edit profile</span>
                                            {
                                                cookies['key'] === 'author' &&
                                                <span onClick={_=> History.push('/customize')} className="dropdown-item">Customize</span>
                                            }
                                            <span onClick={logout} className="dropdown-item">Logout</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NavbarTop