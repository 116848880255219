import React , { memo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faClock, faBookmark } from '@fortawesome/free-regular-svg-icons'
import OwlCarousel from "react-owl-carousel";
import NoImage from '../../../images/no-image.png'
import CryptoJS from "crypto-js";

const Session = (props) => {
    const {
        handlerModalEvent
    } = props
    const sessionDashboard = useSelector(state=> state.eventState.sessionDashboard )
    const History = useHistory()
    const NavigationOwl = useRef();

    const [speakers] = useState([
        // {
        //     id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
        //     fullname: 'Haris Altest Sajo',
        //     profession: 'CEO & Founder',
        //     organization: 'Drag Taste'
        // },
    ])

    const handleOwlNav = (e, ops) => {
        if(e) e.preventDefault()
        if (ops === 'prev') {
            NavigationOwl.current.prev(500)
        } else if (ops === 'next') {
            NavigationOwl.current.next(500)
        }
    }

    const converterDate = (data) => {
        if (data) {
            let flag = null
            let dateConvertFrom
            let dateConvertTo
            let timeStart
            let timeEnd
            let timeZone
            if(data.show_date) {
                flag = true
                dateConvertFrom = data.show_date
                if (data.starttime) {
                    timeStart = data.starttime
                }
                if (data.endtime) {
                    timeEnd = data.endtime
                }
                if (data.time_zone) {
                    timeZone = data.time_zone
                }
                if(data.show_date_end) {
                    if (data.show_date !== data.show_date_end) {
                        dateConvertTo = data.show_date_end
                    }
                }
            }
            if (!flag) return ' Cooming Soon'
            return dateConvertTo ? ` ${dateConvertFrom} ${timeStart ? `| ${timeStart}` : ''} ${timeZone ? timeZone : ''} ${dateConvertTo ? ` - ${dateConvertTo}` : ''} ${timeEnd ? `| ${timeEnd}` : ''} ${timeZone ? timeZone : ''}` : ` ${dateConvertFrom} ${timeStart ? `| ${timeStart}` : ''} ${timeEnd ? ` - ${timeEnd}` : ''} ${timeZone ? timeZone : ''}`
        }
    }

    const handleParams = (data) => {
        if (data) {
            var encryptUser = CryptoJS.AES.encrypt(JSON.stringify({ id: data.id }), 'elive-livelife22').toString();
            History.push(`/watch/q?id=${encryptUser}`)
            goOnTop()
        }
    }

    const goOnTop = () => {
        window.scroll({
            top: 0, 
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        sessionDashboard?.length &&
        <section className="pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <div className="headContent">
                            <div className="titleHeadOwl">
                                <h2>Session &nbsp;<span>Featured</span></h2>
                            </div>
                            <div className="customNavigation">
                                <span className="viewAll" onClick={e=> History.push('/stage')}>View all</span>
                                <a className="btn gray prev" onClick={e=> handleOwlNav(e, 'prev', 'session') }>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </a>
                                <a className="btn gray next" onClick={e=> handleOwlNav(e, 'next', 'session') }>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <OwlCarousel
                        id="session"
                        className='owl-carousel'
                        responsiveClass
                        responsive={
                            {
                                0:{items:1,nav:false},
                                600:{items:2,nav:false},
                                1000:{items:3,nav:false,loop:false}
                            }
                        }
                        margin={10}
                        ref={NavigationOwl}
                    >
                        {
                            sessionDashboard?.map((data,i)=>(
                                <div className="item" key={i.toString()}>
                                    <div
                                        style={{
                                            background: `url(${data?.program_info?.image_banner_url}), url(${data?.program_info?.image_banner_url}) ,url(${NoImage}), url(${NoImage})`,
                                            height: 200,
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat, repeat, no-repeat, repeat'
                                        }}
                                    />
                                    <div className="itemContent">
                                        <div className="sessionTimeBookmark">
                                            <p>
                                                <FontAwesomeIcon icon={faClock} style={{ marginRight: 5 }}/>
                                                {
                                                    converterDate(data?.program_info)
                                                }
                                                {/* { `${data && data.program_info && data.program_info.starttime ? data.program_info.starttime : '-'} ${data && data.program_info && data.program_info.time_zone ? data.program_info.time_zone : '-'}` } - { `${data && data.program_info && data.program_info.endtime ? data.program_info.endtime : '-'} ${data && data.program_info && data.program_info.time_zone ? data.program_info.time_zone : '-'}` } */}
                                            </p>
                                            <div className="itemBookmark">
                                                <button className="bmBtn disabled">
                                                    <FontAwesomeIcon icon={faBookmark}/>
                                                </button>
                                            </div>
                                        </div>
                                        <h4>{ data?.program_info?.programme_name }</h4>
                                        <p>{ data?.program_info?.description }</p>
                                        
                                        <div className="speakerAvatar">
                                            {
                                                speakers?.map((person,index)=>(
                                                (index < 4) &&
                                                    <div className="avaSmall" key={index.toString()}>
                                                        <PopOverCustom
                                                            key={index}
                                                            component={
                                                                <div className='text-center widthPopProfile' >
                                                                    <img src={NoProfileDummy} className='imgPopHover'/>
                                                                    <h3 className='h3PopHover' style={{color:'red'}}>{handleLimitString(person.fullname, 20)}</h3>
                                                                    <p className='pPopHover' style={{color:'red'}}>{handleLimitString(`${person.profession ? person.profession : ''}${person.profession ? ` at ${person.organization}` : ''}`, 25)}</p>
                                                                    <div className='btnViewProf' onClick={_=> goToNewTab(person)}>
                                                                        <span style={{backgroundColor: 'red'}}>View Profile</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            placement="top"
                                                            delay={200}
                                                        >
                                                            <img src={NoProfileDummy} alt="image"
                                                                style={{
                                                                    marginLeft: index !== 0 && 1
                                                                }}
                                                            />
                                                        </PopOverCustom>
                                                    </div>
                                                ))
                                            }
                                            {
                                                speakers?.length ?
                                                (i % 2 === 0) &&
                                                <div className="avaMore" onClick={e => handlerModalPerson(e,true)}>
                                                    <p>
                                                        +{ speakers && speakers.length -4 }
                                                    </p>
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>
                                        
                                        <div className="watchSession" style={{ marginLeft: 'auto' }}>
                                            <span
                                                // className='disabled'
                                                onClick={_=> handleParams(data && data.program_info) }
                                            >Watch Session</span>
                                        </div> 
                                        <div className="moreInfo" onClick={e=>handlerModalEvent(e,true, data, i)}>
                                            <p>
                                                More info &nbsp;<FontAwesomeIcon icon={faCaretDown} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                </div>
            </div>
        </section>
    )
};

export default memo(Session);