import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import Imagebg1 from '../images/bg1.jpg'
import LLLogoSquare from '../images/ll.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkRegular, faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faCaretUp, faSlidersH, faPaperPlane, faUser, faChevronLeft, faChevronRight, faLaptopHouse  } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { sendChat, getChat } from '../store/actions/chat'
import { SEND_CHAT_ACTION } from '../store/constants'
import firebase from 'firebase/compat/app'

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

function Chat(props) {
    let {
        person,
        setPersonSelectChat,
        dbchats,
        unsubscribe
    } = props
    const [cookies, setCookie, removeCookie] = useCookies(['account']);
    const [personSet, setPersonSet] = useState(null)
    const Dispatch = useDispatch()
    const Profile = useSelector(state=>state.cacheState.profile)
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const [startAfterStore, setStartAfterStore] = useState([])
    const [chatLoad, setChatLoad] = useState(false)
    const [parameters, setParameters] = useState({
        scrollHeight: null
    })
    const FirstChatRef = useRef();
    const ChatsRef = useRef();

    const FirstChat = useCallback(node=>{
        if (chatLoad) return
        if (person) {
            if (FirstChatRef.current) FirstChatRef.current.disconnect()
            FirstChatRef.current = new IntersectionObserver(entries=>{
                if (entries[0].isIntersecting) {
                    getChats(dbchats, person)
                }
            })
            if (node) FirstChatRef.current.observe(node)
        }
    },[chatLoad]);

    useEffect(()=>{
        let trigger = false
        let triggerStore = null
        if (personSet !== person) {
            setPersonSet(person)
            setMessages([])
            setStartAfterStore([])
            triggerStore = []
            trigger = true
        }
        getChats(dbchats, person, trigger, triggerStore)
    },[dbchats, person])

    useEffect(()=>{
        if (person && (person === personSet)) {
            let messageID = [Profile.account_info.email, person.registrant_info.email]
            let messageIDsort = messageID.sort().join('x')
            unsubscribe = dbchats.collection('chat')
            .where("message_id", '==', messageIDsort)
            .orderBy('createdAt', 'desc')
            .limit(1)
            .onSnapshot(async res=>{
                if (res) {
                    const data = res.docs.map(doc=>({
                        ...doc.data(),
                        id: doc.id
                    }))
                    if (data[0]) {
                        if (data[0].id) {
                            const getting = dbchats.collection('notification')
                            .where('id_message', '==', data[0].id)
                            let res2 = await getting.get()
                            await res2.docs.map(doc=>{
                                if (doc.id) {
                                    if (doc.data().email === Profile.account_info.email) {
                                        if (!doc.data().received) {
                                            dbchats.collection('notification').doc(doc.id).update({
                                                received: true,
                                            })
                                        }
                                    }
                                }
                            })
                        }
                    }
                    if (data) {
                        setMessages(prev=> [...prev, ...data])
                        let container = document.getElementById('message-body')
                        if (container) container.scrollTop = container.scrollHeight;
                    }
                }
            })
        }
    },[dbchats, person, personSet])

    const getChats = async (dbchats, person, trigger, triggerStore) => {
        try {
            if (dbchats && person) {
                let messageID = [Profile.account_info.email, person.registrant_info.email]
                let messageIDsort = messageID.sort().join('x')
                setChatLoad(true)
                await dbchats
                .collection('chat')
                .where("message_id", '==', messageIDsort)
                .orderBy('createdAt', 'desc')
                .startAfter(startAfterStore || triggerStore)
                .limit(10)
                .get()
                .then(async res => {
                    let data = []
                    await res.docs.map(doc=>{
                        data.push({
                            ...doc.data(),
                            id: doc.id
                        })
                        if (doc.id) {
                            if (!doc.data().received) {
                                dbchats.collection('notification').doc(doc.id).update({
                                    received: true,
                                })
                            }
                        }
                    })
                    if (data) {
                        setStartAfterStore(res.docs[res.docs.length-1])
                        data.reverse()
                        if (trigger) {
                            data.pop()
                            setMessages(prev=> [...data, ...prev])
                            let container = document.getElementById('message-body')
                            if (container) container.scrollTop = container.scrollHeight;
                        } else {
                            setMessages(prev=> [...data, ...prev])
                            if (ChatsRef.current) {
                                const { scrollTop, scrollHeight, clientHeight } = ChatsRef.current;
                                const currentScroll = (scrollHeight-(parameters.scrollHeight - 20)) 
                                setParameters({
                                    ...parameters,
                                    scrollHeight: scrollHeight
                                })
                                if (parameters.scrollHeight) ChatsRef.current.scrollTo(0, currentScroll)
                            }
                        }
                    }
                    setChatLoad(false)
                })
            }
        } catch (error) {
            setChatLoad(false)
        }
    }

    const submit = (e) => {
        if(e) e.preventDefault()
        if (person) {
            if (person.registrant_info) {
                if (person.registrant_info.id === 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw') {
                    return
                }
            }
        }
        if (message) {
            let messageID = [Profile.account_info.email, person.registrant_info.email]
            let messageIDsort = messageID.sort().join('x')
            let times = firebase.firestore.Timestamp.fromDate(new Date())
            dbchats
            .collection('chat')
            .add({
                createdAt: times,
                email: person.registrant_info.email,
                sender_email: Profile.account_info.email,
                fullname: person.registrant_info.fullname,
                sender_fullname: Profile.account_info.fullname,
                message: message,
                message_id: messageIDsort,
                photo: person.registrant_info.profile_photo,
                photo_sender: Profile.account_info.profile_photo,
                chats_find : [person.registrant_info.email, Profile.account_info.email]
            })
            .then((docRef) => {
                setMessage('')
                dbchats.collection('notification').add({
                    id_message: docRef.id,
                    createdAt: times,
                    email: person.registrant_info.email,
                    sender_email: Profile.account_info.email,
                    fullname: person.registrant_info.fullname,
                    sender_fullname: Profile.account_info.fullname,
                    received: false,
                    message: message,
                    message_id: messageIDsort,
                    photo: Profile.account_info.profile_photo,
                    type: 'chat'
                })
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
            
        }
    }

    const Reset = () => {
        setPersonSelectChat(null)
        if (unsubscribe !== undefined) {
            unsubscribe()
        }
    }

    const handleChange = (key, value) => {
        switch (key) {
            case 'message':
                setMessage(value)
                break;
            default:
                break;
        }
    }

    const handleShowHour = (value) => {
        const dates = new Date(value * 1000)
        let minutes = dates.getMinutes();
        let hour = dates.getHours();
        return `${hour < 10 ? '0'+hour: hour}:${minutes < 10 ? '0'+minutes : minutes}`
    }

    const handleShowDate = (value) => {
        // today
        let dateToday = new Date(new Date()).getDate()
        let monthToday = new Date(new Date()).getMonth()
        let yearToday = new Date(new Date()).getFullYear()
        let today = `${dateToday} ${Months[monthToday]} ${yearToday}`

        if (value) {
            let convrt = value.toDate()
            let date = new Date(convrt).getDate()
            let month = new Date(convrt).getMonth()
            let year = new Date(convrt).getFullYear()
            return `${date} ${Months[month]} ${year}` === today ? 'Today' : `${date} ${Months[month]} ${year}`
        }
        return
    }

    return (
        <>
            <div className="toast-header">
                <img src={ person && person.registrant_info && person.registrant_info.profile_photo ? person.registrant_info.profile_photo : 'https://images.livelifeindo.com/upload/default/no-profile.png' } className="me-2" alt="Profile"/>
                <h5 className="me-auto">{ person && person.registrant_info && person.registrant_info.fullname ? person.registrant_info.fullname : '-' }</h5>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={Reset}/>
            </div>
            <div className="toast-body" id="message-body" ref={ChatsRef}>
                {
                    chatLoad &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} >
                        <div className="spinner-border spinner-custom-btn"/>
                    </div>
                }
                {
                    messages &&
                    messages.map((message, i)=>(
                        (message && message.sender_email ) == (Profile && Profile.account_info && Profile.account_info.email) ?
                            <div key={i}>
                                {
                                    i === 0 &&
                                    <div ref={FirstChat}/>
                                }
                                <div>
                                    {
                                        (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt)) &&
                                        <div className="date-chats" >
                                            {
                                                handleShowDate(message && message.createdAt)
                                            }
                                        </div>
                                    }
                                    <div className="chatMessageWrapper chatRight">
                                        <div className="chatUserAvatar">
                                            {
                                                (
                                                    ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                    (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                                ) &&
                                                <img src={Profile && Profile.account_info && Profile.account_info.profile_photo ? Profile.account_info.profile_photo : 'https://images.livelifeindo.com/upload/default/no-profile.png'} alt="LiveLife"/>
                                            }
                                        </div>
                                        <ul className={
                                            (
                                                ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                            ) ? "chatMessage chatMessageFirst" : "chatMessage"}
                                        >
                                            <li>
                                                <p>
                                                    {message && message.message}
                                                    { (handleShowHour(message && message.createdAt) !== handleShowHour(messages[i+1] && messages[i+1].createdAt))
                                                    &&
                                                        <span className="chatMessageTime">{handleShowHour(message && message.createdAt)}</span>
                                                    }
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            :
                            <div key={i}>
                                {
                                    i === 0 &&
                                    <div ref={FirstChat}/>
                                }
                                <div>
                                    {
                                        (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt)) &&
                                        <div className="date-chats" >
                                            {
                                                handleShowDate(message && message.createdAt)
                                            }
                                        </div>
                                    }
                                    <div className="chatMessageWrapper"
                                        style={{
                                            marginTop: messages[i-1] && ((messages[i-1] && messages[i-1].sender_email) !== (message && message.sender_email)) && '.3rem'
                                        }}
                                    >
                                        <div className="chatUserAvatar">
                                            {
                                                (
                                                    ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                    (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                                ) &&
                                                <img src={person && person.registrant_info && person.registrant_info.profile_photo ? person.registrant_info.profile_photo : 'https://images.livelifeindo.com/upload/default/no-profile.png'} alt="LiveLife"/>
                                            }
                                        </div>
                                        <ul className={
                                            (
                                                ((message && message.sender_email) !== (messages[i-1] && messages[i-1].sender_email)) ||
                                                (handleShowDate(message && message.createdAt) !== handleShowDate(messages[i-1] && messages[i-1].createdAt))
                                            ) ? "chatMessage chatMessageFirst" : "chatMessage"}
                                        >
                                            <li>
                                                <p>
                                                    {message && message.message}
                                                    { (handleShowHour(message && message.createdAt) !== handleShowHour(messages[i+1] && messages[i+1].createdAt))
                                                    &&
                                                        <span className="chatMessageTime">{handleShowHour(message && message.createdAt)}</span>
                                                    }
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    ))
                }
            </div>
            <div className="toast-footer">
                <form>
                    <div className="row g-2">
                        <div className="col-sm-10">
                            <input value={message} disabled={(person && person.registrant_info && person.registrant_info.id === 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw') ? true : false} type="text" className="form-control" name="liveChat" placeholder="Type a message" autoComplete="off" onChange={e=> handleChange('message', e.target.value)}/>
                        </div>
                        <div className="col-sm text-end sendMessage">
                            <button disabled={!message} type="submit" className="BtnCircle" onClick={submit}
                                style={{
                                    backgroundColor: !message && '#9897ff',
                                    color: !message && '#e8e8ff'
                                }}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Chat;