import axios from 'axios'
import {
    SERVER
} from '../../api'
import {
    PROFILE_USER
} from '../constants'

export const checkingId = (id, { cookies, setCookie, removeCookie, History, addToast, expired }) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/account/find?token=${process.env.REACT_APP_API_TOKEN}&id=${id}`,
        })
        if (data.status === true) {
            if (data.result) {
                if (data.result.account_info) {
                    setCookie('account', `${data.result.account_info.id}`, { expires: expired, path: '/' })
                    setCookie('_US-IF', JSON.stringify(data.result.account_info), { expires: expired, path: '/' })
                }
                dispatch({
                    type: PROFILE_USER,
                    data : data.result
                })
            }
        } else {
            History.replace(`/login?event=${cookies.event}`)
            removeCookie('account')
            removeCookie('event')
            removeCookie('key')
            addToast('Please re-login', {
                appearance: 'info',
                autoDismiss: true,
            })
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                        History.replace(`/login?event=${cookies.event}`)
                        removeCookie('account')
                        removeCookie('event')
                        removeCookie('key')
                        addToast(message, {
                            appearance: 'warning',
                            autoDismiss: true,
                        })
                    }
                }
            }
        }
    }
}

export const savingProfile = (id,{ setCookie, expired }) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/account/find?token=${process.env.REACT_APP_API_TOKEN}&id=${id}`,
        })
        if (data) {
            if (data.result) {
                if (setCookie) {
                    setCookie('_US-IF', JSON.stringify(data.result.account_info), { expires: expired, path: '/' })
                }
                dispatch({
                    type: PROFILE_USER,
                    data : data.result
                })
            }
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const getProfileDetail = (id, cd) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/account/find?token=${process.env.REACT_APP_API_TOKEN}&id=${id}`,
        })
        if (data) {
            cd(data.status,data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}

export const scanning = ({ decoded, event }, cd) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/registrants/find?token=${process.env.REACT_APP_API_TOKEN}&id_booking=${decoded}&id_idea=${event}`,
        })
        if (data) {
            cd(data.status,data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cd(false,'Qrcode not valid for this event')
                    }
                }
            }
        }
    }
}

export const checkingIdScanning = (id, { setCookie, expired }) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/account/find?token=${process.env.REACT_APP_API_TOKEN}&id=${id}`,
        })
        if (data) {
            if (data.result) {
                if (setCookie) {
                    setCookie('_US-IF', JSON.stringify(data.result.account_info), { expires: expired, path: '/' })
                }
                dispatch({
                    type: PROFILE_USER,
                    data : data.result
                })
            }
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}