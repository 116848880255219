import React from 'react';
import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Alerts(props) {
    const {
        show, variant, text, setAlertProps, close, link
    } = props
    return (
        <Alert show={show} variant={variant}
            style={{
                display: 'flex'
            }}
        >
            { text }
            {
                close &&
                <div
                    style={{
                        marginLeft : 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} onClick={_=> setAlertProps({ show: false, text : '', variant: '', close: false, loading: false })}
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </div>
            }
        </Alert>
    );
}

export default Alerts;