import axios from 'axios'
import {
    SERVER
} from '../../api'
import {
    VENUES_ALL
} from '../constants'

export const getVenues = () => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/venues/list_city?token=${process.env.REACT_APP_API_TOKEN}`,
        })
        if (data.status) {
            dispatch({
                type: VENUES_ALL,
                data : data.result
            })
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}