import React , { memo, useEffect, useState, useRef } from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import NoProfile from '../../../images/no-profile.png'

const UserList = (props) => {
    const {
        eventState,
        setPersonSelect,
        storeAction,//required
        title,
        dataRoot = 'talent_info'
    } = props
    const Dispatch = useDispatch()
    const NavigationOwl = useRef();
    const [data, setData] = useState([])

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const getRootParams = (param) => {
        if (dataRoot) {
            return dataRoot?.split('_')?.[0] + `_${param}`
        }
        return 'talent' + `_${param}`
    }

    const getRoot = () => {
        if (dataRoot) {
            return dataRoot?.split('_')?.[0]
        }
        return 'talent'
    }

    useEffect(()=>{
        if (eventState?.event_info) {
            let event_info = eventState?.event_info
            Dispatch(storeAction({
                id_idea: event_info?.id_idea,
                status: "confirmed",
                is_active: true,
                is_approved: true,
                is_delete: false,
            },(status, data)=>{
                if (status) {
                    if (data) {
                        setData(data?.filter(session=>{
                            if (dataRoot === 'talent_info') {
                                if (session?.[dataRoot]?.is_active_elive === '1') {
                                    return session
                                }
                            } else {
                                return session
                            }
                        }).map(session=>({
                            ...session?.[dataRoot],
                            photo: session?.[dataRoot]?.[getRootParams('photo')],
                            name: session?.[dataRoot]?.[getRootParams('name')] ,
                            role: session?.[dataRoot]?.[getRootParams('role')],
                            email: session?.[dataRoot]?.[getRootParams('email')],
                            description: session?.[dataRoot]?.[getRootParams('description')]
                        })))
                    }
                }
            }))
        }
    },[eventState])

    const handleOwlNav = (e, ops) => {
        if(e) e.preventDefault()
        if (ops === 'prev') {
            NavigationOwl.current.prev(500)
        } else if (ops === 'next') {
            NavigationOwl.current.next(500)
        }
    }

    return (
        data.length !== 0 &&
        <div className="row">
            <div className="col-lg-12 mb-2">
                <div className="headContent">
                    <div className="titleHead">
                        <h2>{title} &nbsp;<span>{data?.length}</span></h2>
                    </div>
                    <div className="customNavigation">
                        <span className="btn gray pplPrev" onClick={e=> handleOwlNav(e, 'prev') }>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                        <span className="btn gray pplNext" onClick={e=> handleOwlNav(e, 'next') }>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <OwlCarousel
                    id="speaker-session"
                    className="owl-carousel"
                    responsiveClass
                    responsive={
                        {
                            0:{items:2,nav:false},
                            600:{items:4,nav:false},
                            1000:{items:6,nav:false,loop:false}
                        }
                    }
                    margin={10}
                    ref={NavigationOwl}
                >
                    {
                        data?.map((person,index)=>(
                            <div className="item" style={{ cursor:  'pointer' }} key={index.toString()}>
                                <div className={['media'].includes(getRoot()) ? `` : "imgPeople"}>
                                    <div id="a" className={['media'].includes(getRoot()) ? `` : "imgPeopleBorder"}>
                                        <span data-bs-toggle="modal" data-bs-target="#usrDetail" onClick={_=>setPersonSelect(person)}>
                                            <img id="q" src={person?.photo} className="blur ds-pa"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = NoProfile;
                                                }}
                                                style={{
                                                    borderRadius: ['media'].includes(getRoot()) && "0.7rem",
                                                    padding: ['media'].includes(getRoot()) && "0.3rem",
                                                }}
                                            />
                                        </span>
                                        <div className="topBooth text-end">
                                            <div className="bmBtnCircle">
                                                <FontAwesomeIcon icon={faBookmark} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={['media'].includes(getRoot()) ? `d-none` : "descPeople"}>
                                    <h4>{ handleLimitString(person?.name, 17) }</h4>
                                    <p>{ handleLimitString(person?.role, 20) }</p>
                                </div>
                            </div>
                        ))
                    }
                </OwlCarousel>
            </div>
        </div>
    )
};

export default memo(UserList);