import React,{ useEffect, useState } from 'react';
import '../../styles/edit-profile.scss';
import { useHistory } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import NavbarTop from '../../components/TopNavbar';
import BottomNavbar from '../../components/BottomNavbar';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import TextareaAutosize from 'react-textarea-autosize';
import { saveProfilePicture } from '../../store/actions/action';
import { LoginAccount } from '../../store/actions/signUpIn';
import NoProfile from '../../images/no-profile.png'

const Years = [ "1940", "1941", "1942", "1943", "1944", "1945", "1946", "1947", "1948", "1949", "1950", "1951", "1952", "1953", "1954", "1955", "1956", "1957", "1958", "1959", "1960", "1961", "1962", "1963", "1964", "1965", "1966", "1967", "1968", "1969", "1970", "1971", "1972", "1973", "1974", "1975", "1976", "1977", "1978", "1979", "1980", "1981", "1982", "1983", "1984", "1985", "1986", "1987", "1988", "1989", "1990", "1991", "1992", "1993", "1994", "1995", "1996", "1997", "1998", "1999", "2000", "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"]
const Month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const EditProfile = () => {
    const { addToast } = useToasts()
    const [cookies, setCookie, removeCookie] = useCookies(['account']);
    const History = useHistory()
    const Dispatch = useDispatch()
    const Profile = useSelector(state=>state.cacheState.profile)
    const Venues = useSelector(state=>state.optionState.venues)
    const [citys, setCitys] = useState([{ label: 'asdasdasd' }])
    const [day, setDay] = useState(0)
    const [paramsClick, setParamsClick] = useState(false)
    const [paramsClickPass, setParamsClickPass] = useState(false)
    const [paramsClickPassNew, setParamsClickPassNew] = useState(false)
    const [loading, setLoading] = useState(null)
    const [first, setFirst] = useState(false)
    const [params, setParams] = useState({
        email: '',
        gender_id: '',
        phone: '',
        phone_code: 'id',
        phone_dialCode: '',
        profession: '',
        organization: '',
        country_id: '',
        live_in: [{ label: ''}],
        fullname: '',
        about: '',
        year: '',
        month: '',
        day: '',
    })
    const [paramsPassword, setParamsPassword] = useState({
        password: '',
        passwordNew: '',
        passwordReNew: '',
    })

    useEffect(()=>{
        if (params.year && params.month) {
            let result = []
            let max = new Date(Number(params.year), Number(params.month), 0).getDate()
            for (let i = 1; i <= max ; i++) {
                result.push(i)
            }
            setDay(result)
        }
    },[params.year, params.month])

    useEffect(()=>{
        if (Profile) {
            if (Profile.account_info) {
                let data = Profile.account_info
                let data2 = Profile.contact_detail
                setParams({
                    ...params,
                    email: data.email,
                    gender_id: data.gender_id,
                    phone: data.phone ? data.phone : '',
                    profession: data2.profession,
                    organization: data2.organization,
                    country_id:  data.country_id,
                    live_in:  [{ label: data.live_in ? data.live_in : '' }],
                    fullname: data.fullname,
                    about: data.aboutMe,
                    phone_code: data.phone_code,
                    phone_dialCode : data.phone_dialCode
                })
                setFirst(!first)
            }
        }
        // document.getElementById('pass-tab').classList.add('active')
        // document.getElementById('profPass').classList.add('active')
        // document.getElementById('profPass').classList.add('show')
        // document.getElementById('prof-tab').classList.remove('active')
        // document.getElementById('profUser').classList.remove('active')
        // document.getElementById('profUser').classList.remove('show')
    },[Profile])

    const setValue = (value, key) => {
        switch (key) {
            case 'fullname':
                setParams({
                    ...params,
                    fullname: value
                })
                break;
            case 'gender_id':
                setParams({
                    ...params,
                    gender_id: value
                })
                break;
            case 'phone':
                setParams({
                    ...params,
                    phone: `${value}`
                })
                break;
            case 'profession':
                setParams({
                    ...params,
                    profession: value
                })
                break;
            case 'organization':
                setParams({
                    ...params,
                    organization: value
                })
                break;
            case 'country_id':
                setParams({
                    ...params,
                    country_id: value
                })
                break;
            case 'live_in':
                setParams({
                    ...params,
                    live_in: value
                })
                break;
            case 'about':
                setParams({
                    ...params,
                    about: value,
                })
                break;
            case 'year':
                setParams({
                    ...params,
                    year: value,
                })
                break;
            case 'month':
                setParams({
                    ...params,
                    month: value,
                })
                break;
            case 'day':
                setParams({
                    ...params,
                    day: value,
                })
                break;
            case 'password':
                if (paramsClickPass) setParamsClickPass(false)
                setParamsPassword({
                    ...paramsPassword,
                    password: value,
                })
                break;
            case 'passwordNew':
                if (paramsClickPassNew) setParamsClickPassNew(false)
                setParamsPassword({
                    ...paramsPassword,
                    passwordNew: value,
                })
                break;
            case 'passwordReNew':
                if (paramsClickPassNew) setParamsClickPassNew(false)
                setParamsPassword({
                    ...paramsPassword,
                    passwordReNew: value,
                })
                break;
            default:
                break;
        }
    }

    const resetPassword = () => {
        setParamsPassword({
            password: '',
            passwordNew: '',
            passwordReNew: '',
        })
        setParamsClick(false)
        setParamsClickPass(false)
        setParamsClickPassNew(false)
    }

    useEffect(()=>{
        let result = []
        if (Venues) {
            let filtered = Venues.filter(country=> country.city_info.id_country == params.country_id)
            if (filtered.length !== 0) {
                filtered.map(country=>{
                    result.push({
                        label: `${country.city_info.city_name} - ${country.city_info.province_name}`
                    })
                })
            }
        }
        setCitys(result)
    },[Venues, params.country_id])

    const processNumber = (isValid, phone, country, fullNumber) => {
        return phone
    };

    const processNumberCountry = (isValid, phone, country) => {
        setParams({
            ...params,
            phone_code: country.iso2,
            phone_dialCode: country.dialCode,
        })
    };

    const onChangeHandler = (file) => {
        let image = (file.target.files[0])
        Dispatch(saveProfilePicture({ filename: image.name, id: cookies['account'] }, { setLoading:null, addToast, message: 'Update profile success' }))
    }

    const submit = (event) => {
        if(event) event.preventDefault()
        setParamsClick(true)
        let flag = true
        for(let key in params){
            if (
                key === 'fullname'
            ) {
                if (!params[key]) {
                    addToast('Complete the form', {
                        appearance: 'warning',
                        autoDismiss: true,
                    })
                    flag = false
                    return
                }
            }
        }
        if (params['year'] && (!params['day'] || !params['month']) ) {
            addToast('Complete the form', {
                appearance: 'warning',
                autoDismiss: true,
            })
            flag = false
            return
        } else if (params['day'] && (!params['year'] || !params['month']) ) {
            addToast('Complete the form', {
                appearance: 'warning',
                autoDismiss: true,
            })
            flag = false
            return
        } else if (params['month'] && (!params['day'] || !params['year']) ) {
            addToast('Complete the form', {
                appearance: 'warning',
                autoDismiss: true,
            })
            flag = false
            return
        }

        if (flag) {
            let result = {
                id: cookies['account'],
                email: params.email,
                gender_id: params.gender_id,
                phone: params.phone.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
                profession: params.profession,
                organization: params.organization,
                country_id: params.country_id,
                live_in: document.getElementsByClassName("rbt-input-main")[0].value,
                fullname: params.fullname,
                aboutMe: params.about,
                phone_code: params.phone_code,
                phone_dialCode: params.phone_dialCode
            }
            if (params.day) result.birthDay = `${params.day} ${Month[params.month-1]} ${params.year}`
            Dispatch(saveProfilePicture(result, {setLoading, addToast, message: 'Update profile success'}))
        }
    }

    const submitPassword = (event) => {
        if(event) event.preventDefault()
        setParamsClick(true)
        let flag = true
        for(let key in paramsPassword){
            if (!paramsPassword[key]) {
                addToast('Complete the form', {
                    appearance: 'warning',
                    autoDismiss: true,
                })
                flag = false
                return
            }
        }
        if (flag) {
            if (paramsPassword.passwordNew.length < 6 || paramsPassword.passwordReNew.length < 6) {
                if (!paramsClickPassNew) setParamsClickPassNew(true)
                addToast('Minimum password 6 characters', {
                    appearance: 'warning',
                    autoDismiss: true,
                })
            } else if (paramsPassword.passwordNew !== paramsPassword.passwordReNew) {
                if (!paramsClickPassNew) setParamsClickPassNew(true)
                addToast('New password is not the same', {
                    appearance: 'warning',
                    autoDismiss: true,
                })
            } else {
                Dispatch(LoginAccount({ username: Profile.account_info.email, password: paramsPassword.password },(data)=>{
                    if (!data.status) {
                        if (!paramsClickPass) setParamsClickPass(true)
                        addToast('Wrong password', {
                            appearance: 'warning',
                            autoDismiss: true,
                        })
                    } else {
                        Dispatch(saveProfilePicture({ password: paramsPassword.passwordNew, id: cookies['account'] }, {setLoading, addToast, message: 'Update password success'}))
                        resetPassword()
                    }
                }))
            }
        }
    }

    return (
        <div id="edit-profile-87612">
            <div className="bg-homepage"/>
            <NavbarTop />

            <section className="forum pt-8 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-2">
                            <div className="profileImg text-center mb-4">
                                <img src={Profile ? Profile.account_info.profile_photo : cookies['_US-IF'] && cookies['_US-IF'].profile_photo} className="mb-3"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoProfile;
                                    }}
                                />
                                <div className="ownerLink">
                                    <label htmlFor="filename-photopicture" className="headTopBtn disabled">Update pict</label>
                                    <input
                                        type="file"
                                        id="filename-photopicture"
                                        accept=".jpg, .jpeg"
                                        disabled
                                        onChange={e=>onChangeHandler(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 mb-2">
                            <div className="boxForumTabs mb-4">
                                <ul className="nav nav-tabs flex-column flex-sm-row" id="userUpdateList" role="tablist">
                                    <li className="flex-sm-fill text-sm-center nav-item profTab" role="presentation" onClick={resetPassword}>
                                        <span className="nav-link active" id="prof-tab" data-bs-toggle="tab" data-bs-target="#profUser" type="button" role="tab">
                                            <span className="iconTabs">
                                                <i className="fas fa-user"/>
                                            </span>
                                            <span className="textTabs">Profile</span>
                                        </span>
                                    </li>
                                    <li className="flex-sm-fill text-sm-center nav-item profTab" role="presentation" onClick={resetPassword}>
                                        <span className="nav-link" id="pass-tab" data-bs-toggle="tab" data-bs-target="#profPass" type="button" role="tab">
                                            <span className="iconTabs"><i className="fas fa-lock"/></span>
                                            <span className="textTabs">Password</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="profUser" role="tabpanel" aria-labelledby="prof-tab">
                                    <div className="boxForum borderContent mb-4">
                                        <form className="userUpdate">
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                                    <div className="col-sm-9">
                                                        <input value={params.fullname} onChange={e=>setValue(e.target.value, 'fullname')} type="text" className="form-control" id="name" placeholder="Your Name"
                                                            style={{
                                                                backgroundColor: ((paramsClick && !params.fullname)) && '#f6d6d9',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="gender" className="col-sm-3 col-form-label">Gender</label>
                                                    <div className="col-sm-9">
                                                        <select value={params.gender_id} onChange={e=>setValue(e.target.value, 'gender_id')} className="form-control customHover" id="gender" placeholder="Select your gender" autoComplete="off"
                                                            style={{ color: !params.gender_id && '#737373' }}
                                                        >
                                                            <option className="optionHover" value="" style={{ color: '#737373' }} >Your gender</option>
                                                            <option className="optionHover" value="1">Male</option>
                                                            <option className="optionHover" value="2">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
                                                    <div className="col-sm-9">
                                                        <input value={params.email} type="email" className="form-control" id="email" disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label for="email" className="col-sm-3 col-form-label">Birthday</label>
                                                    <div className="col-sm-9 d-flex">
                                                        <select
                                                            value={params.day}
                                                            onChange={e=>setValue(e.target.value, 'day')}
                                                            className="form-control customHover"
                                                            id="day"
                                                            placeholder="Day"
                                                            autocomplete="off"
                                                            style={{ color: !params.day && '#737373', textAlign: 'center', backgroundColor: ((paramsClick && (!params.day && (params.month || params.year)))) && '#f6d6d9' }}
                                                        >
                                                            <option className="optionHover" value="" style={{ color: '#737373' }} >Day</option>
                                                            {
                                                                day && day.map((day, i)=>(
                                                                    <option key={i} className="optionHover" value={day}>{day}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <select
                                                            value={params.month}
                                                            onChange={e=>setValue(e.target.value, 'month')}
                                                            className="form-control customHover mx-2"
                                                            id="month"
                                                            placeholder="Month"
                                                            autocomplete="off"
                                                            style={{ color: !params.month && '#737373', textAlign: 'center', backgroundColor: ((paramsClick && (!params.month && (params.day || params.year)))) && '#f6d6d9' }}
                                                        >
                                                            <option className="optionHover" value="" style={{ color: '#737373' }} >Month</option>
                                                            {
                                                                Month && Month.map((month, i)=>(
                                                                    <option key={i} className="optionHover" value={i+1}>{month}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <select
                                                            value={params.year}
                                                            onChange={e=>setValue(e.target.value, 'year')}
                                                            className="form-control customHover"
                                                            id="year"
                                                            placeholder="Year"
                                                            autocomplete="off"
                                                            style={{ color: !params.year && '#737373', textAlign: 'center', backgroundColor: ((paramsClick && (!params.year && (params.day || params.month)))) && '#f6d6d9' }}
                                                        >
                                                            <option className="optionHover" value="" style={{ color: '#737373' }} >Year</option>
                                                            {
                                                                Years && Years.map((year, i)=>(
                                                                    <option key={i} className="optionHover" value={year}>{year}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="phone" className="col-sm-3 col-form-label">Phone or WhatsApp</label>
                                                    <div className="col-sm-9">
                                                        <IntlTelInput
                                                            format={false}
                                                            formatOnInit={false}
                                                            key={first}
                                                            defaultCountry={params.phone_code}
                                                            containerClassName="intl-tel-input"
                                                            inputClassName="form-control"
                                                            // value={params.phone}
                                                            onPhoneNumberFocus={(...args) => {
                                                                processNumberCountry(...args);
                                                            }}
                                                            onPhoneNumberChange={(...args) => {
                                                                setValue(processNumber(...args), 'phone');
                                                            }}
                                                            separateDialCode={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="profession" className="col-sm-3 col-form-label">Profession/Occupation</label>
                                                    <div className="col-sm-9">
                                                        <input value={params.profession} onChange={e=>setValue(e.target.value, 'profession')} type="text" className="form-control" id="profession" placeholder="Profession/Occupation"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="organization" className="col-sm-3 col-form-label">Organization</label>
                                                    <div className="col-sm-9">
                                                        <input value={params.organization} onChange={e=>setValue(e.target.value, 'organization')} type="text" className="form-control" id="organization" placeholder="Organization"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="country_id" className="col-sm-3 col-form-label">Country</label>
                                                    <div className="col-sm-9">
                                                        <select value={params.country_id} onChange={e=>setValue(e.target.value, 'country_id')} className="form-control customHover" id="country_id" placeholder="Type your country" autoComplete="off"
                                                            style={{ color: !params.country_id && '#737373' }}
                                                        >
                                                            <option className="optionHover" value="" style={{ color: '#737373' }} >Type your country</option>
                                                            <option className="optionHover" value="1">Singapore</option>
                                                            <option className="optionHover" value="2">Indonesia</option>
                                                            <option className="optionHover" value="0">Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="live_in" className="col-sm-3 col-form-label">Location</label>
                                                    <div className="col-sm-9">
                                                        <Typeahead
                                                            id="liveinid"
                                                            labelKey={option=> `${option.label}` }
                                                            minLength={1}
                                                            onChange={e=>setValue(e, 'live_in')}
                                                            options={citys}
                                                            placeholder="Type your location"
                                                            selected={params.live_in}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="organization" className="col-sm-3 col-form-label">About</label>
                                                    <div className="col-sm-9">
                                                        <TextareaAutosize className="form-control" id="about" minRows="5" placeholder="About" value={params.about} onChange={e=>setValue(e.target.value, 'about')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    {
                                                        loading ?
                                                        <div type="submit" className="btnLight"
                                                            style={{
                                                                width: '61px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <div className="spinner-border spinner-custom-btn" style={{ borderColor: '#212529' }} />
                                                        </div>:
                                                        <button type="submit" className="btnLight" onClick={submit}
                                                            style={{
                                                                width: '61px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            Update
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profPass" role="tabpanel" aria-labelledby="pass-tab">
                                    <div className="boxForum borderContent mb-4">
                                        <form className="userUpdate">
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="password" className="col-sm-3 col-form-label">Password</label>
                                                    <div className="col-sm-9">
                                                        <input
                                                            style={{
                                                                backgroundColor: ((paramsClick && !paramsPassword.password) || paramsClickPass) && '#f6d6d9',
                                                            }}
                                                            type={"password"}
                                                            className="form-control"
                                                            id="password"
                                                            value={paramsPassword.password}
                                                            onChange={e=> setValue(e.target.value,'password')}
                                                            placeholder="Password"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="newPassword" className="col-sm-3 col-form-label">New Password</label>
                                                    <div className="col-sm-9">
                                                        <input type="password" className="form-control" id="newPassword"
                                                            value={paramsPassword.passwordNew}
                                                            style={{
                                                                backgroundColor: ((paramsClick && !paramsPassword.passwordNew) || paramsClickPassNew) && '#f6d6d9',
                                                            }}
                                                            onChange={e=> setValue(e.target.value,'passwordNew')}
                                                            placeholder="New password"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group">
                                                <div className="mb-2 row">
                                                    <label htmlFor="rePassword" className="col-sm-3 col-form-label">rePassword</label>
                                                    <div className="col-sm-9">
                                                        <input type="password" className="form-control" id="rePassword"
                                                            value={paramsPassword.passwordReNew}
                                                            style={{
                                                                backgroundColor: ((paramsClick && !paramsPassword.passwordReNew) || paramsClickPassNew) && '#f6d6d9',
                                                            }}
                                                            onChange={e=> setValue(e.target.value,'passwordReNew')}
                                                            placeholder="Re-new password"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row-reverse">
                                                <div className="from-group mt-2">
                                                    <button type="submit" className="btnLight" onClick={submitPassword} >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <BottomNavbar />

        </div>
    );
};

export default EditProfile