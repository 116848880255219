import { combineReducers } from 'redux'
import EventReducer from './event'
import CacheReducer from './cache'
import OptionsReducer from './option'
import ChatReducer from './chat'
import ForumReducer from './forum'

const reducers = combineReducers({
    eventState : EventReducer,
    cacheState : CacheReducer,
    optionState : OptionsReducer,
    chatState : ChatReducer,
    forumState : ForumReducer
})

export default reducers