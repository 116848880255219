import React,{ useEffect, useState, useRef, useCallback } from 'react';
import '../../styles/stage.scss'
import { useHistory } from 'react-router-dom'
import NavbarTop from '../../components/TopNavbar'
import BottomNavbar from '../../components/BottomNavbar'
import PopOverCustom from '../../components/popOver'
import CryptoJS from "crypto-js";
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faBookmark, faClock } from '@fortawesome/free-regular-svg-icons'
import { OverlayTrigger, Modal, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getSessions } from '../../store/actions/event'
import { converterDateSessionStartTime, converterDateSessionTime } from '../../helpers/global'
import SpeakersModal from '../../components/SpeakersModal'
import SessionModal from '../../components/SessionModal'

import NoImage from '../../images/no-image.png'
import NoProfile from '../../images/no-profile.png'
import LoadingGif from '../../images/loading-livelife.gif'
import { useMemo } from 'react';

const Stage = () => {
    const eventState = useSelector(state=> state.eventState.event)
    const Dispatch = useDispatch()
    const History = useHistory()
    const [modalPerson, setModalPerson] = useState(false);
    const [modalEvent, setModalEvent] = useState({
        show: false,
        data: null,
        index: null
    });
    const [sliderValue, setSliderValue] = useState(true);
    const [_, setDateValue] = useState(new Date());
    const [sessionSearch, setSessionSearch] = useState([])
    const [sessions, setSessions] = useState([])
    const [parameterSession, setParameterSession] = useState({
        id_idea : null,
        limit : 4,
        offsetSave: null,
        offset : '0',
        limitterSave: null,
        limitter: false,
        order_by: 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC',
        search: null,
        searchLoad: false,
        is_active_elive: 1,
    })
    const [speakers] = useState([
        // {
        //     id: 'KwePOTCJRailpUMhdXUNUlrnE7Yz2kDcCGTU320oeKw',
        //     fullname: 'Haris Altest Sajo',
        //     profession: 'CEO & Founder',
        //     organization: 'Drag Taste'
        // },
    ])
    const [debouncedSearch, setDebouncedSearch] = useState(parameterSession.search);
    const [sessionLoad, setSessionLoad] = useState(false)
    const LastSessionRef = useRef();
    const LastSessionCall = useCallback(node=>{
        if (sessionLoad) return
        if (parameterSession.limitter) return 
        if (LastSessionRef.current) LastSessionRef.current.disconnect()
        LastSessionRef.current = new IntersectionObserver(entries=>{
            if (entries[0].isIntersecting) {
                let offset = Number(parameterSession.offset)
                let limit = Number(parameterSession.limit)
                let counting = offset += limit
                setParameterSession({
                    ...parameterSession,
                    offset: counting
                })
            }
        })
        if (node) LastSessionRef.current.observe(node)
    },[sessionLoad]);

    useEffect(()=>{
        if (eventState?.event_info?.id_event) {
            setParameterSession({
                ...parameterSession,
                id_idea: eventState?.event_info?.id_event
            })
        }
    },[eventState])

    useEffect(()=>{
        if (parameterSession.limitter) return 
        if (!parameterSession.limitter) setSessionLoad(true)
        if (parameterSession?.id_idea) {
            Dispatch(getSessions(parameterSession,(status, data)=>{
                if (status) {
                    if (parameterSession.search) setSessionSearch(prev=> [...prev, ...data])
                    else setSessions(prev=> [...prev, ...data])
                    if (data.length < parameterSession.limit) {
                        setParameterSession({
                            ...parameterSession,
                            limitter: true
                        })
                    }
                    setSessionLoad(false)
                } else if (!status){
                    addToast('Something Problem...', {
                        appearance: 'warning',
                        autoDismiss: true,
                    })
                }
            }))
        }
    },[parameterSession.offset, parameterSession.searchLoad === true, parameterSession?.id_idea])

    useEffect(()=>{
        const timer = setTimeout(() => {
            if (debouncedSearch !== null) {
                setParameterSession({
                    ...parameterSession,
                    search: debouncedSearch,
                })
            }
        }, 1000);
        return () => clearTimeout(timer);
    },[debouncedSearch])

    useEffect(()=>{
        if (parameterSession.search) {
            setSessionSearch([])
            setParameterSession({
                ...parameterSession,
                offsetSave: parameterSession.offsetSave ? parameterSession.offsetSave : parameterSession.offset,
                offset: '0',
                limitterSave: parameterSession.limitterSave ? parameterSession.limitterSave : parameterSession.limitter,
                limitter: false,
                searchLoad: !parameterSession.searchLoad
            })
        } else if (parameterSession.search === '') {
            setParameterSession({
                ...parameterSession,
                offsetSave: null,
                offset: parameterSession.offsetSave,
                limitter: parameterSession.limitterSave,
                searchLoad: false
            })
        }
    },[parameterSession.search])

    useEffect(()=>{
        if (document.getElementById('search')) {
            if ($('#search').val()) {
                document.getElementById('search').classList.add('active')
            } else {
                document.getElementById('search').classList.remove('active')
            }
        }
    },[$('#search').val()])

    const searchHandler = (e) => {
        if (e) e.preventDefault()
        if (document.getElementById('search')) {
            if (document.getElementById('search').classList.contains('active')) {
                document.getElementById('search').classList.remove('active')
            }
        }
    }
    
    const goOnTop = () => {
        window.scroll({
            top: 0, 
            left: 0,
            behavior: 'smooth'
        });
    }

    const handlerModalPerson = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModalPerson(value)
        } else {
            setModalPerson(value)
        }
    }

    const handlerModalEvent = (e, value, data, index = null) => {
        if(e) e.preventDefault()
        setModalEvent({
            show: value,
            data,
            index
        })
    }

    useEffect(()=>{
        $(".calendarInput").datepicker({
            format: 'yyyy-mm-dd',
            autoclose: true,
            todayHighlight: true,
        }).datepicker("setDate", "0");;
        $(".calendarInput").on('changeDate', (e) => {
            if (e) {
                if (e.date) {
                    setDateValue(new Date(e.date))
                }
            }
        })
    },[])

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    const goToNewTab = (user) => {
        const win = window.open(`https://livelifeindo.com/profile/view/${user && user.id}`, "_blank");
        win.focus();
    }

    const handleFilter = (key, value) => {
        switch (key) {
            case 'keyword':
                setDebouncedSearch(value)
                break;
            default:
                break;
        }
    }

    const converterDateSession = (data) => {
        if (data) {
            let flag = null
            let dateConvertFrom
            let dateConvertTo
            let timeStart
            let timeEnd
            let timeZone
            if(data.show_date) {
                flag = true
                dateConvertFrom = data.show_date
                if (data.starttime) {
                    timeStart = data.starttime
                }
                if (data.endtime) {
                    timeEnd = data.endtime
                }
                if (data.time_zone) {
                    timeZone = data.time_zone
                }
                if(data.show_date_end) {
                    if (data.show_date !== data.show_date_end) {
                        dateConvertTo = data.show_date_end
                    }
                }
            }
            if (!flag) return ' Cooming Soon'
            return dateConvertTo ? ` ${dateConvertFrom} ${timeStart ? `| ${timeStart}` : ''} ${timeZone ? timeZone : ''} ${dateConvertTo ? ` - ${dateConvertTo}` : ''} ${timeEnd ? `| ${timeEnd}` : ''} ${timeZone ? timeZone : ''}` : ` ${dateConvertFrom} ${timeStart ? `| ${timeStart}` : ''} ${timeEnd ? ` - ${timeEnd}` : ''} ${timeZone ? timeZone : ''}`
        }
    }

    const handleParams = (data) => {
        if (data) {
            var encryptUser = CryptoJS.AES.encrypt(JSON.stringify({ id: data.id }), 'elive-livelife22').toString();
            History.push(`/watch/q?id=${encryptUser}`)
            goOnTop()
        }
    }

    return (
        <div id="stage-87612">
            <div className="bg-homepage"/>
            <NavbarTop />
            <section className="contentHeaderDetail pt-8 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className="headContent">
                                <div className="titleHead">
                                    <h2>Stage</h2>
                                </div>
                                <div className="customViewAll">
                                    <div className="filterRightColumn">
                                        <p className="primaryColor normal typoText bodyRegular" data-testid="" title=""> Show Live Stage</p>
                                        <label className="switch">
                                            <input type="checkbox" disabled checked={sliderValue} onClick={_=> setSliderValue(!sliderValue) } className='disabled'/>
                                            <span className="slider round"></span>
                                        </label>
                                        <div className="position-relative float-left filterArea">
                                            <button type="button" title="" className="btn filterBtn disabled">
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faSlidersH} style={{ marginRight: 5 }} />
                                                </span>
                                                <span>Filter</span>
                                            </button>
                                        </div>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={<Tooltip className="tooltip-custom">Search stage</Tooltip>}
                                        >
                                            <div className="position-relative float-right">
                                                <div id="wrap">
                                                    <form action="" autocomplete="off" className='form-search-stage'>
                                                        <input id="search" name="search" type="text" placeholder="What're we looking for ?"
                                                            // disabled
                                                            onChange={e=> handleFilter('keyword', e.target.value)}
                                                            value={debouncedSearch}
                                                        />
                                                        <input id="search_submit" value="" type="submit" onClick={searchHandler}
                                                            // className='disabled'
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FILTER KALENDAR */}
                        {/* <div className="col-lg-12">
                            <div className="subHeadContent">
                                <div className="dateTopBtn" style={{ cursor: 'default' }}>
                                    <span>
                                        { DateChanger(dateValue) }
                                        &nbsp;
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                </div>
                                <div className="customCalendarBtn">
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip className="tooltip-custom">Calendar</Tooltip>}
                                    >
                                        <span ref={DateRef} id="selectCalendar" className="calendarInput">
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="pt-4 pb-5">
                <div className="container">
                    {
                        (
                            parameterSession.search ?
                            (sessionSearch && sessionSearch)
                            :
                            (sessions && sessions)
                        ).map((data, i)=>(
                            <div className="contentStage" ref={LastSessionCall} key={i}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="headContent">
                                            <div className="titleStages">
                                                <h3>{converterDateSessionStartTime(data?.program_info?.show_date)}</h3>
                                                <p className="sessionStart">
                                                    <span>{converterDateSessionTime(data?.program_info)}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-md-6">
                                            <div
                                                style={{
                                                    background: `url(${data?.program_info?.image_banner_url}), url(${NoImage})`,
                                                    height: "100%",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card-body">
                                                <div className="itemContent">
                                                    <div className="sessionTimeBookmark">
                                                        <p>
                                                            <FontAwesomeIcon icon={faClock} style={{ marginRight: 1 }}/>
                                                            { converterDateSession(data?.program_info)}
                                                        </p>
                                                        <div className="itemBookmark">
                                                            <button className="bmBtn disabled" >
                                                                <FontAwesomeIcon icon={faBookmark} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <h4>{ data?.program_info?.programme_name }</h4>
                                                    <p>{ data?.program_info?.description }</p>
                                                    <div className="speakerAvatar">
                                                        <div className="avaSmall">
                                                            {
                                                                speakers?.map((person,index)=>(
                                                                (index < 4) &&
                                                                    <PopOverCustom
                                                                        key={index}
                                                                        component={
                                                                            <div className='text-center widthPopProfile'>
                                                                                <img src={NoProfile} className='imgPopHover'/>
                                                                                <h3 className='h3PopHover' style={{color:'red'}}>{handleLimitString(person.fullname, 20)}</h3>
                                                                                <p className='pPopHover' style={{color:'red'}}>{handleLimitString(`${person.profession ? person.profession : ''}${person.profession ? ` at ${person.organization}` : ''}`, 25)}</p>
                                                                                <div className='btnViewProf' onClick={_=> goToNewTab(person)} >
                                                                                    <span style={{backgroundColor: 'red'}}>View Profile</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        placement="top"
                                                                        delay={200}
                                                                    >
                                                                        <img src={NoProfile} alt="image"
                                                                            style={{
                                                                                marginLeft: index !== 0 && 3
                                                                            }}
                                                                        />
                                                                    </PopOverCustom>
                                                                ))
                                                            }
                                                        </div>
                                                        {
                                                            speakers?.length ?
                                                            (i % 2 === 0) &&
                                                            <div className="avaMore" onClick={e => handlerModalPerson(e,true)}>
                                                                <p>
                                                                    +{ speakers && speakers.length -4 }
                                                                </p>
                                                            </div>
                                                            : 
                                                            ''
                                                        }
                                                    </div>
                                                    <div className="watchSession">
                                                        <span onClick={_=> handleParams(data?.program_info) }>Watch Session</span>
                                                    </div>
                                                    <div className="moreInfo" onClick={e=>handlerModalEvent(e,true, data, i)}>
                                                        <p>
                                                            More info &nbsp;
                                                            <FontAwesomeIcon icon={faCaretDown} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        sessionLoad &&
                        <div className="item loading-item">
                            <img src={LoadingGif} alt="loading" className="loading-ll-gif"/>
                        </div>
                    }
                </div>
            </section>
            
            <BottomNavbar />

            <Modal
                show={modalPerson}
                onHide={e => handlerModalPerson(e,false)}
                size="lg"
                centered
                backdropClassName="modalBackdropPerson"
                className="modaloverlayPerson modalDialogPerson fade zoom-in"
                data-easein="bounceLeftIn"
            >
                <SpeakersModal onHide={e => handlerModalPerson(e,false)}/>
            </Modal>

            <Modal
                show={modalEvent.show}
                onHide={e => handlerModalEvent(e,false, null)}
                size="lg"
                centered
                backdropClassName="modalBackdropEvent"
                className="modalDialogEvent fade zoom-in"
                data-easein="bounceLeftIn"
                scrollable
            >
                <SessionModal
                    onHide={e => handlerModalEvent(e,false, null)}
                    session={{ ...modalEvent.data, index: modalEvent.index}}
                    handlerModalPerson={e => handlerModalPerson(e,true)}
                    goOnTop={goOnTop}
                    History={History}
                />
            </Modal>

        </div>
    );
};

export default Stage