import React , { memo, useEffect, useState, useRef } from 'react'
import PSLImage from '../../../images/PSL.png'
import ARLImage from '../../../images/ARL.png'
import TLImage from '../../../images/TL.png'
import cocaImage from '../../../images/coca.png'
import DETIKImage from '../../../images/DETIK.png'
import TEMPOImage from '../../../images/TEMPO.png'
import BBCImage from '../../../images/BBC.png'

const Sponsor = (props) => {
    const {
    } = props

    return (
        <section className="imageLogo pt-4 pb-5 d-none">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className="headContent">
                            <div className="titleHead">
                                <h2>Sponsor &nbsp;<span>Featured</span></h2>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="imgLogoContent mt-3">
                            <ul>
                                <li className="image">
                                    <img src={PSLImage} draggable={false}/>
                                </li>
                                <li className="image">
                                    <img src={ARLImage} draggable={false}/>
                                </li>
                                <li className="image">
                                    <img src={TLImage} draggable={false}/>
                                </li>
                                <li className="image">
                                    <img src={cocaImage} draggable={false}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className="headContent">
                            <div className="titleHead">
                                <h2>Partner &nbsp;<span>Featured</span></h2>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="imgLogoContent mt-3">
                            <ul>
                                <li className="image">
                                    <img src={DETIKImage} draggable={false}/>
                                </li>
                                <li className="image">
                                    <img src={TEMPOImage} draggable={false}/>
                                </li>
                                <li className="image">
                                    <img src={BBCImage} draggable={false}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default memo(Sponsor);