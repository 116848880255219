import React , { memo, useEffect, useState, Fragment } from 'react'
import { getSessions } from '../../../store/actions/event'
import { useDispatch } from 'react-redux'
import moment from 'moment';

const Description = (props) => {
    const {
        eventState
    } = props
    const Dispatch = useDispatch()
    const [description, setDescription] = useState([])

    const handleTimeFormat = (session) => {
        let time = session?.starttime || ''
        let date = false
        if (session?.show_date) {
            let split = (session?.show_date).split('/')
            date = `${split[1]}/${split[0]}/${split[2]}`
        }
        let timeZone = session?.time_zone || ''
        let program_name = session?.programme_name || ''

        return `${date ? moment(new Date(date)).format('dddd, DD MMM YYYY, ') : ''}${time} ${timeZone} (PT): ${program_name}`
    }

    useEffect(()=>{
        if (eventState?.event_info) {
            let event_info = eventState?.event_info
            Dispatch(getSessions({
                id_idea : event_info?.id_event,
                order_by: 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC',
                is_active_elive: 1
            },(status, data)=>{
                if (status) {
                    if (data) {
                        setDescription(data?.map(session=>({
                            ...session?.program_info
                        })))
                    }
                }
            }))
        }
    },[eventState])

    return (
        <div className="sessionContentText">
            {
                description?.map((session, index)=>(
                    <Fragment key={index}>
                        <div className="timeTitle">
                            { handleTimeFormat(session) }
                        </div>
                        {/* <div className="contentAbout">About the session</div> */}
                        <p>
                            { session?.description }
                        </p>
                    </Fragment>
                ))
            }
            {/* DONT DELETE THIS. EXAMPLE FOR EVENT DESC */}
            {/* <div className="timeTitle" style={{ color: 'red' }}>
                12:28 PM (PT): The Radical Reimagination of Engagement
            </div>
            <div className="whoIs">
                <div className="whoIsName" style={{ color: 'red' }}>Vaibhav Jain</div>
                <div className="whoIsJob" style={{ color: 'red' }}>CEO & Founder</div>
                <div className="whoIsAs" style={{ color: 'red' }}>Hubilo</div>
            </div>
            <div className="contentAbout" style={{ color: 'red' }}>About the session</div>
            <p style={{ color: 'red' }}>
                Virtual and hybrid events are shaping the future of the events industry. To utilize their immense scale and potential to the fullest, there is a critical need for radical reimagination of engagement too. Let's explore what all we need to learn about the digitally transformed audience to succeed with our events.
            </p> */}
        </div>
    )
};

export default memo(Description);