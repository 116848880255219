import axios from 'axios'
import {
    SERVER
} from '../../api'
import { savingProfile } from './checking'

export const saveProfilePicture = (dataSend, {setLoading, addToast, message}) => async dispatch => {
    try {
        if (setLoading) setLoading(true)
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/account/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: dataSend
        })
        if (setLoading) setLoading(false)
        if (addToast) {
            addToast(message, {
                appearance: 'success',
                autoDismiss: true,
            })
        }
        if (data.status) {
            dispatch(savingProfile(data.id, { setCookie: null }))
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Encryption Failed' && status === false) {
                    }
                }
            }
        }
    }
}