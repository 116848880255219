import React,{ Fragment, useEffect, useState } from 'react';
import '../styles/login.scss'
import { useHistory } from 'react-router-dom'
import ImageLogo from '../images/logo_indo_beta.png'
import NoImage from '../images/no-image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '../plugins/useQuery'
import { getEventByKey } from '../store/actions/event'
import NotFound from '../screens/404'
import Loading from '../components/Loading'
import { checkingEmailUser, checkingEmailAttendee } from '../store/actions/signUpIn'
import Alert from '../components/Alert'
import MD5 from "crypto-js/md5";

const Forgot = () => {
    const Query = useQuery()
    const History = useHistory()
    const Dispatch = useDispatch()
    const [notFoundPages, setNotFoundPages] = useState(true)
    const Event = useSelector(state=> state.eventState.event)
    const [paramsClick, setParamsClick] = useState(false)
    const [params, setParams] = useState({
        email: '',
    })
    const [alertProps, setAlertProps] = useState({
        text: '', //string
        variant: '', //danger //warning //success
        show: false, //true //false
        close: false, //true //false,
        loading: false
    });

    useEffect(()=>{
        if (!Query.get('event')) {
            setNotFoundPages(null)
        } else if (Query.get('event') && !Event) {
            Dispatch(getEventByKey(Query.get('event')))
        }
    },[Query.get('event')])

    useEffect(()=>{
        if (Event == '404') {
            setNotFoundPages(null)
        }
    },[Event])

    const getValue = (data, key) => {
        if (data) {
            if (data.event_info) {
                if (data.event_info[key]) {
                    if (key === 'venue_name') {
                        return `${data.event_info[key]}, `
                    }
                    if (key === 'venue_address') {
                        return `${data.event_info[key]}, `
                    }
                    return data.event_info[key]
                }
            }
        }
        return ''
    }

    const setValue = (value, key) => {
        switch (key) {
            case 'email':
                setParams({
                    ...params,
                    email: value
                })
                break;
            default:
                break;
        }
    }

    const submit = (event) => {
        if(event) event.preventDefault()
        setParamsClick(true)
        setAlertProps({
            ...alertProps,
            loading: true
        })
        if (params.email) {
            Dispatch(checkingEmailUser(params.email, (status, userId)=>{
                if (userId && status) {
                    Dispatch(checkingEmailAttendee(userId, Query.get('event'), (status)=>{
                        if (status === true) {
                            let password = `${Math.random().toString(36).slice(-6)}`
                            console.log({ password, MD5: `${MD5(password)}` });
                            setAlertProps({
                                text: 'The forgotten password request has been processed. Please check your email!',
                                variant: 'success',
                                show: true,
                                close: true,
                                loading: false
                            })
                        } else {
                            if (status === false) {
                                setAlertProps({
                                    text: 'Sorry, email was not found. Make sure again you have entered the email registered in this event correctly!',
                                    variant: 'danger',
                                    show: true,
                                    close: true,
                                    loading: false
                                })
                            }
                        }
                    }))
                } else {
                    if (status === false) {
                        setAlertProps({
                            text: 'Sorry, email was not found. Make sure again you have entered the email registered in this event correctly!',
                            variant: 'danger',
                            show: true,
                            close: true,
                            loading: false
                        })
                    }
                }
            }))
        } else {
            setAlertProps({
                ...alertProps,
                text: 'Please fill in e-mail',
                variant: 'danger',
                show: true,
                close: true,
                loading: false
            })
        }
    }

    return (
        notFoundPages ?
        <Fragment>
            <Loading status={Event} />
            <div className="website-logo">
                <a href="index.html">
                    <div className="logo">
                        <img className="logo-size" src={ImageLogo} alt="livelifeindo" draggable={false}/>
                    </div>
                </a>
            </div>
            <div className="event-login">
                <div className="img-holder">
                    <div className="bg"
                        style={{
                            backgroundImage: Query.get('event') && `url(${getValue(Event, 'event_photo')})`
                        }}
                    />
                    <div className="info-holder">
                        {
                            <img
                                src={Query.get('event') && getValue(Event, 'event_photo')}
                                alt="livelifeindo" draggable={false}
                                onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                            />
                        }
                    </div>
                </div>

                <div className="form-holder">
                    <div className="form-content align-middle d-flex justify-content-center">
                        <div className="form-items">
                            <h2>
                                {
                                    Query.get('event') &&
                                    getValue(Event, 'title')
                                }
                            </h2>
                            <p style={{ marginBottom: 2 }} >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'venue_name')} ${getValue(Event, 'venue_address')} ${getValue(Event, 'venue_city')}`
                                }
                            </p>
                            <p className="regist-timeD" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - ${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                }
                            </p>
                            <p className="regist-timeM" style={{ marginBottom: 2 }}>
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - `
                                }
                            </p>
                            <p className="regist-timeM" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                } 
                            </p>
                            {/* <div className="page-links">
                                <span onClick={_=> History.push('/login')} className="active">Login</span>
                                <span onClick={_=> History.push('/register')}>Register</span>
                                <span onClick={_=> History.push('/scan')}>Scan QR</span>
                            </div> */}
                            <span className="option-section-3871" style={{ cursor: 'pointer' }} onClick={_=> History.push(`/login?event=${Query.get('event')}`) }>
                                <FontAwesomeIcon icon={faCaretLeft} /> Back to login
                            </span>
                            <form autoComplete="off" style={{ marginTop: 5 }}>
                                <input className="form-control" type="text" name="username" placeholder="E-mail Address"
                                    onChange={e=> setValue(e.target.value,'email')}
                                    style={{
                                        backgroundColor: (paramsClick && !params.email) && '#f6d6d9',
                                    }}
                                />
                                <div className="form-button">
                                    <button id="submit" type="submit" className="ibtn" onClick={submit}
                                        style={{
                                            width: '110px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            alertProps.loading ?
                                            <div className="spinner-border spinner-custom-btn"  />
                                            :
                                            `Submit`
                                        }
                                    </button>
                                </div>
                            </form>
                            {/* <div className="alert alert-success alert-dismissible fade show" role="alert">
                                A simple success alert—check it out!
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                A simple danger alert—check it out!
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div> */}
                            {/* <!--
                            <div className="other-links">
                                <span>Or login with</span><a href="#">Facebook</a><a href="#">Google</a><a href="#">Linkedin</a>
                            </div>
                            --> */}
                            <Alert
                                show={alertProps.show}
                                text={alertProps.text}
                                variant={alertProps.variant}
                                setAlertProps={setAlertProps}
                                close={alertProps.close}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
        :
        <NotFound
            title={'Event'}
        />
    );
};

export default Forgot