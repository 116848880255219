import React,{ Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import '../../styles/login.scss'
import ImageLogo from '../../images/logo_indo_beta.png'
import NoImage from '../../images/no-image.png'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '../../plugins/useQuery'
import { getEventByKey } from '../../store/actions/event'
import NotFound from '../404'
import Loading from '../../components/Loading'
import { useCookies } from 'react-cookie';
import { LoginAccount, checkingEmailAttendee, checkingUserId } from '../../store/actions/signUpIn'
import { getAttendee, getSessionsUpcoming, getPolling, getDataCheckinMe, updateCheckinMe, getEventId } from '../../store/actions/event';
import { getVenues } from '../../store/actions/option';
import { savingProfile } from '../../store/actions/checking'
import Alert from '../../components/Alert'
import CryptoJS from "crypto-js";
import { useToasts } from 'react-toast-notifications';

const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const { addToast } = useToasts()
    const Query = useQuery()
    const History = useHistory()
    const Dispatch = useDispatch()
    const [notFoundPages, setNotFoundPages] = useState(true)
    const [price, setPrice] = useState(null)
    const [loadingScan, setLoadingScan] = useState(false)
    const Event = useSelector(state=> state.eventState.event)
    const [paramsClick, setParamsClick] = useState(false)
    const [params, setParams] = useState({
        username: '',
        password: '',
    })
    const [paramsEncrypt, setParamsEncypt] = useState("")
    const [alertProps, setAlertProps] = useState({
        text: '', //string
        variant: '', //danger //warning //success
        show: false, //true //false
        close: false, //true //false,
        loading: false
    });

    const [alertPropsCrypto, setAlertPropsCrypto] = useState({
        text: '', //string
        variant: '', //danger //warning //success
        show: false, //true //false
        close: false, //true //false,
        loading: false
    });

    useEffect(()=>{
        let exp = new Date();
        exp.setDate(exp.getDate()+3);
        if (!Query.get('auth') && !Query.get('key')) {
            setLoadingScan(false)
        } else if (Query.get('auth')) {
            setLoadingScan(true)
            let API = Query.get('auth').split(' ').join('+')
            let decry = CryptoJS.AES.decrypt(API, 'elive-livelife22')
            if (decry) {
                let utf8 = decry.toString(CryptoJS.enc.Utf8);
                if (utf8) {
                    const convert = JSON.parse(utf8);
                    if (convert) {
                        Dispatch(savingProfile(convert.id,{ setCookie, expired: exp }))
                        Dispatch(getDataCheckinMe({id_idea : Query.get('event'), id_user : convert.id, id_status : 6},(status,data)=>{
                            if (status) {
                                if (data[0]) {
                                    if (data[0].registrant_info) {
                                        let dataReal = data[0].registrant_info
                                        if (!dataReal.checkedin_date) {
                                            let datasend = {
                                                id_idea_registrant: dataReal.id_idea_registrant,
                                                is_checkedin: 1,
                                                checkedin_date: new Date()
                                            }
                                            Dispatch(updateCheckinMe(datasend,(status,data)=>{
                                                if (status) {
                                                    addToast('Checkin Success', {
                                                        appearance: 'success',
                                                        autoDismiss: true,
                                                    })
                                                }
                                            }))
                                        }
                                    }
                                }
                            }
                        }))
                        Dispatch(getVenues())
                        Dispatch(getAttendee({
                            keyword : null,
                            id_idea : Query.get('event'),
                            id_user : null,
                            id_status : null,
                            limit : '30',
                            offset : '0',
                            order_by: 'created ASC',
                            is_checkedin: 1
                        }))
                        Dispatch(getSessionsUpcoming({
                            limit : '6',
                            offset: '0',
                            order_by : 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC',
                            // time: 'upcoming',
                            id_idea: Query.get('event')
                        }))
                        Dispatch(getPolling({
                            id_idea: Query.get('event'),
                            survey_type: 'poll',
                            is_active_elive: 1,
                            order_by: 'll_surveys.created ASC',
                        }))
                        setCookie('account', `${convert.id}`, { expires: exp, path: '/' })
                        setCookie('event', Query.get('event'), { expires: exp, path: '/' })
                        setTimeout(() => {
                            History.push('/home')
                        }, 1000);
                    } else {
                        setLoadingScan(false)
                    }
                } else {
                    setLoadingScan(false)
                }
            } else {
                setLoadingScan(false)
            }
        } else if (Query.get('key')) {
            Dispatch(getEventId(Query.get('event'),(status,data)=>{
                if (status) {
                    // if (data?.event_info?.host_id) {
                    //     Dispatch(checkingUserId(data.event_info.host_id,Query.get('event'),(status,dataUsrEvnt)=>{
                    //         if (status) {
                    //             let dataEvntUsr = dataUsrEvnt.registrant_info.email
                    //             dataEvntUsr = dataUsrEvnt?.registrant_info?.email
                    //             Dispatch(checkingUserId(Query.get('key'),Query.get('event'),(status,dataUsr)=>{
                    //                 if (status) {
                    //                     if (dataUsr?.registrant_info?.email) {
                    //                         if (dataEvntUsr === dataUsr.registrant_info.email) {
                                                Dispatch(getVenues())
                                                Dispatch(getAttendee({
                                                    keyword : null,
                                                    id_idea : Query.get('event'),
                                                    id_user : null,
                                                    id_status : null,
                                                    limit : '30',
                                                    offset : '0',
                                                    order_by: 'created ASC',
                                                    is_checkedin: 1
                                                }))
                                                Dispatch(getSessionsUpcoming({
                                                    limit : '6',
                                                    offset: '0',
                                                    order_by : 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC',
                                                    // time: 'upcoming',
                                                    id_idea: Query.get('event')
                                                }))
                                                Dispatch(getPolling({
                                                    id_idea: Query.get('event'),
                                                    survey_type: 'poll',
                                                    is_active_elive: 1,
                                                    order_by: 'll_surveys.created ASC',
                                                }))
                                                // setCookie('account', `${dataUsr.registrant_info.id_user}`, { expires: exp, path: '/' })
                                                setCookie('account', Query.get('key'), { expires: exp, path: '/' })
                                                setCookie('event', Query.get('event'), { expires: exp, path: '/' })
                                                setTimeout(() => {
                                                    History.push('/customize')
                                                }, 1000);
                                                setCookie('key', 'author', { expires: exp, path: '/' })
                    //                         }
                    //                     }
                    //                 }
                    //             }))
                    //         }
                    //     }))
                    // }
                }
                setLoadingScan(false)
            }))
        }
    },[Query.get('auth')])

    useEffect(()=>{
        if (!Query.get('event')) {
            setNotFoundPages(null)
        } else if (Query.get('event') && !Event) {
            Dispatch(getEventByKey(Query.get('event')))
        }
    },[Query.get('event')])

    useEffect(()=>{
        if (Event == '404') {
            setNotFoundPages(null)
        } else {
            if (Event) {
                if (Event.event_pricing) {
                    let prices = Event.event_pricing
                    let status = 'free'
                    prices.forEach(({price})=>{
                        if (Number(price) !== 0) {
                            status = 'paid'
                            return
                        }
                    })
                    setPrice(status)
                }
            }
        }
    },[Event])

    const setValue = (value, key) => {
        switch (key) {
            case 'username':
                setParams({
                    ...params,
                    username: value
                })
                break;
            case 'password':
                setParams({
                    ...params,
                    password: value
                })
                break;
            default:
                break;
        }
    }

    const getValue = (data, key) => {
        if (data) {
            if (data.event_info) {
                if (data.event_info[key]) {
                    if (key === 'venue_name') {
                        return `${data.event_info[key]}, `
                    }
                    if (key === 'venue_address') {
                        return `${data.event_info[key]}, `
                    }
                    return data.event_info[key]
                }
            }
        }
        return ''
    }

    const goToNewTab = () => {
        const win = window.open(`https://livelifeindo.com/event/view?key=${Query.get('event')}`, "_blank");
        win.focus();
    }

    const submit = (event) => {
        if (event) event.preventDefault()
        let exp = new Date();
        exp.setDate(exp.getDate()+3);
        setParamsClick(true)
        let flag = true
        for(let key in params){
            if (!params[key]) {
                setAlertProps({
                    ...alertProps,
                    text: 'please complete the form..',
                    variant: 'danger',
                    show: true,
                    close: true
                })
                flag = false
                return
            }
        }
        if (flag) {
            setAlertProps({
                ...alertProps,
                loading: true
            })
            if (params.username.length >= 5 && params.password.length >= 6) {
                Dispatch(LoginAccount(params,(data)=>{
                    if (!data.status) {
                        setAlertProps({
                            ...alertProps,
                            text: 'Wrong email/phone or password',
                            variant: 'danger',
                            show: true,
                            close: true,
                            loading: false
                        })
                    } else {
                        Dispatch(checkingEmailAttendee(data.account_id, Query.get('event'), (status)=>{
                            if (status === true) {
                                Dispatch(savingProfile(data.account_id,{ setCookie, expired: exp }))
                                Dispatch(getDataCheckinMe({id_idea : Query.get('event'), id_user : data.account_id, id_status : 6},(status,data)=>{
                                    if (status) {
                                        if (data[0]) {
                                            if (data[0].registrant_info) {
                                                let dataReal = data[0].registrant_info
                                                if (!dataReal.checkedin_date) {
                                                    let datasend = {
                                                        id_idea_registrant: dataReal.id_idea_registrant,
                                                        is_checkedin: 1,
                                                        checkedin_date: new Date()
                                                    }
                                                    Dispatch(updateCheckinMe(datasend,(status,data)=>{
                                                        if (status) {
                                                            addToast('Checkin Success', {
                                                                appearance: 'success',
                                                                autoDismiss: true,
                                                            })
                                                        }
                                                    }))
                                                }
                                            }
                                        }
                                    }
                                }))
                                Dispatch(getVenues())
                                Dispatch(getAttendee({
                                    keyword : null,
                                    id_idea : Query.get('event'),
                                    id_user : null,
                                    id_status : null,
                                    limit : '30',
                                    offset : '0',
                                    order_by: 'created ASC',
                                    is_checkedin: 1
                                }))
                                Dispatch(getSessionsUpcoming({
                                    limit : '6',
                                    offset: '0',
                                    order_by : 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC',
                                    // time: 'upcoming',
                                    id_idea: Query.get('event')
                                }))
                                Dispatch(getPolling({
                                    id_idea: Query.get('event'),
                                    survey_type: 'poll',
                                    is_active_elive: 1,
                                    order_by: 'll_surveys.created ASC',
                                }))
                                setCookie('account', `${data.account_id}`, { expires: exp, path: '/' })
                                setCookie('event', Query.get('event'), { expires: exp, path: '/' })
                                History.push('/home')
                                setAlertProps({
                                    ...alertProps,
                                    loading: false
                                })
                            } else {
                                if (status === false) {
                                    setAlertProps({
                                        text: 'You have not registered for this event',
                                        variant: 'danger',
                                        show: true,
                                        close: true,
                                        loading: false
                                    })
                                }
                            }
                        }))
                    }
                }))
            } else {
                setAlertProps({
                    ...alertProps,
                    text: 'Wrong email/phone or password',
                    variant: 'danger',
                    show: true,
                    close: true,
                    loading: false
                })
            }
        }
    }

    const encryptWithCryptoJS = (e) => {
        if (e) {
            e?.preventDefault()
        }
        var encryptUser = CryptoJS.AES.encrypt(JSON.stringify({ id: paramsEncrypt }), 'elive-livelife22').toString();
        console.log(`
        copy this encrypt ID : \n ${encryptUser}
        `);
    }

    return (
        notFoundPages ?
        <Fragment>
            <Loading status={Event && !loadingScan}/>
            <div className="website-logo">
                <div className="logo">
                    <img className="logo-size" src={ImageLogo} alt="livelifeindo" draggable={false}/>
                </div>
            </div>
            <div className="event-login">
                <div className="img-holder">
                    <div className="bg"
                        style={{
                            backgroundImage: Query.get('event') && `url(${getValue(Event, 'event_photo')})`
                        }}
                    />
                    <div className="info-holder">
                        {
                            <img
                                src={Query.get('event') && getValue(Event, 'event_photo')}
                                alt="livelifeindo" draggable={false}
                                onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                            />
                        }
                    </div>
                </div>

                <div className="form-holder">
                    <div className="form-content align-middle d-flex justify-content-center">
                        <div className="form-items">
                            <h2>
                                {
                                    Query.get('event') &&
                                    getValue(Event, 'title')
                                }
                            </h2>
                            <p style={{ marginBottom: 2 }} >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'venue_name')} ${getValue(Event, 'venue_address')} ${getValue(Event, 'venue_city')}`
                                }
                            </p>
                            <p className="regist-timeD" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - ${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                }
                            </p>
                            <p className="regist-timeM" style={{ marginBottom: 2 }}>
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - `
                                }
                            </p>
                            <p className="regist-timeM" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                } 
                            </p>
                            <form autoComplete="off" className="animation-input-lgn" >
                                <input className="form-control" autoComplete="off" type="text" name="username" placeholder="E-mail Address or Phone Number"
                                    onChange={e=> setValue(e.target.value,'username')}
                                    style={{
                                        backgroundColor: ((paramsClick && !params.username) || (alertProps.text === 'Wrong email/phone or password')) ? '#f6d6d9' : alertProps.text === 'Enter email correctly' && '#fff4ce',
                                    }}
                                />
                                <input className="form-control" autoComplete="off" type="password" name="password" placeholder="Password"
                                    onChange={e=> setValue(e.target.value,'password')}
                                    style={{
                                        backgroundColor: ((paramsClick && !params.password) || (alertProps.text === 'Wrong email/phone or password')) && '#f6d6d9',
                                    }}
                                />
                                <div className="form-button"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <button className="ibtn" onClick={submit}
                                        style={{
                                            width: '100px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            alertProps.loading ?
                                            <div className="spinner-border spinner-custom-btn"  />
                                            :
                                            `Login`
                                        }
                                    </button>
                                    {/* <span
                                        // onClick={_=> History.push(`/forgot?event=${Query.get('event')}`)}
                                        className="disabled"
                                    > Forget password?</span> */}
                                </div>
                            </form>
                            {/* register */}
                            {/* <div className="option-section-3871" >
                                Don't have an account? {
                                    price === 'free' ?
                                        <span
                                            // onClick={_=> History.push(`/register?event=${Query.get('event')}`) }
                                            className="disabled"
                                        > Register </span>
                                    :
                                    price === 'paid' ?
                                        <span
                                            // onClick={_=> goToNewTab()}
                                            className="disabled"
                                        > Register </span>
                                    :
                                    <span> Register </span>
                                }
                            </div> */}
                            <div className="option-section-3871 pb-2" >
                                Scan with <span onClick={_=> History.push(`/scan?event=${Query.get('event')}`) } > QRCode </span>
                            </div>
                            <Alert
                                show={alertProps.show}
                                text={alertProps.text}
                                variant={alertProps.variant}
                                setAlertProps={setAlertProps}
                                close={alertProps.close}
                            />

                            {/* <div className="option-section-3871 pb-2" >
                                Encrypt With <strong> CryptoJS </strong>
                            </div>
                            <form autoComplete="off" className="animation-input-lgn" >
                                <input
                                    className="form-control"
                                    autoComplete="off" type="text" name="username" placeholder={ `registrant_info.id_user` }
                                    onChange={e=> setParamsEncypt(e.target.value)}
                                />
                                <div className="form-button"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <button className="ibtn"
                                        onClick={encryptWithCryptoJS}
                                        style={{
                                            width: '100px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        Encrypt
                                    </button>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
        :
        <NotFound
            title={'Event'}
        />
    );
};

export default Login