import React,{ useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import '../../styles/forum.scss'
import NavbarTop from '../../components/TopNavbar'
import BottomNavbar from '../../components/BottomNavbar'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenFancy } from '@fortawesome/free-solid-svg-icons'
import { topicCreate } from '../../store/actions/topic'
import { getAttendeesPeople } from '../../store/actions/event'
import { MentionsInput, Mention } from 'react-mentions'
import { useCookies } from 'react-cookie';
import Session from './Components/Session'
import Content from './Components/Content';
import AdsLL from '../../images/ads.jpg'
import NoProfile from '../../images/no-profile.png'
import { useQuery } from '../../plugins/useQuery'
import { savingProfile } from '../../store/actions/checking'
import { getDataCheckinMe, updateCheckinMe, getEventByKey } from '../../store/actions/event';
import Loading from '../../components/Loading'
import CryptoJS from "crypto-js";
import { useHistory } from 'react-router-dom';
import NotFound from '../404'

const checkType = (type) => {
    switch (type) {
        case 'draft':
            return 'draft'
        case 'opencall':
            return 'opencall'
        case 'sponsorship':
            return 'sponsorship'
        case 'tasks':
            return 'tasks'
        case 'resources':
            return 'resources'
        case 'promotion':
            return 'promotion'
        default:
            return 'elive'
    }
}

const Forum = () => {
    const History = useHistory()
    const Query = useQuery()
    const [cookies, setCookie] = useCookies(['account']);
    const Profile = useSelector(state=>state.cacheState.profile)
    const EventState = useSelector(state=> state.eventState.event)
    const Topics = useSelector(state=> state.forumState[checkType(Query.get('type'))])
    const Dispatch = useDispatch()
    const [discussion, setDiscussion] = useState('')
    const [topic, setTopic] = useState('')
    const [topicQuery, setTopicQuery] = useState(null)
    const contentRef = useRef();
    const [loadingScan, setLoadingScan] = useState(false)
    const [notFoundPages, setNotFoundPages] = useState(true)

    useEffect(()=>{
        if (Query.get('type')) {
            let exp = new Date();
            exp.setDate(exp.getDate()+3);
            if (!Query.get('auth')) {
                setLoadingScan(false)
            } else if (Query.get('auth')) {
                setLoadingScan(true)
                if (!Query.get('event')) {
                    setNotFoundPages(false)
                } else {
                    let API = Query.get('auth').split(' ').join('+')
                    let decry = CryptoJS.AES.decrypt(API, 'elive-livelife22')
                    if (decry) {
                        let utf8 = decry.toString(CryptoJS.enc.Utf8);
                        if (utf8) {
                            const convert = JSON.parse(utf8);
                            if (convert) {
                                Dispatch(savingProfile(convert.id,{ setCookie, expired: exp }))
                                Dispatch(getEventByKey(Query.get('event')))
                                Dispatch(getDataCheckinMe({id_idea : Query.get('event'), id_user : convert.id, id_status : 6},(status,data)=>{
                                    if (status) {
                                        if (data[0]) {
                                            if (data[0].registrant_info) {
                                                let dataReal = data[0].registrant_info
                                                if (!dataReal.checkedin_date) {
                                                    let datasend = {
                                                        id_idea_registrant: dataReal.id_idea_registrant,
                                                        is_checkedin: 1,
                                                        checkedin_date: new Date()
                                                    }
                                                    Dispatch(updateCheckinMe(datasend,(status,data)=>{
                                                        if (status) {
                                                            addToast('Checkin Success', {
                                                                appearance: 'success',
                                                                autoDismiss: true,
                                                            })
                                                        }
                                                    }))
                                                }
                                            }
                                        }
                                    }
                                }))
                                setCookie('account', `${convert.id}`, { expires: exp, path: '/' })
                                setCookie('event', Query.get('event'), { expires: exp, path: '/' })
                                setTimeout(() => {
                                    const type = Query.get('type')
                                    const topic = Query.get('topic')
                                    if (
                                        type === 'draft' ||
                                        type === 'opencall' ||
                                        type === 'sponsorship' ||
                                        type === 'tasks' ||
                                        type === 'resources' ||
                                        type === 'promotion' ||
                                        type === 'elive'
                                    ) {
                                        if (Query.get('topic')) {
                                            fetchTopic(Query.get('topic'), (topics) => {
                                                if (topics[0]) {
                                                    History.push(`/forum?type=${type}&topic=${topics[0]?.id}`)
                                                } else {
                                                    History.push(`/forum?type=${type}`)
                                                }
                                            })
                                        } else {
                                            History.push(`/forum?type=${type}`)
                                        }
                                    } else {
                                        History.push(`/forum`)
                                    }
                                }, 1000);
                            } else {
                                setLoadingScan(false)
                            }
                        } else {
                            setLoadingScan(false)
                        }
                    } else {
                        setLoadingScan(false)
                    }
                }
            }
        }
    },[Query.get('auth')])

    useEffect(()=>{
        if (Query.get('topic')) {
            fetchTopic(Query.get('topic'), (topics) => {
                if (topics[0]) {
                    setTopicQuery([topics[0]])
                    setTopic(topics[0]?.display)
                }
            })
        }
    },[Query.get('topic')])

    useEffect(()=>{
        let animateReactionsOn;
        let animateReactionsOut;

        $(function() {
            $(".options").mouseenter(function() {
                let parent = $(this)

                animateReactionsOn = setTimeout(function() {
                    parent.find(".reaction").each(function(index) {
                        let element = $(this)
                        setTimeout(function() {
                            element.addClass("is-visible");
                        }, index * 60);
                    });
                }, 500);

                clearTimeout(animateReactionsOut);
            });

            $(".options").mouseleave(function() {
                let parent = $(this)

                animateReactionsOut = setTimeout(function() {
                    parent.removeClass("active");
                    parent.find(".reaction").removeClass("is-visible");
                }, 500);

                clearTimeout(animateReactionsOn);
            });

            let dataPopupOld = null;
            let dataPopupNew = null;


            $(".options .button").click(function() {
                let children = $(this)
                let parent = $(this).parent()

                if ($(this).parent().is(".Like, .Love, .Thankful, .Haha, .Wow, .Sad, .Angry")) {
                    parent.removeClass("Like Love Thankful Haha Wow Sad Angry");
                    parent.addClass("default");
                    parent.find(".button").text("Like");
                } else {
                    parent.addClass("Like");
                }

                if ($(this).parent().hasClass("default")) {
                    $(this).addClass("Like");
                }
            });


            $(".reaction").click(function() {
                dataPopupNew = $(this).attr("data-popup");

                $(".options").removeClass("default");

                $(".options .button").text(dataPopupNew);

                $('.options:contains(Like)').css('color', 'rgb(88, 144, 255)');
                $('.options:contains(Love)').css('color', 'rgb(242, 82, 104)');
                $('.options:contains(Thankful)').css('color', 'rgb(157, 135, 210)');
                $('.options:contains(Haha), .options:contains(Wow), .options:contains(Sad)').css('color', 'rgb(240, 186, 21)');
                $('.options:contains(Angry)').css('color', 'rgb(247, 113, 75)');

                $(".options").removeClass(dataPopupOld);
                $(".options").addClass(dataPopupNew);

                dataPopupOld = dataPopupNew
            });
        });
        
        $("textarea").each(function () {
            this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
        }).on("input", function () {
            this.style.height = "auto";
            this.style.height = (this.scrollHeight) + "px";
        });
    },[])

    const goToNewTab = (id) => {
        if (id) {
            const win = window.open(`https://livelifeindo.com/profile/view/${id}`, "_blank");
            win.focus();
        }
    }

    const handlePost = () => {
        if (discussion && EventState && Profile) {
            Dispatch(topicCreate({
                id_idea: EventState?.event_info?.id_idea,
                id_user: Profile?.account_info?.id,
                topic: topic || null,
                type: checkType(Query.get('type')),
                discussion: discussion,
            },(status)=>{
                if (status) {
                    contentRef?.current?.refresh(EventState, Profile)
                    setDiscussion('')
                    if (!topicQuery) {
                        setTopic('')
                    }
                }
            }))
        }
    }

    function fetchUsers(query, callback) {
        Dispatch(getAttendeesPeople({
            keyword : query,
            id_idea : cookies['event'],
            id_user : null,
            id_status : 6,
            limit : 6,
            offset : '0',
            order_by: 'fullname ASC',
            join_as: '',
            var_join_as: '',
            not_id_user: cookies['account'],
            is_checkedin: 1
        },(status, data)=>{
            if (status) {
                const dataFix = data.map(user=>({
                    display: user.registrant_info?.fullname,
                    profile_photo: user.registrant_info?.profile_photo,
                    id: user.registrant_info?.id_user
                }))
                return callback(dataFix)
            }
        }))
    }

    function fetchTopic(query, callback) {
        const result = Topics.filter(a=> query ? ((a).toLowerCase()).includes((query).toLowerCase()) : a)
        callback(result?.map(a=>({
            display: a,
            id: a
        })))
    }

    const handleChange = (e) => {
        setDiscussion(e.target.value)
    }

    return (
        !notFoundPages ?
        <NotFound
            title={'Event'}
        />
        :
        <div id="forum-87612">
            <Loading status={Event && !loadingScan}/>
            <div className="bg-homepage"/>
            {
                !Query.get('type') &&
                <NavbarTop />
            }

            <section className={`forum ${(!Query.get('type')) ? 'pt-8' : 'pt-4'} pb-5`}>
                <div className="container">
                    <div className="row">
                        {
                            Query.get('type') &&
                            <div className="col-lg-8 mb-2">
                                <div className="boxForum borderContent mb-1" >
                                    <div>
                                        <span style={{ fontSize: 14 }} > Event Concept : </span>
                                        <span style={{ textTransform: "capitalize", fontWeight: 'bold' }}> { EventState?.event_info?.project_name || '-' } </span>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-lg-8 mb-2">
                            <div className="boxForum borderContent mb-4">
                                <form onSubmit={handlePost}>
                                    <div className="from-group">
                                        <MentionsInput
                                            value={discussion}
                                            onChange={handleChange}
                                            className="mention-textarea"
                                            placeholder="Share what's on your mind?"
                                        >
                                            <Mention
                                                displayTransform={(_, display) => `@${display} ` }
                                                trigger="@"
                                                markup="@__display__ "
                                                data={fetchUsers}
                                                renderSuggestion={(suggest)=>(
                                                    <div className="mention-textarea">
                                                        <img
                                                            src={suggest?.profile_photo}
                                                            alt="image"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = NoProfile;
                                                            }}
                                                        />
                                                        <span>  { suggest?.display } </span>
                                                    </div>
                                                )}
                                            />
                                            <Mention
                                                displayTransform={(_, display) => `#${display} ` }
                                                trigger="#"
                                                markup="#__display__ "
                                                data={topicQuery || fetchTopic}
                                                renderSuggestion={(suggest)=>(
                                                    <div className="mention-textarea">
                                                        <span># { suggest?.display } </span>
                                                    </div>
                                                )}
                                            />
                                        </MentionsInput>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="me-auto mt-2">
                                            <select className="btnLight" value={topic} onChange={e=> setTopic(e.target.value) } disabled={topicQuery} >
                                                <option value={''} >Select Topic</option>
                                                {
                                                    Topics?.map(topic=>(
                                                        <option value={topic} >#{topic}</option>
                                                    ))
                                                }
                                            </select>
                                            {/* <span className="headTopBtn">
                                                <FontAwesomeIcon className="i" icon={faSmileWink} /> Emoji
                                            </span>
                                            <span className="headTopBtn">
                                                <FontAwesomeIcon className="i" icon={faCamera} /> Image
                                            </span> */}
                                        </div>
                                        <div className="from-group mt-2">
                                            <span className="btnLight" onClick={handlePost} >
                                                <FontAwesomeIcon className="i" icon={faPenFancy} /> Post
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {
                                Profile &&
                                <Content
                                    topicQuery={topicQuery}
                                    topic={topic}
                                    profile={Profile}
                                    eventState={EventState}
                                    ref={contentRef}
                                    fetchUsers={fetchUsers}
                                    fetchTopic={fetchTopic}
                                    Topics={Topics}
                                    type={checkType(Query.get('type'))}
                                />
                            }
                        </div>
                        <div className="col-lg-4 mb-2">
                            <div className="ownerForum text-center mb-4 borderContent">
                                <img
                                    src={Profile?.account_info?.profile_photo}
                                    alt="LiveLife User"
                                    className="mb-3"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoProfile;
                                    }}
                                />
                                <h5>{ Profile?.contact_detail?.contact_name }</h5>
                                <p className="mb-3">{ Profile?.contact_detail?.profession }</p>
                                <div className="ownerLink">
                                    <span className="headTopBtn" onClick={_=> goToNewTab(Profile?.account_info?.id)} >View profile</span>
                                </div>
                            </div>

                            {
                                (!Query.get('type')) &&
                                <Session/>
                            }

                            
                            {/* <div className="boxForum borderContent mb-4">
                                <div className="headContent">
                                    <div className="titleHead">
                                        <h2>Poll</h2>
                                    </div>
                                    <div className="customViewAll">
                                        <div className="filterRightColumn">									
                                            <div className="pollTop">
                                                <span className="pollBtnDisable boxForumSpan">209 votes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="mt-3">
                                    <div className="sidePoll">
                                        <div className="pollQuestion mb-3">
                                            <p>What new feature are you most excited about?</p>
                                        </div>
                                        <div className="pollAnswer">
                                            <form>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="pollEvent" id="quest1"/>
                                                    <label className="form-check-label" for="quest1">Minimalist navigation panel</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="pollEvent" id="quest2"/>
                                                    <label className="form-check-label" for="quest2">Global side panel</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="pollEvent" id="quest3"/>
                                                    <label className="form-check-label" for="quest3">Redesigned lounges</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="pollEvent" id="quest4"/>
                                                    <label className="form-check-label" for="quest4">Expressive 1-1 chats with emoji</label>
                                                </div>
                                                <div className="form-group mt-3 text-center">
                                                    <span className="btnLight" style={{ marginRight: 5 }} >Vote</span>
                                                    <span className="btnLight">View result</span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="boxForum borderContent mb-4">
                                <div className="headContent">
                                    <div className="titleHead">
                                        <h2>Poll</h2>
                                    </div>
                                    <div className="customViewAll">
                                        <div className="filterRightColumn">									
                                            <div className="pollTop">
                                                <span className="pollBtnDisable boxForumSpan">210 votes</span>
                                                <span className="pollBtnDisable boxForumSpan">Poll ended</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="mt-3">
                                    <div className="sidePoll">
                                        <div className="pollQuestion mb-3">
                                            <p>What new feature are you most excited about?</p>
                                        </div>
                                        <div className="pollAnswer">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
                                                <span className="listPoll">Minimalist navigation panel</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                <span className="listPoll">Global side panel</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100">5%</div>
                                                <span className="listPoll">Redesigned lounges</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">10%</div>
                                                <span className="listPoll">Expressive 1-1 chats with emoji</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="forumAds mb-4">
                                <img src={AdsLL} alt="ads"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <BottomNavbar hide={Query.get('type')}/>
        </div>
    );
};

export default Forum