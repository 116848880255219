import React , { memo, useState, useEffect } from 'react'
import { getResources } from '../../../store/actions/event'
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { saveAs } from 'file-saver';

const Resources = (props) => {
    const {
        eventState
    } = props
    const Dispatch = useDispatch()
    const [data, setData] = useState([])

    useEffect(()=>{
        if (eventState?.event_info) {
            let event_info = eventState?.event_info
            Dispatch(getResources({
                id_idea: event_info?.id_idea,
                is_active_elive: true
            },(status, data)=>{
                if (status) {
                    if (data) {
                        setData(data?.filter(session=>{
                            if (session?.resource_info?.is_active_elive === '1') {
                                return session
                            }
                        }).map(session=>({
                            ...session.resource_info,
                            updated: moment(new Date(session?.resource_info?.created)).format('DD MMM YYYY - hh:mm:ss'),
                        })))
                    }
                }
            }))
        }
    },[eventState])

    const View = (file) => {
        if (file) {
            const win = window.open(file, "_blank");
            win.focus();
        }
    }

    const TypeFile = (type) => {
        if (type) {
            if (['doc','docx'].includes((type).toLowerCase())) {
                return {
                    icon: 'fa-file-word',
                    type: 'word'
                }
            }
            if (['xlxs','xls','xlsb','xlsm'].includes((type).toLowerCase())) {
                return {
                    icon: 'fa-file-excel',
                    type: 'excel'
                }
            }
            if (['ppt','pptx'].includes((type).toLowerCase())) {
                return {
                    icon: 'fa-file-powerpoint',
                    type: 'pPoint'
                }
            }
            if (['png','jpg','jpeg'].includes((type).toLowerCase())) {
                return {
                    icon: 'fa-file-image',
                    type: 'fImages'
                }
            }
            if (['pdf'].includes((type).toLowerCase())) {
                return {
                    icon: 'fa-file-pdf',
                    type: 'pdf'
                }
            }
        }
        return {
            icon: 'fa-file',
            type: 'fOther'
        }
    }

    const Download = (file) => {
        if (file?.url_link) {
            saveAs(file?.url_link);
        }
    };

    return (
        data.length !== 0 &&
        <div className="row">
            <div className="col-lg-12 mb-2">
                <div className="headContent">
                    <div className="titleHead">
                        <h2>Resources &nbsp;<span>{data?.length}</span></h2>
                    </div>
                </div>
            </div>
            {
                data?.map((resource,index)=>(
                    <div className="col-lg-6" key={index.toString()}>
                        <div className="borderContent boothContent">
                            <div className="boothFile">
                                <div className="row">
                                    <div className="d-flex align-items-center">
                                        <div className="m-0 col-11 p-2 align-middle">
                                            <div className="boothFileDetail">
                                                <div className={`fileIcon ${TypeFile(resource?.category_file).type}`}>
                                                    <i className={`fas ${TypeFile(resource?.category_file).icon}`}></i>
                                                </div>
                                                <p>{ resource?.file_name }.{resource?.category_file}</p>
                                                <span>Update: {resource?.updated}</span>
                                            </div>
                                        </div>
                                        <div className="start-end col-1">
                                            <div className="boothFileAction">
                                                <div className="dropup">
                                                    <a href="#" className="dropdown-toggle fileAct" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-ellipsis-v"></i>
                                                    </a>
                                                    <ul className="dropdown-menu">
                                                        <li><span onClick={_=> Download(resource)}><i className="fas fa-download"></i> Download</span></li>
                                                        <li><span onClick={_=> View(resource?.url_link)} style={{ color: !(resource?.url_link) && "#888a8c" }}><i className="fas fa-eye" style={{ color: !(resource?.url_link) && "#888a8c" }}></i> View</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
};

export default memo(Resources);