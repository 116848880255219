import React, { memo } from 'react';
import NoImage from '../images/no-image.png'
import { convertYoutubeLink, checkingBannerStatus } from 'helpers'

const BannerEvent = memo(({ eventState }) =>{
    if (eventState) {
        let checking = checkingBannerStatus(eventState?.event_info, 'type_banner_elive')
        if ((checking === 'image')) {
            return (
                <img
                    src={eventState?.event_info?.url_banner_elive}
                    alt=""
                    draggable={false}
                    onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                />
            )
        } else if(checking === 'youtube'){
            return (
                convertYoutubeLink(eventState?.event_info, 'url_banner_elive') ?
                <iframe width="560" height="315" src={convertYoutubeLink(eventState?.event_info, 'url_banner_elive')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
                :
                <img
                    src={eventState?.event_info?.event_photo}
                    alt=""
                    draggable={false}
                    onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                />
            )
        } else if (checking === 'empty'){
            return (
                <img
                    src={eventState?.event_info?.event_photo}
                    alt=""
                    draggable={false}
                    onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                />
            )
        }
    }
});

export default BannerEvent;