import moment from 'moment';

export const checkingBannerStatus = (object, key) => {
    if (object) {
        const dataType = object?.[key]
        if (dataType) {
            if (dataType === 'image') return 'image';
            if (dataType === 'youtube') return 'youtube';
            return 'empty';
        }
    }
}

const fixDateFormat = (date) => {
    const split = date.split('/')
    const temp = split[0]
    split[0] = split[1]
    split[1] = temp
    return split.join('/')
}

export const converterDateSessionStartTime = (date) => {
    if (date) return moment(new Date(fixDateFormat(date))).format("DD MMMM YYYY")
    else return "Coming Soon"
}

export const converterDateSessionTime = (data) => {
    if (!data?.show_date) return "Coming Soon"
    if (data?.starttime) {
        if (data?.time_zone) {
            return `${moment(new Date(fixDateFormat(data?.show_date))).format("dddd,")} ${data?.starttime} ${data?.time_zone}`
        }
        return `${moment(new Date(fixDateFormat(data?.show_date))).format("dddd,")} ${data?.starttime} -`
    }
    return `${moment(new Date(fixDateFormat(data?.show_date))).format("dddd,")} -`
}