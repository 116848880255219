import React,{ Fragment } from 'react';
import '../styles/login.scss'
import ImageLogo from '../images/logo_indo_beta.png'
import ImageSecurity from '../images/security.png'

const NotFound = (props) => {
    const { title } = props

    return (
        <Fragment>
            <div className="website-logo">
                <div className="logo">
                    <img className="logo-size" src={ImageLogo} alt="livelifeindo" draggable={false}/>
                </div>
            </div>
            <div className="event-login">
                <div className="img-holder">
                    <div className="bg"
                    />
                    <div className="info-holder"
                        style={{ padding: 60 }}
                    >
                        {
                            <img
                                style={{
                                    maxWidth: '378px',
                                    boxShadow: 'none'
                                }}
                                src={ImageSecurity}
                                alt="livelifeindo" draggable={false}
                            />
                        }
                    </div>
                </div>

                <div className="form-holder">
                    <div className="form-content align-middle d-flex justify-content-center">
                        <div className="form-items">
                            <h2>
                                { title } Not Found
                            </h2>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
};

export default NotFound