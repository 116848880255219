import React,{ useState } from 'react';
import Flag from '../images/flag'
import CountryPhoneCode from '../countryPhone'
import { Dropdown } from 'react-bootstrap'

function CountryPhone(props) {
    const { countryCode, phoneCountryCode, setValue, style } = props
    const { height, width } = style
    const [search, setSearch] = useState('')

    const getFlag = (value) => {
        switch (value) {
            case 'AF': return <Flag.Afganistan style={{ height, width }}/>
            case 'AL': return <Flag.Albania style={{ height, width }}/>
            case 'DZ': return <Flag.Algeria style={{ height, width }}/>
            case 'AS': return <Flag.AmericanSamoa style={{ height, width }}/>
            case 'AD': return <Flag.Andorra style={{ height, width }}/>
            case 'AO': return <Flag.Angola style={{ height, width }}/>
            case 'AI': return <Flag.Anguilla style={{ height, width }}/>
            case 'AQ': return <Flag.Antarctica style={{ height, width }}/>
            case 'AG': return <Flag.AntiguaAndBarbuda style={{ height, width }}/>
            case 'AR': return <Flag.Argentina style={{ height, width }}/>
            case 'AM': return <Flag.Armenia style={{ height, width }}/>
            case 'AW': return <Flag.Aruba style={{ height, width }}/>
            case 'AU': return <Flag.Australia style={{ height, width }}/>
            case 'AT': return <Flag.Austria style={{ height, width }}/>
            case 'AZ': return <Flag.Azerbaijan style={{ height, width }}/>
            case 'BS': return <Flag.Bahamas style={{ height, width }}/>
            case 'BH': return <Flag.Bahrain style={{ height, width }}/>
            case 'BD': return <Flag.Bangladesh style={{ height, width }}/>
            case 'BB': return <Flag.Barbados style={{ height, width }}/>
            case 'BY': return <Flag.Belarus style={{ height, width }}/>
            case 'BE': return <Flag.Belgium style={{ height, width }}/>
            case 'BZ': return <Flag.Belize style={{ height, width }}/>
            case 'BJ': return <Flag.Benin style={{ height, width }}/>
            case 'BM': return <Flag.Bermuda style={{ height, width }}/>
            case 'BT': return <Flag.Bhutan style={{ height, width }}/>
            case 'BO': return <Flag.Bolivia style={{ height, width }}/>
            case 'BA': return <Flag.BosniaAndHerzegovina style={{ height, width }}/>
            case 'BW': return <Flag.Botswana style={{ height, width }}/>
            case 'BR': return <Flag.Brazil style={{ height, width }}/>
            case 'IO': return <Flag.BritishIndianOceanTerritory style={{ height, width }}/>
            case 'VG': return <Flag.BritishVirginIslands style={{ height, width }}/>
            case 'BN': return <Flag.Brunei style={{ height, width }}/>
            case 'BG': return <Flag.Bulgaria style={{ height, width }}/>
            case 'BF': return <Flag.BurkinaFaso style={{ height, width }}/>
            case 'BI': return <Flag.Burundi style={{ height, width }}/>
            case 'KH': return <Flag.Cambodia style={{ height, width }}/>
            case 'CM': return <Flag.Cameroon style={{ height, width }}/>
            case 'CA': return <Flag.Canada style={{ height, width }}/>
            case 'CV': return <Flag.CapeVerde style={{ height, width }}/>
            case 'KY': return <Flag.CaymanIslands style={{ height, width }}/>
            case 'CF': return <Flag.CentralAfricanRepublic style={{ height, width }}/>
            case 'TD': return <Flag.Chad style={{ height, width }}/>
            case 'CL': return <Flag.Chile style={{ height, width }}/>
            case 'CN': return <Flag.China style={{ height, width }}/>
            case 'CX': return <Flag.ChristmasIsland style={{ height, width }}/>
            case 'CC': return <Flag.CocosIslands style={{ height, width }}/>
            case 'CO': return <Flag.Colombia style={{ height, width }}/>
            case 'KM': return <Flag.Comoros style={{ height, width }}/>
            case 'CK': return <Flag.CookIslands style={{ height, width }}/>
            case 'CR': return <Flag.CostaRica style={{ height, width }}/>
            case 'HR': return <Flag.Croatia style={{ height, width }}/>
            case 'CU': return <Flag.Cuba style={{ height, width }}/>
            case 'CW': return <Flag.Curacao style={{ height, width }}/>
            case 'CY': return <Flag.Cyprus style={{ height, width }}/>
            case 'CZ': return <Flag.CzechRepublic style={{ height, width }}/>
            case 'CD': return <Flag.DemocraticRepublicOfTheCongo style={{ height, width }}/>
            case 'DK': return <Flag.Denmark style={{ height, width }}/>
            case 'DJ': return <Flag.Djibouti style={{ height, width }}/>
            case 'DM': return <Flag.Dominica style={{ height, width }}/>
            case 'DO': return <Flag.DominicanRepublic style={{ height, width }}/>
            case 'DO': return <Flag.DominicanRepublic style={{ height, width }}/>
            case 'DO': return <Flag.DominicanRepublic style={{ height, width }}/>
            case 'TL': return <Flag.EastTimor style={{ height, width }}/>
            case 'EG': return <Flag.Egypt style={{ height, width }}/>
            case 'EC': return <Flag.Ecuador style={{ height, width }}/>
            case 'GQ': return <Flag.EquatorialGuinea style={{ height, width }}/>
            case 'SV': return <Flag.ElSalvador style={{ height, width }}/>
            case 'EE': return <Flag.Estonia style={{ height, width }}/>
            case 'ER': return <Flag.Eritrea style={{ height, width }}/>
            case 'FK': return <Flag.FalklandIslands style={{ height, width }}/>
            case 'ET': return <Flag.Ethiopia style={{ height, width }}/>
            case 'FJ': return <Flag.Fiji style={{ height, width }}/>
            case 'FO': return <Flag.FaroeIslands style={{ height, width }}/>
            case 'FR': return <Flag.France style={{ height, width }}/>
            case 'FI': return <Flag.Finland style={{ height, width }}/>
            case 'GA': return <Flag.Gabon style={{ height, width }}/>
            case 'PF': return <Flag.FrenchPolynesia style={{ height, width }}/>
            case 'GE': return <Flag.Georgia style={{ height, width }}/>
            case 'GM': return <Flag.Gambia style={{ height, width }}/>
            case 'GH': return <Flag.Ghana style={{ height, width }}/>
            case 'DE': return <Flag.Germany style={{ height, width }}/>
            case 'GR': return <Flag.Greece style={{ height, width }}/>
            case 'GI': return <Flag.Gibraltar style={{ height, width }}/>
            case 'GD': return <Flag.Grenada style={{ height, width }}/>
            case 'GL': return <Flag.Greenland style={{ height, width }}/>
            case 'GT': return <Flag.Guatemala style={{ height, width }}/>
            case 'GU': return <Flag.Guam style={{ height, width }}/>
            case 'GN': return <Flag.Guinea style={{ height, width }}/>
            case 'GG': return <Flag.Guernsey style={{ height, width }}/>
            case 'GY': return <Flag.Guyana style={{ height, width }}/>
            case 'GW': return <Flag.GuineaBissau style={{ height, width }}/>
            case 'HN': return <Flag.Honduras style={{ height, width }}/>
            case 'HT': return <Flag.Haiti style={{ height, width }}/>
            case 'HU': return <Flag.Hungary style={{ height, width }}/>
            case 'HK': return <Flag.HongKong style={{ height, width }}/>
            case 'IN': return <Flag.India style={{ height, width }}/>
            case 'IS': return <Flag.Iceland style={{ height, width }}/>
            case 'IR': return <Flag.Iran style={{ height, width }}/>
            case 'ID': return <Flag.Indonesia style={{ height, width }}/>
            case 'IE': return <Flag.Ireland style={{ height, width }}/>
            case 'IQ': return <Flag.Iraq style={{ height, width }}/>
            case 'IL': return <Flag.Israel style={{ height, width }}/>
            case 'IM': return <Flag.IsleOfMan style={{ height, width }}/>
            case 'CI': return <Flag.IvoryCoast style={{ height, width }}/>
            case 'IT': return <Flag.Italy style={{ height, width }}/>
            case 'JP': return <Flag.Japan style={{ height, width }}/>
            case 'JM': return <Flag.Jamaica style={{ height, width }}/>
            case 'JO': return <Flag.Jordan style={{ height, width }}/>
            case 'JE': return <Flag.Jersey style={{ height, width }}/>
            case 'KE': return <Flag.Kenya style={{ height, width }}/>
            case 'KZ': return <Flag.Kazakhstan style={{ height, width }}/>
            case 'XK': return <Flag.Kosovo style={{ height, width }}/>
            case 'KI': return <Flag.Kiribati style={{ height, width }}/>
            case 'KG': return <Flag.Kyrgyzstan style={{ height, width }}/>
            case 'KW': return <Flag.Kuwait style={{ height, width }}/>
            case 'LV': return <Flag.Latvia style={{ height, width }}/>
            case 'LA': return <Flag.Laos style={{ height, width }}/>
            case 'LS': return <Flag.Lesotho style={{ height, width }}/>
            case 'LB': return <Flag.Lebanon style={{ height, width }}/>
            case 'LY': return <Flag.Libya style={{ height, width }}/>
            case 'LR': return <Flag.Liberia style={{ height, width }}/>
            case 'LT': return <Flag.Lithuania style={{ height, width }}/>
            case 'LI': return <Flag.Liechtenstein style={{ height, width }}/>
            case 'MO': return <Flag.Macau style={{ height, width }}/>
            case 'LU': return <Flag.Luxembourg style={{ height, width }}/>
            case 'MG': return <Flag.Madagascar style={{ height, width }}/>
            case 'MK': return <Flag.Macedonia style={{ height, width }}/>
            case 'MY': return <Flag.Malaysia style={{ height, width }}/>
            case 'MW': return <Flag.Malawi style={{ height, width }}/>
            case 'ML': return <Flag.Mali style={{ height, width }}/>
            case 'MV': return <Flag.Maldives style={{ height, width }}/>
            case 'MH': return <Flag.MarshallIslands style={{ height, width }}/>
            case 'MT': return <Flag.Malta style={{ height, width }}/>
            case 'MU': return <Flag.Mauritius style={{ height, width }}/>
            case 'MR': return <Flag.Mauritania style={{ height, width }}/>
            case 'MX': return <Flag.Mexico style={{ height, width }}/>
            case 'YT': return <Flag.Mayotte style={{ height, width }}/>
            case 'MD': return <Flag.Moldova style={{ height, width }}/>
            case 'FM': return <Flag.Micronesia style={{ height, width }}/>
            case 'MN': return <Flag.Mongolia style={{ height, width }}/>
            case 'MC': return <Flag.Monaco style={{ height, width }}/>
            case 'MS': return <Flag.Montserrat style={{ height, width }}/>
            case 'ME': return <Flag.Montenegro style={{ height, width }}/>
            case 'MZ': return <Flag.Mozambique style={{ height, width }}/>
            case 'MA': return <Flag.Morocco style={{ height, width }}/>
            case 'NA': return <Flag.Namibia style={{ height, width }}/>
            case 'MM': return <Flag.Myanmar style={{ height, width }}/>
            case 'NP': return <Flag.Nepal style={{ height, width }}/>
            case 'NR': return <Flag.Nauru style={{ height, width }}/>
            case 'AN': return <Flag.NetherlandsAntilles style={{ height, width }}/>
            case 'NL': return <Flag.Netherlands style={{ height, width }}/>
            case 'NZ': return <Flag.NewZealand style={{ height, width }}/>
            case 'NC': return <Flag.NewCaledonia style={{ height, width }}/>
            case 'NE': return <Flag.Niger style={{ height, width }}/>
            case 'NI': return <Flag.Nicaragua style={{ height, width }}/>
            case 'NU': return <Flag.Niue style={{ height, width }}/>
            case 'NG': return <Flag.Nigeria style={{ height, width }}/>
            case 'MP': return <Flag.NorthernMarianaIslands style={{ height, width }}/>
            case 'KP': return <Flag.NorthKorea style={{ height, width }}/>
            case 'NO': return <Flag.Norway style={{ height, width }}/>
            case 'OM': return <Flag.Oman style={{ height, width }}/>
            case 'PK': return <Flag.Pakistan style={{ height, width }}/>
            case 'PW': return <Flag.Palau style={{ height, width }}/>
            case 'PS': return <Flag.Palestine style={{ height, width }}/>
            case 'PA': return <Flag.Panama style={{ height, width }}/>
            case 'PG': return <Flag.PapuaNewGuinea style={{ height, width }}/>
            case 'PY': return <Flag.Paraguay style={{ height, width }}/>
            case 'PE': return <Flag.Peru style={{ height, width }}/>
            case 'PH': return <Flag.Philippines style={{ height, width }}/>
            case 'PN': return <Flag.Pitcairn style={{ height, width }}/>
            case 'PL': return <Flag.Poland style={{ height, width }}/>
            case 'PT': return <Flag.Portugal style={{ height, width }}/>
            case 'PR': return <Flag.PuertoRico style={{ height, width }}/>
            case 'PR': return <Flag.PuertoRico style={{ height, width }}/>
            case 'QA': return <Flag.Qatar style={{ height, width }}/>
            case 'CG': return <Flag.RepublicOfTheCongo style={{ height, width }}/>
            case 'RE': return <Flag.Reunion style={{ height, width }}/>
            case 'RO': return <Flag.Romania style={{ height, width }}/>
            case 'RU': return <Flag.Russia style={{ height, width }}/>
            case 'RW': return <Flag.Rwanda style={{ height, width }}/>
            case 'BL': return <Flag.SaintBarthelemy style={{ height, width }}/>
            case 'SH': return <Flag.SaintHelena style={{ height, width }}/>
            case 'KN': return <Flag.SaintKittsAndNevis style={{ height, width }}/>
            case 'LC': return <Flag.SaintLucia style={{ height, width }}/>
            case 'MF': return <Flag.SaintMartin style={{ height, width }}/>
            case 'PM': return <Flag.SaintPierreAndMiquelon style={{ height, width }}/>
            case 'VC': return <Flag.SaintVincentAndTheGrenadines style={{ height, width }}/>
            case 'WS': return <Flag.Samoa style={{ height, width }}/>
            case 'SM': return <Flag.SanMarino style={{ height, width }}/>
            case 'ST': return <Flag.SaoTomeAndPrincipe style={{ height, width }}/>
            case 'SA': return <Flag.SaudiArabia style={{ height, width }}/>
            case 'SN': return <Flag.Senegal style={{ height, width }}/>
            case 'RS': return <Flag.Serbia style={{ height, width }}/>
            case 'SC': return <Flag.Seychelles style={{ height, width }}/>
            case 'SL': return <Flag.SierraLeone style={{ height, width }}/>
            case 'SG': return <Flag.Singapore style={{ height, width }}/>
            case 'SX': return <Flag.SintMaarten style={{ height, width }}/>
            case 'SK': return <Flag.Slovakia style={{ height, width }}/>
            case 'SI': return <Flag.Slovenia style={{ height, width }}/>
            case 'SB': return <Flag.SolomonIslands style={{ height, width }}/>
            case 'SO': return <Flag.Somalia style={{ height, width }}/>
            case 'ZA': return <Flag.SouthAfrica style={{ height, width }}/>
            case 'KR': return <Flag.SouthKorea style={{ height, width }}/>
            case 'SS': return <Flag.SouthSudan style={{ height, width }}/>
            case 'ES': return <Flag.Spain style={{ height, width }}/>
            case 'LK': return <Flag.SriLanka style={{ height, width }}/>
            case 'SD': return <Flag.Sudan style={{ height, width }}/>
            case 'SR': return <Flag.Suriname style={{ height, width }}/>
            case 'SJ': return <Flag.SvalbardAndJanMayen style={{ height, width }}/>
            case 'SZ': return <Flag.Swaziland style={{ height, width }}/>
            case 'SE': return <Flag.Sweden style={{ height, width }}/>
            case 'CH': return <Flag.Switzerland style={{ height, width }}/>
            case 'SY': return <Flag.Syria style={{ height, width }}/>
            case 'TW': return <Flag.Taiwan style={{ height, width }}/>
            case 'TJ': return <Flag.Tajikistan style={{ height, width }}/>
            case 'TZ': return <Flag.Tanzania style={{ height, width }}/>
            case 'TH': return <Flag.Thailand style={{ height, width }}/>
            case 'TG': return <Flag.Togo style={{ height, width }}/>
            case 'TK': return <Flag.Tokelau style={{ height, width }}/>
            case 'TO': return <Flag.Tonga style={{ height, width }}/>
            case 'TT': return <Flag.TrinidadAndTobago style={{ height, width }}/>
            case 'TN': return <Flag.Tunisia style={{ height, width }}/>
            case 'TR': return <Flag.Turkey style={{ height, width }}/>
            case 'TM': return <Flag.Turkmenistan style={{ height, width }}/>
            case 'TC': return <Flag.TurksAndCaicosIslands style={{ height, width }}/>
            case 'TV': return <Flag.Tuvalu style={{ height, width }}/>
            case 'VI': return <Flag.USVirginIslands style={{ height, width }}/>
            case 'UG': return <Flag.Uganda style={{ height, width }}/>
            case 'UA': return <Flag.Ukraine style={{ height, width }}/>
            case 'AE': return <Flag.UnitedArabEmirates style={{ height, width }}/>
            case 'GB': return <Flag.UnitedKingdom style={{ height, width }}/>
            case 'US': return <Flag.UnitedStates style={{ height, width }}/>
            case 'UY': return <Flag.Uruguay style={{ height, width }}/>
            case 'UZ': return <Flag.Uzbekistan style={{ height, width }}/>
            case 'VU': return <Flag.Vanuatu style={{ height, width }}/>
            case 'VA': return <Flag.Vatican style={{ height, width }}/>
            case 'VE': return <Flag.Venezuela style={{ height, width }}/>
            case 'VN': return <Flag.Vietnam style={{ height, width }}/>
            case 'WF': return <Flag.WallisAndFutuna style={{ height, width }}/>
            case 'EH': return <Flag.WesternSahara style={{ height, width }}/>
            case 'YE': return <Flag.Yemen style={{ height, width }}/>
            case 'ZM': return <Flag.Zambia style={{ height, width }}/>
            case 'ZW': return <Flag.Zimbabwe style={{ height, width }}/>
            default:
                break;
        }
    }

    const handleSearch = (data) => {
        if(data){
            if (((data.country).toLowerCase()).includes((search).toLowerCase()) || ((data.phoneCode).toLowerCase()).includes((search).toLowerCase())) {
                return data
            }
        }
    }

    return (
        <Dropdown className="selectable-form-dropdown-flag" >
            <Dropdown.Toggle className="form-control selectable-form-control">
                { getFlag(countryCode) }
            </Dropdown.Toggle>

            <Dropdown.Menu className="selectable-form-control-body" >
                <input type="text" placeholder="Search" onChange={e=> setSearch(e.target.value)}/>
                <div className="selectable-form-control-content" >
                    {
                        CountryPhoneCode
                        .filter(data=> search ? handleSearch(data) : data)
                        .map((data, i)=>(
                            <Dropdown.Item key={i} className="selectable-form-control-item"
                                onClick={_=> setValue({ phone: data.phoneCode, country: data.ISOShort }, 'phoneCountryCode')}
                                style={{
                                    backgroundColor: countryCode === data.ISOShort && '#6d76df',
                                    color: countryCode === data.ISOShort && 'white'
                                }}
                            >
                                { getFlag(data.ISOShort) } { data.phoneCode }
                            </Dropdown.Item>
                        ))
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CountryPhone;