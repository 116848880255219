import axios from 'axios'
import { Query } from '../../helpers/query'
import {
    SERVER
} from '../../api'

export const topicFind = (query = { id_discussion, id_user }, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/discussions/find?token=${process.env.REACT_APP_API_TOKEN}&${Query(query)}`,
        })
        if (data.status) {
            cb(true, data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, [])
                    }
                }
            }
        }
    }
}

export const topicList = (query = { id_idea, id_user, topic, type, limit, offset, order_by, group_by }, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER}/discussions/list?token=${process.env.REACT_APP_API_TOKEN}&${Query(query)}`,
        })
        if (data.status) {
            cb(true, data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, [])
                    }
                }
            }
        }
    }
}

export const topicCreate = (sendData = { id_idea, id_user, topic, type, discussion }, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${SERVER}/discussions/create?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(true, data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, [])
                    }
                }
            }
        }
    }
}

export const topicUpdate = (sendData = { id_discussion, id_idea, id_user, topic, type, discussion, is_delete }, cb) => async dispatch => {
    try {
        const { data } = await axios({
            method: 'PUT',
            url: `${SERVER}/discussions/update?token=${process.env.REACT_APP_API_TOKEN}`,
            data: sendData
        })
        if (data.status) {
            cb(true, data.result)
        }
    } catch (error) {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    let { status, message } = error.response.data
                    if (message === 'Not Found' && status === false) {
                        cb(true, [])
                    }
                }
            }
        }
    }
}