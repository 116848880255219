import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.js"
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import 'react-intl-tel-input/dist/main.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './styles/global.scss'
import { Route ,  Switch, useHistory } from 'react-router-dom';
import Customize from './screens/Customize' // new
import Homepage from './screens/Home'
import Login from './screens/Login'
import Forgot from './screens/forgot'
import Register from './screens/Register'
import MainStage from './screens/MainStage'
import Scan from './screens/Scan'
import Stage from './screens/Stage'
import Booth from './screens/booth'
import BoothDetail from './screens/boothDetail'
import Watch from './screens/Watch/index'
import People from './screens/People'
import Forum from './screens/Forum'
import Inbox from './screens/inboxNew'
import EditProfile from './screens/EditProfile'
import NotFound from './screens/404'
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { checkingId } from './store/actions/checking';
import { getVenues } from './store/actions/option';
import { getAttendee, getEventByKey, getSessionsUpcoming, getPolling, getPollingAnswersByUser } from './store/actions/event';
import { useToasts } from 'react-toast-notifications'
import { useQuery } from './plugins/useQuery'

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['account']);
  const { addToast } = useToasts()
  const History = useHistory()
  const Query = useQuery()
  const Dispatch = useDispatch()
  const Profile = useSelector(state=>state.cacheState.profile)
  const eventState = useSelector(state=> state.eventState.event )
  const pollingDashboard = useSelector(state=> state.eventState.pollingDashboard )
  const [pollList, setPollList] = useState([]);
  const [pollAnswer, setPollAnswer] = useState(false);
  const [nextPoll, setNextPoll] = useState(false);
  const [pollAnswerQuest, setPollAnswerQuest] = useState(null);
  const [pollAnswerQuestTotaly, setPollAnswerQuestTotaly] = useState(null);

  useEffect(()=>{
    if (!cookies['account']) {
      if (
        !(History.location.pathname).includes('/login') &&
        !(History.location.pathname).includes('/register') &&
        !(History.location.pathname).includes('/forgot') &&
        !(History.location.pathname).includes('/scan') &&
        !(History.location.pathname).includes('/forum') &&
        !(History.location.pathname).includes('/not-found')
      ) {
        History.replace('/login?event=')
      }
    } else if (cookies['account']){
      let exp = new Date();
      exp.setDate(exp.getDate()+3);
      Dispatch(checkingId(cookies['account'],{ cookies, setCookie, removeCookie, History, addToast, expired: exp }))
      Dispatch(getVenues())
      Dispatch(getAttendee({
        keyword : null,
        id_idea : cookies['event'],
        id_user : null,
        id_status : null,
        limit : '30',
        offset : '0',
        order_by: 'created ASC',
        is_checkedin: 1
      }))
      Dispatch(getSessionsUpcoming({
        limit : '6',
        offset: '0',
        order_by : 'show_date ASC, TIME(starttime) ASC, TIME(endtime) ASC, ll_programmes.approved ASC',
        // time: 'upcoming',
        id_idea: cookies['event']
      }))
      Dispatch(getPolling({
        id_idea: cookies['event'],
        survey_type: 'poll',
        is_active_elive: 1,
        order_by: 'll_surveys.created ASC',
      }))
      Dispatch(getEventByKey(cookies['event']))
    } 
  },[])

  useEffect(()=>{
    if (cookies['account']){
      if (
        History.location.pathname === '/' ||
        History.location.pathname.includes('/login')
      ) {
        History.push('/home')
      }
    } else {
      if (
        History.location.pathname.includes('/register') ||
        History.location.pathname.includes('/forgot')
      ) {
        History.replace(`/login?event=${Query.get('event')}`)
      }
    }
  },[History.location.pathname])

  useEffect(()=>{
    if (pollingDashboard.length !== 0) {
        let listPolls = []
        try {
            pollingDashboard.forEach(async (poll,index)=>{
                if (poll.question_info) {
                    await Dispatch(getPollingAnswersByUser({ id_survey: poll.question_info.id_survey, id_user: cookies['account'], id_survey_question: poll.question_info.id_survey_question }, (status, data)=>{
                        if (!status) {
                            listPolls.push(poll.question_info)
                        }
                    }))
                    if (index === pollingDashboard.length-1) {
                        setPollList(listPolls)
                    }
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
  },[pollingDashboard])

  useEffect(()=>{
    if (pollAnswer === true) {
      setTimeout(() => {
        let changing = pollList
        changing.shift()
        setPollList(changing)
        setPollAnswer(false)
        setPollAnswerQuest(null)
        setPollAnswerQuestTotaly(null)
      }, 5 * 60 * 1000);
    }
  },[pollAnswer])

  useEffect(()=>{
    if (nextPoll === true) {
      let changing = pollList
      changing.shift()
      setPollList(changing)
      setNextPoll(false)
      setPollAnswerQuest(null)
      setPollAnswerQuestTotaly(null)
    }
  },[nextPoll])

  useEffect(()=>{
    if (eventState) {
      if (eventState.event_info) {
        if (History.location.pathname === '/home') {
          document.title = 'Home | ' + eventState.event_info.title
        }
        if (History.location.pathname === '/stage') {
          document.title = 'Stage | ' + eventState.event_info.title
        }
        if (History.location.pathname === '/people') {
          document.title = 'People | ' + eventState.event_info.title
        }
        if (History.location.pathname === '/inbox') {
          document.title = 'Inbox | ' + eventState.event_info.title
        }
        if (History.location.pathname === '/edit-profile') {
          document.title = 'Edit Profile | ' + eventState.event_info.title
        }
        if (History.location.pathname === '/customize') {
          document.title = 'Customize | ' + eventState.event_info.title
        }
      }
    } else {
      document.title = 'Welcome | Connecting People with Activities'
    }
  },[eventState, History.location.pathname])

  return (
    <div className="App" id="AppScroolBar">
      <Switch>
        <Route path="/customize" component={Customize} />
        <Route path="/main-stage" component={MainStage} />
        <Route path="/edit-profile" component={EditProfile} />
        <Route path="/inbox" component={Inbox} />
        <Route path="/forum" component={Forum} />
        <Route path="/people" component={People} />
        <Route path="/watch/q" component={Watch} />
        <Route path="/booth-detail" component={BoothDetail} />
        <Route path="/booth" component={Booth} />
        <Route path="/stage" component={Stage} />
        <Route path="/scan" component={Scan} />
        <Route path="/register" component={Register} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/login" component={Login} />
        <Route path="/home" component={()=>
          <Homepage
            pollList={pollList}
            pollAnswer={pollAnswer}
            setPollAnswer={setPollAnswer}
            pollAnswerQuest={pollAnswerQuest}
            setPollAnswerQuest={setPollAnswerQuest}
            pollAnswerQuestTotaly={pollAnswerQuestTotaly}
            setPollAnswerQuestTotaly={setPollAnswerQuestTotaly}
            setNextPoll={setNextPoll}
          />
        } />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
