import React,{ Fragment, useEffect, useState } from 'react';
import '../../styles/login.scss'
import { useHistory, Link } from 'react-router-dom'
import ImageLogo from '../../images/logo_indo_beta.png'
import NoImage from '../../images/no-image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '../../plugins/useQuery'
import { getEventByKey } from '../../store/actions/event'
import { scanning, checkingIdScanning } from '../../store/actions/checking'
import NotFound from '../404'
import Loading from '../../components/Loading'
import Alert from '../../components/Alert'
import CryptoJS from "crypto-js";
import Scanner from "../../components/scanner";
import { useToasts } from 'react-toast-notifications';

const Scan = () => {
    const { addToast } = useToasts()
    const Query = useQuery()
    const History = useHistory()
    const Dispatch = useDispatch()
    const [notFoundPages, setNotFoundPages] = useState(true)
    const [price, setPrice] = useState(null)
    const Event = useSelector(state=> state.eventState.event)
    const [alertProps, setAlertProps] = useState({
        text: '', //string
        variant: '', //danger //warning //success
        show: false, //true //false
        close: true, //true //false,
        loading: false,
        link: '',
        decoded: false
    });
    
    useEffect(()=>{
        sessionStorage.removeItem('EL_DECODED')
        if (!Query.get('event')) {
            setNotFoundPages(null)
        } else if (Query.get('event') && !Event) {
            Dispatch(getEventByKey(Query.get('event')))
        }
    },[Query.get('event')])

    useEffect(()=>{
        if (Event == '404') {
            setNotFoundPages(null)
        } else {
            if (Event) {
                if (Event.event_pricing) {
                    let prices = Event.event_pricing
                    let status = 'free'
                    prices.forEach(({price})=>{
                        if (Number(price) !== 0) {
                            status = 'paid'
                            return
                        }
                    })
                    setPrice(status)
                }
            }
        }
    },[Event])

    const getValue = (data, key) => {
        if (data) {
            if (data.event_info) {
                if (data.event_info[key]) {
                    if (key === 'venue_name') {
                        return `${data.event_info[key]}, `
                    }
                    if (key === 'venue_address') {
                        return `${data.event_info[key]}, `
                    }
                    return data.event_info[key]
                }
            }
        }
        return ''
    }

    const onScanSuccess = (decodedText, decodedResult) => {
        if (decodedText !== sessionStorage.getItem('EL_DECODED')) {
            Dispatch(scanning({ decoded: decodedText, event: Query.get('event')}, (status, data)=>{
                if (status) {
                    let { registrant_info } = data
                    var encryptUser = CryptoJS.AES.encrypt(JSON.stringify({ id: registrant_info.id_user }), 'elive-livelife22').toString();
                    sessionStorage.setItem('EL_DECODED', decodedText)
                    addToast('Success Scan', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    setAlertProps({
                        ...alertProps,
                        text: `You're Success to check-in. Please check your email! ${registrant_info.email}`,
                        link: `/login?event=${Query.get('event')}&auth=${encryptUser}`,
                        variant: 'success',
                        show: true,
                        close: false,
                        loading: true,
                    })
                } else {
                    sessionStorage.removeItem('EL_DECODED')
                    setAlertProps({
                        ...alertProps,
                        text: `${data}`,
                        variant: 'danger',
                        show: true,
                        close: true,
                        link: '',
                    })
                }
            }))
        }
    }

    function onScanFailure(error) {
        console.warn(`Code scan error = ${error}`);
        setAlertProps({
            ...alertProps,
            text: `${error}`,
            variant: 'danger',
            show: true,
            close: true,
            link: '',
        })
    }

    const resetCode = () => {
        sessionStorage.removeItem('EL_DECODED')
        setAlertProps({
            ...alertProps,
            text: '', 
            variant: '', 
            show: false, 
            close: true,
            loading: false,
            link: '',
            decoded: !alertProps.decoded
        })
    }

    return (
        notFoundPages ?
        <Fragment>
            <Loading status={Event}/>
            <div className="website-logo">
                <div className="logo">
                    <img className="logo-size" src={ImageLogo} alt="livelifeindo" draggable={false} />
                </div>
            </div>
            <div className="event-login">
                <div className="img-holder">
                    <div className="bg"
                        style={{
                            backgroundImage: Query.get('event') && `url(${getValue(Event, 'event_photo')})`
                        }}
                    />
                    <div className="info-holder">
                        {
                            <img
                                src={Query.get('event') && getValue(Event, 'event_photo')}
                                alt="livelifeindo" draggable={false}
                                onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                            />
                        }
                    </div>
                </div>

                <div className="form-holder">
                    <div className="form-content align-middle d-flex justify-content-center">
                        <div className="form-items">
                            <h2>
                                {
                                    Query.get('event') &&
                                    getValue(Event, 'title')
                                }
                            </h2>
                            <p style={{ marginBottom: 2 }} >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'venue_name')} ${getValue(Event, 'venue_address')} ${getValue(Event, 'venue_city')}`
                                }
                            </p>
                            <p className="regist-timeD" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - ${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                }
                            </p>
                            <p className="regist-timeM" style={{ marginBottom: 2 }}>
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_date')} ${getValue(Event, 'event_time')}`
                                }
                                {
                                    Query.get('event') &&
                                    (getValue(Event, 'event_end_date') && getValue(Event, 'event_end_time')) &&
                                    ` - `
                                }
                            </p>
                            <p className="regist-timeM" >
                                {
                                    Query.get('event') &&
                                    `${getValue(Event, 'event_end_date')} ${getValue(Event, 'event_end_time')}`
                                } 
                            </p>
                            {/* <div className="page-links">
                                <span onClick={_=> History.push('/login')}>Login</span>
                                <span onClick={_=> History.push('/register')}>Register</span>
                                <span className="active">Scan QR</span>
                            </div> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span className="option-section-3871" style={{ cursor: 'pointer' }} onClick={_=> History.push(`/login?event=${Query.get('event')}`) }>
                                    <FontAwesomeIcon icon={faCaretLeft} /> Back to login
                                </span>
                                {
                                    sessionStorage.getItem('EL_DECODED') &&
                                    <span className="option-section-3871" style={{ cursor: 'pointer' }}
                                        onClick={resetCode}
                                    >
                                        Reset
                                    </span>
                                }
                            </div>
                            <Scanner 
                                fps={10}
                                qrbox={250}
                                disableFlip={false}
                                qrCodeSuccessCallback={onScanSuccess}
                            />
                            <Alert
                                show={alertProps.show}
                                text={alertProps.text}
                                variant={alertProps.variant}
                                setAlertProps={setAlertProps}
                                close={alertProps.close}
                                link={alertProps.link}
                            />
                            {/* <div className="option-section-3871" >
                                Don't have an account? {
                                    price === 'free' ?
                                    <span
                                        // onClick={_=> History.push(`/register?event=${Query.get('event')}`) }
                                        className="disabled"
                                    > Register </span>
                                    :
                                    price === 'paid' ?
                                        <span
                                            // onClick={_=> goToNewTab()}
                                            className="disabled"
                                        > Register </span>
                                    :
                                    <span> Register </span>
                                }
                            </div> */}
                            {
                                alertProps.link && 
                                <div className="form-button"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Link
                                        className="ibtn"
                                        to={alertProps.link}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '5px 10px',
                                            fontWeight: 'normal'
                                        }}
                                    >
                                        Click Here to <b style={{marginLeft: 5}}>AutoLogin</b>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
        :
        <NotFound
            title={'Event'}
        />
    );
};

export default Scan