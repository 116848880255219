import React from 'react';
import '../styles/loading.scss' 

function Loading(props) {
    const {
        status,
    } = props
    return (
        <div className={`loading-container d-flex justify-content-center align-items-center ${status && "hidden-loading"}`}>
            <div className="spinner-border spinner-custom"/>
        </div>
    );
}

export default Loading;